<template>
  <el-dialog :title="title" :visible.sync="dialogFormVisible" width="800px" @close="close" v-if="dialogFormVisible"
    :close-on-click-modal="false">
    <el-row class="el-dialog-div-box">
      <el-form ref="form" :rules="rules" :model="form" :validateOnRuleChange="false" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="中文名称" prop="nameCn">
              <el-input v-model="form.nameCn" placeholder="输入中文名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="英文名称" prop="nameEn">
              <el-input v-model="form.nameEn" placeholder="输入英文名称" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="印刷条件" prop="pricingName">
              <el-select v-model="form.pricingName" placeholder="请选择" collapse-tags filterable value-key="id"
                @change="pricingNameChange">
                <el-option v-for="(item, index) in proValuationData" :key="index" :label="item.nameCn"
                  :value="item.nameCn"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-row :gutter="10">
              <el-col :span="24">
                <el-form-item>
                  <template slot="label">
                    <i style="color: #ff4d4f">*</i>
                    计价方式
                  </template>
                  <el-select v-model="value1" placeholder="请选择" multiple collapse-tags>
                    <el-option v-for="(item, index) in pricingValue" :key="index" :label="item.pricingValue"
                      :value="item.pricingValue"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="4">
                <el-button @click="addValutionClick">新增</el-button>
              </el-col> -->
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="printingPositionIds" label="印刷位">
              <el-select v-model="form.printingPositionIds" placeholder="请选择" multiple collapse-tags>
                <el-option v-for="(item, index) in proPrintData" :key="index" :label="item.nameEn"
                  :value="item.productSettingId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="适用分类" prop="categoryNames">
              <el-cascader :options="options" v-model="form.categoryNames" @change="handleChange" :props="optionProps"
                collapse-tags ref="cascaderAddr"></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="border-bottom: 1px solid #ededed" class="mt10"></el-row>
      </el-form>
      <!-- table -->
      <el-row>
        <el-table :data="tableData" class="w100 mt30 table-height">
          <el-table-column prop="pricingValue" label="计价方式" width="180" align="center" />
          <el-table-column label="单价" prop="price" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.price" placeholder="请输入价格" size="normal" clearable
                onkeyup="this.value= this.value.match(/\d+(\.\d{0,3})?/) ? this.value.match(/\d+(\.\d{0,3})?/)[0] : ''" />
            </template>
          </el-table-column>
          <el-table-column label="版费价格" prop="toolingCharge" align="center">
            <template #default="{ row }">
              <el-input v-model="row.toolingCharge" placeholder="请输入版费" size="normal"
                onkeyup="this.value= this.value.match(/\d+(\.\d{0,3})?/) ? this.value.match(/\d+(\.\d{0,3})?/)[0] : ''"
                clearable />
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="mt30 mb30" type="flex">
        <label style="width: 100px">备注信息</label>
        <el-input v-model="remark" type="textarea" maxlength="500" show-word-limit style="white-space: pre-wrap"
          :autosize="{ minRows: 2 }" placeholder="请输入500个字符以内" />
      </el-row>
    </el-row>
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" :loading="loading" @click="save">
          确认
        </el-button>
        <el-button @click="close">取消</el-button>
      </el-row>
    </template>
    <PublicModel ref="PublicModel" @public-model="getDetailByType4" />
    <ProValutionModel ref="valutionModel" @fetch-data="getDetailByType2" />
  </el-dialog>
</template>

<script>
  import { getDetailByType } from '@/api/product/productSetting'
  import {
    insertPO,
    updatePO,
    getDetail,
    categoryListForSelect,
  } from '@/api/product/productPrintWay'
  import PublicModel from '@/views/product/ProductSetting/components/public-model'

  import ProValutionModel from './ProValutionModel'
  import { deepCopy } from 'kits'
  export default {
    name: 'ProPrintWayModel',
    components: {
      PublicModel,
      ProValutionModel,
    },
    data() {
      return {
        title: '印刷方式',
        form: { remark: '', pricingId: '', categoryIds: [], categoryNames: '' },
        rules: {
          nameCn: [
            { required: true, trigger: 'blur', message: '请输入中文名称' },
          ],
          nameEn: [
            { required: true, trigger: 'blur', message: '请输入英文名称' },
            {
              validator: this.$formValidation.englishName,
              trigger: 'blur',
            },
          ],
          categoryNames: [
            { required: true, message: '请选择适用分类', trigger: 'blur' },
          ],
          pricingName: [
            { required: true, message: '请选择印刷条件', trigger: 'blur' },
          ],
          pricingValues: [
            { required: true, message: '请选择计价方式', trigger: 'blur' },
          ],
          printingPositionIds: [
            { required: true, message: '请选择印刷位', trigger: 'blur' },
          ],
        },
        dialogFormVisible: false,
        options: [], //分类下拉
        optionProps: {
          value: 'productCategoryId',
          label: 'nameCn',
          children: 'children',
          checkStrictly: false,
          multiple: true,
        },
        proValuationData: [], //计价方式2
        pricingValue: [], //印刷位（计价方式的value）
        proMaterialData: [], //产品材质数据4
        proPrintData: [], //印刷条件6
        isPrintiBol: true, //默认印刷位是不可用，需先选择印刷条件
        //----------------------------------------------------------------
        loading: false,
        bcglXiangXiList: [],
        oldTest: [], //旧值
        newTest: [],
        printWayId: '',
        pricingNameBol: true, //计价下拉是否可用，根据印刷条件是否有值来判断
        testId: '',
        value1: [],
        remark: '',
        pricingId: '', //选中的印刷条件id
        categoryIds: '', //适用分类
        testEn: [],
        testCn: [],
      }
    },
    watch: {
      value1(newVal) {
        if (Array.isArray(newVal)) {
          this.pricingValue.forEach((item) => {
            if (!newVal.includes(item.pricingValue)) {
              item.price = ''
              item.toolingCharge = ''
            }
          })
        }
      },
    },
    computed: {
      tableData() {
        return this.pricingValue.filter((item) =>
          this.value1.includes(item.pricingValue)
        )
      },
    },
    mounted() {
      this.init()
    },
    methods: {
      removeTag(tag) { },
      handleChange(val) {
        let checksNode = this.$refs['cascaderAddr'].getCheckedNodes()
        this.form.categoryIds = checksNode.map((item) => {
          return item.value
        })
      },
      init() {
        // 获取产品计价下拉信息(用于印刷条件和计价方式两个下拉框)
        this.getDetailByType2()
        // 获取产品材质下拉信息
        this.getDetailByType4()
        // 获取产品印刷下拉信息
        this.getDetailByType6()
        //使用分类下拉
        this.getCategoryListForSelect()
      },
      async getDetailByType2(nameCn) {
        let response = await getDetailByType({ type: 2 })
        if ((response.code === '000000')) {
          this.proValuationData = response.data
        }
        if (nameCn) {
          this.pricingNameChange(nameCn)
        }
      },
      async getDetailByType4() {
        let response = await getDetailByType({ type: 4 })
        if ((response.code === '000000')) {
          this.proMaterialData = response.data
        }
      },
      async getDetailByType6() {
        let response = await getDetailByType({ type: 5 })
        if ((response.code === '000000')) {
          this.proPrintData = response.data
        }
      },
      async getCategoryListForSelect() {
        let response = await categoryListForSelect()
        if ((response.code === '000000')) {
          this.options = response.data
        }
      },
      // 印刷条件选择,赋值给计价方式
      pricingNameChange(nameCn) {
        this.pricingValue = []
        this.value1 = []
        let mate = this.proValuationData.filter(
          (item) => item.nameCn === nameCn
        )
        this.pricingId = mate[0].productSettingId
        this.form.pricingId = this.pricingId
        let test = mate[0].value.split(',')
        this.testCn = test
        this.testEn = mate[0].valueEn && mate[0].valueEn.split(',')
        test.forEach((item) => {
          let obj = {
            productPrintingPricingOptionsId: '',
            price: null,
            pricingValue: item,
            toolingCharge: null,
          }
          this.pricingValue.push(obj)
        })
      },

      // //展示弹框
      proPrintWayModel(id) {
        if (!id) {
        } else {
          this.printWayId = id
          this.getDetail(id)
        }
        this.dialogFormVisible = true
      },
      // 编辑回显
      async getDetail(id) {
        this.testId = id
        let response = await getDetail({ productPrintingPricingId: id })
        this.form = response.data
        this.value1 = this.form.pricingValues.split(',')
        this.form.printingPositionIds = this.form.printingPositionIds.split(',')

        this.form.categoryIds = this.form.categoryIds.split(',')
        this.form.categoryNames = JSON.parse(this.form.categoryNames)

        this.pricingId = response.data.pricingId
        this.remark = this.form.remark
        if (this.form.pricingName) {
          let mate = this.proValuationData.filter(
            (item) => item.nameCn === this.form.pricingName
          )
          this.testCn = mate?.[0]?.value.split(',') ?? []
          this.testEn = mate?.[0]?.valueEn.split(',') ?? []
          let pricingValueArr = mate?.[0]?.value?.split(',')
          let pricingValueArr2 = []
          let tableData = response.data.optionsVOList
          if (Array.isArray(pricingValueArr)) {
            pricingValueArr.forEach((item) => {
              let obj = {
                productPrintingPricingOptionsId: '',
                price: '',
                pricingValue: item,
                toolingCharge: '',
              }
              pricingValueArr2.push(obj)
            })
          }

          const duplicateKey = tableData.map((item) => item.pricingValue)
          const newTable = pricingValueArr2
            .filter((item) => !duplicateKey.includes(item.pricingValue))
            .concat(tableData)
          this.pricingValue = newTable
        }
      },
      // 关闭
      close() {
        this.remark = ''
        this.value1 = []
        this.pricingValue = []
        this.pricingNameBol = true
        this.$refs['form'].resetFields()
        this.form = {}
        this.printWayId = ''
        this.dialogFormVisible = false
      },
      setJJFSywm(arr) {
        return arr.map((item, index) => {
          let obj = deepCopy(item)
          let idx = this.testCn.findIndex((v) => item.pricingValue === v)
          obj.pricingValueEn = this.testEn && this.testEn[idx]
          return obj
        })
      },

      // 保存
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (!this.value1.length) {
              return this.$baseMessage(
                '请选择计价方式',
                'warning',
                false,
                'erp-hey-message-warning'
              )
            }
            if (!this.form.printingPositionIds.length) {
              return this.$baseMessage(
                '请选择印刷位',
                'warning',
                false,
                'erp-hey-message-warning'
              )
            }
            // 接口入参
            const params = { ...this.form }
            if (params.categoryIds?.length) {
              params.categoryIds = params.categoryIds.join()
              delete params.categoryNames
            }

            params.pricingValues = this.value1.join(',')
            params.printingPositionIds = this.form.printingPositionIds.join(',')
            params.remark = this.remark
            let tableData = deepCopy(this.tableData)
            tableData.forEach((element) => {
              element.price = element.price?.toString()
              element.toolingCharge = element.toolingCharge?.toString()
              if (typeof element.price === 'string') {
                if (element.price && element.price.includes('.')) {
                  if (element.price.split('.')[1].length > 3) {
                    let test = element.price.split('.')[1].substring(0, 3)
                    element.price =
                      element.price.split('.')[0] + '.'.concat(test)
                  }
                }
              }
              if (typeof element.toolingCharge === 'string') {
                if (
                  element.toolingCharge &&
                  element.toolingCharge.includes('.')
                ) {
                  if (element.toolingCharge.split('.')[1].length > 3) {
                    let test = element.toolingCharge
                      .split('.')[1]
                      .substring(0, 3)
                    element.toolingCharge =
                      element.toolingCharge.split('.')[0] + '.'.concat(test)
                  }
                }
              }
            })
            tableData.forEach((item) => {
              item.price =
                item.price && item.price !== 0 ? Number(item.price) : ''
              item.toolingCharge =
                item.toolingCharge && item.toolingCharge !== 0
                  ? Number(item.toolingCharge)
                  : ''
            })

            //新增接口
            params.optionsDTOList = this.setJJFSywm(tableData)
            if (params.optionsVOList) {
              delete params.optionsVOList
            }

            let response
            this.loading = true
            if (this.printWayId === '') {
              response = await insertPO(params)
              this.loading = false
              if ((response.code === '000000')) {
                this.$baseMessage(
                  '操作成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
                this.$emit('fetch-data')
                this.close()
              }
            } else {
              response = await updatePO(params)
              this.loading = false
              if ((response.code === '000000')) {
                this.$baseMessage(
                  '操作成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
                this.$emit('fetch-data')
                this.close()
              }
            }
          }
        })
      },
      // 匹配材质新增（调产品设置里面的公共弹框）
      addMaterialClick(str, row) {
        this.$refs['PublicModel'].showAddEdit(str, row)
      },
      // 计价方式新增
      addValutionClick() {
        if (!this.form.pricingName || this.form.pricingName === '') {
          return this.$baseMessage(
            '请先选择印刷条件',
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        this.$refs['valutionModel'].valutionModel(
          this.form.pricingName,
          this.pricingValue,
          this.pricingId
        )
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-dialog-div-box {
    height: 50vh;
    overflow: hidden;
    overflow-y: auto;

    >.el-row,
    >.el-form {
      width: 95%;
      margin: 0 auto;
    }
  }

  ::v-deep {

    .el-select,
    .el-cascader {
      width: 100%;
    }

    .el-textarea__inner {
      padding: 5px 50px 5px 10px;
    }

    .logMgCls {
      .el-select__tags {
        >span {
          display: flex;
        }
      }
    }

    .el-select__tags-text {
      display: inline-block;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    // .el-select .el-tag__close.el-icon-close {
    //   top: -5px;
    // }
  }
</style>
