<template>
  <el-row>
    <div class="product-info">
      <el-row :gutter="40">
        <el-col :span="24" style="overflow: hidden">
          <el-row>
            <el-row type="flex" align="middle">
              <h3 class="product-nameCn">{{ productInfo.nameEn }}</h3>
              <el-tag
                class="ml10"
                :type="
                  productInfo.standardCustomized === '订制品' ? 'success' : ''
                "
              >
                {{ productInfo.standardCustomized }}
              </el-tag>
              <p class="f_s_12 ml20">产品编码：{{ productInfo.productCode }}</p>
            </el-row>
          </el-row>

          <el-row class="pro-base-msg mt15">
            <h4>基础信息</h4>
            <el-row type="flex" align="middle" class="mt15">
              <el-col :span="8">
                <label>中文名称：</label>
                <span>{{ productInfo.nameCn }}</span>
              </el-col>
              <el-col :span="8">
                <label>产品类型：</label>
                <span>{{ productInfo.standardCustomized }}</span>
              </el-col>
              <el-col :span="8">
                <label>产品来源：</label>
                <span>{{ productInfo.createWay | createWayFilter }}</span>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle" class="mt15">
              <el-col :span="8">
                <label>单位：</label>
                <span>{{ productInfo.productUnit }}</span>
              </el-col>
              <el-col :span="8">
                <label>材质：</label>
                <span>{{ productInfo.material || '--' }}</span>
              </el-col>
              <el-col :span="8">
                <el-row type="flex" align="middle">
                  <label>产品类别：</label>
                  <tooltip-over
                    class="c_pointer tooltip-span"
                    v-if="productInfo.category"
                    :content="productInfo.category || '--'"
                    refName="tooltipOver1"
                  ></tooltip-over>
                </el-row>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle" class="mt15">
              <el-col :span="8">
                <label>产线：</label>
                <span>
                  {{ productInfo.productionLine || '--' }}({{
                    productInfo.productionLineOperation
                  }})
                </span>
              </el-col>
              <el-col :span="8">
                <label class="special-label">客户信用额度适用：</label>
                <el-switch
                  v-model="productInfo.creditLimit"
                  :active-value="1"
                  :inactive-value="0"
                  disabled
                ></el-switch>
              </el-col>
            </el-row>
          </el-row>

          <el-row class="mt15">
            <!-- 供应商列表 -->
            <h4 class="mt20">供应商列表</h4>
            <el-table
              :data="productInfo.productSupplierVOList"
              border
              style="width: 100%"
              class="mt10"
            >
              <el-table-column
                type="index"
                width="50"
                label="序号"
                align="center"
              ></el-table-column>
              <el-table-column prop="supplierCode" label="编码" align="center">
                <template slot-scope="scope">
                  <span class="blue-text">
                    <router-link
                      target="_blank"
                      :to="`/client-supplier/supplier-manage-detail?supplierId=${
                        scope.row.supplierId
                      }&noReturn=${true}`"
                    >
                      {{ scope.row.supplierCode }}
                    </router-link>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="supplierName"
                label="名称"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column prop="quantity" label="起订数量" align="center">
                <template slot-scope="scope">
                  {{ scope.row.quantity || '--' }}
                </template>
              </el-table-column>
              <el-table-column
                prop="price"
                label="价格"
                align="center"
                width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.price || '--' }}
                </template>
              </el-table-column>
              <el-table-column
                prop="major"
                label="是否主要"
                align="center"
                width="100"
              >
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.major"
                    :active-value="1"
                    :inactive-value="0"
                    disabled
                  ></el-switch>
                </template>
              </el-table-column>
              <el-table-column
                prop="creator"
                label="添加人"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.creatorName || '--' }}
                </template>
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="添加时间"
                align="center"
                width="180"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime || '--' }}
                </template>
              </el-table-column>
            </el-table>

            <!-- 英文描述中文描述 -->
            <el-row class="mt20">
              <el-col :span="24">
                <el-row type="flex">
                  <label class="no-wrap">英文描述：</label>
                  <p style="white-space: pre-line; word-break: break-all">
                    {{ productInfo.descriptionEn || '--' }}
                  </p>
                </el-row>
              </el-col>
            </el-row>
            <el-row class="mt20" v-if="productInfo.descriptionCn">
              <el-col :span="24">
                <el-row type="flex" align="top">
                  <label class="no-wrap">中文描述：</label>
                  <p style="white-space: pre-line; word-break: break-all">
                    {{ productInfo.descriptionCn || '--' }}
                  </p>
                </el-row>
              </el-col>
            </el-row>
            <el-row class="mt20 test" type="flex">
              <label class="no-wrap">关键词：</label>
              <p style="white-space: pre-line; word-break: break-all; flex: 1">
                {{ productInfo.keyWord || '--' }}
              </p>
            </el-row>
            <el-row
              class="mt20 label-content"
              v-if="productInfo.productKeyShowVOList"
            >
              <h4>产品标签</h4>
              <el-row
                v-for="(item, index) of tableData"
                :key="index"
                type="flex"
                class="mt10"
              >
                <label>{{ item.nameCn }}：</label>
                <p
                  v-if="
                    item.keyChildEditVOList &&
                    item.keyChildEditVOList.length > 0
                  "
                >
                  <span
                    v-for="(child, i) in item.keyChildEditVOList"
                    :key="i"
                    v-show="child.isShow"
                  >
                    {{ child.nameCn || '--' }}
                  </span>
                </p>
              </el-row>
            </el-row>
          </el-row>
        </el-col>
      </el-row>
      <!-- 规格列表 -->
      <product-info-spec
        :productInfo="productInfo"
        ref="productInfoSpec"
      ></product-info-spec>
      <!-- 产品计价 -->
      <template v-if="!productInfo.oldFlag">
        <el-row class="mt20">
          <el-col :span="24">
            <h4>产品计价</h4>
            <el-row type="flex" justify="space-between" class="mb20 mt10">
              <el-col :span="24">
                <!-- 开启三位小数-->
                <span class="mr10">
                  {{ $t('productDetail.Enable3ecimalPlaces') }}
                </span>
                <el-switch
                  v-model="productInfo.priceScale"
                  disabled
                  :active-value="3"
                  :inactive-value="2"
                ></el-switch>
                <span class="f_s_12 ml15">
                  Tip:{{ $t('productDetail.Enable3ecimalPlacestIips') }}
                </span>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              justify="space-between"
              class="mb20 mt10"
              v-if="productInfo.standardCustomized === '标准品'"
            >
              <el-col :span="24">
                <!-- 展示Black价格-->
                <span class="mr10">
                  {{ $t('productDetail.ShowBlankPrice') }}
                </span>
                <el-switch
                  v-model="productInfo.showBlank"
                  disabled
                  :active-value="1"
                  :inactive-value="0"
                ></el-switch>
                <span class="f_s_12 ml15">
                  Tip:{{ $t('productDetail.ShowBlankPriceTip') }}
                </span>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle" class="mt15">
              <p>
                <label>成本价：</label>
                <span>{{ productInfo.costPrice }}</span>
              </p>
              <p class="ml20">
                <label>汇率：</label>
                <span>{{ productInfo.exchange }}</span>
              </p>
              <p class="ml20">
                <label>美金价：</label>
                <span>
                  {{
                    (productInfo.costPrice / productInfo.exchange).toFixed(2)
                  }}
                </span>
              </p>
              <p class="ml20">
                <label>{{ $t('productDetail.ShippingMethod') }}：</label>
                <span>
                  {{
                    settings === 'zh'
                      ? transportationType.nameCn
                      : transportationType.nameEn
                  }}
                </span>
              </p>
              <p class="ml20">
                <label>价格利润率：</label>
                <span>
                  {{ profitRateTemplateNameCn }}
                </span>
              </p>
              <!-- <p class="ml20">
              <label>税率：</label>
              <span>{{ productInfo.taxRate }}</span>
            </p> -->
            </el-row>
          </el-col>
        </el-row>
        <el-row class="mt10" v-if="productInfo.skuSettingForProduct == 1">
          <el-col :span="23">
            <!-- 装箱信息 -->
            <el-row :gutter="20">
              <!-- 装箱数量 -->
              <el-col :span="4">
                {{ $t('productDetail.PackingQuantity') }}：{{
                  productInfo.numberPerBox
                }}
              </el-col>
              <!-- 毛重 -->
              <el-col :span="6">
                {{ $t('productDetail.GrossWeight') }}：{{
                  productInfo.boxWeightKgs
                }}
                KGS
                <el-tooltip
                  class="item"
                  effect="light"
                  :content="
                    (productInfo.boxWeightKgs * 2.2046).toFixed(1) + ' LBS'
                  "
                  placement="top"
                >
                  <i class="el-icon-warning-outline cl5"></i>
                </el-tooltip>
              </el-col>
              <el-col :span="10" v-if="transportType != 2">
                <!-- 装箱尺寸 -->
                {{ $t('productDetail.PackingSize') }}：{{
                  productInfo.boxLengthCm
                }}
                X {{ productInfo.boxWidthCm }} X
                {{ productInfo.boxHeightCm }} CM
                <el-tooltip
                  class="item"
                  effect="light"
                  :content="
                    productInfo.boxLengthIn +
                    ' X ' +
                    productInfo.boxWidthIn +
                    ' X ' +
                    productInfo.boxHeightIn +
                    ' IN'
                  "
                  placement="top"
                >
                  <i class="el-icon-warning-outline cl5"></i>
                </el-tooltip>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <!-- 数量价格阶梯 总和计算 -->
        <el-row class="mt15">
          <el-col :span="24">
            <!-- <h4>数量价格阶梯/总和计算</h4> -->
            <el-table
              border
              :data="productInfo.productLadderPriceVOList"
              style="width: 100%"
              :key="tableKey"
            >
              <!-- 数量 -->
              <el-table-column
                prop="num"
                :label="$t('productDetail.Quantity')"
                align="center"
                fixed="left"
              >
                <template slot-scope="scope">{{ scope.row.num }}</template>
              </el-table-column>
              <!-- 利润率 -->
              <el-table-column
                prop="profitRate"
                :label="$t('productDetail.ProfitRate')"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.profitRate }}%
                </template>
              </el-table-column>
              <el-table-column
                prop="profitRate"
                :label="$t('productDetail.FirstLegFreightUnitPrice') + '($)'"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.transportHeadPrice }}
                </template>
              </el-table-column>
              <!-- Net Price($) -->
              <el-table-column prop="netPrice" align="center">
                <template slot="header">
                  <p style="text-align: center">Blank</p>
                  <p style="text-align: center">
                    {{ $t('productDetail.NetPrice') + '($)' }}
                    <!-- <el-tooltip class="item" effect="light" placement="top">
                    <template slot="content">
                      <p>
                        ({{ $t('productDetail.USDprice') }} +
                        {{ $t('productDetail.FirstLegFreightUnitPrice') }} ÷
                        {{ $t('productDetail.ExchangeRate') }}) ÷ (1 -
                        {{ $t('productDetail.ProfitRate') }})
                      </p>
                    </template>
                    <i class="el-icon-warning-outline c_pointer"></i>
                  </el-tooltip> -->
                  </p>
                </template>
                <template slot-scope="scope">
                  <span>{{ scope.row.netPrice }}</span>
                  <el-tooltip class="item" effect="light" placement="top">
                    <template slot="content">
                      <div>
                        ({{ $t('productDetail.USDprice') }} +
                        {{ $t('productDetail.FirstLegFreightUnitPrice') }}) ÷ (1
                        - {{ $t('productDetail.ProfitRate') }})
                        <br />
                        ({{
                          (
                            productInfo.costPrice /
                            (productInfo.exchange || this.rate)
                          ).toFixed(2)
                        }}
                        +
                        {{ scope.row.transportHeadPrice }}
                        ) ÷ ( 1 -
                        {{ scope.row.profitRate }}%)
                      </div>
                    </template>
                    <i
                      class="el-icon-warning-outline c_pointer"
                      style="margin-left: 5px"
                    ></i>
                  </el-tooltip>
                </template>
              </el-table-column>
              <!-- List Price($) -->
              <el-table-column prop="listPrice" align="center">
                <template slot="header">
                  <p style="text-align: center">Blank</p>
                  <p style="text-align: center">
                    {{ $t('productDetail.ListPrice') + '($)' }}
                    <el-tooltip class="item" effect="light" placement="top">
                      <template slot="content">
                        <p>Blank Net Price($) ÷ {{ productInfo.priceRate }}</p>
                      </template>
                      <i class="el-icon-warning-outline c_pointer"></i>
                    </el-tooltip>
                  </p>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.listPrice }}
                </template>
              </el-table-column>

              <!-- 出运申报单价($) -->
              <el-table-column
                prop="shippingPrice"
                :label="$t('productDetail.ShippingDeclarationUnitPrice')"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.shippingPrice }}
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </template>
      <template v-else>
        <el-row class="mt20">
          <el-col :span="24">
            <h4>产品计价</h4>

            <el-row type="flex" align="middle" class="mt15">
              <p>
                <label>成本价：</label>
                <span>{{ productInfo.costPrice }}</span>
              </p>
              <p class="ml20">
                <label>汇率：</label>
                <span>{{ rate }}</span>
              </p>
              <p class="ml20">
                <label>美金价：</label>
                <span>{{ (productInfo.costPrice / 6.5).toFixed(2) }}</span>
              </p>
            </el-row>
          </el-col>
        </el-row>
        <el-row class="mt10" v-if="productInfo.skuSettingForProduct == 1">
          <el-col :span="23">
            <!-- 装箱信息 -->
            <el-row :gutter="20">
              <!-- 装箱数量 -->
              <el-col :span="4">
                {{ $t('productDetail.PackingQuantity') }}：{{
                  productInfo.numberPerBox
                }}
              </el-col>
              <!-- 毛重 -->
              <el-col :span="4">
                {{ $t('productDetail.GrossWeight') }}：{{
                  productInfo.boxWeightKgs
                }}KGS
                <el-tooltip
                  class="item"
                  effect="light"
                  :content="
                    (productInfo.boxWeightKgs * 2.2046).toFixed(1) + ' LBS'
                  "
                  placement="top"
                >
                  <i class="el-icon-warning-outline cl5"></i>
                </el-tooltip>
              </el-col>
              <el-col :span="6">
                <!-- 装箱尺寸 -->
                {{ $t('productDetail.PackingSize') }}：{{
                  productInfo.boxLengthCm
                }}
                X {{ productInfo.boxWidthCm }} X
                {{ productInfo.boxHeightCm }} CM
                <el-tooltip class="cl5" effect="light" placement="top">
                  <div slot="content">
                    <span v-if="productInfo.boxLengthIn">
                      {{ productInfo.boxLengthIn }}&nbsp;x&nbsp;
                    </span>
                    <span v-if="productInfo.boxWidthIn">
                      {{ productInfo.boxWidthIn }}&nbsp;x&nbsp;
                    </span>
                    <span v-if="productInfo.boxHeightIn">
                      {{ productInfo.boxHeightIn }}
                    </span>
                    <span>IN</span>
                  </div>
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <!-- 数量价格阶梯 总和计算 -->
        <el-row class="mt15">
          <el-col :span="24">
            <el-table
              border
              :data="productInfo.productPricingStepVOList"
              style="width: 100%"
            >
              <el-table-column prop="num" label="数量" align="center">
                <template slot-scope="scope">{{ scope.row.num }}</template>
              </el-table-column>

              <el-table-column prop="profitRate" label="利润率" align="center">
                <template slot-scope="scope">
                  {{ scope.row.profitRate }}
                </template>
              </el-table-column>
              <el-table-column
                prop="taxRate"
                label="关税率"
                align="center"
              ></el-table-column>

              <el-table-column prop="tax" label="关税" align="center">
                <template slot-scope="scope">{{ scope.row.tax }}</template>
              </el-table-column>
              <el-table-column
                prop="price"
                label="产品价($)"
                align="center"
                width="120"
              >
                <template slot-scope="scope">{{ scope.row.price }}</template>
              </el-table-column>
              <el-table-column
                prop="totalPackingVolume"
                label="装箱总体积(m³)"
                align="center"
                width="140"
              >
                <template slot-scope="scope">
                  {{ scope.row.totalPackingVolume }}
                </template>
              </el-table-column>
              <el-table-column
                prop="totalPackingWeight"
                label="装箱总重量(Kg)"
                align="center"
                width="120"
              >
                <template slot-scope="scope">
                  {{ scope.row.totalPackingWeight }}
                </template>
              </el-table-column>
              <el-table-column
                prop="transportWay"
                label="运输方式"
                align="center"
                width="180"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.transportNameCn || '--' }}
                </template>
              </el-table-column>
              <el-table-column
                prop="transportPrice"
                label="运费单价($)"
                align="center"
                width="120"
              >
                <template slot-scope="scope">
                  {{ scope.row.transportPrice || '--' }}
                </template>
              </el-table-column>
              <el-table-column
                prop="totalTransportPrice"
                label="运费总价($)"
                align="center"
                width="120"
              >
                <template slot-scope="scope">
                  {{ scope.row.totalTransportPrice || '--' }}
                </template>
              </el-table-column>
              <el-table-column
                prop="netPrice"
                label="Net Price($)"
                align="center"
                width="120"
              >
                <template slot-scope="scope">{{ scope.row.netPrice }}</template>
              </el-table-column>
              <el-table-column
                prop="listPrice"
                label="List Price($)"
                align="center"
                width="120"
              >
                <template slot-scope="scope">
                  {{ scope.row.listPrice }}
                </template>
              </el-table-column>
              <el-table-column
                prop="totalNetPrice"
                label="Net 总价($)"
                align="center"
                width="120"
              >
                <template slot-scope="scope">
                  {{ scope.row.totalNetPrice || '--' }}
                </template>
              </el-table-column>
              <el-table-column
                prop="totalListPrice"
                label="List 总价($)"
                align="center"
                width="120"
              >
                <template slot-scope="scope">
                  {{ scope.row.totalListPrice || '--' }}
                </template>
              </el-table-column>
              <el-table-column
                prop="shippingPrice"
                label="出运申报单价($)"
                align="center"
                width="140"
              >
                <template slot-scope="scope">
                  {{ scope.row.shippingPrice }}
                </template>
              </el-table-column>
              <el-table-column
                prop="produceDay"
                label="生产周期"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.produceDay || '' }}
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </template>
      <el-row class="mt15">
        <product-info-spec-add-price
          :productInfo="productInfo"
          ref="productInfoSpecAddPrice"
        ></product-info-spec-add-price>
      </el-row>
      <el-row class="mt20" type="flex">
        <el-col :span="3">
          {{ $t('productDetail.SupportProofing') }}：
          <el-switch v-model="supportProofingBol" disabled></el-switch>
        </el-col>
        <el-col :span="5" v-if="supportProofingBol">
          {{ $t('productDetail.ProofingFee') }}($)：{{
            productInfo.proofingFee
          }}&nbsp;&nbsp; ({{ $t('productDetail.ShippingIsNotIncluded') }})
        </el-col>
        <el-col :span="3" v-if="supportProofingBol">
          {{ $t('productDetail.ProofingCycle') }}：
          {{ productInfo.proofingDay }}
          {{ $t('productDetail.WorkingDay') }}
        </el-col>
      </el-row>
      <!-- 印刷区域 -->
      <product-operation-detail
        :productInfo="productInfo"
      ></product-operation-detail>
      <!-- 印刷计价 -->
      <el-row class="mt20">
        <el-col :span="24">
          <h4>印刷计价</h4>
          <pro-print-calc-info
            ref="printCalc"
            :productInfo="productInfo"
          ></pro-print-calc-info>
          <!-- additional -->
          <el-row class="mt20">
            <product-additonal-price
              ref="productAdditionalPrice"
              :productInfo="productInfo"
            ></product-additonal-price>
          </el-row>
        </el-col>
      </el-row>

      <!-- 报关信息 -->
      <el-row class="mt20">
        <el-col
          :span="23"
          v-if="
            productInfo.productSpecsDeclareVOList &&
            productInfo.productSpecsDeclareVOList.length > 0
          "
        >
          <!-- 报关信息 -->
          <h4>报关信息</h4>
          <price-declare-info
            ref="priceDeclare"
            :productInfo="productInfo"
          ></price-declare-info>
        </el-col>
      </el-row>
      <el-row class="mt20">
        <h4 class="mb20">主图</h4>
        <el-row v-if="productInfo.imgMain" style="margin-left: -10px">
          <el-image
            class="img-main"
            :src="productInfo.imgMain"
            scale-down
            fit="fit"
            @click="handlePreview(productInfo.imgMain)"
          ></el-image>
        </el-row>
        <el-row v-else>暂无</el-row>
      </el-row>
      <el-row class="mt20" v-if="imgSwiperFileList.length > 0">
        <h4 class="mb20">细节图</h4>
        <el-row>
          <el-image
            fit="fit"
            v-for="(item, index) of imgSwiperFileList"
            :key="index"
            v-show="!item.isMain"
            class="img-swiper c_pointer"
            :src="item.url"
            @click="handlePreview(item.url)"
          ></el-image>
        </el-row>
      </el-row>
      <el-row class="mt20" v-if="productCutImgList.length > 0">
        <h4 class="mb20">刀模图</h4>
        <div class="flie-list" v-for="(item, i) in productCutImgList" :key="i">
          <i class="el-icon-document"></i>
          <a class="page-link ml5">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.name"
              placement="top-start"
            >
              <span class="ell" style="max-width: 200px">
                <router-link
                  :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                  target="_blank"
                >
                  {{ item.name }}
                </router-link>
              </span>
            </el-tooltip>
          </a>
          <span class="size ml10">
            {{ utils.calculateFileSize(item.size) }}
          </span>
          <span class="ml10">{{ item.time }}</span>
        </div>
      </el-row>
      <!-- 备注 -->
      <el-row class="mt20" v-if="productInfo.remark">
        <el-col :span="24">
          <el-row type="flex">
            <span class="no-wrap">备注&emsp;</span>
            <p style="white-space: pre-wrap">{{ productInfo.remark }}</p>
          </el-row>
        </el-col>
      </el-row>

      <!-- 附件 -->
      <el-row class="mt20">
        <h4>附件</h4>
        <div v-show="fileList.length" class="mt15">
          <div class="flie-list" v-for="(item, i) in fileList" :key="i">
            <i class="el-icon-document"></i>
            <a class="page-link ml5">
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.name"
                placement="top-start"
              >
                <span class="ell">
                  <router-link
                    :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                    target="_blank"
                  >
                    {{ item.name }}
                  </router-link>
                </span>
              </el-tooltip>
            </a>
            <span class="size ml10">
              {{ utils.calculateFileSize(item.size) }}
            </span>
            <span class="ml10">{{ item.time }}</span>
          </div>
        </div>
        <div class="mt15" v-show="!fileList.length">无</div>
      </el-row>
      <!-- YouTube视频 -->
      <el-row class="mt20" v-if="productInfo.youtubeId">
        <el-col>
          <span style="font-size: 14px; font-weight: bold">
            YouTube {{ $t('productDetail.YouTubeVideoID') }}:{{
              productInfo.youtubeId
            }}
          </span>
          <el-button
            type="text"
            @click="viewVideoFn(productInfo.youtubeId)"
            style="margin-left: 20px; font-size: 14px"
          >
            {{ $t('productCenter.ViewVideo') }}
          </el-button>
        </el-col>
      </el-row>
      <!-- 2.2.0新增详情，hadPlatFormData为true并且detailInfo有值时展示 -->
      <PlatformProductDetails
        v-if="productInfo.hadPlatFormData && detailInfo"
        type="2"
        :detailInfo="detailInfo"
        markOptions
      />
      <!--查看视频 -->
      <el-dialog
        :visible.sync="dialogVisible"
        width="50%"
        append-to-body
        @close="youTubeVideoClose"
      >
        <YouTubeVideo :playerId="playerId" ref="youTubeVideoRef"></YouTubeVideo>
      </el-dialog>
      <ProductSpecListModel :formData="currentRow" ref="productSpecListModel" />
      <!-- 查看标签 -->
      <ProLabelApplyModel ref="ProLabelApplyModel" />

      <!-- 图片预览 -->
      <el-image-viewer
        v-if="showViewer"
        :on-close="closeViewer"
        :url-list="[imgUrl]"
      />
    </div>
  </el-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  import {
    ProductListInteractor,
    auditInteractor,
    PlatformProductListInteractor,
  } from '@/core'
  import { listProductSetting } from '@/api/product/productSetting'
  import ProductSpecListModel from '@/views/product/ProductView/components/ProductSpecListModel'
  import ProLabelApplyModel from '@/views/product/ProductView/components/ProLabelApplyModel'
  import tooltipOver from '@/components/base-tooltip'
  import { supplierAuditStatus } from '@/utils/en-match-zh'
  import ProPrintCalcInfo from '@/views/product/ProductView/components/proPrintCalcInfo'
  import ProductInfoSpec from '@/views/product/ProductView/components/productInfoSpec'
  import ProductOperationDetail from '@/views/product/ProductView/components/ProductOperationDetail'

  import PriceDeclareInfo from '@/views/product/ProductView/components/priceDeclareInof'
  import ProductInfoSpecAddPrice from '@/views/product/ProductView/components/productInfoSpecAddPrice'
  import productAdditonalPrice from '@/views/product/ProductView/components/productAdditonalPrice'

  import YouTubeVideo from '@/components/YouTubeVideo'
  import PlatformProductDetails from '@/views/product/platformProduct/components/PlatformProductDetails'
  export default {
    name: 'ProductDetail',
    components: {
      ProductSpecListModel,
      ProLabelApplyModel,
      tooltipOver,
      ProPrintCalcInfo,
      ProductInfoSpec,
      PriceDeclareInfo,
      ProductInfoSpecAddPrice,
      productAdditonalPrice,
      ProductOperationDetail,
      YouTubeVideo,
      PlatformProductDetails,
    },
    props: {
      detailId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        dialogVisible: false, //查看视频
        playerId: '', //视频id
        remark1: '',
        remark2: '',
        productInfo: {},
        currentRow: {},
        printWay: [],
        productPrintingWayVOList: [],
        currentIndex: 0,
        productSpecsShowVOList: [],
        tableSpecsType: [],
        proPrintData: [],
        labelTableData: [],
        productKeyEditVOList: [],
        categoryId: '',
        isSample: false,
        showViewer: false, //图片预览参数
        imgUrl: '', //图片预览url
        // calcFreightBol: true, //是否计算运费
        fileList: [], //附件
        tableData: [],
        testData: [],
        testDataWidth: 70,
        rate: null, //汇率
        imgSwiperFileList: [],
        productCutImgList: [],
        tableKey: 1,
        profitRateTemplateNameCn: '', //价格利润率模版名称
        profitMarginOptions: [],
        showBol: false,
        chooseSize: 0,
        transportType: 0,
        detailInfo: {}, // 传递给PlatformProductDetails组件的数据
      }
    },
    // 状态过滤
    filters: {
      createWayFilter(status) {
        const createWay = {
          0: '自行新增',
          1: '生产系统',
          2: '询盘生成',
        }
        return createWay[status]
      },
    },
    computed: {
      ...mapGetters({
        settings: 'settings/language',
      }),
      supportProofingBol() {
        if (
          (this.productInfo.proofingFee &&
            this.productInfo.proofingFee != '0.00') ||
          this.productInfo.proofingDay
        ) {
          return true
        } else {
          return false
        }
      },
      typeArr: {
        get() {
          if (this.productInfo.specsType) {
            let typearr = this.productInfo.specsType.split(',')
            return typearr
          }
        },
      },
      specArray: {
        get() {
          if (this.productInfo.specsType && this.productInfo.specsValue) {
            let typearr = this.productInfo.specsType.split(',')
            let svalue = this.productInfo.specsValue
            let repalcevalue = svalue.replace(/\'/g, '"')
            let valarr = [[]]
            valarr = JSON.parse(repalcevalue)

            let arr = []
            for (let i = 0; i < typearr.length; i++) {
              let obj = {}
              Object.defineProperty(obj, 'type', {
                value: typearr[i],
              })
              Object.defineProperty(obj, 'value', {
                value: valarr[i],
              })
              arr.push(obj)
            }
            return arr
          }
        },
      },
      // 运输类型
      transportationType() {
        const obj = {
          1: () => ({
            nameCn: '空运',
            nameEn: 'Air Transport',
          }),
          2: () => ({
            nameCn: '海运',
            nameEn: 'Ocean Shipping',
          }),
          3: () => ({
            nameCn: '快递',
            nameEn: 'Express',
          }),
        }
        if ([1, 2, 3].includes(this.productInfo?.transportType * 1)) {
          return obj[this.productInfo?.transportType]()
        } else {
          return {
            nameCn: '',
            nameEn: '',
          }
        }
      },
      // 运输周期表格数据
      deliveryPeriodList() {
        let tableList = [
          {
            nameCn1: '头程',
            nameEn1: 'FirstLeg',
            price: '',
            transportDay: '',
            oldPrice: 0,
            tip: '',
          },
          {
            nameCn1: '尾程',
            nameEn1: 'LastLeg',
            price: '',
            transportDay: '',
            oldPrice: 0,
            tip: '',
          },
        ]
        tableList.map((item, index) => {
          item.transportDay = this.productInfo?.productTransportVO?.transportDay
          if (index === 0) {
            item.price =
              this.productInfo?.productTransportVO?.transportHeadPrice
            item.oldPrice = this.productInfo?.productTransportVO?.avgHeadPrice
          } else {
            item.price =
              this.productInfo?.productTransportVO?.transportTailPrice
            item.oldPrice = this.productInfo?.productTransportVO?.avgTailPrice
          }
          if (this.productInfo?.skuSettingForProduct == 1) {
            if (this.transportType == 2 || this.chooseSize == 1) {
              item.tip = `${item.oldPrice} * ${this.productInfo?.boxWeightKgs} ÷ ${this.productInfo?.numberPerBox}`
            } else {
              item.tip = `${item.oldPrice} * ( ${
                this.productInfo?.boxLengthCm
              } * ${this.productInfo?.boxWidthCm} * ${
                this.productInfo?.boxHeightCm
              } ÷ ${this.transportType == 1 ? 6000 : 5000}) ÷ ${
                this.productInfo?.numberPerBox
              }`
            }
          } else {
            if (this.transportType == 2 || this.chooseSize == 1) {
              item.tip = `${item.oldPrice} * ${this.productInfo?.productSpecsShowVOList?.[0]?.boxWeightKgs} ÷ ${this.productInfo?.productSpecsShowVOList?.[0]?.numberPerBox}`
            } else {
              item.tip = `${item.oldPrice} * ( ${
                this.productInfo?.productSpecsShowVOList?.[0]?.boxLengthCm
              } * ${
                this.productInfo?.productSpecsShowVOList?.[0]?.boxWidthCm
              } * ${
                this.productInfo?.productSpecsShowVOList?.[0]?.boxHeightCm
              } ÷ ${this.transportType == 1 ? 6000 : 5000}) ÷ ${
                this.productInfo?.productSpecsShowVOList?.[0]?.numberPerBox
              }`
            }
          }

          return item
        })
        return tableList
      },
      FirstLegTipShoe() {
        if (this.transportType === 2) {
          return this.$t('productDetail.FirstLegTip')
        } else if (this.transportType === 1) {
          return this.$t('productDetail.FirstLegTip1')
        } else {
          return this.$t('productDetail.FirstLegTip2')
        }
      },
      LastLegTipShoe() {
        if (this.transportType === 2) {
          return this.$t('productDetail.LastLegTip')
        } else if (this.transportType === 1) {
          return this.$t('productDetail.LastLegTip1')
        } else {
          return this.$t('productDetail.LastLegTip2')
        }
      },
    },
    watch: {
      settings() {
        this.tableKey++
      },
      detailId: {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) return
          this.getProductDetail(value)
        },
      },
    },
    created() {
      this.getListProductSettingFn()
      //供应商付款方式下拉
      this.pubApi.getCurrency({ financeName: '美元' }).then((response) => {
        if (response.code === '000000') {
          this.rate = response.data
        }
      })
    },
    methods: {
      // 查看视频
      viewVideoFn(id) {
        this.playerId = id
        this.dialogVisible = true
      },
      // 关闭视频
      youTubeVideoClose() {
        this.$refs.youTubeVideoRef.stopVideo()
      },
      //产品详情
      async getProductDetail(value) {
        let response = await ProductListInteractor.getDetailForShowApi({
          productId: value,
        })
        if (response.code === '000000') {
          this.productInfo = response.data
          if (response.data.hadPlatFormData) {
            this.getPlatformProductDetailsInfo(response.data.productId) // 获取getPlatformProductDetails组件所需详情
          }
          this.tableKey++
          this.$nextTick(() => {
            this.$refs.printCalc.detailInfo(this.productInfo)
            this.$refs.productInfoSpec.detailInfo(this.productInfo)
            this.$refs.productInfoSpecAddPrice.detailInfo(this.productInfo)
            this.$refs.productAdditionalPrice.detailInfo(this.productInfo)
          })
          this.tableSpecsType = []

          if (
            this.productInfo?.procuctStepForPrintingVOList &&
            this.productInfo?.procuctStepForPrintingVOList?.length
          ) {
            this.testData = []

            let test = this.productInfo.procuctStepForPrintingVOList
            test.forEach((item, index) => {
              if (item.setUpCharge) {
                if (item.setUpCharge.length >= 4) {
                  this.testDataWidth = 100
                }
                let test2 = []
                for (let i = 0; i < item.setUpCharge.length; i++) {
                  if (item.setUpCharge[i].printWayValueList.length) {
                    test2.push(item.setUpCharge[i])
                  }
                }
                item.setUpCharge = test2
              }
            })
            this.testData = test
          }
          //图片
          if (
            this.productInfo.productFileShowVOList &&
            this.productInfo.productFileShowVOList.length
          ) {
            this.productInfo.productFileShowVOList.forEach((item, index) => {
              if (item.url === this.productInfo.imgMain) {
                item.isMain = true
              }
            })

            this.imgSwiperFileList =
              this.productInfo.productFileShowVOList.filter((it) => {
                return it.type === '细节图'
              })
            this.productCutImgList =
              this.productInfo.productFileShowVOList.filter((it) => {
                return it.type === '刀模图'
              })

            this.productCutImgList = this.productCutImgList.map((it) => {
              return {
                ...it,
                ...JSON.parse(it.jsonContent),
              }
            })
          }
          if (this.productInfo.specsType) {
            let specsType = this.productInfo.specsType.split(',')
            specsType.forEach((item) => {
              this.tableSpecsType.push(item)
            })
          }
          if (this.productInfo.productKeyShowVOList) {
            this.productKeyEditVOList = this.productInfo.productKeyShowVOList
          }
          //标签
          if (this.productInfo.categoryId) {
            this.categoryId = this.productInfo.categoryId
            this.getListForPopupByCategoryId()
          }
          //附件
          this.fileList = this.productInfo.file
            ? JSON.parse(this.productInfo.file)
            : []
          //印刷方式
          if (this.productInfo.printingWay) {
            let test = this.productInfo.printingWay.split('#')
            if (test[0] === '1') {
              this.printWay.push({
                productPrintingPricingId: '99991',
                nameCn: 'Blank',
                nameEn: 'Blank',
              })
            }
            if (test[1] === '1') {
              this.printWay.push({
                productPrintingPricingId: '99992',
                nameCn: 'Sample',
                nameEn: 'Sample',
              })
            }
            let yin = []
            yin = JSON.parse(test[2])
            if (yin.length) {
              yin.forEach((item) => {
                this.printWay.push(item)
              })
            }
            if (this.productInfo.productPrintingWayVOList) {
              this.productPrintingWayVOList =
                this.productInfo.productPrintingWayVOList
              this.productPrintingWayVOList.forEach((item, index) => {
                item.nameCn = yin[index].nameCn
                this.getListProductSettingByIds(item.printingPositions, index)
              })
            }
          }
          //利润率模版名称
          const interestRateObj = this.profitMarginOptions.filter((item) => {
            return (
              item.productSettingId === this.productInfo.profitRateTemplateId
            )
          })?.[0]
          this.profitRateTemplateNameCn = interestRateObj?.nameCn
          if (this.productInfo?.skuSettingForProduct == 1) {
            const { size } = this.getPackingSize(
              this.productInfo?.productTransportVO?.transportType,
              this.productInfo
            )
            this.chooseSize = size
          } else {
            const { size } = this.getPackingSize(
              this.productInfo?.productTransportVO?.transportType,
              this.productInfo?.productSpecsShowVOList?.[0]
            )
            this.chooseSize = size
          }
          this.transportType =
            this.productInfo.productTransportVO?.transportType * 1
          this.showBol = true
        }
      },
      // 获取装箱尺寸大小
      getPackingSize(type, obj) {
        const unit = type * 1 === 1 ? 6000 : 5000
        let packingSize = 0
        let size = 1 //1是毛重大 2是装箱尺寸大
        if (!obj.boxLengthCm || !obj.boxWidthCm || !obj.boxHeightCm) {
          packingSize = 0
        } else {
          packingSize =
            (Number(obj.boxLengthCm) *
              Number(obj.boxWidthCm) *
              Number(obj.boxHeightCm)) /
            unit
        }
        if (packingSize > Number(obj.boxWeightKgs)) size = 2
        return {
          packingSize,
          size,
        }
      },
      async getListProductSettingFn() {
        let response = await listProductSetting()
        if (response.code === '000000') {
          this.profitMarginOptions = response.data.filter(
            (item) => item.type === 10
          )
        }
      },
      //旧标签显示
      async getListForPopupByCategoryId() {
        let that = this
        let response = await ProductListInteractor.listForPopupByCategoryIdApi({
          categoryId: this.categoryId,
        })

        //判断标签中是否有关联标签
        let initData = response.data
        let initKeyParentId = [] //标签的id
        let initlinkedParentIds = [] //标签的关联id
        let requestParentIds = []
        const keyParentIds = initData.map((i) => i.keyParentId)
        initData.forEach((item) => {
          item.linkedParentIds &&
            initlinkedParentIds.push(...item.linkedParentIds.split(','))
        })
        initlinkedParentIds.forEach((item) => {
          if (keyParentIds.includes(item)) {
            requestParentIds.push(item)
          }
        })
        let isNoRelate = requestParentIds.length //标签内部是否有关联其他标签
        if (isNoRelate) {
          if ((response.code = '000000')) {
            response.data.forEach((item, index) => {
              that.productKeyEditVOList.forEach((item2, index2) => {
                item2.childKeyIds.split(',').forEach((item3, index3) => {
                  item.keyChildEditVOList.forEach((item4, index4) => {
                    if (item3 === item4.keyChildId) {
                      item4.isShow = true
                    }
                  })
                })
              })
            })

            this.tableData = response.data.map((item) => {
              if (item.multiple == '1') {
                return {
                  ...item,
                  value: [],
                }
              } else {
                return {
                  ...item,
                  value: '',
                }
              }
            })
            if (this.labelList?.length) {
              this.tableData = this.labelList
            }
          }
        } else {
          //如果有关联标签，那也只能是一个主标签，根据其他关联标签（关联标签可能多个）
          //查找当前标签下面关联的其他标签
          let linkedParentIdsArr = []
          response.data.forEach((item, index) => {
            if (item.linkedParentIds) {
              linkedParentIdsArr.push(...item.linkedParentIds.split(','))
            }
          })
          let allRelateArr = [] //所有关联标签
          linkedParentIdsArr.forEach((item, index) => {
            allRelateArr.push(
              new Promise((resolve, reject) => {
                ProductListInteractor.getDetailForEditApi({
                  keyParentId: item,
                }).then((response) => {
                  resolve(response.data)
                })
              })
            )
          })
          //主标签定义main=0，主标签里面的关联标签定义main=1
          Promise.all(allRelateArr).then((result, index) => {
            let mergeData = initData.concat(result)
            mergeData.forEach((item, index) => {
              that.productKeyEditVOList.forEach((item2, index2) => {
                item2.childKeyIds.split(',').forEach((item3, index3) => {
                  item.keyChildEditVOList.forEach((item4, index4) => {
                    if (item3 === item4.keyChildId) {
                      item4.isShow = true
                    }
                  })
                })
              })
            })
            this.tableData = mergeData.map((item) => {
              if (item.multiple == '1') {
                return {
                  ...item,
                  value: [],
                }
              } else {
                return {
                  ...item,
                  value: '',
                }
              }
            })
            if (this.labelList && this.labelList.length) {
              this.tableData = this.labelList
            }
          })
        }
      },

      //查看标签
      showLabelClick() {
        this.$refs['ProLabelApplyModel'].proLabelApplyModel(
          this.categoryId,
          this.productInfo.productKeyShowVOList
        )
      },
      //listProductSettingByIds
      async getListProductSettingByIds(idsname, index) {
        let response = await ProductListInteractor.listProductSettingByIdsApi({
          productSettingIds: idsname,
        })
        this.productPrintingWayVOList[index].printingPositions = response.data
      },
      tagsClick(item) {
        this.currentIndex = item.productPrintingPricingId
        if (item.nameCn === 'Sample') {
          this.isSample = true
        } else {
          this.isSample = false
        }
      },
      //查看规格
      // 规格配置编辑
      specEditClick(row, index) {
        this.currentRow = row
        this.currentRow.typeArr = this.typeArr
        this.$refs['productSpecListModel'].productSpecListModel(row, index)
      },

      cancelClick1() {
        this.$refs.cancel1.doClose()
      },
      cancelClick2() {
        this.$refs.cancel2.doClose()
      },
      //同意驳回第一层
      auditClick() {
        //this.$emit('handleSearch')
      },
      //同意确认
      auditClickConfirm1() {
        if (this.remark1 === '') {
          return this.$baseMessage(
            '请输入您的审核同意意见',
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        this.audit(this.remark1, 2)
      },
      //驳回确认
      auditClickConfirm2() {
        if (this.remark2 === '') {
          return this.$baseMessage(
            '请输入您的审核驳回意见',
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        this.audit(this.remark2, 3)
      },
      //审核
      async audit(remark, num) {
        this.passRow.auditStatus = num
        this.passRow.remarks = remark

        let response = await auditInteractor.auditApi(this.passRow)
        if (response.code === '000000') {
          this.$baseMessage(
            '审核成功',
            'success',
            false,
            'erp-hey-message-success'
          )
          this.closeClick()
        }
      },
      closeClick() {
        this.$emit('handleClose')
      },
      // 大图预览
      handlePreview(item) {
        this.imgUrl = item
        this.showViewer = true
      },
      closeViewer() {
        this.showViewer = false
      },
      //审核状态匹配
      supplierAuditStatus(val) {
        return supplierAuditStatus(val)
      },
      // 运输周期合并
      objectSpanMethodDeliveryPeriod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 3) {
          if (rowIndex % 2 === 0) {
            return {
              rowspan: 2,
              colspan: 1,
            }
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            }
          }
        }
      },

      // 获取PlatformProductDetails组件所需详情
      async getPlatformProductDetailsInfo(erpProductId) {
        try {
          const { code, data } =
            await PlatformProductListInteractor.platformProductInfoApi({
              erpProductId,
            })
          if (code === '000000') {
            this.detailInfo = data
          }
        } catch (err) {
          console.log(err)
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .product-info {
    margin-bottom: 50px;
    padding: 25px;

    p {
      line-height: 20px;
      text-align: justify;
    }

    .img-main {
      max-width: 100px;
      max-height: 100px;
      border-radius: 2px;
      margin-left: 10px;
    }

    .img-swiper {
      max-width: 100px;
      max-height: 100px;
      border-radius: 2px;

      margin-bottom: 10px;
    }

    .img-swiper + .img-swiper {
      margin-left: 10px;
    }
  }

  .test {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    p {
      word-wrap: break-word !important;
    }
  }

  .border-line {
    padding-bottom: 15px;
    border-bottom: 1px solid #ededed;
  }

  .product_info_table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      height: 38px;
      text-align: center;
      border: 1px solid #ebeef5;
    }

    th {
      font-weight: normal;
      background-color: #f5f7fa;
    }
  }

  .c_pointer.active {
    background-color: #e8f4ff;
    border-color: #d1e9ff;
    color: #1890ff;
  }

  .label-content {
    label {
      color: #000;
    }

    ::v-deep {
      .el-form-item--small.el-form-item {
        margin-bottom: 0;
        height: 32px;
        line-height: 32px !important;
        overflow: hidden;
      }
    }
  }

  .label-text {
    max-width: 100px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .label-row {
    flex-wrap: wrap;

    p {
      margin-bottom: 10px;
      margin-right: 20px;

      span {
        color: #909399;

        i {
          font-style: normal;
          display: inline-block;
          margin-right: 7px;
        }
      }
    }
  }

  .operate {
    height: 60px;
    line-height: 60px;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0 40px;
    background: #fff;
    border-top: 1px solid #ededed;
  }

  .pro-base-msg {
    label {
      width: 90px;
      display: inline-block;
      color: #000;
    }

    .special-label {
      width: 130px;
    }

    span {
      width: calc(100% - 90px);
      padding-right: 20px;
      display: inline-block;
    }

    .tooltip-span {
      width: calc(100% - 90px);
      padding-right: 20px;
      display: inline-block;
    }
  }

  .custom-table {
    overflow: auto;

    p + p {
      margin-top: 3px;
    }
  }

  .tooltip-content {
    max-height: 350px;
    overflow: hidden;
    overflow-y: auto;
    padding: 15px;

    p {
      font-size: 14px;
    }

    p + p {
      margin-top: 8px;
    }
  }

  .custom-table {
    td {
      .row-charge {
        height: 115px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
    }
  }

  .testData-row {
    max-height: 370px;
    overflow-y: auto;
  }

  .delivery-period {
    p {
      text-align: center;
    }
  }
</style>
