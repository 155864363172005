<!-- 收款管理详情 -->
<template>
  <div class="table-container">
    <div class="custom-detail">
      <el-row>
        <el-col>
          <em>收款单号：</em>
          <span>{{ detail.financeCode || '--' }}</span>
        </el-col>
        <el-col>
          <em>收款类型：</em>
          <span>
            {{
            detail.financeType == 1 && detail.entryType == 1
            ? '销售退款'
            : '销售订单'
            }}
          </span>
        </el-col>
        <el-col class="w_105">
          <em>收款方：</em>
          <span>{{ detail.shroffAccountId || '--' }}</span>
        </el-col>
        <el-col>
          <em>退款方式：</em>
          <span>
            {{ detail.financeMethod || '--' }}
          </span>
          <span v-show="detail.ticketNumber" style="color: #666; font-size: 12px">
            (票据单号:{{ detail.ticketNumber }})
          </span>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <em>币种：</em>
          <span>{{ detail.currency || '--' }}</span>
        </el-col>
        <el-col>
          <em>退款金额：</em>
          <span :class="detail.entryType == 1 ? 'red' : ''">
            {{ detail.entryType == 1 ? '-' : '' }}
            {{ detail.amountCollected |orderMoneyFormat }}
          </span>
        </el-col>
        <el-col class="w_105">
          <em>退款申请日期：</em>
          <span>{{ detail.amountTime || '--' }}</span>
        </el-col>
      </el-row>
    </div>

    <div class="detail-table mt20">
      <el-table border :data="detail.fovos" class="mt20">
        <el-table-column align="center" type="index" label="#"></el-table-column>
        <el-table-column align="center" prop="orderCode" label="订单编号">
          <template slot-scope="scope">
            <span class="blue-text">
              <router-link target="_blank" :to="`/order/orderList/orderDetail?orderCode=${
                  scope.row.orderCode
                }&noReturn=${true}`">
                {{ scope.row.orderCode }}
              </router-link>
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="customerName" label="客户名称"></el-table-column>
        <el-table-column align="center" prop="companyName" label="公司名称">
          <template slot-scope="scope">
            {{ scope.row.companyName || '--' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="businessName" label="客户代表"></el-table-column>
        <el-table-column align="center" prop="area" label="所属地区"></el-table-column>
        <el-table-column align="center" prop="contractNum" label="币种">
          <template slot-scope="scope">
            {{ scope.row.currency || '--' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="totlePrice" label="订单金额">
          <template slot-scope="scope">
            <!-- 订单金额 -->
            <el-popover placement="bottom" width="800" trigger="click">
              <order-money-detail :ref="'orderMoneyDetail' + scope.$index" :detailMsg="detailMsg"
                :currency="scope.row.currency" @showDetail="showDetail"></order-money-detail>
              <span slot="reference" class="blue-text" @click="
                  (e) => {
                    handelTotalPriceClick(scope.row, scope.$index)
                  }
                ">
                <span>
                  {{ scope.row.totlePrice | orderMoneyFormat }}
                </span>
              </span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column align="center" label="已收金额">
          <template slot-scope="scope">
            {{ (scope.row.totlePrice * 1 - scope.row.outstandingAmount*1) | orderMoneyFormat}}
          </template>
        </el-table-column>
        <el-table-column align="center" label="退款金额" prop="amountCollected">
          <template slot-scope="scope">
            <div :class="detail.entryType == 1 ? 'red' : ''">
              {{ detail.entryType == 1 ? '-' : '' }}
              {{
              scope.row.amountCollected
              ? toFixed2(scope.row.amountCollected)
              : '0.00'
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="退还手续费" prop="commissionAmount">
          <template slot-scope="scope">
            <div :class="detail.entryType == 1 ? 'red' : ''">
              {{ detail.entryType == 1 ? '-' : '' }}
              {{
              scope.row.commissionAmount
              ? toFixed2(scope.row.commissionAmount)
              : '0.00'
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="结算手续费" prop="settleFee">
          <template slot-scope="scope">
            <div :class="detail.entryType == 1 ? 'red' : ''">
              {{ detail.entryType == 1 ? '-' : '' }}
              {{ scope.row.settleFee ? toFixed2(scope.row.settleFee) : '0.00' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="实退金额" prop="settleFee">
          <template slot-scope="scope">
            <div :class="detail.entryType == 1 ? 'red' : ''">
              {{ detail.entryType == 1 ? '-' : '' }}
              {{
              toFixed2(scope.row.amountCollected - scope.row.commissionAmount)
              }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 退款合计 -->
      <div v-if="detail.financeType == 1 && detail.entryType == 1" class="money-sum">
        <span>合计</span>
        <span>退款金额:</span>
        -{{ (moneySum[0] === '--' ? moneySum[0] :moneySum[0]) | orderMoneyFormat }}
        <span>退还手续费:</span>
        <span>{{ (moneySum[1] === '--' ? moneySum[1] :moneySum[1]) | orderMoneyFormat }}</span>
        <span>结算手续费:</span>
        {{(moneySum[2] === '--' ? moneySum[2] :moneySum[2]) | orderMoneyFormat }}
        <span>实退金额:</span>
        <span>-{{(moneySum[3] === '--' ? moneySum[3] :moneySum[3]) | orderMoneyFormat }}</span>
      </div>
    </div>

    <div class="custom-detail mt20">
      <el-row>
        <el-col>
          <em>备注：</em>
          <span>{{ detail.remark || '--' }}</span>
        </el-col>
        <el-col>
          <em>创建人：</em>
          <span>
            {{ detail.creatorName || '--' }}
          </span>
        </el-col>
        <el-col>
          <em>创建时间：</em>
          <span>{{ detail.createTime || '--' }}</span>
        </el-col>
      </el-row>
    </div>
    <p>附件</p>
    <div v-show="fileList.length">
      <div class="flie-list" v-for="(item, i) in fileList" :key="i">
        <i class="el-icon-document"></i>
        <a class="page-link ml5">
          <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
            <span class="ell">
              <router-link :to="`/preview-file/index?name=${item.name}&url=${item.url}`" target="_blank">
                {{ item.name }}
              </router-link>
            </span>
          </el-tooltip>
        </a>
        <span class="size ml10">
          {{ utils.calculateFileSize(item.size) }}
        </span>
        <span class="ml10">{{ item.time }}</span>
      </div>
    </div>
    <!-- 真实账户 -->
    <RefundRealAccount
      v-if="detail.financeType == 1 && detail.entryType == 1"
      :accountInfo="realAccount"
      :financeMethod="detail.financeMethod"
      disabled
      />
    <ReceiptDetailModelOut ref="ReceiptDetail" />
  </div>
</template>

<script>
  import { CreditNoteAddInteractor, CreditManageInteractor } from '@/core'
  import orderMoneyDetail from '@/views/finance-center/credit-manage/components/order-money-detail.vue'
  import ReceiptDetailModelOut from '@/views/finance-center/credit-manage/components/receipt-detail-model'
  import RefundRealAccount from '@/components/RefundRealAccount/index'
  export default {
    props: {
      detailId: {
        type: String,
        required: true,
      },
    },
    components: {
      orderMoneyDetail,
      ReceiptDetailModelOut,
      RefundRealAccount
    },
    data() {
      return {
        detail: {},
        fileList: [],
        detailMsg: {},
      }
    },
    computed: {
      realAccount() {
        let detail = this.detail || {}
        return {
          ...(detail.realAccount || {}),
          realAccountFlag:detail.realAccountFlag
        }
      },
      moneySum() {
        let vos = this.detail.fovos
        const sum = []
        sum[0] = vos.reduce((prev, next) => {
          prev += next.amountCollected * 1000
          return prev
        }, 0)
        sum[1] = vos.reduce((prev, next) => {
          prev += next.commissionAmount * 1000
          return prev
        }, 0)
        sum[2] = vos.reduce((prev, next) => {
          prev += next.settleFee * 1000
          return prev
        }, 0)
        sum[3] = vos.reduce((prev, next) => {
          prev += next.amountCollected * 1000 - next.commissionAmount * 1000
          return prev
        }, 0)
        return sum.map((item) => {
          if (Number.isNaN(item) || item === 0) {
            return '--'
          } else {
            return (item / 1000).toFixed(3)
          }
        })
      },
    },

    created() {
      this.init()
    },
    methods: {
      init() {
        CreditManageInteractor.confirmReceiptDetailApi({
          financeId: this.detailId,
        }).then((res) => {
          if (res.code == '000000') {
            this.detail = res.data
            this.fileList = res.data.financeUrl
              ? JSON.parse(res.data.financeUrl)
              : []
          }
        })
      },

      //处理点击订单金额显示详情
      handelTotalPriceClick(e, index) {
        this.detailMsg = {}
        CreditNoteAddInteractor.financeOrderPayVOApi({ orderCode: e.orderCode }).then((res) => {
          if (res.code === '000000') {
            this.detailMsg = res.data
          }
        })
      },
      showDetail(val) {
        this.$refs['ReceiptDetail'].showReceiptDetail(
          val.financeCode,
          val.financeStatus
        )
      },
    },
  }
</script>

<style scoped lang="scss">
  .table-container {
    padding: 20px !important;

    & .title {
      display: flex;
      align-items: center;
    }

    & .detail-fields {
      .el-row {
        & .label {
          color: #282c34;
          width: 100px;
          text-align: right;
          display: inline-block;
          margin-right: 15px;
        }

        & .content {
          color: #000016;
        }
      }
    }
  }

  .header {
    font-size: 18px;
    color: #606266;
    font-family: 'PingFang Bold';
  }

  .pointer {
    cursor: pointer;
  }

  .mb20 {
    margin-bottom: 20px;
  }

  .money-sum {
    color: #666;
    text-align: right;
    padding: 5px;

    span {
      margin: 0 5px;
    }
  }
</style>
