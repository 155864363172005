<template>
  <div class="search_form">
    <el-form ref="form" :model="form" label-position="top" :rules="rules">
      <!-- 签约日期 -->
      <el-form-item :label="$t('orderList.DateOfSigning')" prop="contractDate">
        <el-date-picker
          v-model="contractDate"
          type="daterange"
          value-format="yyyy-MM-dd"
          :start-placeholder="$t('placeholder.startDate')"
          :end-placeholder="$t('placeholder.endDate')"
        />
      </el-form-item>
      <!-- 审核通过时间 -->
      <el-form-item :label="$t('orderList.ApprovalDate')" prop="auditPassTime">
        <el-date-picker
          v-model="auditPassTime"
          type="daterange"
          value-format="yyyy-MM-dd"
          :start-placeholder="$t('placeholder.startDate')"
          :end-placeholder="$t('placeholder.endDate')"
        />
      </el-form-item>
      <!-- 创建时间 -->
      <el-form-item
        :label="$t('orderList.CreationTime')"
        prop="createDate"
        :rules="[
          {
            required: true,
            message: $t('placeholder.plsSel'),
          },
        ]"
      >
        <el-date-picker
          :editable="false"
          ref="mypicker"
          v-model="form.createDate"
          type="daterange"
          value-format="yyyy-MM-dd"
          :start-placeholder="$t('placeholder.startDate')"
          :end-placeholder="$t('placeholder.endDate')"
          :picker-options="pickerOptions"
          @focus="onFocus"
        />
      </el-form-item>
      <!-- 产品编号 -->
      <el-form-item :label="$t('orderList.ProductCode')" prop="productCode">
        <el-input
          v-model="form.productCode"
          :placeholder="$t('placeholder.plsInput')"
          clearable
        />
      </el-form-item>
      <!-- 产品名称 -->
      <el-form-item :label="$t('orderList.ProductName')" prop="productEn">
        <el-input
          v-model="form.productEn"
          :placeholder="$t('placeholder.plsInput')"
          clearable
        />
      </el-form-item>
      <!-- <el-form-item label="产品类别" prop="productEn">
        <el-select
          v-model="form.rendingsStatus"
          :placeholder="$t('placeholder.plsSel')"
          class="w100"
          clearable
        >
          <el-option label="已确认" value="1" />
        </el-select>
      </el-form-item> -->
      <!-- 效果图状态 -->
      <el-form-item
        :label="$t('orderList.RenderingsStatus')"
        prop="rendingsStatus"
      >
        <el-select
          v-model="form.rendingsStatus"
          :placeholder="$t('placeholder.plsSel')"
          class="w100"
          clearable
        >
          <!-- 已确认 -->
          <el-option :label="$t('orderList.HasBeenConfirmed')" value="1" />
          <!-- 没有文件 -->
          <el-option :label="$t('orderList.NoFile')" value="2" />
          <!-- 客户确认中 -->
          <el-option :label="$t('orderList.CustomerConfirmation')" value="3" />
          <!-- 修改中 -->
          <el-option :label="$t('orderList.BeingRevised')" value="4" />
        </el-select>
      </el-form-item>
      <!-- 产品数量 -->
      <el-form-item :label="$t('orderList.ProductQuantity')">
        <div class="line_input">
          <el-form-item prop="beginAmount">
            <el-input
              v-model="form.beginAmount"
              maxlength="10"
              :placeholder="$t('placeholder.plsInput')"
              clearable
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            />
          </el-form-item>
          <span>{{ $t('other.To') }}</span>
          <el-form-item prop="endAmount">
            <el-input
              v-model="form.endAmount"
              maxlength="10"
              :placeholder="$t('placeholder.plsInput')"
              clearable
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            />
          </el-form-item>
        </div>
      </el-form-item>
      <!-- 总金额 -->
      <el-form-item :label="$t('orderList.TotalOrderAmount')">
        <div class="line_input">
          <el-form-item prop="beginTotlePrice">
            <el-input
              v-model="form.beginTotlePrice"
              maxlength="10"
              :placeholder="$t('placeholder.plsInput')"
              clearable
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
              :disabled="!isViewCost"
            />
          </el-form-item>
          <span>{{ $t('other.To') }}</span>
          <el-form-item prop="endTotlePrice">
            <el-input
              v-model="form.endTotlePrice"
              maxlength="10"
              :placeholder="$t('placeholder.plsInput')"
              clearable
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
              :disabled="!isViewCost"
            />
          </el-form-item>
        </div>
      </el-form-item>
      <!-- 客户代表 -->
      <el-form-item :label="$t('orderList.CustomerRepresentative')">
        <el-input
          v-if="lang === 'en'"
          clearable
          v-model="businessNameEn"
          :placeholder="$t('placeholder.plsSel')"
          @focus="selectUserClick('business')"
          @change="businessNameChange"
        ></el-input>
        <el-input
          v-else
          clearable
          v-model="businessName"
          :placeholder="$t('placeholder.plsSel')"
          @focus="selectUserClick('business')"
          @change="businessNameChange"
        ></el-input>
      </el-form-item>
      <!-- 订单支持 -->
      <el-form-item :label="$t('orderList.OrderSupport')">
        <el-input
          v-if="lang === 'en'"
          clearable
          v-model="fromBusinessNameEn"
          :placeholder="$t('placeholder.plsSel')"
          @focus="selectUserClick('fromBusiness')"
          @change="fromBusinessNameChange"
        ></el-input>
        <el-input
          v-else
          clearable
          v-model="fromBusinessName"
          :placeholder="$t('placeholder.plsSel')"
          @focus="selectUserClick('fromBusiness')"
          @change="fromBusinessNameChange"
        ></el-input>
      </el-form-item>
      <!-- 公司名称 -->
      <el-form-item :label="$t('orderList.CompanyName')" prop="companyName">
        <el-input
          v-model="form.companyName"
          :placeholder="$t('placeholder.plsInput')"
          clearable
        />
      </el-form-item>
      <!-- 客户订单号 -->
      <el-form-item
        :label="$t('orderList.CustomerOrderNumber')"
        prop="customerOrderCode"
      >
        <el-input
          v-model="form.customerOrderCode"
          :placeholder="$t('placeholder.plsInput')"
          clearable
        />
      </el-form-item>
      <!-- 订单来源 -->
      <el-form-item :label="$t('orderList.OrderSource')" prop="orderSource">
        <el-select
          v-model="form.orderSource"
          :placeholder="$t('placeholder.plsSel')"
          class="w100"
          clearable
        >
          <el-option
            v-for="item in orderSourceList"
            :key="item.amountId"
            :label="item.amountName"
            :value="item.amountName"
          />
        </el-select>
      </el-form-item>
      <!-- 订单状态 -->
      <el-form-item :label="$t('orderList.Status')" prop="orderStatus2">
        <el-select
          v-model="form.orderStatus2"
          :placeholder="$t('placeholder.plsSel')"
          class="w100"
          clearable
          multiple
        >
          <!-- 待处理 -->
          <el-option :label="$t('orderList.ToBeProcessed')" value="1" />
          <!-- 审核中 -->
          <el-option :label="$t('orderList.InReview')" value="4" />
          <!-- 已通过 -->
          <el-option :label="$t('orderList.Passed')" value="5" />
          <!-- 待发货 -->
          <el-option :label="$t('orderList.PendingShipment')" value="6" />
          <!-- 待收货 -->
          <el-option :label="$t('orderList.PendingDelivery')" value="8" />
          <!-- 已收货 -->
          <el-option :label="$t('orderList.Delivered')" value="9" />
          <!-- 已完成 -->
          <el-option :label="$t('orderList.Completed')" value="10" />
          <!-- 已作废 -->
          <el-option :label="$t('orderList.Voided')" value="11" />
          <!-- 已驳回 -->
          <el-option :label="$t('orderList.Rejected')" value="2" />
        </el-select>
      </el-form-item>
      <!-- 产品状态 -->
      <el-form-item :label="$t('orderList.ProductStatus')" prop="productStatus">
        <el-select
          v-model="productStatus"
          :placeholder="$t('placeholder.plsSel')"
          class="w100"
          clearable
          multiple
        >
          <el-option
            :label="$t('orderList.PendingArrangement')"
            value="待安排"
          />
          <el-option
            :label="$t('orderList.PendingPurchase')"
            value="待采购处理"
          />
          <el-option :label="$t('orderList.OutOfWarehouse')" value="已出库" />
          <el-option :label="$t('orderList.Purchased')" value="已采购" />
          <el-option :label="$t('orderList.Shipped')" value="已出运" />
        </el-select>
      </el-form-item>
      <!-- 收款进度 -->
      <el-form-item
        :label="$t('orderList.ReceivePaymentProgress')"
        prop="paymentSchedule"
      >
        <el-select
          v-model="paymentSchedule"
          :placeholder="$t('placeholder.plsSel')"
          class="w100"
          clearable
          multiple
        >
          <el-option :label="$t('orderList.Unpaid')" value="未收款" />
          <el-option
            :label="$t('orderList.CustomerHasPaidToBeCredited')"
            value="客户已付待入账"
          />
          <el-option :label="$t('orderList.PartialPayment')" value="部分收款" />
          <el-option :label="$t('orderList.PaymentReceived')" value="已收款" />
          <el-option
            :label="$t('orderList.FinanceToBeConfirmed')"
            value="财务待确认"
          />
        </el-select>
      </el-form-item>
      <!-- 结算状态 -->
      <el-form-item
        :label="$t('orderList.SettlementStatus')"
        prop="settlementStatus"
      >
        <el-select
          v-model="form.settlementStatus"
          :placeholder="$t('placeholder.plsSel')"
          class="w100"
          clearable
        >
          <!-- 未结算 -->
          <el-option label="-" value="1" />
          <!-- 结算中 -->
          <el-option :label="$t('orderList.Settling')" value="2" />
          <!-- 已结算 -->
          <el-option :label="$t('orderList.Settled')" value="3" />
          <!-- 待结算 -->
          <el-option :label="$t('orderList.ToSettled')" value="4" />
          <!-- 结算驳回 -->
          <el-option :label="$t('orderList.SettlementReject')" value="5" />
          <!-- 结算调整 -->
          <el-option :label="$t('orderList.SettlementAdjustment')" value="6" />
        </el-select>
      </el-form-item>
      <!-- 地区 -->
      <el-form-item :label="$t('orderList.Area')" prop="area">
        <el-select
          v-model="form.area"
          :placeholder="$t('placeholder.plsSel')"
          class="w100"
        >
          <!-- 合肥 -->
          <el-option :label="$t('orderList.Hefei')" value="合肥" />
          <!-- 苏州 -->
          <el-option :label="$t('orderList.Suzhou')" value="苏州" />
          <!-- 义乌 -->
          <el-option :label="$t('orderList.Yiwu')" value="义乌" />
        </el-select>
      </el-form-item>
      <!-- 订单历史问题备注 -->
      <el-form-item
        :label="$t('orderList.NotesOnOrder')"
        prop="historyRemarkFlag"
      >
        <el-select
          v-model="form.historyRemarkFlag"
          :placeholder="$t('placeholder.plsSel')"
          class="w100"
        >
          <!-- 空 -->
          <el-option :label="$t('orderList.Empty')" value="0" />
          <!-- 有值 -->
          <el-option :label="$t('orderList.HaveAvalue')" value="1" />
        </el-select>
      </el-form-item>
      <!-- 创建人 -->
      <el-form-item :label="$t('orderList.Creator')" prop="companyName">
        <el-input
          v-if="lang === 'en'"
          clearable
          v-model="creatorNameEn"
          :placeholder="$t('placeholder.plsSel')"
          @focus="selectUserClick('creatorName')"
          @change="creatorChange"
        ></el-input>
        <el-input
          v-else
          clearable
          v-model="creatorName"
          :placeholder="$t('placeholder.plsSel')"
          @focus="selectUserClick('creatorName')"
          @change="creatorChange"
        ></el-input>
      </el-form-item>
      <!-- 交货日期 -->
      <el-form-item :label="$t('orderList.deliveryDate')" prop="deliveryDate">
        <el-date-picker
          ref="mypicker"
          v-model="form.deliveryDate"
          type="daterange"
          value-format="yyyy-MM-dd"
          :start-placeholder="$t('placeholder.startDate')"
          :end-placeholder="$t('placeholder.endDate')"
        />
      </el-form-item>

      <div class="btn">
        <!-- 确定 -->
        <el-button type="primary" @click="searchClick">
          {{ $t('inquiryList.Confirm') }}
        </el-button>
        <!-- 重置 -->
        <el-button @click="resetClick">
          {{ $t('inquiryList.Reset') }}
        </el-button>
      </div>
    </el-form>

    <!-- 添加成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>

<script>
  import { orderGetDict } from '@/api/order'
  import UserChoose from '@/components/userChoose2'
  import viewCost from '@/views/order/orderList/util'
  import { mapGetters } from 'vuex'
  import * as dayjs from 'dayjs'
  import { nextMonth } from 'element-ui/src/utils/date-util'
  export default {
    name: 'ProductSearch',
    components: {
      UserChoose,
    },
    data() {
      return {
        isViewCost: viewCost(),
        orderSourceList: [], // 订单来源
        optionProps: {
          value: 'id',
          label: 'nameCn',
          children: 'children',
          checkStrictly: true,
        },
        form: {
          orderStatus2: 0,
          createDate: [], // 创建时间
        },

        businessName: '', // 客户代表名称
        businessNameEn: '', // 客户代表英文名称
        businessNameId: [], // 客户代表id
        fromBusinessName: '', // 订单支持名称
        fromBusinessNameEn: '', // 订单支持英文名称
        fromBusinessNameId: [], // 订单支持id
        creatorName: '', //创建人
        creatorNameEn: '', //创建人英文
        creatorId: [], //创建人id
        isUserChoose: '', //当前组织架构是谁调用
        contractDate: [], // 签约日期
        arrivalDate: [], // 到货日期

        auditPassTime: [], // 审核通过时间
        productStatus: [], // 产品状态
        paymentSchedule: [], // 收款进度
        rules: {
          productCode: [
            { max: 50, message: '长度不能超过50个字符', trigger: 'blur' },
          ],
          productEn: [
            { max: 50, message: '长度不能超过50个字符', trigger: 'blur' },
          ],
          companyName: [
            { max: 50, message: '长度不能超过50个字符', trigger: 'blur' },
          ],
          customerOrderCode: [
            { max: 50, message: '长度不能超过50个字符', trigger: 'blur' },
          ],
        },
        pickerMinDate: '',
        pickerOptions: {
          onPick: ({ minDate, maxDate }) => {
            this.pickerMinDate = minDate
            if (maxDate) {
              this.pickerMinDate = ''
            }
          },
          disabledDate: (time) => {
            if (this.pickerMinDate) {
              let allowMaxTime = dayjs(this.pickerMinDate)
                .add(6, 'month')
                .valueOf()
              let allowMinTime = dayjs(this.pickerMinDate)
                .subtract(6, 'month')
                .valueOf()
              let currentTime = time.getTime()
              return (
                currentTime > allowMaxTime ||
                currentTime < allowMinTime ||
                currentTime > Date.now()
              )
            } else {
              return time.getTime() > Date.now()
            }
          },
        },
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    created() {
      this.getDict()
    },
    mounted() {
      this._initCreateDate()
    },

    watch: {
      // 签约日期
      contractDate(dateArray) {
        if (dateArray) {
          this.form.beginContractDate = dateArray[0]
          this.form.endContractDate = dateArray[1]
        } else {
          this.form.beginContractDate = ''
          this.form.endContractDate = ''
        }
      },
      // 创建时间
      'form.createDate': function (dateArray) {
        if (dateArray) {
          this.form.beginCreateDate = dateArray[0]
          this.form.endCreateDate = dateArray[1]
        } else {
          this.form.beginCreateDate = ''
          this.form.endCreateDate = ''
        }
      },
      // 创建时间
      auditPassTime(dateArray) {
        if (dateArray) {
          this.form.auditPassTimeStart = dateArray[0]
          this.form.auditPassTimeEnd = dateArray[1]
        } else {
          this.form.auditPassTimeStart = ''
          this.form.auditPassTimeEnd = ''
        }
      },
      // 交货日期
      'form.deliveryDate': function (dateArray) {
        if (dateArray) {
          this.form.deliveryStartDate = dateArray[0]
          this.form.deliveryEndDate = dateArray[1]
        } else {
          this.form.deliveryStartDate = ''
          this.form.deliveryEndDate = ''
        }
      },
      productStatus(val) {
        if (val && val.length) {
          this.form.productStatus = val.join(',')
        } else {
          this.form.productStatus = ''
        }
      },
      // 收款进度
      paymentSchedule(val) {
        if (val && val.length) {
          this.form.paymentSchedule = val.join(',')
        } else {
          this.form.paymentSchedule = ''
        }
      },
    },
    methods: {
      // 创建时间聚焦
      onFocus(val) {
        if (val && val.picker) {
          console.log(val)
          if (
            Array.isArray(this.form.createDate) &&
            this.form.createDate.length > 0
          ) {
            this.$nextTick(() => {
              const leftDate = new Date(this.form.createDate[1])
              val.picker.leftDate = leftDate
              val.picker.rightDate = nextMonth(leftDate)
            })
          }
        }
      },
      // 创建事件初始化
      _initCreateDate() {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 186)
        const picker = this.$refs.mypicker
        this.form.createDate = [start, end]
      },
      initCreateDate() {
        if (!this.form.createDate || this.form.createDate?.length !== 2) {
          this._initCreateDate()
        }
      },
      // 创建时间初始化 供父组件使用

      // 获取订单来源
      getDict() {
        orderGetDict({ amountType: 3 }).then((res) => {
          this.orderSourceList = res.data
        })
      },

      //客户代表，订单支持，创建人选择
      selectUserClick(str) {
        this.isUserChoose = str
        if (str === 'business') {
          this.$refs['UserChoose'].showAddEdit(
            'listSearch',
            this.businessNameId
          )
        } else if (str == 'fromBusiness') {
          this.$refs['UserChoose'].showAddEdit(
            'listSearch',
            this.fromBusinessNameId
          )
        } else {
          this.$refs['UserChoose'].showAddEdit('listSearch', this.creatorId)
        }
      },

      // 获取客户代表
      chooseUser(checkedUser) {
        if (this.isUserChoose === 'business') {
          this.businessNameChange()
        } else if (this.isUserChoose === 'fromBusiness') {
          this.fromBusinessNameChange()
        } else {
          this.creatorChange()
        }

        if (!checkedUser.length) {
          return
        }
        let userName = []
        let userNameEn = []
        checkedUser.forEach((item) => {
          userName.push(item.name)
          userNameEn.push(item.englishName)
          if (this.isUserChoose === 'business') {
            this.businessName = userName.join(',')
            this.businessNameEn = userNameEn.join(',')
            this.businessNameId.push(item.id)
          } else if (this.isUserChoose === 'fromBusiness') {
            this.fromBusinessName = userName.join(',')
            this.fromBusinessNameEn = userNameEn.join(',')
            this.fromBusinessNameId.push(item.id)
          } else {
            this.creatorName = userName.join(',')
            this.creatorNameEn = userNameEn.join(',')
            this.creatorId.push(item.id)
          }
        })
      },
      setHighForm(cache) {
        let self = this
        let cacheField = [
          'businessName', // 客户代表名称
          'businessNameEn', // 客户代表英文名称
          'businessNameId', // 客户代表id
          'fromBusinessName', // 订单支持名称
          'fromBusinessNameEn', // 订单支持英文名称
          'fromBusinessNameId', // 订单支持id
          'creatorName', //创建人
          'creatorNameEn', //创建人英文
          'creatorId', //创建人id
          'isUserChoose', //当前组织架构是谁调用
          'contractDate', // 签约日期
          'arrivalDate', // 到货日期
          'auditPassTime', // 审核通过时间
          'productStatus', // 产品状态
          'paymentSchedule', // 收款进度
        ]
        cacheField.forEach((field) => {
          self[field] = cache[field]
        })
        this.form = cache.form
      },
      // 获取高级筛选查询参数
      _getHighForm() {
        let cacheField = [
          'businessName', // 客户代表名称
          'businessNameEn', // 客户代表英文名称
          'businessNameId', // 客户代表id
          'fromBusinessName', // 订单支持名称
          'fromBusinessNameEn', // 订单支持英文名称
          'fromBusinessNameId', // 订单支持id
          'creatorName', //创建人
          'creatorNameEn', //创建人英文
          'creatorId', //创建人id
          'isUserChoose', //当前组织架构是谁调用
          'contractDate', // 签约日期
          'arrivalDate', // 到货日期
          'auditPassTime', // 审核通过时间
          'productStatus', // 产品状态
          'paymentSchedule', // 收款进度
        ]
        let highForm = {}
        cacheField.forEach((field) => {
          highForm[field] = this[field]
          highForm.form = this.form
        })

        return JSON.stringify(highForm)
      },
      // 查询
      searchClick() {
        let self = this
        self.$refs.form.validate((valid) => {
          if (valid) {
            if (self.businessNameId) {
              self.form.businessName = self.businessNameId.join(',')
            }
            if (self.fromBusinessNameId) {
              self.form.fromBusinessName = self.fromBusinessNameId.join(',')
            }
            if (self.creatorId) {
              self.form.creatorId = self.creatorId.join(',')
            }
            // 获取当前表格form
            let highForm = this._getHighForm()
            self.$emit('searchClick', self.form, highForm)
          }
        })
      },

      // 重置
      resetClick() {
        this.contractDate = []
        this.form.createDate = []
        this.form.deliveryDate = []
        this.paymentSchedule = []
        this.productStatus = []
        this.businessNameChange()
        this.fromBusinessNameChange()
        this.creatorChange()
        this.$refs.form.resetFields()
        this.form = this.$options.data().form
        this._initCreateDate()
      },

      // 删除客户代表
      businessNameChange() {
        this.businessNameId = []
        this.businessName = ''
        this.businessNameEn = ''
        this.form.businessName = ''
        this.form.businessNameEn = ''
      },

      // 删除订单支持
      fromBusinessNameChange() {
        this.fromBusinessNameId = []
        this.fromBusinessName = ''
        this.fromBusinessNameEn = ''
        this.form.fromBusinessName = ''
        this.form.fromBusinessNameEn = ''
      },

      // 删除创建人
      creatorChange() {
        this.creatorId = []
        this.creatorName = ''
        this.creatorNameEn = ''
        this.form.creatorId = ''
      },
    },
  }
</script>

<style lang="scss" scoped>
  .search_form {
    padding: 0 20px 80px 20px;
    .el-date-editor--datetimerange.el-input__inner {
      width: 100%;
    }
    .btn {
      padding: 20px 0;
      width: 100%;
      position: fixed;
      bottom: 0;
      background: white;
    }
  }
  ::v-deep {
    .el-cascader {
      width: 100%;
    }
    .el-date-editor {
      width: 100%;
    }
    .el-divider {
      margin: 70px 0 28px 0 !important;
    }
  }
</style>
