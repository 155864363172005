<template>
  <!-- 选择供应商 -->
  <el-dialog :title="$t('productDetail.SelectASupplier')" :visible.sync="dialogFormVisible" width="1000px"
    @close="close" top="8vh" v-if="dialogFormVisible" :close-on-click-modal="false">
    <div class="dialog-div">
      <el-row class="w-el-row" :gutter="20">
        <el-col :span="16">
          <el-form ref="form">
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item>
                  <!-- 请输入供应商名称 -->
                  <el-input v-model="supplierName" @input="searchInput" clearable @keyup.enter.native="searchClick"
                    :placeholder="$t('placeholder.M2017')" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!-- 搜索 -->
                <el-button @click="searchClick" class="pub-h-36">
                  {{ $t('productDetail.Search') }}
                </el-button>
              </el-col>
            </el-row>
          </el-form>

          <el-row class="container">
            <!-- stripe -->
            <!--  @select="handleSelect" -->
            <!-- @selection-change="setSelectRows" -->
            <el-table :data="tableData" ref="dataTable" border class="mt10 w100 table-height" :row-key="
                (row) => {
                  return row.supplierId
                }
              ">
              <!-- 序号 -->
              <!-- <el-table-column
                align="center"
                type="selection"
                width="55"
                :label="$t('productDetail.SerialNumber')"
                :reserve-selection="true"
              /> -->
              <!-- 供应商编码 -->
              <el-table-column align="center" :label="$t('productDetail.SupplierCode')" width="180" prop="supplierCode">
                <template slot-scope="scope">
                  <span>{{ scope.row.supplierCode || '--' }}</span>
                </template>
              </el-table-column>
              <!-- 供应商名称 -->
              <el-table-column align="center" :label="$t('productDetail.SupplierName')" prop="supplierName">
                <template slot-scope="scope">
                  <span>{{ scope.row.supplierName || '--' }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" :label="$t('productDetail.Operate')" width="120">
                <template slot-scope="scope">
                  <el-link :underline="false" type="primary" @click="addChoose(scope.row)">
                    <!-- 选择 -->
                    {{ $t('productDetail.choice') }}
                  </el-link>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
          <el-pagination v-if="total" :current-page="page.pageNo" :page-size="page.pageLe" :total="total"
            @current-change="currentChange" />
        </el-col>
        <el-col :span="7" class="ml30">
          <p>{{ $t('productDetail.SupplierSelected') }}</p>
          <small>{{ $t('productDetail.clickTopMain') }}</small>

          <div class="mt15 chooseBox">
            <p class="supplierChoose mt10" v-for="(it, i) in chooseSupplier" :key="it.supplierId + 2">
              <span>
                {{ it.supplierName }}
              </span>
              <span class="iconbox">
                <i @click="setMain(it)" :class="it.main ? 'el-icon-star-on' : 'el-icon-star-off'" :style="
                    it.main
                      ? 'color: #409eff; font-size: 18px'
                      : 'font-size: 18px'
                  "></i>
                <i @click="del(i)" class="el-icon-error ml10" style="color: #909399; font-size: 18px"></i>
              </span>
            </p>
          </div>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <el-row class="text-center">
        <!-- 确认 -->
        <el-button type="primary" @click="save">
          {{ $t('productDetail.Confirm') }}
        </el-button>
        <!-- 取消 -->
        <el-button @click="close">{{ $t('productDetail.Cancel') }}</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { listSupplierByProductCategoryId } from '@/api/product/productList'
  export default {
    name: 'SupplierListModel',
    data() {
      return {
        // title: '选择供应商',
        // title: this.$t('productDetail.SelectASupplier'),
        supplierName: '', //供应商名称查询
        dialogFormVisible: false,
        tableData: [],
        selectRows: [], //表格勾选
        mainIds: [], //主要的供应商id
        selectIds: '', // 选中的供应商
        categoryId: '', //产品分类的id
        page: {
          pageLe: 10,
          pageNo: 1,
        },
        total: 0,
        selectSupplierId: '', //选中的id
        mainSupplierId: '', //选中的主供应商
        isMain: false,
        isEdit: false,
        chooseSupplier: [], //右边列表展示
      }
    },
    created() { },
    methods: {
      //展示弹框(id-产品类别id，supplierId-所有选中的供应商id，mainSupplierId主要供应商id)
      supplierListModel(supplierId, supplierName, mainSupplierId) {
        let supplierNames = []
        this.listSupplierSelect()
        if (mainSupplierId) {
          this.mainIds = mainSupplierId.split(',')
        }
        if (supplierName) {
          supplierNames = supplierName.split(',')
        }
        if (supplierId) {
          this.isEdit = true
          this.selectIds = Array.from(new Set(supplierId?.split(',')))
        }
        this.dialogFormVisible = true
        // 右边列表数据
        if (this.selectIds.length > 0) {
          this.selectIds?.forEach((it, i) => {
            let obj = {
              supplierId: it,
              supplierName: supplierNames[i],
              main: it == this.mainIds[0] ? true : false,
            }
            this.chooseSupplier.push(obj)
          })
        }
      },

      //供应商列表
      async listSupplierSelect(str) {
        let response = await listSupplierByProductCategoryId({
          params: this.supplierName,
          ...this.page,
        })
        if (response.code === '000000') {
          this.tableData = response.data.data
          this.total = response.data.total

          this.tableData.forEach((item) => {
            this.$set(item, 'main', false)
            this.$set(item, 'disabled', true)

            //主要供应商有值
            this.mainIds.forEach((supplierId) => {
              if (item.supplierId === supplierId) {
                this.mainSupplierId = supplierId
                item.main = true
                item.disabled = false
              }
            })

            //勾选的供应商
            this.selectRows.forEach((item2, index2) => {
              if (item.supplierId === item2.supplierId) {
                item.disabled = false
              }
            })
          })
        }

        if (this.tableData) {
          this.selectSupplier()
        }
      },

      // 编辑回显勾选
      selectSupplier() {
        this.$nextTick(function () {
          this.tableData.forEach((row, index) => {
            if (this.selectIds.includes(row.supplierId)) {
              this.$refs.dataTable.toggleRowSelection(row, true)
              row.disabled = false
            } else {
              this.$nextTick(() => {
                row.main = false
              })
            }
            if (this.mainSupplierId === row.supplierId) {
              row.main = true
            }
          })
        })
      },
      //主要供应商选择
      mainSwitchChange(i, row) {
        this.mainIds = []
        if (row.main) {
          this.mainSupplierId = row.supplierId
          this.mainIds.push(row.supplierId)
        } else {
          this.mainSupplierId = ''
        }

        this.isMain = row.main
        if (this.isMain) {
          this.tableData.forEach((item, index) => {
            if (row.supplierId !== item.supplierId) {
              item.main = false
            }
          })
          this.selectRows.forEach((item, index2) => {
            if (this.mainSupplierId !== item.supplierId) {
              item.main = false
            } else {
              item.main = true
            }
          })
        }
      },
      // 获取右侧已经展示的供应商ids
      _getRightShowSupplierIds() {
        return this.chooseSupplier.map((it) => {
          return it.supplierId
        })
      },
      handleSelect(list, curItem) {
        const rightIds = this._getRightShowSupplierIds()
        if (rightIds.includes(curItem.supplierId)) {
          //清除右侧已展示的供应商
          const index = this.chooseSupplier.findIndex(
            (i) => i.supplierId === curItem.supplierId
          )
          this.chooseSupplier.splice(index, 1)
        } else {
          this.chooseSupplier.push(curItem)
        }
        // 标识符，是否已经处理过勾选或取消勾选
        this.hasExecRight = true

        // this.selectIds = []
        // list.forEach((item, index) => {
        //   this.selectIds.push(item.supplierId)
        // })
        if (this.selectIds) {
          this.selectIds.forEach((item, index) => {
            if (item === curItem.supplierId) {
              this.selectIds.splice(index, 1)
            }
          })
        }

        if (list.length) {
          list.forEach((item) => {
            //如果勾选的数组中，有当前点击的这一个，一定是新增
            if (list.includes(curItem)) {
              item.disabled = false
            } else {
              this.tableData.forEach((item2, index) => {
                if (item.supplierId !== item2.supplierId) {
                  item2.disabled = true
                  item2.main = false
                }
                if (item2.supplierId === curItem.supplierId) {
                  this.$nextTick(() => {
                    item2.main = false
                  })
                }
              })
            }
          })
        } else {
          this.tableData.forEach((item, index) => {
            item.disabled = true
            item.main = false
          })
        }

        this.tableData.forEach((item3, index3) => {
          if (this.selectIds.includes(item3.supplierId)) {
            item3.disabled = false
          }
        })
        this.$forceUpdate()
      },
      // 选择
      addChoose(row) {
        let ids = this.chooseSupplier.map((it) => {
          return it.supplierId
        })
        if (ids.indexOf(row.supplierId) > -1) {
          this.$message.warning(this.$t('productDetail.selectedSup'))
        } else {
          let obj = {
            main: false,
            supplierId: row.supplierId,
            supplierName: row.supplierName,
          }
          this.chooseSupplier.push(obj)
        }
      },
      // 表格勾选
      setSelectRows(val) {
        let _that = this
        // 只处理当前页数据
        // 首先获取当前页的表格供应商id
        const currentPageSupplierIds = this.tableData.map((it) => {
          return it.supplierId
        })
        // 判断当前页是全选中还是全选取消
        this.currentPageChecked = val.filter((item) =>
          currentPageSupplierIds.includes(item.supplierId)
        )
        _that.selectRows = val
        if (this.isEdit) {
          if (this.selectIds.length) {
            _that.tableData.forEach((item, index) => {
              if (this.mainIds.includes(item.supplierId)) {
                item.main = true
              } else {
                this.$nextTick(() => {
                  item.main = false
                })
              }
            })
          } else {
            _that.tableData.forEach((item, index) => {
              item.main = false
            })
          }
        }
        if (this.hasExecRight && typeof this.hasExecRight !== 'undefined') {
          this.hasExecRight = false
        } else {
          this.setRightList()
        }
      },
      // 设置右边选中
      setRightList() {
        const currentPageCheckedIds = this.currentPageChecked.map(
          (i) => i.supplierId
        )
        // 表格
        let tableDataIds = this.tableData.map((it) => {
          return it.supplierId
        })
        let chooseIds = this.chooseSupplier.map((it) => {
          return it.supplierId
        })
        if (currentPageCheckedIds.length === 0) {
          //当前页全选取消选中
          this.chooseSupplier = this.chooseSupplier.filter(
            (item) => !tableDataIds.includes(item.supplierId)
          )
        } else {
          // 当前页全选选中
          this.currentPageChecked.forEach((item) => {
            if (!chooseIds.includes(item.supplierId)) {
              this.chooseSupplier.push(item)
            }
          })
        }
      },

      currentChange(val) {
        this.page.pageNo = val
        this.selectRows = []

        this.listSupplierSelect('test')
      },

      searchClick(event) {
        event.preventDefault();
        this.page.pageNo = 1
        this.listSupplierSelect()
      },
      // 搜索框清空

      // 搜索框清空
      searchInput(val) {
        if (val === '') {
          this.listSupplierSelect()
        }
      },

      // 保存
      save() {
        //还要再判断有没有数据
        this.$emit('supplierSelect', this.chooseSupplier)
        this.close()
      },
      setMain(it) {
        if (it.main) {
          this.$set(it, 'main', false)
        } else {
          this.chooseSupplier.forEach((item) => {
            this.$set(item, 'main', false)
          })
          this.$set(it, 'main', true)
        }
      },
      del(i) {
        this.chooseSupplier.splice(i, 1)
      },
      // 关闭
      close() {
        this.isEdit = false
        this.page.pageNo = 1
        this.total = 0
        this.supplierName = ''
        this.categoryId = ''
        this.mainIds = []
        this.tableData = []
        this.selectIds = ''
        this.dialogFormVisible = false
        this.chooseSupplier = []
      },
    },
  }
</script>

<style lang="scss" scoped>
  .dialog-div {
    max-height: 70vh;
    overflow: hidden;
  }

  .container {
    height: 55vh;
    overflow: hidden;
    overflow-y: auto;
  }

  ::v-deep {
    .dialog-div {
      .w-el-row {
        width: 95% !important;
        margin: 0 auto !important;
      }
    }
  }

  .supplierChoose {
    line-height: 30px;
    background-color: #f4f4ff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 3px;

    .el-icon-error,
    .el-icon-star-on,
    .el-icon-star-off:hover {
      cursor: pointer;
    }

    .iconbox {
      display: flex;
      align-items: center;
    }
  }

  .chooseBox {
    overflow: hidden;
    overflow-y: scroll;
    height: 510px;
  }
</style>
