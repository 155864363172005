<!--入库单详情-->
<template>
  <div class="table-container">
    <el-row v-if="$route.path !== '/audit-process/audit-manage/index'">
      <el-page-header @back="goBack" v-if="!$route.query.noReturn">
        <template slot="content">
          <el-row type="flex" align="middle">
            <p class="title">
              <span class="header mr15">
                入库单号:
                <span class="ml15">{{ detail.warehouseInNumber }}</span>
              </span>
              <el-tag v-if="detail.adjustStatus == 1" type="scuucess">
                已调整
              </el-tag>
              <el-tag v-else-if="detail.adjustStatus == 2" type="info">
                调整中
              </el-tag>
              <span v-else></span>
            </p>
          </el-row>
        </template>
      </el-page-header>
    </el-row>

    <template>
      <el-tabs v-model="active" class="mt20">
        <el-tab-pane label="入库详情" name="1">
          <h3 class="mt10 mb20">基本信息</h3>
          <BaseDetail :detail="detail" />

          <div class="detail-table">
            <h3 class="mt30 mb20">入库产品</h3>
            <BaseProDetail :detailId="detailId" :detail="detail"></BaseProDetail>
          </div>
          <!-- 库位 -->
          <Loc :params="locParams" @close="onClose('loc')" ref="loc" :showStatistics="false" width="600px"></Loc>
          <div class="detail-files mt20">
            <h3 class="mt30 mb20">附件</h3>
            <div v-if="fileList.length">
              <div class="flie-list" v-for="(item, i) in fileList" :key="i">
                <i class="el-icon-document"></i>
                <a class="page-link ml5">
                  <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                    <span class="ell">
                      <router-link :to="`/preview-file/index?name=${item.name}&url=${item.url}`" target="_blank">
                        {{ item.name }}
                      </router-link>
                    </span>
                  </el-tooltip>
                </a>
                <span class="size ml10">
                  {{ utils.calculateFileSize(item.size) }}
                </span>
                <span class="ml10">{{ item.time }}</span>
              </div>
            </div>
            <div v-else>--</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="关联调整单" name="2">
          <div class="costTable">
            <el-table :data="tableData" border :header-cell-style="{ 'text-align': 'center' }"
              :cell-style="{ 'text-align': 'center' }">
              <el-table-column label="调整单号" prop="costAdjustNumber" width="150"></el-table-column>
              <el-table-column label="产品编码" prop="sku" width="120"></el-table-column>
              <el-table-column label="产品名称" prop="productCn"></el-table-column>
              <el-table-column label="规格" prop="specification" width="120"></el-table-column>
              <el-table-column label="数量" prop="warehouseInNum" width="120"></el-table-column>
              <el-table-column label="调整金额" prop="adjustPrice" width="120">
                <template slot-scope="scope">
                  {{
                  scope.row.adjustPrice && scope.row.adjustPrice.toFixed(3)
                  }}
                </template>
              </el-table-column>
              <el-table-column label="调整前单价" prop="priceExcludingTax" width="120">
                <template slot-scope="scope">
                  {{
                  scope.row.priceExcludingTax &&
                  scope.row.priceExcludingTax.toFixed(3)
                  }}
                </template>
              </el-table-column>
              <el-table-column label="调整前总成本" prop="totalPriceExcluding" width="120">
                <template slot-scope="scope">
                  {{
                  scope.row.totalPriceExcluding &&
                  scope.row.totalPriceExcluding.toFixed(3)
                  }}
                </template>
              </el-table-column>
              <el-table-column label="调整后单价" prop="priceExcludingTaxed" width="120">
                <template slot-scope="scope">
                  {{
                  scope.row.priceExcludingTaxed &&
                  scope.row.priceExcludingTaxed.toFixed(3)
                  }}
                </template>
              </el-table-column>
              <el-table-column label="调整后总成本" prop="totalPriceExcluded" width="120">
                <template slot-scope="scope">
                  {{
                  scope.row.totalPriceExcluded &&
                  scope.row.totalPriceExcluded.toFixed(3)
                  }}
                </template>
              </el-table-column>
              <el-table-column label="创建人" prop="creatorName" width="120"></el-table-column>
              <el-table-column label="创建时间" prop="createTime"></el-table-column>
            </el-table>
          </div>

          <el-pagination :current-page="page.pageNo" :page-size="page.pageLe" :total="total"
            :page-sizes="[10, 20, 30, 40, 50, 100]" @current-change="pageChange" @size-change="sizeChange"
            layout="total, sizes, prev, pager, next, jumper" />
        </el-tab-pane>
      </el-tabs>
    </template>
  </div>
</template>

<script>
  import { InboundSheet } from '@/core'
  import BaseStar from '@/components/base-star/base-star'
  import Loc from './components/loc'
  import BaseDetail from './components/base-detail'
  import BaseProDetail from './components/base-product-detail'
  import { getRemainingHeight } from 'kits'
  let self
  export default {
    name: 'inbound-sheet-detail',
    props: {
      detailId: {
        type: String,
        default: '',
      },
    },
    data() {
      self = this
      return {
        active: '1',
        detail: {
          area: '',
          createWay: 0,
          fileNames: '',
          fileUrls: '',
          operationId: '',
          operationName: '',
          orderNumber: '',
          remark: '',
          status: 0,
          supplierName: '',
          type: 0,
          warehouseId: '',
          warehouseInId: '',
          warehouseInNumber: '',
          adjustStatus: 0,

          warehouseInProductVOList: [
            {
              contractNum: 0,
              giftNum: 0,
              priceExcludingTax: 0,
              priceIncludingTax: 0,
              productName: '',
              productSpec: '',
              productType: '',
              productUnit: '',
              remark: '',
              skuId: '',
              tenantId: '',
              totalPriceExcluding: 0,
              totalPriceIncluding: 0,
              warehouseInId: '',
              warehouseInNum: 0,
              warehouseInProductId: '',
              warehouseInProductLocationVOList: [
                {
                  locationNumber: '',
                  num: 0,
                  warehouseInProductLocationId: '',
                },
              ],
            },
          ],
        },
        warehouses: [],
        fileList: [],
        locParams: {
          show: false,
          disabled: true,
        },
        tableData: [],
        page: {
          pageNo: 1,
          pageLe: 10,
        },
        total: 0,
      }
    },

    components: {
      Loc,
      BaseStar,
      BaseDetail,
      BaseProDetail,
    },
    computed: {
      warehouseInId() {
        return this.$route.query.warehouseInId
      },
      typeIsCg() {
        //采购入库
        return this.detail.type === 0
      },
      //退货入库
      typeIsTh() {
        return this.detail.type === 1
      },
      //其他入库
      typeIsQt() {
        return this.detail.type === 2
      },
    },
    // mixins: [globalBackButtonMixin],
    methods: {
      goBack() {
        this.$router.push('/stock-center/inbound-sheet/inbound-sheet')
      },

      onClose() {
        this.locParams.show = false
      },
      addLoc(row) {
        this.locParams.show = true
        this.$nextTick().then(() => {
          this.$refs.loc.locs = row.warehouseInProductLocationVOList.map(
            (i) => {
              i.currentNum = 0
              return i
            }
          )
        })
      },

      // 获取关联调整单
      getCostAdjust(warehouseNumber) {
        const params = {
          warehouseNumber: warehouseNumber,
          ...this.page,
        }
        InboundSheet.costAdjustWarehouseIn(params).then((res) => {
          this.tableData = res?.data?.data || []
          this.total = res?.data?.total || 0
        })
      },

      pageChange(val) {
        this.page.pageNo = val
        this.getCostAdjust(this.detail.warehouseInNumber)
      },

      sizeChange(val) {
        this.page.pageNo = 1
        this.page.pageLe = val
        this.getCostAdjust(this.detail.warehouseInNumber)
      },
    },

    created() {
      const warehouseInId = this.detailId || this.$route.query.warehouseInId
      let method = this.detailId
        ? InboundSheet.getDetailNoAuth
        : InboundSheet.getDetail

      InboundSheet.findWarehouseList({
        hasCloudWarehouse: 1,
      }).then((res) => {
        this.warehouses = res.data || []
      })
      method({ warehouseInId }).then((res) => {
        if (res && res.code == '000000') {
          if (res.data && res.data.warehouseInProductVOList) {
            res.data.warehouseInProductVOList.forEach((i) => {
              i.totalPriceExcluding = this.toFixed3(i.totalPriceExcluding)
              i.priceExcludingTax = this.toFixed3(i.priceExcludingTax)
              i.priceIncludingTax = this.toFixed3(i.priceIncludingTax)
            })
            res.data.warehouseInProductVOList =
              res.data.warehouseInProductVOList.map((item) => {
                return {
                  ...item,
                  processNumber: item.salesOrderNumber,
                  salesOrderNumber: res.data.orderNumber,
                }
              })
          }
          // 详情显示销售单号
          res.data.salesOrderNumber =
            res.data.warehouseInProductVOList[0].processNumber
          setTimeout(() => {
            this.detail = res.data
            let files = []
            if (res.data.fileUrls) {
              files = files.concat(JSON.parse(res.data.fileUrls))
            }
            if (res.data.originFileUrls) {
              files = files.concat(JSON.parse(res.data.originFileUrls))
            }
            this.fileList = files

            // 获取关联调整单
            this.getCostAdjust(this.detail.warehouseInNumber)
            // 将warehouseId传递给父组件
            this.$emit('warehouseId', res.data?.warehouseId)
          }, 400)

        }
      })



      // 审核详情隐藏tabs标签
      if (this.$route.name === 'AuditManage') {
        this.$nextTick(() => {
          const _ele = document.getElementsByClassName('el-tabs__header')[2]
          if (_ele) _ele.style.display = 'none'
        })
      }
    },
  }
</script>

<style scoped lang="scss">
  .table-container {
    padding: 20px !important;

    & .title {
      display: flex;
      align-items: center;
    }
  }

  .detail-title {
    margin-bottom: 15px;
  }

  .detail-label {
    font-size: 14px;
    color: #606266;
  }

  .detail-content {
    font-size: 16px !important;
    margin-top: 10px;
    font-weight: normal;
  }

  .header {
    font-size: 18px;
    color: #606266;
    //font-family: 'PingFang Bold';
  }

  .mr15 {
    margin-right: 15px;
  }

  .ml15 {
    margin-left: 15px;
  }

  .mt20 {
    margin-top: 20px;
  }

  .pointer {
    cursor: pointer;
  }

  .costTable {
    height: calc(100vh - 260px);
    overflow-y: auto;
  }

  ::v-deep {
    .el-page-header {
      margin: 0 !important;
    }

    .el-pagination {
      height: 38px;
    }
  }

  .el-tooltip__popper {
    max-width: 400px;
  }
</style>
