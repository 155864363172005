<template>
  <el-dialog title="加工完成" width="1630px" :visible.sync="visible" :before-close="handleClose"
    :close-on-click-modal="false" append-to-body>
    <el-form ref="form" :model="form">
      <div class="flex">
        <el-form-item label="完工结果" label-width="70px">
          <el-radio-group v-model="form.completeStatus">
            <el-radio :label="3">正常完工</el-radio>
            <el-radio :label="4">异常完工</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.completeStatus === 4" class="ml30" label="异常原因" prop="abnormalRemark"
          label-width="90px" :rules="[
            {
              required: true,
              message: '填写异常完工的原因',
              trigger: 'blur',
            },
          ]">
          <el-input v-model="form.abnormalRemark" placeholder="填写异常完工的原因" style="width: 300px"></el-input>
        </el-form-item>
      </div>

      <el-table class="dyn-table mt10" :data="form.completeProcessProductDTOList"
        :cell-style="{ 'text-align': 'center' }" :header-cell-style="{ 'text-align': 'center' }" :max-height="500"
        border>
        <el-table-column label="生产单号" prop="processOrderCode" width="100px"></el-table-column>
        <el-table-column label="销售单号" prop="salesOrderCode" width="100px"></el-table-column>
        <el-table-column label="效果图" width="80px">
          <template slot-scope="scope">
            <div>
              <erp-image :imgs="scope.row.productImage"></erp-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="产品信息" width="160px">
          <template slot-scope="scope">
            <div class="text-left">
              <p>{{ scope.row.skuId }}</p>
              <el-tooltip class="item" effect="dark" :content="scope.row.productCn" placement="top-start">
                <p class="ell">{{ scope.row.productCn }}</p>
              </el-tooltip>
              <p>产品颜色：{{ scope.row.productSpecs }}</p>
              <!-- <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row.productMaterial"
                placement="top-start"
              >
                <p class="ell">材质：{{ scope.row.productMaterial }}</p>
              </el-tooltip> -->
              <p>印刷方式：{{ scope.row.printWay }}</p>
              <p>印刷颜色：{{ scope.row.printColor }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="用料数量" prop="useNum" width="80px"></el-table-column>
        <el-table-column label="要求加工数量" width="120px" fixed="right">
          <template slot-scope="scope">
            <div class="text-left">
              <p>总数：{{ scope.row.processNum + scope.row.sampleNum }}</p>
              <p>订单数量：{{ scope.row.processNum }}</p>
              <p>留样数量：{{ scope.row.sampleNum }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="合格数量" width="90px" label-class-name="required-th" fixed="right">
          <template slot-scope="scope">
            <el-form-item :prop="
                'completeProcessProductDTOList.' + scope.$index + '.qualityNum'
              " :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: 'blur',
                },
                {
                  pattern: /^[0-9]+?$/,
                  message: '请输入>=0的整数',
                  trigger: 'blur',
                },
              ]">
              <el-input v-model="scope.row.qualityNum" @blur="calcTotalFee(scope.$index)" placeholder="请输入"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="报废数量" width="170px" label-class-name="required-th" fixed="right">
          <template slot-scope="scope">
            <div class="num-sty text-left">
              <el-form-item label="制程损耗:" label-width="90px" :prop="
                  'completeProcessProductDTOList.' +
                  scope.$index +
                  '.processLoseNum'
                " :rules="[
                  {
                    required: true,
                    message: '请输入',
                    trigger: 'blur',
                  },
                  {
                    pattern: /^[0-9]+?$/,
                    message: '请输入>=0的整数',
                    trigger: 'blur',
                  },
                ]">
                <el-input v-model="scope.row.processLoseNum" @blur="calcNum(scope.$index, 'processLoseNum')"
                  placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="原料问题:" label-width="90px" :prop="
                  'completeProcessProductDTOList.' +
                  scope.$index +
                  '.rawScrapNum'
                " :rules="[
                  {
                    required: true,
                    message: '请输入',
                    trigger: 'blur',
                  },
                  {
                    pattern: /^[0-9]+?$/,
                    message: '请输入>=0的整数',
                    trigger: 'blur',
                  },
                ]">
                <el-input v-model="scope.row.rawScrapNum" @blur="calcNum(scope.$index)" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="供应商问题:" label-width="90px" :prop="
                  'completeProcessProductDTOList.' +
                  scope.$index +
                  '.supplierScrapNum'
                " :rules="[
                  {
                    required: true,
                    message: '请输入',
                    trigger: 'blur',
                  },
                  {
                    pattern: /^[0-9]+?$/,
                    message: '请输入>=0的整数',
                    trigger: 'blur',
                  },
                ]">
                <el-input v-model="scope.row.supplierScrapNum" @blur="calcNum(scope.$index)"
                  placeholder="请输入"></el-input>
              </el-form-item>
              <p>
                <label>报废总数:</label>
                <span class="red">{{ scope.row.scrapNum }}</span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="加工费单价" width="110px" label-class-name="required-th" fixed="right">
          <template slot-scope="scope">
            <el-form-item :prop="
                'completeProcessProductDTOList.' +
                scope.$index +
                '.feeUnitPrice'
              " :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: 'blur',
                },
                {
                  pattern: /^\d+(\.\d{1,2})?$/,
                  message: '>=0最多两位小数',
                  trigger: 'blur',
                },
              ]">
              <el-input v-model="scope.row.feeUnitPrice" @blur="calcTotalFee(scope.$index)"
                placeholder="请输入"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="版费" width="90px" fixed="right">
          <template slot-scope="scope">
            <el-form-item :prop="
                'completeProcessProductDTOList.' + scope.$index + '.editionFee'
              " :rules="[
                {
                  pattern: /^\d+(\.\d{1,2})?$/,
                  message: '>=0最多两位小数',
                  trigger: 'blur',
                },
              ]">
              <el-input v-model="scope.row.editionFee" @blur="calcTotalFee(scope.$index)" placeholder="请输入"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="其它费用" width="90px" fixed="right">
          <template slot-scope="scope">
            <el-form-item :prop="
                'completeProcessProductDTOList.' + scope.$index + '.otherFee'
              " :rules="[
                {
                  pattern: /^\d+(\.\d{1,2})?$/,
                  message: '>=0最多两位小数',
                  trigger: 'blur',
                },
              ]">
              <el-input v-model="scope.row.otherFee" @blur="calcTotalFee(scope.$index)" placeholder="请输入"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="费用说明" width="120px" fixed="right">
          <template slot-scope="scope">
            <el-input type="textarea" maxlength="500" show-word-limit :rows="3" v-model="scope.row.feeDesc"
              placeholder="500字符以内"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="折扣金额" width="120px" fixed="right">
          <template slot-scope="scope">
            <div class="discount-sty">
              <el-form-item :prop="
                  'completeProcessProductDTOList.' +
                  scope.$index +
                  '.discountAmount'
                " :rules="[
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message: '>=0最多两位小数',
                    trigger: 'blur',
                  },
                ]">
                <el-input v-model="scope.row.discountAmount" @blur="calcTotalFee(scope.$index)"
                  placeholder="请输入"></el-input>
              </el-form-item>
              <el-button :class="
                  form.completeProcessProductDTOList[scope.$index].discountDesc
                    ? 'icon-b'
                    : 'icon-y'
                " icon="el-icon-edit-outline" circle
                @click="discountClick(scope.row.discountDesc, scope.$index)"></el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="合计费用" width="90px" fixed="right">
          <template slot-scope="scope">
            {{ scope.row.totalFee }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="60px" fixed="right">
          <template slot-scope="scope">
            <el-button type="danger" plain icon="el-icon-delete" circle size="mini"
              @click="delItem(scope.row, scope.$index)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="save" :loading="loading">确 定</el-button>
      <el-button v-show="rows.length === 1" @click="printXM(rows[0])">
        打印箱唛
      </el-button>
    </span>

    <el-dialog title="折扣金额说明" width="600px" :visible.sync="modelVisible" :close-on-click-modal="false"
      :before-close="descClose" append-to-body>
      <el-form :model="descForm" :rules="descRules" ref="descForm" label-width="80px">
        <el-form-item label="折扣备注" prop="discountDesc">
          <el-input type="textarea" v-model="descForm.discountDesc" maxlength="500" show-word-limit :rows="3"
            placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>

      <div class="text-center mt30">
        <el-button type="primary" @click="descSave">确认</el-button>
        <el-button @click="descClose">取消</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
  import { OutProcessing } from '@/core/interactors/purchase/outsourceProcess'
  export default {
    data() {
      return {
        descIndex: 0,
        form: {
          completeStatus: 3, // 加工完成状态
          abnormalRemark: '', // 异常原因
          processOrderCode: '', // 	加工单编码
          processOrderId: '', // 	加工单ID
          salesOrderCode: '', // 	加工单销售订单
          completeProcessProductDTOList: [], // 完成商品信息
        },
        rows: {},
        descForm: {
          discountDesc: '',
        },
        descRules: {
          discountDesc: [
            {
              required: true,
              message: '请输入',
              trigger: 'blur',
            },
          ],
        },
        visible: false,
        modelVisible: false,
        loading: false
      }
    },
    methods: {
      // 初始化
      init(_arr) {
        this.rows = _arr
        this.form.processOrderCode = _arr[0].processOrderCode
        this.form.processOrderId = _arr[0].processOrderId
        this.form.salesOrderCode = _arr[0].salesOrderCode
        this.form.completeProcessProductDTOList = _arr.map((item) => {
          return {
            ...item,
            discountAmount: '', // 折扣金额
            discountDesc: '', // 折扣说明
            editionFee: '', // 版费
            feeDesc: '', // 费用说明
            feeUnitPrice: '', // 加工费单价
            otherFee: '', // 其他费用
            processLoseNum: 0, // 制程损耗
            qualityNum: Number(item.processNum) + Number(item.sampleNum), // 	合格数量
            rawScrapNum: 0, // 原料问题报废数量
            scrapNum: 0, // 报废数量
            supplierScrapNum: 0, // 供应商报废数量
            totalFee: 0, // 费用合计
          }
        })
        this.visible = true
      },

      /**
       * 计算制程损耗
       * 订单数量+留样数量）x 损耗率，结果抹除多余小数位、保留整数
       */
      getProcessLoseNum(obj) {
        return Math.floor(
          (Number(obj.processNum) + Number(obj.sampleNum)) * obj.loseRatio * 0.01
        )
      },

      /**
       * 计算报废总数
       * @param {i}
       * @param {type} 制程损耗失焦单独处理
       */
      calcNum(i, type) {
        let obj = this.form.completeProcessProductDTOList[i]

        obj.scrapNum =
          Number(obj.processLoseNum) +
          Number(obj.rawScrapNum) +
          Number(obj.supplierScrapNum)
        type === 'processLoseNum' && this.calcTotalFee(i)
      },

      /**
       * 合计费用计算
       * 加工费单价 x（【合格数量】+损耗率范围内的【制程损耗】数量）+版费+其他费用-折扣金额
       * 合理损耗范围=（订单数量+留样数量）x 损耗率，结果抹除多余小数位、保留整数
       */
      calcTotalFee(i) {
        let list = this.form.completeProcessProductDTOList[i]

        if (list.feeUnitPrice && list.qualityNum) {
          let num = this.getProcessLoseNum(list)

          num > Number(list.processLoseNum) && (num = list.processLoseNum)
          list.totalFee = (
            Number(list.feeUnitPrice * (Number(list.qualityNum) + Number(num))) +
            Number(list.editionFee) +
            Number(list.otherFee) -
            Number(list.discountAmount)
          ).toFixed(2)
        }
      },

      // 取消，关闭弹窗
      handleClose() {
        this.form = this.$options.data().form
        this.$refs.form.resetFields()
        this.visible = false
      },

      // 保存
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const list = this.form.completeProcessProductDTOList

            for (let i = 0; i < list.length; i++) {
              // 校验折扣金额说明必填
              if (list[i].discountAmount && !list[i].discountDesc) {
                return this.$message.warning('折扣金额说明必填')
              }

              // 正常完工校验合格数量≥要求加工数量
              if (
                this.form.completeStatus === 3 &&
                list[i].qualityNum < list[i].processNum + list[i].sampleNum
              ) {
                return this.$message.warning(
                  '正常完工时，合格数量不能小于要求加工数量'
                )
              }

              // 校验填写的合格品+报废品数量之和必须等于用料数量
              if (
                Number(list[i].qualityNum) + Number(list[i].scrapNum) !=
                list[i].useNum
              ) {
                return this.$message.warning(
                  '填写的合格品+报废品数量之和必须等于用料数量'
                )
              }
            }

            this.submit()
          }
        })
      },

      // 提交
      async submit() {
        this.loading = true
        const res = await OutProcessing.completeProcessProduct({
          ...this.form,
        })


        if (res && res.code === '000000') {
          this.$message.success('操作成功')
          this.$emit('FinishPorcess')
          this.handleClose()
        }
        this.loading = false
      },

      // 折扣金额说明
      discountClick(text, i) {
        this.descIndex = i
        this.descForm.discountDesc = text
        this.modelVisible = true
      },

      // 折扣金额保存
      descSave() {
        this.$refs.descForm.validate((valid) => {
          if (valid) {
            this.form.completeProcessProductDTOList[this.descIndex].discountDesc =
              this.descForm.discountDesc
            this.descClose()
          }
        })
      },

      // 折扣金额弹窗关闭
      descClose() {
        this.$refs.descForm.resetFields()
        this.modelVisible = false
      },

      // 删除一行
      delItem(row, index) {
        if (this.form.completeProcessProductDTOList.length === 1) {
          return this.$message.warning('至少保留一条数据')
        }
        this.$confirm('是否删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.form.completeProcessProductDTOList.splice(index, 1)
          this.rows.splice(index, 1)
        })
      },

      // 打印箱唛
      async printXM(row) {
        if (row.operateProcessOrder != 1) {
          this.$message.warning('没有操作权限！')
          return
        }
        const res = await OutProcessing.processPrintOrderMarks({
          orderId: row.salesOrderId,
          orderProductUniqueId: row.orderProductUniqueId.split(','),
          printType: 3,
          checkFlag: 0,
          skuIds: row.skuId.split(','),
        })

        if (res && res.code === '000000') {
          if (res.data?.marksShowVOS?.length) {
            const _str = JSON.stringify(res.data?.marksShowVOS)

            window.open(
              window.location.origin +
              `#/print/orderShipMark?QRCode=${true}&rendImgDisplayed=${false}&content=${_str}&type=1`,
              '_blank'
            )
          } else {
            this.$message.warning('箱唛内容为空！')
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .num-sty {
    p {
      margin-bottom: 5px;

      label {
        width: 95px;
        text-align: right;
        display: inline-block;
        padding-right: 10px;
      }
    }

    /deep/ .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
      display: none;
    }
  }

  .dialog-footer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .discount-sty {
    position: relative;
    text-align: left;

    .el-input {
      width: 70px;
    }

    .el-button {
      position: absolute;
      top: 8px;
      right: -5px;
      z-index: 2;
      height: 28px;
      width: 28px;
      margin: 0;

      /deep/ i {
        font-size: 16px;
        margin: -3px;
      }
    }
  }

  ::v-deep {
    .el-dialog__body {
      padding: 5px 20px 20px;
    }

    .el-form-item__label {
      color: #303133;
    }

    .el-form-item--small.el-form-item {
      margin-bottom: 10px;
    }
  }
</style>