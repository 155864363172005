<template>
  <div class="table-container" ref="mallOrder">
    <el-row class="page-header align_center pub-h-36" type="flex">
      <!-- 查询表单 -->
      <el-form ref="form" :inline="true" :model="queryForm" @submit.native.prevent :label-width="lang === 'en' ? '160px' : '100px'">
        <el-form-item :label="$t('orderDetails.MallOrderNumber') + '：'">
          <el-input v-model="queryForm.mallOrderCode" clearable class="pub-h-36" />
        </el-form-item>
        <el-form-item :label="$t('orderDetails.CompanyName') + '：'">
          <el-input v-model="queryForm.companyName" clearable class="pub-h-36" />
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="searchClick" class="pub-h-36">
        搜索
      </el-button>
    </el-row>
    <el-row class="mt20 ml10">
      <el-col>
        <el-radio-group v-model="queryForm.handleFlag" @change="handleFlagChangeFn">
          <el-radio label="">{{ $t('orderDetails.All') }}</el-radio>
          <el-radio :label="0">{{ $t('orderDetails.NotProcessed') }}</el-radio>
          <el-radio :label="1">{{ $t('orderDetails.Processed') }}</el-radio>
        </el-radio-group>
      </el-col>
    </el-row>
    <!-- 表格 -->
    <div class="main-table mt20">
      <el-row :style="{ height: `${maxHeight}px` }" class="mt10" style="overflow-y: auto">
        <el-table :data="tableData" style="width: 100%" border ref="testTable" class="test-table">
          <el-table-column prop="mallOrderCode" :label="$t('orderDetails.MallOrderNumber')"
            align="center"></el-table-column>
          <el-table-column prop="contractDate" :label="$t('orderDetails.OrderDate')" align="center"></el-table-column>
          <el-table-column :label="$t('orderDetails.ProductNumber')" align="center">
            <template slot-scope="scope">
              <el-tooltip placement="top" effect="dark" :content="scope.row.productCode">
                <p class="product">{{ scope.row.productCode }}</p>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column :label="$t('orderDetails.ProductName')" align="center">
            <template slot-scope="scope">
              <el-tooltip placement="top" effect="dark" :content="scope.row.productEn">
                <p class="product">{{ scope.row.productEn }}</p>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column :label="$t('orderDetails.TotalAmount')" align="center">
            <template slot-scope="scope">
              <span>${{ scope.row.totlePrice | orderMoneyFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="businessName" :label="$t('orderDetails.CustomerRepresentative')"
            align="center"></el-table-column>
          <el-table-column min-width="140px" prop="companyName" :label="$t('orderDetails.CompanyName')"
            align="center"></el-table-column>
          <el-table-column prop="handleFlag" :label="$t('orderDetails.Status')" align="center">
            <template slot-scope="scope">
              <span>
                {{
                scope.row.handleFlag === 1
                ? $t('orderDetails.Processed')
                : $t('orderDetails.NotProcessed')
                }}
              </span>
              <el-tooltip placement="top" effect="dark" v-if="scope.row.rendingFlag == 1 || scope.row.rendingFlag == 2">
                <div slot="content">
                  <span v-if="scope.row.rendingFlag == 1">
                    {{$t('orderDetails.NoArtWork')}}
                  </span>

                  <span v-else-if="scope.row.rendingFlag == 2">
                    {{$t('orderDetails.toConfirm')}}
                  </span>
                </div>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column :label="$t('AddOrder.Operation')" align="center" width="180">
            <template slot-scope="scope">
              <router-link v-if="
                  scope.row.handleFlag === 1 && scope.row.operateMallOrder == 1
                "
                :to="`/order/orderList/orderDetail?orderCode=${scope.row.salesOrderCode}&orderId=${scope.row.orderId}`">
                <el-button type="text">
                  {{ $t('orderDetails.CheckOrder') }}
                </el-button>
              </router-link>
              <router-link v-if="
                  scope.row.handleFlag === 0 && scope.row.operateMallOrder == 1
                " :to="`/order/mallOrder/dea-with?id=${scope.row.id}`">
                <el-button type="text">
                  {{ $t('orderDetails.DealWith') }}
                </el-button>
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <Pagination v-if="tableData && tableData.length" :limit.sync="queryForm.pageLe" :page.sync="queryForm.pageNo"
        :total="queryForm.total" @pagination="fetchData" />
    </div>
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination/Index'
  import { MallOrderInteractor } from '@/core'
  import {
    mapGetters
  } from 'vuex'
  export default {
    name: 'MallOrder',
    data() {
      return {
        tableData: [],
        queryForm: {
          mallOrderCode: '',
          companyName: '',
          handleFlag: '',
          pageLe: 10,
          pageNo: 1,
          total: 0,
        },
        maxHeight: 0,
      }
    },
    computed:{
      ...mapGetters({
        lang: 'settings/language'
      }),
    },
    components: {
      Pagination,
    },
    created() {
      if(this.$route.query.handleFlag) {
        this.queryForm.handleFlag = this.$route.query.handleFlag * 1
      }
      this.initTableStyle()

      this.fetchData()
    },
    methods: {
      initTableStyle() {
        this.$nextTick(() => {
          const baseTableEle = this.$refs['mallOrder']
          const maxHeight = baseTableEle.offsetHeight - 212
          this.maxHeight = maxHeight
        })
      },
      searchClick() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData(pageData) {
        if (pageData) {
          this.queryForm.pageNo = pageData.page
          this.queryForm.pageLe = pageData.limit
        }
        const data = {
          mallOrderCode: this.queryForm.mallOrderCode,
          companyName: this.queryForm.companyName,
          handleFlag: this.queryForm.handleFlag,
          pageNo: this.queryForm.pageNo,
          pageLe: this.queryForm.pageLe,
        }
        const res = await MallOrderInteractor.listPageMallOrderApi(data)
        if (res.code === '000000') {
          this.queryForm.total = res.data.total
          this.tableData = res.data.data
        }
      },
      handleFlagChangeFn(val) {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep.table-container {
    .page-header {
      padding: 20px 0;
    }

    .el-form--inline .el-form-item {
      margin-bottom: 0;
    }

    .product {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
