<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item :label="labelText" prop="name">
              <el-input v-model.trim="form.name" placeholder="请输入" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" :loading="loading" @click="save">
          确 认
        </el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { insertPO, updatePO } from '@/api/power-group'
  export default {
    name: 'PowerGroupAddModel',
    data() {
      return {
        loading: false,
        title: '添加分组',
        form: {
          divideGroup: 1,
          name: '',
        },
        labelText: '分组名称',
        rules: {
          name: [{ required: true, trigger: 'blur', message: `请输入` }],
        },
        dialogFormVisible: false,
        value: null,
        isUpdata: false,
      }
    },
    created() {},
    methods: {
      //新增、编辑
      showAddEdit(str, data, str2) {
        if (str === 'role' || str === 'role2') {
          this.title = str === 'role' ? '新增角色' : '编辑角色'
          this.labelText = '角色名称'
          this.form.parentId = data.roleId
          this.form.divideGroup = 0
        }
        if (str === 'group2') {
          this.title = '编辑分组'
        }
        if (str2 === 'update') {
          this.isUpdata = true
          this.form.name = data.name
          this.form.parentId = data.parentId
          this.form.roleId = data.roleId
        }
        this.dialogFormVisible = true
      },
      close() {
        this.isUpdata = false
        this.title = '添加分组'
        this.labelText = '分组名称'
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      async save() {
        let form = JSON.parse(JSON.stringify(this.form))
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.loading = true
            if (!this.isUpdata) {
              let response = await insertPO(form)
              this.loading = false
              if (response.code === '000000') {
                this.$baseMessage(
                  '操作成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            } else {
              let response = await updatePO(form)
              this.loading = false
              if (response.code === '000000') {
                this.$baseMessage(
                  '操作成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            }
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
</style>
