<template>
  <el-dialog :title="$t('orderList.onlinePayment')" width="800px" class="dialog" :visible.sync="payVisible"
    :close-on-click-modal="false" :before-close="close">
    <table class="custom-table">
      <thead>
        <tr>
          <th class="index">#</th>
          <th width="400"><!-- 收款节点 -->{{ $t('orderList.paymentMilestones') }}</th>
          <th :width="lang === 'en' ? 260 : 200"><!-- 预计收款时间 -->{{ $t('orderList.expectedPaymentDate') }}</th>
          <th width="200"><!-- 应收金额 -->{{ $t('orderList.receivableAmount') }}</th>
          <th width="200"><!-- 已收金额 -->{{ $t('orderList.receivedAmount') }}</th>
        </tr>
      </thead>
      <tbody v-if="payList.length">
        <tr v-for="(item, i) in payList" :key="i">
          <td>{{ i + 1 }}</td>
          <td>{{ item.paymentLink ? `${item.paymentLink}--${item.paymentPropor}%` : '--' }}</td>
          <td>{{ item.receivablePriceDate || '--' }}</td>
          <td>
            <div v-if="isViewCost">
              {{ item.receivablePrice || '--' }}
            </div>
            <NoViewCost v-else />
          </td>
          <td>
            <div v-if="isViewCost">
              {{ item.actualReceivablePrice || '--' }}
            </div>
            <NoViewCost v-else />
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="5">暂无数据</td>
        </tr>
      </tbody>
    </table>
    <el-row class="mt20">
      <el-col :span="16">
        <el-form :model="form" ref="ruleForm">
          <!-- 在线支付金额 -->
          <el-form-item :label="$t('orderList.onlinePaymentAmount')" :label-width="lang === 'en' ? '180px' : '110px'"
            prop="payAmount" :rules="[
              { required: true, message: lang === 'en' ? 'Please enter the online payment amount' : '请输入在线支付金额', trigger: 'blur' },
              {
                pattern: /(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/,
                message: lang === 'en' ? '>0 and up to two decimal places' : '>0且最多保留两位小数',
                trigger: 'blur',
              },
            ]">
            <el-input v-model="form.payAmount" @blur="payAmountChange" maxlength="20" clearable></el-input>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="8" class="red" v-show="showTips">
        <div class="ml10" style="margin-top: 8px"><!-- 提示：有进行中的收款单 -->{{ $t('orderList.tip') }}</div>
      </el-col>
    </el-row>

    <div class="text-center mt20">
      <el-button type="primary" @click="createLink"><!-- 生成链接 -->{{ $t('orderList.generateLink') }}</el-button>
      <el-button @click="close()"><!-- 取消 -->{{ $t('orderList.cancel') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { orderPayOnline, orderGetLink } from '@/api/order'
  import { listFinanceVliedSheet } from '@/api/finance/finance-cost'
  import viewCost from '@/views/order/orderList/util'
  import NoViewCost from '@/views/purchase/purchaseOrder/components/no-view-cost'
  import { keyStr } from '@/config'
  import { base64Encode } from 'kits'
  import { mapGetters } from 'vuex'
  export default {
    components: {
      NoViewCost,
    },
    data() {
      return {
        isViewCost: viewCost(),
        orderId: '',
        orderCode: '',
        form: {
          payAmount: 0,
        },
        payList: [],
        maxMoney: null, // 可输入的最大支付金额
        lastNodeFlag: '', // 最后一个节点标志 0:非最后一个节点 1:最后一个节点
        type: null, // 支付节点
        payVisible: false,
        showTips: false,
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    methods: {
      // 获取详情
      getDetail(code, id) {
        let self = this
        self.orderCode = code
        self.orderId = id
        orderPayOnline({ orderCode: code }).then((res) => {

          if (res.code !== '000000') return
          self.payList = res?.data ?? []
          // 计算最大支付金额
          let [rMoney, aMoney] = [0, 0]
          self.payList.map((item) => (rMoney += Number(item.receivablePrice)))
          self.payList.map(
            (item) => (aMoney += Number(item.actualReceivablePrice))
          )
          self.maxMoney = rMoney - aMoney
          // 获取在线支付金额，=已收金额有值的下一条应收金额
          let flag = true
          let isLast = self.payList.every((item) => item.actualReceivablePrice)
          if (isLast) {
            self.form.payAmount = 0
            self.lastNodeFlag = 1
            self.type = self.payList.length
          } else {
            self.payList.forEach((item, i) => {
              if (!item.actualReceivablePrice && flag) {
                self.form.payAmount = self.payList[i].receivablePrice
                self.lastNodeFlag = 1
                self.type = i + 1
                flag = false
              }
            })
          }
          self.payVisible = true
        })

        this.getListFinanceVliedSheet(code)
      },

      // 销售订单是否存在待处理待确认的收款单
      getListFinanceVliedSheet(code) {
        listFinanceVliedSheet({ orderCode: code }).then((res) => {
          if (res && res.code === '000000') {
            this.showTips = res.data.length > 0 ? true : false
          }
        })
      },

      // 在线支付金额输入事件
      payAmountChange() {
        let self = this
        if (self.form.payAmount > self.maxMoney) {
          self.$message.warning(this.lang === 'en' ? 'The online payment amount cannot exceed the maximum receivable amount!' : '在线支付金额不能超过最大应收金额！')
          self.form.payAmount = self.maxMoney.toFixed(2)
        }
      },

      // 生成链接
      createLink() {
        let self = this
        self.$refs.ruleForm.validate((valid) => {
          if (valid) {
            const params = {
              lastNodeFlag: self.lastNodeFlag,
              orderCode: self.orderCode,
              orderId: self.orderId,
              payAmount: self.form.payAmount,
              type: self.type,
            }
            console.log(params, 'params====')
            orderGetLink({ param: base64Encode(params, keyStr) }).then(
              (res) => {
                if (res?.code === '000000') {
                  self.$emit('createLink', res?.data)
                  self.payVisible = false
                }

              }
            )
          } else {
            return false
          }
        })
      },

      // 关闭弹窗重置表单
      close() {
        this.$refs.ruleForm.resetFields()
        this.payVisible = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .dialog {
    .el-form-item {
      margin-bottom: 18px;
    }
  }
</style>
