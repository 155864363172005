import {
  otherChargesDetail,
  rejectOrder,
  adjustment,
  listAdjustmentRecord,
} from '@/core/services/api/order-settlement/index'

class orderSettleInteractor {
  static getInstance() {
    return this._instance
  }

  async getOtherChargesDetail(data) {
    try {
      const res = await otherChargesDetail(data).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 结算驳回
  async setRejectOrder(data) {
    try {
      const res = await rejectOrder(data).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 结算调整
  async adjustment(data) {
    try {
      const res = await adjustment(data).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 查询结算调整记录 getListAdjustmentRecord

  async getListAdjustmentRecord(data) {
    try {
      const res = await listAdjustmentRecord(data).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        res.data = res.data.map((item) => {
          return {
            ...item,
            settlementRecordStatusDesc:
              item.settlementRecordStatus == 1
                ? '<div>已完成<span style="color:red">（结算调整）</span></div>'
                : item.settlementRecordStatus == 2
                ? '<span style="color:red">结算调整</span>'
                : item.settlementRecordStatus == 3
                ? '已完成'
                : '--',
          }
        })
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }
}

orderSettleInteractor._instance = new orderSettleInteractor()

export const OrderSettleInteractor = orderSettleInteractor.getInstance()
