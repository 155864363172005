<!--
  功能：需我审核
  作者：jiangyongqiang
  时间：2021年11月16日 20:20:50
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <IndexCard title="需我审核" :loading="loading">
    <div v-if="list.length > 0">
      <template v-for="(item, index) in list">
        <IndexCardItem :key="index" class="my-audit-item">
          <div class="content" @click="clientFollowClick(item)">
            <div class="left">
              <div class="top">
                <span>({{ item.subName }})提交的</span>
                <span :style="boldStyle.bold">{{ item.name }}</span>
              </div>
              <div class="bottom">
                <span class="user">审核单号</span>
                <span style="color: #1890ff">{{ item.auditNumber }}</span>
              </div>
            </div>
            <div class="right index-card-time">
              {{ item.subTime | timeFilter }}
            </div>
          </div>
        </IndexCardItem>
      </template>
    </div>
    <IndexCardEmpty v-else></IndexCardEmpty>
    <!-- 抽屉 -->
    <!-- 审核详情 -->
    <el-drawer
      title="审核"
      :visible.sync="drawer"
      :before-close="handleClose"
      size="80%"
    >
      <div class="audit-content-wrapper">
        <!-- 审核内容 -->
        <h4>{{ $t('auditManage.AuditContent') }}</h4>
        <Detail
          ref="Detail"
          :leftCurrentText="leftCurrentText"
          :detailId="detailId"
          :isBatch="false"
          :passRow="passRow"
          :payType="payType"
          :operation="operation"
          :auditObjectId="auditObjectId"
          v-if="drawer"
          @handleClose="handleClose"
        />
        <!-- 审核进度 -->
        <h4>{{ $t('auditManage.AuditProgress') }}</h4>
        <Audit ref="Audit" :auditObjectId="auditObjectId" />
      </div>
    </el-drawer>
  </IndexCard>
</template>

<script>
  import mixins from '../mixins'
  import Detail from '@/views/audit-process/audit-manage/components/detail.vue'
  import Audit from '@/components/Audit/ApprovalsDrawer'
  import { AuditInteractor } from '@/core'
  export default {
    name: 'MyAudit',
    props: {},
    mixins: [mixins],
    components: {
      Detail,
      Audit,
    },
    // 组件状态值
    data() {
      return {
        list: [],
        statusDrawer: false,
        auditObjectId: '',
        leftCurrentText: '订单管理',
        drawer: false,
        detailId: '',
        passRow: {},
        payType: 1,
        operation: '1',
      }
    },
    computed: {},

    methods: {
      getLeftCurrentTextAndIdByAuditType(item) {
        let { auditType, auditContent } = item
        const type2text = {
            1: '订单管理',
            2: '采购备货',
            3: '采购订单',
            4: '入库管理',
            5: '出库管理',
            6: '产品管理',
            7: '付款管理',
            8: '费用管理',
            9: '售后管理',
            10: '供应商管理',
            11: '收付款方式',
            12: '订单结算',
            13: '收款管理',
            14: '结算调整',
            15: '成本调整',
            16: '客评中心',
            17: '客户审核',
            18: '公海审核',
            19: '客户黑名单审核',
            20: '调拨审核',
            21: '盘点审核',
            22: '客户账期',
          },
          type2Id = {
            1: 'orderCode',
            2: 'numberPrefix',
            3: 'number',
            4: 'warehouseInId',
            5: 'warehouseOutId',
            6: 'productId',
            7: 'applicationPayOrderId',
            8: 'costNumber',
            9: 'refundOrderId',
            10: 'supplierId',
            11: 'paymentId',
            12: 'settlementId',
            13: 'financeId',
            14: 'settlementSalesOrderId',
            15: 'costAdjustId',
            16: 'customerComplainId',
            20: 'transferOrderId',
            21: 'stockTakingId',
          }
        this.leftCurrentText = type2text[auditType]
        auditContent = JSON.parse(auditContent)
        this.detailId = auditContent[type2Id[auditType]] || ''
        if (auditType === 7) {
          this.payType = auditContent.payType
          this.detailId = {
            detailId: auditContent.payOrderId,
            isNewPayMent: true,
          }
        }
      },
      clientFollowClick(item) {
        const { auditObjectId, auditType, auditContent, internalType } = item
        this.getLeftCurrentTextAndIdByAuditType(item)
        this.auditObjectId = auditObjectId
        const { userId, userName, tenantId } = this.userInfo
        this.passRow = {
          auditObjectId,
          auditType: auditType,
          userId,
          userName,
          tenantId,
          auditContent: auditContent ? JSON.parse(auditContent) : {},
          internalType,
        }
        this.drawer = true
      },
      handleClose() {
        this.drawer = false
        this.getList()
      },

      getList() {
        this.loading = true
        AuditInteractor.listPage({
          auditType: '',
          operation: 1,
          status: 0,
          pageNo: 1,
          pageLe: 30,
          userId: this.userInfo.userId,
        }).then((res) => {
          if (res.code === '000000') {
            this.list = res.data.data
          }
          this.loading = false
        })
      },
    },

    created() {
      this.getList()
    },
  }
</script>

<style scoped lang="scss">
  .my-audit-item {
    .content {
      display: flex;
      cursor: pointer;
      .left {
        display: flex;
        flex-direction: column;
        color: #000;
        flex: 1;
        .top {
          margin-bottom: 10px;
          color: #000;
        }
        .bottom {
          color: #5e5e5e;
          font-size: 12px;
          .user {
            margin-right: 7px;
          }
        }
      }
      .right {
        padding-left: 10px;
        display: flex;
        align-items: flex-start;
      }
    }
  }
  .audit-content-wrapper {
    padding-bottom: 100px;
    > h4 {
      padding-left: 20px;
      color: #0486fe;
    }
  }
</style>
