<template>
  <div class="table-container outbound">
    <el-row v-if="$route.query.noReturn !== 'true'">
      <el-page-header @back="goBack" content="入库申请"></el-page-header>
    </el-row>
    <h3 class="mt30 mb20">基本信息</h3>
    <div class="form-wrapper">
      <el-form ref="form" label-width="100px" :model="form" :rules="rules">
        <el-row>
          <el-col :span="6">
            <el-form-item label="入库方式" prop="type">
              <el-select
                v-model="currentType"
                value-key="type"
                disabled
                style="width: 215px"
              >
                <el-option
                  v-for="item in typeNames"
                  :key="item.type"
                  :value="item"
                  :label="item.typeName"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="入库仓库" prop="warehouseId" x>
              <el-select
                style="width: 215px"
                v-model="form.warehouseId"
                value-key="type"
                :popper-append-to-body="false"
              >
                <el-option
                  :title="item.name"
                  v-for="item in warehouses"
                  :key="item.warehouseId"
                  :value="item.warehouseId"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="销售单号" prop="orderNumber">
              <el-input
                v-model="form.salesOrderCode"
                placeholder="请输入"
                disabled
                style="width: 215px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 加工单号 -->
            <el-form-item label="加工单号" prop="orderNumber">
              <el-input
                style="width: 215px"
                v-model="form.processOrderCode"
                placeholder="请输入"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item prop="creator">
              <span slot="label">创建人</span>
              <el-input
                disabled
                v-model="form.creator"
                style="width: 215px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="所属地区">
              <el-input
                disabled
                v-model="form.creatorArea"
                style="width: 215px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="type == 'JIA'">
            <el-form-item prop="creator">
              <span slot="label">操作人</span>
              <el-input
                disabled
                v-model="form.operationName"
                style="width: 215px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item prop="remark">
              <span slot="label">备注</span>
              <el-input
                style="width: 90%"
                type="textarea"
                :autosize="{ minRows: 2 }"
                v-model="form.remark"
                maxlength="500"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 入库产品 -->
    <h3 class="mt30 mb20">入库产品</h3>
    <OutsourceInboundApplyProducts
      ref="outsourceInboundApplyProducts"
      :applyType="applyType"
    />
    <h3 class="mt30 mb20">相关附件</h3>
    <ErpUpload
      ref="ErpUpload"
      :uploadParams="uploadParams"
      @uploadList="uploadList"
      @initUpload="initUpload"
      style="margin-bottom: 40px; background: #e4e7ed; padding: 15px"
    ></ErpUpload>
    <p class="divider"></p>
    <div style="text-align: center; position: sticky; bottom: 0">
      <el-button @click="goBack" class="ml15">取消</el-button>
      <el-button type="primary" @click="onSubmit">确认</el-button>
    </div>
    <!-- 审核选择 -->
    <AuditChoose ref="AuditChoose" @auditData="saveClick" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { OutboundSheetInteractor, InboundSheet, OutProcessing } from '@/core'
  import { mixin as productMixin } from '@/views/purchase/outsourceProcess/help/mixin'
  import OutsourceInboundApplyProducts from '@/views/purchase/outsourceProcess/components/outsource-inbound-apply-products'
  import AuditChoose from '@/components/AuditChoose'

  export default {
    name: 'OutsourceInboundApply',
    components: {
      OutsourceInboundApplyProducts,
      AuditChoose,
    },
    mixins: [productMixin],
    data() {
      return {
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        showReject: false,
        reason: '',
        form: {
          creatorArea: '',
          creator: '',
          warehouseName: '',
          warehouseId: '', //出库仓库
          remark: '',
          createWay: 0,
          warehouseName: '',
          salesOrderCode: '',
          processOrderCode: '',
          orderNumber: '',
        },

        rules: {
          warehouseId: [
            {
              required: true,
              message: '请选择入库仓库',
            },
          ],
        },

        typeNames: [
          {
            type: 15,
            typeName: '加工入库',
          },
          {
            type: 14,
            typeName: '退料入库',
          },
        ],
        currentType: {
          type: 14,
          typeName: '退料入库',
        }, //选中出库方式
        warehouses: [], //仓库列表
        params: {
          show: false,
          warehouseName: '',
        },
        currentRow: {},
        files: '',
        applyType: '',
      }
    },

    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),

      tenantId() {
        return this.userInfo.tenantId
      },

      fileNames() {
        return this.files.map((f) => f.name).join(',')
      },
      fileUrls() {
        return this.files.map((f) => f.url).join(',')
      },
      type() {
        return this.$route.query.type
      },
    },

    watch: {},
    async created() {
      const { processProductIds, type } = this.$route.query
      let res = await InboundSheet[
        type === 'TUI' ? 'returnPickingQuery' : 'warehouseInApplyQuery'
      ](processProductIds)
      if (res && res.code === '000000') {
        this.initData(res.data)
      }
    },
    methods: {
      // 返回
      goBack() {
        this.$router.push({
          path: '/purchase/outsourceProcess/outsource-process',
          query: {
            tabsType: '2',
          },
        })
      },
      backCancel() {},
      uploadList(fileList) {
        this.files = JSON.stringify(fileList)
      },
      // 获取仓库配置
      async getStockCostConfig(id) {
        let res = await OutboundSheetInteractor.getStockCostConfigureVO(id)
        if (res && res.code === '000000') {
          this.isChoose = res.data?.warehouseInBatch === 1 ? true : false
          this.warehouseOutType = res.data?.warehouseOutType
        }
      },

      deletePro(index) {
        this.tableData.splice(index, 1)
      },

      async onSubmit() {
        let promiseCheck = [
          this.$refs.form.validate(),
          this.$refs.outsourceInboundApplyProducts.validate(),
        ]
        Promise.all(promiseCheck)
          .then((data) => {
            if (this.form.needAudit) {
              this.showAudit()
            } else {
              this.submit()
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
      // 显示审核处理
      showAudit() {
        let auditParams = {
          auditType: 4,
          status: 0,
          tenantId: this.userInfo.tenantId,
        }
        this.pubApi.getAuditTemplates(auditParams).then((response) => {
          if (!response.data.length) {
            this.$baseMessage(
              '请联系管理员配置审核模板',
              'warning',
              false,
              'erp-hey-message-warning'
            )
          } else {
            this.$refs['AuditChoose'].showAddEdit(auditParams)
          }
        })
      },
      // 提交不走审核
      submit(auditObjectDTO) {
        const {
          operationId,
          operationName,
          remark,
          warehouseId,
          supplierName,
          supplierId,
          purchaseOrderNumber,
          orderNumber,
          creatorArea,
          creator,
        } = this.form
        const { type, typeName } = this.currentType
        const requestData = {
          originFileUrls: this.files,
          operationId,
          operationName,
          creatorArea,
          creator,
          orderNumber: this.form.processOrderCode,
          remark,
          type,
          typeName,
          warehouseId,
          supplierName,
          supplierId,
          warehouseName: this.warehouses.find(
            (item) => this.form.warehouseId === item.warehouseId
          ).name,
          warehouseInProductDTOList:
            this.$refs.outsourceInboundApplyProducts.getTableData(),
          createWay: 1,
        }
        if (this.type === 'JIA') {
          requestData.autoLock = 0
        }
        if (auditObjectDTO) {
          auditObjectDTO.orderId = this.form.processOrderCode
          requestData.auditObjectDTO = auditObjectDTO
        }
        InboundSheet.insertWarehouseIn(requestData).then((res) => {
          if (res && res.code === '000000') {
            this.$message.success('操作成功')
            this.$router.push({
              path: '/purchase/outsourceProcess/outsource-process',
              query: {
                tabsType: 2,
              },
            })
          }
        })
      },
      saveClick(auditObjectDTO) {
        this.submit(auditObjectDTO)
      },
      // 初始化基础信息
      _initBaseInfo(data) {
        const { userName, area, userId } = this.userInfo
        this.form.operationName = userName
        this.form.operationId = userId
        this.form.creatorArea = area
        this.form.creator = userName
        this.form.processOrderCode = data.processOrderCode
        this.form.salesOrderCode = data.salesOrderCode
        this.form.orderNumber = data.processOrderCode
        this.form.supplierId = data.supplierId
        this.form.supplierName = data.supplierName
        let type = this.$route.query.type
        this.$refs.outsourceInboundApplyProducts.initProducts(
          data.products,
          type
        )

        if (type === 'JIA') {
          this.currentType = {
            type: 15,
            typeName: '加工入库',
          }
        } else {
          this.currentType = {
            type: 14,
            typeName: '退料入库',
          }
        }
      },

      // 初始化数据
      async initData(data) {
        this._initBaseInfo(data)
        this._initDefaultWarehouseId('in', this.type)
        this._getWarehouseList()
      },

      initUpload(data, showClose) {
        this.$refs.ErpUpload.initUpload(data, showClose)
      },
    },
  }
</script>

<style scoped lang="scss">
  .page-header {
    height: 33px;
  }

  ::v-deep {
    .page-header {
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
  .divider {
    height: 1px;
    background-color: #f5f7fa;
    margin: 9px 0 15px 0;
  }

  .mb30 {
    margin-bottom: 30px;
  }
  .mt30 {
    margin-top: 30px;
  }

  .mb20 {
    margin-bottom: 20px;
  }
  .mt20 {
    margin-top: 20px;
  }
  .mb15 {
    margin-bottom: 15px;
  }
  .mt15 {
    margin-top: 15px;
  }
  .table-container {
    padding: 20px 15px !important;
  }
  .operation {
    display: flex;
    justify-content: space-evenly;
    i {
      font-size: 20px;
    }
  }
  .error-info {
    color: #ff4d4f;
    font-size: 12px;
    line-height: 1;
    position: relative;
    padding-top: 5px;
  }
  .pointer {
    cursor: pointer;
  }
  ::v-deep .el-input-number--small {
    width: 100px !important;
  }
  ::v-deep .el-input-number--small .el-input__inner {
    padding: 0 10px !important;
  }
  ::v-deep .el-input-number--small .el-input-number__increase,
  ::v-deep .el-input-number--small .el-input-number__decrease {
    display: none !important;
  }
  ::v-deep .el-input-number--small.error .el-input__inner {
    border-color: #ff4d4f;
  }
  .lot-wrapper {
    display: flex;
    align-items: center;
    .el-input {
      flex: 1;
    }
    .el-icon-circle-plus-outline {
      margin-left: 5px;
      cursor: pointer;
      font-size: 20px;
      color: rgb(40, 130, 255);
    }
  }
</style>

<style>
  .outbound .el-select-dropdown__item {
    width: 200px;
  }
  .el-tooltip__popper {
    max-width: 400px;
  }
</style>
