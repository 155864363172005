<template>
  <el-row class="p30 content">
    <el-row :gutter="20">
      <el-col :span="12">
        <span>类型</span>
        <p class="mt10">{{ form.payee === 2 ? '付款方式' : '收款方式' }}</p>
      </el-col>
      <el-col :span="12">
        <span>内容</span>
        <p class="mt10">{{ form.content || '--' }}</p>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="mt20">
      <el-col :span="12">
        <span>创建人</span>
        <p class="mt10">{{ form.createName || '--' }}</p>
      </el-col>
      <el-col :span="12">
        <span>创建时间</span>
        <p class="mt10">{{ form.createTime || '--' }}</p>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import { CustomerConfigInteractor } from '@/core'  
export default {
  name: 'PutPay',
  props: {
    detailId: {
      type: String,
      required: false,
    },
  },
  watch: {
    detailId: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!value) return
        this.getPaymentDetail(value)
      },
    },
  },
  data() {
    return {
      form: {},
    }
  },
  methods: {
    //详情
    async getPaymentDetail(paymentId) {
      let response = await CustomerConfigInteractor.paymentDetailApi({ id: paymentId })
      if (response.code === '000000') {
        this.form = response.data
      }
    },
  },
}
</script>

<style scoped lang="scss">
.el-row {
  span {
    color: #666;
  }
  p {
    color: #000;
    font-size: 16px;
  }
}
</style>
