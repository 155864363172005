<template>
  <div class="custom-wrap">
    <el-row>
      <div class="flex justify_start">
        <el-page-header :title="$t('other.back')" @back="goBack()" :content="$route.query.trackNumber"></el-page-header>
      </div>
    </el-row>

    <!-- 基本信息 -->
    <div class="track-content">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <!-- 基本信息 -->
        <h4 class="mb20">{{ $t('Documentary.BI') }}</h4>
        <el-row>
          <el-col :span="6">
            <!-- 采购订单编号 -->
            <el-form-item :label="$t('Documentary.PON') + '：'" prop="purchaseOrderNumber"
              :label-width="lang === 'en' ? '200px' : '120px'">
              {{ form.purchaseOrderNumber || '--' }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 客户代表 -->
            <el-form-item :label="$t('Documentary.CR') + '：'" :label-width="lang === 'en' ? '200px' : '160px'">
              {{
              lang === 'en'
              ? form.businessNameEn || '--'
              : form.businessName || '--'
              }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 订单编号 -->
            <el-form-item :label="$t('Documentary.ON') + '：'" :label-width="lang === 'en' ? '200px' : '140px'">
              {{ form.salesOrderNumber || '--' }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 地区 -->
            <el-form-item :label="$t('Documentary.area') + '：'" label-width="120px">
              {{ form.buyerArea | buyerAreaTransl }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 供应商名称 -->
            <el-form-item :label="$t('Documentary.supplierName') + '：'" prop="supplierName"
              :label-width="lang === 'en' ? '200px' : '120px'">
              {{ form.supplierName || '--' }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 采购开发 -->
            <el-form-item :label="$t('Documentary.PD') + '：'" :label-width="lang === 'en' ? '200px' : '160px'">
              {{
              lang === 'en'
              ? form.buyerNameEn || '--'
              : form.buyerName || '--'
              }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 质检员(QC) -->
            <el-form-item :label="$t('Documentary.QI') + '：'" :label-width="lang === 'en' ? '200px' : '140px'">
              {{
              lang === 'en'
              ? form.merchandiserNameEn || '--'
              : form.merchandiserName || '--'
              }}
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <!-- 状态 -->
            <el-form-item :label="$t('Documentary.state') + '：'" label-width="120px">
              <!-- 待安排 -->
              <span v-if="form.status == 1">{{ $t('Documentary.TBA') }}</span>
              <!-- 进行中 -->
              <span v-else-if="form.status == 2">
                {{ $t('Documentary.processing') }}
              </span>
              <!-- 已完成 -->
              <span v-else-if="form.status == 3">
                {{ $t('Documentary.completed') }}
              </span>
              <!-- 已作废 -->
              <span v-else-if="form.status == 4">
                {{ $t('Documentary.OOD') }}
              </span>
              <!-- 预警中 -->
              <span v-else-if="form.status == 5">
                {{ $t('Documentary.warning') }}
              </span>
              <!-- 延期 -->
              <span v-else-if="form.status == 6">
                {{ $t('Documentary.postpone') }}
              </span>
              <!-- 已完成 -->
              <span v-else-if="form.status == 7">
                {{ $t('Documentary.completed') }}
              </span>
              <span v-else>--</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 订单支持 -->
            <el-form-item :label="$t('Documentary.OS') + '：'" :label-width="lang === 'en' ? '200px' : '120px'">
              {{
              lang === 'en'
              ? form.fromBusinessNameEn || '--'
              : form.fromBusinessName || '--'
              }}
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <!-- 工厂交期(周期) -->
            <el-form-item :label="$t('Documentary.FD') + '：'" prop="deliveryDays"
              :label-width="lang === 'en' ? '200px' : '160px'" class="label-style">
              <em>
                <el-tooltip placement="top" effect="dark" :content="$t('Documentary.FDPOD')">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </em>
              <el-input :disabled="true" v-model.trim="form.deliveryDays" :placeholder="$t('Documentary.PE')" clearable
                maxlength="4">
                <template slot="append">{{ $t('Documentary.day') }}</template>
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <!-- 是否有样品 -->
            <el-form-item :label="$t('Documentary.ITAS') + '：'" prop="sample"
              :label-width="lang === 'en' ? '200px' : '140px'">
              <el-select v-model="form.sample" :disabled="isSample" :placeholder="$t('Documentary.PC')" clearable>
                <el-option :label="$t('Documentary.none')" value="0"></el-option>
                <el-option :label="$t('Documentary.have')" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 类型 -->
            <el-form-item :label="$t('Documentary.type') + '：'" label-width="120px">
              {{ form.modeType | modeTypeName }}
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 采购物品 -->
        <h4 class="mb10">{{ $t('Documentary.PI') }}</h4>
        <el-table border ref="multipleTable" :data="form.productDTO" style="width: 100%"
          :cell-style="{ 'text-align': 'center' }" :header-cell-style="{ 'text-align': 'center' }">
          <!-- 产品编码 -->
          <el-table-column :label="$t('Documentary.PRC')">
            <template slot-scope="scope">{{ scope.row.sku }}</template>
          </el-table-column>
          <!-- 产品名称 -->
          <el-table-column prop="productName" :label="$t('Documentary.PN')">
            <template slot-scope="scope">
              {{
              lang === 'en' ? scope.row.productNameEn : scope.row.productName
              }}
            </template>
          </el-table-column>
          <!-- 采购数量 -->
          <el-table-column prop="quantity" :label="$t('Documentary.PQ')"></el-table-column>
          <!-- 产品总金额(￥) -->
          <el-table-column prop="amount" :label="$t('Documentary.TPA')">
            <template slot-scope="scope">
              {{ isRight ? toFixed2(scope.row.amount) : '******' }}
            </template>
          </el-table-column>
        </el-table>

        <!-- 备注说明 -->
        <h4 class="mt30 mb10">{{ $t('Documentary.IM') }}</h4>
        <el-table border :max-height="340" ref="remarkTable" :data="form.remarkDTO" style="width: 100%"
          :cell-style="{ 'text-align': 'center' }" :header-cell-style="{ 'text-align': 'center' }">
          <!-- 序号 -->
          <el-table-column :label="$t('Documentary.SN')" type="index" width="60"></el-table-column>
          <!-- 时间 -->
          <el-table-column :label="$t('Documentary.time')" width="200">
            <template slot-scope="scope">
              {{ utils.formatTime(scope.row.createTime) }}
            </template>
          </el-table-column>
          <!-- 姓名 -->
          <el-table-column prop="creator" :label="$t('Documentary.name')" width="200"></el-table-column>
          <!-- 备注内容 -->
          <el-table-column prop="remark" :label="$t('Documentary.Remarks')"></el-table-column>
        </el-table>

        <!-- 跟单节点 -->
        <h4 class="mt30 mb20">{{ $t('Documentary.DN') }}</h4>
        <track-node :nodeDTO="form.nodeDTO" :deleteNodeDTO="form.deleteNodeDTO" :code="form.salesOrderNumber"
          :purchaseOrderNumber="form.purchaseOrderNumber" :product="form.productDTO" nodeStatus="update"></track-node>
      </el-form>
    </div>


  </div>
</template>

<script>
  let self = null
  import { trackInterface } from '@/core'
  import trackNode from './components/track-node.vue'
  import { hasRight } from '@/utils/permissionBtn.js'
  import { areaTransl } from '@/utils/translate.js'
  import { mapGetters } from 'vuex'
  export default {
    components: { trackNode },
    data() {
      self = this
      return {
        orderTrackingId: '',
        form: {
          purchaseOrderNumber: '', // 采购订单编号
          businessName: '', // 客户代表
          salesOrderNumber: '', // 订单编号
          buyerArea: '', // 地区
          supplierName: '', // 供应商名称
          buyerName: '', // 采购开发
          merchandiserName: '', // 质检员
          deliveryDays: '', // 工厂交期
          fromBusinessName: '', // 订单支持
          sample: '', // 是否有样品（0：无，1有）
          productDTO: [], // 采购物品Table
          remarkDTO: [], // 备注说明Table
          nodeDTO: [], // 跟单节点
          deleteNodeDTO: [], // 删除的跟单节点
        },
        rules: {},
        loading: false,
        isSample: false,
        checkFlag: null,
        specNodes: ['QC', 'LOGISTICS_FREIGHT', 'THE_CUSTOMER_TO_SIGN_FOR'], // 特殊节点
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      isRight() {
        return hasRight('TRACK:VIEW_COST')
      },
    },
    filters: {
      modeTypeName(n) {
        const types = {
          1: {
            zh: '按需采购',
            en: 'Purchase on demand',
          },
          2: {
            zh: '备货采购',
            en: 'Stock purchase',
          },
        }
        if (n) {
          return self.lang === 'en' ? types[n].en : types[n].zh
        } else {
          return '--'
        }
      },
      buyerAreaTransl(n) {
        return self.lang === 'en' ? areaTransl(n) : n
      },
    },
    created() {
      this.orderTrackingId = this.$route.query.orderTrackingId
      this.checkFlag = this.$route.query.checkFlag ? 1 : 0
      this.getDetail(this.orderTrackingId)
    },
    methods: {
      // 获取模板节点
      async getDetail(orderTrackingId) {
        const res = await trackInterface.getDetailForEdit(
          orderTrackingId,
          this.checkFlag
        )

        if (res && res.code === '000000') {
          const data = res.data
          const form = this.form
          // 赋值基本信息
          form.purchaseOrderNumber = data.purchaseOrderNumber // 采购订单编号
          form.businessName = data.businessName // 客户代表
          form.businessNameEn = data.businessNameEn // 客户代表英文
          form.salesOrderNumber = data.salesOrderNumber // 订单编号
          form.buyerArea = data.area // 地区
          form.supplierName = data.supplierName // 供应商名称
          form.buyerName = data.buyerName // 采购开发
          form.buyerNameEn = data.buyerNameEn // 采购开发英文
          form.merchandiserName = data.merchandiserName // 质检员
          form.merchandiserNameEn = data.merchandiserNameEn // 质检员英文
          form.status = data.status // 状态
          form.fromBusinessName = data.fromBusinessName // 订单支持
          form.fromBusinessNameEn = data.fromBusinessNameEn // 订单支持英文
          form.deliveryDays = data.deliveryDays // 工厂交期
          form.sample = data.sample != null ? String(data.sample) : '' // 是否有样品
          form.modeType = data.modeType // 类型
          // 赋值采购物品
          form.productDTO = data.orderTrackingProductVOList
          // 赋值备注说明
          form.remarkDTO = data.orderTrackingRemarkVOList
          // 赋值跟单节点
          form.nodeDTO = data.orderTrackingNodeVOList.map((item) => {
            return {
              ...item,
              deferred: this.isDeferred(item), // 是否延期
              deferredDays: item.realDate && this.deferredDaysCalc(item), // 延期天数
            }
          })
          // 是否有样品是否可以编辑
          this.isSample = data.sample != null ? true : false

          this.getNodeStatus()
        }
      },

      // 是否延期
      isDeferred(item) {
        if (item.realDate && item.dueDate) {
          return (
            new Date(item.realDate).getTime() -
            new Date(item.dueDate).getTime() >
            0
          )
        } else {
          return false
        }
      },

      // 计算延期天数
      deferredDaysCalc(item, i) {
        return Math.abs(
          parseInt(
            (new Date(item.realDate).getTime() -
              new Date(item.dueDate).getTime()) /
            (1000 * 60 * 60 * 24)
          )
        )
      },

      // 跟单节点特殊处理--已完成，延期状态且下一节点实际日期更新过，当前节点置灰
      getNodeStatus() {
        const node = this.form.nodeDTO

        if (node.length > 0) {
          this.form.nodeDTO = node.map((item, i) => {
            return {
              ...item,
              deferred: item.deferred == 1 ? true : false, // 是否延期
              disabled: this.statusCheck(item, i, node), // true:不可编辑，false:可编辑
            }
          })
        }
      },

      // 更新判断节点能否编辑
      statusCheck(item, i, node) {
        if (i === node.length) {
          return false
        } else {
          if (
            (item.status === 2 || item.status === 3) &&
            this.specHandle(node, i)
          ) {
            return true
          }
        }
      },

      // 特殊节点不做校验
      specHandle(node, i) {
        if (i === node.length - 1) {
          return false
        } else {
          if (this.specNodes.includes(node[i].nodeType)) {
            this.specHandle(node, i + 1)
          } else {
            if (node[i + 1].realDate) {
              return true
            } else {
              return false
            }
          }
        }
      },

      // 保存
      save() {
        this.$refs['ruleForm'].validate(async (valid) => {
          if (valid) {
            // 实际日期校验-中间节点不能为空
            let index = 0,
              boo = false,
              nodeDTO = this.form.nodeDTO
            for (let i = nodeDTO.length - 1; i >= 0; i--) {
              if (
                nodeDTO[i].realDate &&
                !this.specNodes.includes(nodeDTO[i].nodeType)
              ) {
                index = i
                break
              }
            }
            for (let i = 0; i < index; i++) {
              if (
                !nodeDTO[i].realDate &&
                !this.specNodes.includes(nodeDTO[i].nodeType)
              ) {
                this.$message.warning(
                  `${this.lang === 'en' ? 'The first' : '第'}${i + 1}${this.lang === 'en'
                    ? 'actual date of the node is required'
                    : '节点实际日期必填'
                  }`
                )
                boo = true
                break
              }
            }
            if (boo) return false

            this.loading = true
            // 跟单节点数据处理
            this.form.nodeDTO = this.form.nodeDTO.map((item, i) => {
              return {
                ...item,
                nodeSerialNumber: i + 1, // 跟单节点序号赋值
                deferred: item.deferred ? 1 : 0, // 是否延期格式处理
              }
            })

            const params = {
              ...this.form,
              orderTrackingId: this.orderTrackingId,
            }
            const res = await trackInterface.updatePO(params)

            if (res && res.code === '000000') {
              this.$router.push('/track/orderTracking/trackList')
              this.$message.success(this.$t('Documentary.SS'))
              this.loading = false
            } else {
              this.loading = false
            }
          }
        })
      },

      // 返回
      goBack() {
        this.$router.go(-1)
      },
    },
  }
</script>

<style scoped lang="scss">
  .track-content {
    min-height: calc(100% - 120px);

    h4 {
      font-weight: 600;
      font-size: 14px;
      margin: 10px 0 0 0;
    }

    .el-input,
    .el-select {
      width: 100%;
    }

    .label-style {
      position: relative;

      em {
        position: absolute;
        top: -8px;
        left: -18px;
        z-index: 2;

        i {
          font-size: 16px;
        }
      }
    }
  }

  .oper-btn {
    position: sticky;
    bottom: 0;
    text-align: center;
    background: white;
    padding: 10px 0;
  }

  ::v-deep {
    .el-input-group__append {
      background: none;
      padding: 0 10px;
    }
  }
</style>
