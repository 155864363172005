<template>
  <PrintWrapper>
    <div id="printTest">
      <div v-if="$route.query.content && type != 1" class="mt_box" style="white-space: pre-wrap; line-height: 38px">
        <div v-for="(ele, index) in printContent" :key="index" class="print-content" :style="
          index === 0
            ? 'page-break-after: always;'
            : 'page-break-before: always'
        ">
          <div v-html="ele"></div>
          <div :id="'qrcode' + index" v-if="QRCode" class="qrcode"></div>
          <template
            v-if="pruchaseProductImgs.length > 0 && pruchaseProductImgs[index] && pruchaseProductImgs[index].url">
            <div class="product-img-wrapper" v-for="(img,z) in pruchaseProductImgs[index].url">
              <div class="v-responsive v-img v-img--booting w-100 mb-2 img">
                <div class="v-responsive__sizer" style="padding-bottom: 100%;"></div>
                <img class="v-img__img v-img__img--contain" :src="img">
                <i class="el-icon-delete" @click="deleteImg(i,k,z)"></i>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div v-else class="content">
        <div v-for="(item, i) in printContent" :key="i" class="mt_box" :style="
          i === 0 ? 'page-break-after: always;' : 'page-break-before: always'
        ">
          <div v-for="(name, k) in item" :key="k" :style="name.markPrefix.startsWith('Made') ? 'text-align:center' : ''"
            v-if="name.markPrefix !== 'productRendImg'">
            <div class="text">
              <span>{{ name.markPrefix }}</span>
              <span v-show="!name.markPrefix.startsWith('Made')">：</span>
              <input v-show="name.templateFlag != 1" type="text" />
              <span v-if="
                ['Quantity', 'CTN SPEC', 'G.W', 'CTN.NO'].includes(
                  name.markPrefix
                )
              " class="p-span">
                <input v-if="dataArray[i]" v-model="dataArray[i][name.markPrefix]" />
                <span class="c-span" v-if="dataArray[i]">
                  {{ dataArray[i][name.markPrefix] }}
                </span>
              </span>
              <span v-else style="white-space: pre-wrap">
                {{ name.markSuffix }}
              </span>
            </div>
          </div>
          <!-- 效果图图片 -->
          <div v-else>
            <template v-if="name.markSuffix && showImg">

              <div class="product-img-wrapper" v-for="(img,z) in name.markSuffix.split(',')">
                <div class="v-responsive v-img v-img--booting w-100 mb-2 img">
                  <div class="v-responsive__sizer" style="padding-bottom: 100%;"></div>
                  <img class="v-img__img v-img__img--contain" :src="img">
                  <i class="el-icon-delete" @click="deleteImg(i,k,z)"></i>
                </div>
              </div>
            </template>

          </div>
          <div :id="'qrcode' + i" v-if="QRCode" class="qrcode"></div>
        </div>
      </div>
    </div>
  </PrintWrapper>
</template>

<script>
  import { orderPrintOrder } from '@/api/order.js'
  import viewCost from '@/views/order/orderList/util'
  import { generateQRCode } from '@/utils/qrcode'
  import PrintWrapper from './printWrapper'

  import { OrderSettingInteractor, OrderListInteractor } from '@/core'



  export default {
    name: 'orderShipMark',
    components: {
      PrintWrapper
    },
    data() {
      return {

        type: null, // 入口来源 1：委外加工
        isViewCost: viewCost(),
        printInfo: {},
        PIList: [], // PI单号集合
        dataArray: [],
        markConfig: {
          area: "",
          id: "",
          productImgDisplayed: '',
          qrCodeDisplayed: 1,
          rendImgDisplayed: "",
          type: ""
        },
        purchaseProductShowVOList: [], //采购订单产品

      }
    },

    computed: {
      QRCode() {  //二维码控制，采购和销售订单取qrCodeDisplayed，其他模块取QRCode
        return this.markConfig.qrCodeDisplayed === 1 || this.$route.query.QRCode
      },
      showImg() {
        return this.markConfig.rendImgDisplayed && this.$route.query.rendImgDisplayed !== 'false'
      },
      printContent() {
        const query = this.$route.query
        let _arr = null

        query.type && (this.type = query.type)
        if (query.content) {
          if (query.type == 1) {
            _arr = JSON.parse(query.content.replaceAll(/~~~/g, '#'))
          } else {
            if (!this.printInfo.length) {
              return []
            }
            let arr = this.printInfo.split('--------------')
            arr = arr.map((item) => {
              return item
                .replace(
                  /Made in China/g,
                  '<div style="text-align:center">Made in China</div>'
                )
                .replace('\nItem', 'Item')
            })
            _arr = arr
          }
        } else {
          _arr = this.printInfo.marksShowVOS
        }

        this.$nextTick(() => {
          if (_arr?.length) {
            const nData = []
            const signArr = ['Quantity', 'CTN SPEC', 'G.W', 'CTN.NO']
            for (let i = 0; i < _arr.length; i++) {
              nData.push({})
              for (let n = 0; n < _arr[i].length; n++) {
                if (signArr.includes(_arr[i][n].markPrefix)) {
                  nData[i][_arr[i][n].markPrefix] =
                    '      ' + _arr[i][n].markSuffix
                }
              }
            }

            this.dataArray = nData
          }
        })
        return _arr
      },

      // 采购订单图片
      pruchaseProductImgs() {
        let length = this.printContent.length
        let {
          productImgDisplayed,
          rendImgDisplayed,
        } = this.markConfig
        if (!this.purchaseProductShowVOList.length) {
          return []
        }
        let imgs = []
        for (let i = 0; i < length; i++) {
          let img = ''
          if (rendImgDisplayed) {  //取效果图
            img = this.purchaseProductShowVOList[i].printingImg
          }
          if (rendImgDisplayed && !img && productImgDisplayed) { //取主图
            img = this.purchaseProductShowVOList[i].imgMain
          }
          imgs.push({
            url: img ? img.split(",") : ''
          })
        }
        return imgs
      }
    },
    created() {

      let settingtype = this.$route.query.settingtype
      if (['0', '1'].includes(settingtype)) {
        this.getMarkConfig().then(() => {
          this.init()
        })
      } else {
        this.init()
      }

    },
    methods: {
      // 初始化
      init() {
        let type = this.$route.query?.type
        let content = this.$route.query?.content
        let preview = this.$route.query?.preview
        if (content) {
          // 为了解决地址中可能存在#字符而作的转化
          content = content.replaceAll(/~~~/g, '#')
          try {
            this.printInfo = JSON.parse(content)
          } catch(err) {
            console.log('json解析异常')
          }

          if (content.includes('PI')) {
            if (type == 1) {
              this.getQRCode(this.printInfo, 1)
            } else {
              let _s = content.replaceAll('\\n', '').replace(/ /g, '')
              this.getPI(_s)
              this.getQRCode(this.PIList, 0)
            }
          }
          // 采购订单预览
          if (preview === '1') {
            this.getPurchaseOrderDetail(this.$route.query.businessId)
          }
        } else {
          this.getPrintInfo()
        }
      },
      // 删除图片
      deleteImg(i, k, z) {
        let productRendImg = this.printContent[i][k].markSuffix
        productRendImg = productRendImg.split(',')
        productRendImg.splice(z, 1)
        this.printContent[i][k].markSuffix = productRendImg.join(',')
      },
      // 获取配置
      async getMarkConfig() {
        let type = +this.$route.query.settingtype
        if ([0, 1].includes(type)) {
          this.markConfig = await OrderSettingInteractor.findByUserApi(type)
        }
      },

      // 获取采购订单图片资源
      async getPurchaseOrderDetail(businessId) {
        let res = await OrderListInteractor.purchaseGetDetailForShow({ businessId })
        if (res?.code === '000000') {
          // 采购订单产品列表
          this.purchaseProductShowVOList = res.data?.purchaseProductShowVOList
        }
      },
      // 获取打印信息
      getPrintInfo() {

        const params = {
          orderId: this.$route.query.orderId,
          printType: 3,
          checkFlag: this.isViewCost ? 1 : 0,
        }
        orderPrintOrder(params).then((res) => {
          if (res?.code === '000000') {
            this.printInfo = res.data
            this.getQRCode(this.printInfo?.marksShowVOS, 1)
          }

        })
      },

      /**
       * 生成二维码
       * @param _arr 唛头信息源数组
       * @param type 0:url传参的数据 1:接口返回数据
       */
      getQRCode(_arr, type) {
        if (_arr.length) {
          if (type === 0) {
            this.$nextTick(() => {
              _arr.forEach((item, i) => {
                generateQRCode({ selector: '#qrcode' + i, text: item })
              })
            })
          } else {
            this.$nextTick(() => {
              _arr.forEach((item, i) => {
                item.forEach((obj) => {
                  if (obj.markPrefix === 'PI') {
                    generateQRCode({
                      selector: '#qrcode' + i,
                      text: obj.markSuffix,
                    })
                  }
                })
              })
            })
          }
        }
      },

      /**
       * 递归计算PI
       * @param _s 可能包含PI的字符串
       */
      getPI(_s) {
        let [_sIndex, _eIndex] = [_s.search('PI:') + 3, _s.search('PO:')]
        this.PIList.push(_s.substring(_sIndex, _eIndex))

        const _nStr = _s.substring(_eIndex + 3)
        if (_nStr.includes('PI:')) {
          this.getPI(_nStr)
        }
      },
    },
  }
</script>


<style lang="scss" scoped>
  @page {
    size: A6;
  }

  .container {
    min-height: 100vh;
    height: 100vh;
    padding: 9px;
    background: #fff;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
      color 0.1s, font-size 0s;
    overflow-y: auto;
    // overflow: hidden;
  }

  .mt_box {
    width: 700px;
    margin: 0 auto 20px;
    font-size: 28px;
    position: relative;

    .qrcode {
      position: absolute;
      top: 120px;
      right: 40px;
    }
  }

  .print-content {
    letter-spacing: 1px !important;
    color: #000000 !important;
    font-weight: bold !important;
    position: relative !important;
    font-family: 'PingFang', Arial, 'Microsoft YaHei', sans-serif !important;

    .qrcode {
      position: absolute;
      top: 120px;
      right: 0;
    }
  }

  .mt_box .text {
    line-height: 38px;

    font-size: 28px;
    color: #000000;
    font-weight: bold;
  }

  .mt_box .text input {
    width: 360px;
    height: 38px;
    line-height: 38px;
    font-size: 28px;
    border: none;
    background: none;
    font-weight: bold;
  }

  .mt_box .text span:first-child {
    font-size: 28px;
    color: #000000;
    font-weight: bold;
  }

  .liId {
    list-style: none;
  }

  .p-span {
    position: relative;
  }

  .c-span {
    position: absolute;
    left: 50px;
    z-index: -1;
    user-select: none;
  }

  .product-img-wrapper {
    border: 1px solid #ededed;
    border-radius: 4px;
    cursor: pointer;

    position: relative;
    transition: all .3s ease-in-out;
    height: 400px;
    width: 400px;
    margin-bottom: 10px;

    &:hover {
      .el-icon-delete {
        display: inline-block !important;
      }

    }

    .v-responsive {
      transform: translate3d(0, -50%, 0);
      border-radius: 4px;
      max-height: 100%;
      max-width: 100%;
      -o-object-fit: contain;
      object-fit: contain;
      position: absolute;
      top: 50%;
      display: flex;
      width: 100% !important;

      .v-responsive__sizer {
        padding-bottom: 100%;
      }

      img {
        object-fit: contain;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
        display: block;
      }

      .el-icon-delete {
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 20px;
        display: none;
        color: red;

      }
    }
  }
</style>
