<template>
  <el-dialog :title="title" width="900px" class="custom-dialog" :visible.sync="orderPaymentVisable"
    v-if="orderPaymentVisable" :close-on-click-modal="false" :before-close="close">
    <el-form :model="form" ref="ruleForm">
      <div class="mb-10">
        <el-radio-group v-model="form.entryType">
          <el-radio :label="0" :disabled="disabled && form.entryType == 1">
            采购付款
          </el-radio>
          <el-radio :label="1" :disabled="disabled && form.entryType == 0">
            采购退款
          </el-radio>
        </el-radio-group>
      </div>
      <table class="custom-table">
        <thead>
          <tr>
            <th>供应商名称</th>
            <th>收款名称</th>
            <th>开户银行</th>
            <th>账户</th>
            <th>买方</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <el-row type="flex" align="middle" justify="center" class="supplierName-row">
                <tooltip-over class="tooltipOver" :content="form.supplierName" refName="tooltipOver5"
                  style="flex: 1"></tooltip-over>
                <p v-if="form.isAgreement == 1" style="margin: 0">
                  <el-tag type="danger" size="mini" class="ml5">协议</el-tag>
                </p>
              </el-row>
            </td>
            <td>{{ form.accountName || '--' }}</td>
            <td>{{ form.bankName || '--' }}</td>
            <td>{{ form.bankAccount || '--' }}</td>
            <td>{{ form.purchaser || '--' }}</td>
          </tr>
        </tbody>
      </table>

      <table class="custom-table mt20" v-if="form.entryType === 0">
        <thead>
          <tr>
            <th>采购订单编号</th>
            <th>币种</th>
            <th>采购合同金额</th>
            <th>付款节点</th>
            <th>应付款金额</th>
            <th>总应付金额</th>
            <th>
              <span>
                <i>*</i>
                申请付款金额
              </span>
            </th>
          </tr>
        </thead>
        <tbody v-if="form.applicationPayOrderChildEditDTOList.length">
          <tr v-for="(item, i) in form.applicationPayOrderChildEditDTOList" :key="i">
            <td style="width: 140px">{{ item.purchaseOrderNumber || '--' }}</td>
            <td>{{ item.currency || '--' }}</td>
            <td>{{ utils.numberFormat(item.amountTotal, 2) }}</td>
            <td>{{ item.paymentNodeName || '--' }}</td>
            <td>{{ utils.numberFormat(item.amountPayable, 2) }}</td>
            <td>{{ utils.numberFormat(item.payableTotal, 2) }}</td>
            <td class="no-space">
              <el-form-item :prop="
                  'applicationPayOrderChildEditDTOList.' +
                  i +
                  '.amountApplication'
                " :rules="[
                  {
                    required: true,
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message: '>=0最多两位小数',
                    trigger: 'blur',
                  },
                  {
                    validator: (rule, value, cb) => {
                      if (value === '0') {
                        cb(new Error('不能输入0'))
                      } else {
                        cb()
                      }
                    },
                    trigger: 'blur',
                  },
                ]">
                <el-input maxlength="20" v-model="
                    form.applicationPayOrderChildEditDTOList[i]
                      .amountApplication
                  " @change="applyMoneyChange(i)" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="empty">
            <td colspan="5">暂无数据</td>
          </tr>
        </tbody>
      </table>
      <table class="custom-table mt20" v-else>
        <thead>
          <tr>
            <th>采购订单编号</th>
            <th>币种</th>
            <th>采购合同金额</th>
            <th>应付金额</th>
            <th>已付金额</th>
            <th>
              <span>
                <i>*</i>
                申请退款金额
              </span>
            </th>
          </tr>
        </thead>
        <tbody v-if="form.applicationPayOrderChildEditDTOList.length">
          <tr v-for="(item, i) in form.applicationPayOrderChildEditDTOList" :key="i">
            <td style="width: 140px">{{ item.purchaseOrderNumber || '--' }}</td>
            <td>{{ item.currency || '--' }}</td>
            <td>{{ utils.numberFormat(item.amountTotal, 2) }}</td>
            <td>{{ utils.numberFormat(item.amountPayable, 2) }}</td>
            <td>{{ utils.numberFormat(item.amountPaid, 2) }}</td>
            <td class="no-space">
              <el-form-item :prop="
                  'applicationPayOrderChildEditDTOList.' +
                  i +
                  '.amountApplication'
                " :rules="[
                  {
                    required: true,
                    pattern: /^\d+(\.\d{1,2})?$/,

                    message: '>=0最多两位小数',
                    trigger: 'blur',
                  },
                ]">
                <el-input maxlength="20" v-model="
                    form.applicationPayOrderChildEditDTOList[i]
                      .amountApplication
                  " @change="applyMoneyChangeT(i)" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="empty">
            <td colspan="5">暂无数据</td>
          </tr>
        </tbody>
      </table>

      <p class="mt10 text-right red" v-show="refundIds && form.entryType === 0">
        采购订单{{ refundIds }}有待处理退款
      </p>
      <p class="text-right mt10 bold" v-if="form.entryType === 0">
        总金额：
        <span>
          {{
          form.applicationPayOrderChildEditDTOList[0].currency === '人民币'
          ? '￥'
          : '$'
          }}
        </span>
        <span class="f_s_16">
          {{
          utils.amountTotal(
          form.applicationPayOrderChildEditDTOList,
          'amountApplication',
          2
          )
          }}
        </span>
      </p>
      <p class="text-right mt10 bold" v-if="form.entryType === 1">
        总退款金额：

        <span>
          {{
          form.applicationPayOrderChildEditDTOList[0].currency === '人民币'
          ? '￥'
          : '$'
          }}
        </span>
        <span class="f_s_16">
          {{
          utils.amountTotal(
          form.applicationPayOrderChildEditDTOList,
          'amountApplication',
          2
          )
          }}
        </span>
      </p>

      <p class="mt20">
        <span v-if="form.entryType === 1" class="red">*</span>
        备注
      </p>
      <el-form-item :rules="[
          {
            required: true,
            message: '请输入备注',
          },
        ]" prop="remark" v-if="form.entryType === 1">
        <el-input type="textarea" v-model="form.remark" maxlength="500" show-word-limit :rows="3"
          placeholder="请输入500个字符以内"></el-input>
      </el-form-item>
      <el-input v-else type="textarea" v-model="form.remark" maxlength="500" show-word-limit :rows="3"
        placeholder="请输入500个字符以内"></el-input>

      <p class="mt20">凭证/附件</p>
      <ErpUpload ref="ErpUpload" :uploadParams="uploadParams" @uploadList="uploadList"></ErpUpload>
    </el-form>

    <div class="text-center mt20">
      <el-button type="primary" @click="save" :loading="loading">
        确认
      </el-button>
      <el-button @click="close()">取消</el-button>
    </div>

    <!-- 审核模板-->
    <AuditChoose ref="AuditChoose" @auditData="saveClick" />
  </el-dialog>
</template>

<script>
  import {
    OrderListInteractor,
    PaymentInteractor,
    CostManageInteractor,
  } from '@/core'
  import AuditChoose from '@/components/AuditChoose'
  import { mapGetters } from 'vuex'
  import tooltipOver from '@/components/base-tooltip'
  export default {
    props: {
      title: {
        type: String,
        default: '批量付款/退款申请',
      },
      isResubmit: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      AuditChoose,
      tooltipOver,
    },
    data() {
      return {
        form: {
          supplierName: '', // 供应商名称
          accountName: '', // 收款名称
          bankName: '', // 开户银行
          bankAccount: '', // 账户
          applicationPayOrderChildEditDTOList: [],
          remark: '', // 备注
          fileAnnex: '', // 附件
          auditObjectDTO: {}, // 审核信息
          payType: 1,
          entryType: 0, //0采购付款 1采购退款
          purchaser: '', // 买方
          isAgreement: '',
        },
        refundIds: '', // 退款的id集合
        orderPaymentVisable: false,
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        disabled: false,
        loading: false,
        Error: Error,
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    methods: {
      // 初始化加载
      async initLoading(ids, { disabled, entryType = 0 } = {}) {
        let self = this
        this.form.entryType = entryType
        this.disabled = disabled

        let res
        if (this.isResubmit) {
          res = await CostManageInteractor.getDetailForReissuePay(ids)
        } else {
          res = await OrderListInteractor.orderCreatePayOrder({
            businessIds: ids,
          })
        }
        if (res && res.code == '000000') {
          // 重新提交添加业务id payOrderId
          if (this.isResubmit) {
            this.form.payOrderId = res.data.payOrderId
          } else {
            this.form.payOrderId = ''
          }
          self.form.supplierName = res.data.supplierName
          self.form.accountName = res.data.accountName
          self.form.bankName = res.data.bankName
          self.form.bankAccount = res.data.bankAccount
          self.form.applicationPayOrderChildEditDTOList =
            res.data.applicationPayOrderChildEditVOList
          self.orderPaymentVisable = true
          self.form.purchaser = res.data.purchaser
          self.form.isAgreement = res.data.isAgreement
          if (self.isResubmit) {
            self.form.payType = res.data.payType
            self.form.entryType = res.data.entryType // 类型
            self.disabled = true
            self.form.remark = res.data.remark
            if (res.data.fileAnnex) {
              self.form.fileAnnex = res.data.fileAnnex
              self.initUpload(res.data.fileAnnex)
            }
          }
        }
      },
      initUpload(data) {
        setTimeout(() => {
          this.$refs.ErpUpload.initUpload(data)
        }, 400)
      },
      _compare(n1, n2) {
        n1 = this.toFixed2(n1) * 100
        n2 = this.toFixed2(n2) * 100
        return n1 > n2
      },

      // 申请付款金额≤应付金额
      applyMoneyChange(i) {
        let list = this.form.applicationPayOrderChildEditDTOList
        if (list && list[i]) {
          const { amountApplication, payableTotal } = list[i]
          if (this._compare(amountApplication, payableTotal)) {
            list[i].amountApplication = list[i].payableTotal.toFixed(2)
            this.$message.warning('申请付款金额不能大于总应付金额!')
          }
        }

      },
      applyMoneyChangeT(i) {
        let list = this.form.applicationPayOrderChildEditDTOList
        if (this._compare(list[i].amountApplication, list[i].amountPaid)) {
          list[i].amountApplication = list[i].amountPaid.toFixed(2)
          this.$message.warning('申请退款金额不能大于已付金额!')
        }
      },

      // 获取上传文件地址
      uploadList(fileList) {
        this.form.fileAnnex = JSON.stringify(fileList)
      },

      // 校验

      // 保存
      save() {
        let self = this

        self.$refs.ruleForm.validate((valid) => {
          if (valid) {
            let auditParams = {
              auditType: 7,
              status: 0,
              tenantId: self.userInfo.tenantId,
            }
            if (this.form.entryType === 0) {
              let purchaseOrderNumbers = ''
              let list = this.form.applicationPayOrderChildEditDTOList
              for (let i = 0; i < list.length; i++) {
                if (
                  this._compare(list[i].amountApplication, list[i].payableTotal)
                ) {
                  purchaseOrderNumbers += `${list[i].purchaseOrderNumber} `
                }
              }

              if (purchaseOrderNumbers) {
                this.$confirm(
                  `采购订单${purchaseOrderNumbers}
                  付款金额 > 总应付金额，是否确认付款?`,
                  '提示',
                  {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                  }
                )
                  .then(() => {
                    self.$refs['AuditChoose'].showAddEdit(auditParams)
                  })
                  .catch(() => { })
              } else {
                self.$refs['AuditChoose'].showAddEdit(auditParams)
              }
            } else {
              // 配置审核模板
              self.$refs['AuditChoose'].showAddEdit(auditParams)
            }
          }
        })
      },

      // 保存审核模板并提交
      saveClick(data) {
        let self = this
        this.loading = true
        self.form.applicationPayOrderChildEditDTOList.forEach((i) => {
          i.entryType = this.form.entryType
        })
        const params = {
          ...self.form,
          auditObjectDTO: data,
          payOrderChildEditDTOList:
            self.form.applicationPayOrderChildEditDTOList,
        }
        PaymentInteractor.insertByApplicationOrder(params)
          .then((res) => {
            if (res && res.code == '000000') {
              self.$message.success('保存成功')
              self.$parent.initLoading && self.$parent.initLoading()
              self.$parent.getDetail &&
                self.$parent.getDetail(self.$route.query.id)
              self.close()
              self.$emit('refresh')
            }
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
          })
      },

      // 关闭弹窗重置表单
      close() {
        this.$refs.ruleForm.resetFields()
        this.form = this.$options.data().form
        this.$refs.ErpUpload.uploadListEmpty()
        this.orderPaymentVisable = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .custom-dialog {
    .el-form-item {
      margin-bottom: 18px;
    }

    p {
      margin: 0 0 10px;
    }
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .red {
    color: red;
  }

  .supplierName-row {
    .supplierName-p {
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
    }
  }
</style>
