<template>
  <div class="custom-wrap">
    <el-row>
      <el-page-header @back="goBack" content="委托单详情" v-if="!$route.query.noReturn"></el-page-header>
      <el-tag :type="getTagType(form.status)">
        {{ statusList[form.status] }}
      </el-tag>
      <div class="button-wrapper">
        <div v-if="![3, 4].includes(form.status)" style="display: inline-block; margin-right: 10px">
          <el-button v-if="form.status == 1 && hasRight('SHIPMENT:MANUAL')" @click="manualShipment">
            手动出运
          </el-button>
          <el-button v-if="[0, 1, 2, 6].includes(form.status)" @click="updateSheet">
            修改单据
          </el-button>
          <el-button @click="cancelShipping" v-allowed="['SHIPMENT:CANCEL']">
            取消出运
          </el-button>
          <el-button v-if="[5].includes(form.status)  && hasRight('SHIPMENT:MAIL')" @click="sendGoodsMail">
            发货邮件
          </el-button>
        </div>

        <el-button @click="uploadOtherFile">上传附件</el-button>
        <el-button @click="setWaybillNumber">{{ buttonText }}</el-button>
      </div>
    </el-row>
    <el-row>
      <h4>基本信息</h4>
      <div class="custom-detail">
        <el-row>
          <el-col>
            <em>委托单号：</em>
            <span>
              {{ form.number }}
            </span>

          </el-col>
          <el-col>
            <em>订单编号：</em>
            <span v-if="form.businessNumber" class="el-link el-link--primary"
              @click="goDetail('salesOrder', form.businessNumber)">
              {{ form.businessNumber }}
            </span>
            <span v-else>--</span>
          </el-col>
          <el-col>
            <em>出运计划单:</em>
            <span v-if="form.planNumber" class="el-link el-link--primary"
              @click="goDetail('shipmentPlanDetail', form.shippingPlanOrderId)">
              {{ form.planNumber }}
            </span>
            <span v-else>--</span>
          </el-col>
          <el-col>
            <em>运输方式：</em>
            <span>{{ form.shippingMethod }}</span>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <em>贸易条款：</em>
            <span>{{ form.trade || '--' }}</span>
          </el-col>
          <el-col>
            <em>发货地点：</em>
            <span>{{ form.portShipment || '--' }}</span>
          </el-col>
          <el-col>
            <em>单证员：</em>
            <span>{{ form.clerkName || '--' }}</span>
          </el-col>

          <el-col>
            <em>最晚交期：</em>
            <span>{{ form.deliveryDate || '--' }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <em>装运日期：</em>
            <span>{{ form.shippingDate || '--' }}</span>
          </el-col>
          <el-col>
            <em>国内物流：</em>
            <span>{{ form.domesticLogistics | domesticLogisticsFilter }}</span>
          </el-col>
          <el-col v-if="form.domesticLogistics === 0">
            <em>快递单号：</em>
            <span>{{ form.trackingNo || '--' }}</span>
          </el-col>
          <el-col>
            <em>垫付运费：</em>
            <span>{{ form.advanceFreight === 0 ? '是' : '否' }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <em>客户名称：</em>
            <span>{{ form.customerName || '--' }}</span>
          </el-col>
          <el-col>
            <em>客户代表：</em>
            <span>{{ form.businessName || '--' }}</span>
          </el-col>
          <el-col>
            <em>创建人：</em>
            <span>{{ form.creatorName || '--' }}</span>
          </el-col>
          <el-col>
            <em>创建时间：</em>
            <span>{{ form.createTime }}</span>
          </el-col>
        </el-row>
        <template v-if="canSelectAutoEmail &&
          form.shippingMethod &&
          ingoreShippingMethodList.includes(form.shippingMethod.toUpperCase())
          && form.emailAutoFlag !== 0
          ">
          <el-row type="flex" align="center">
            <!-- <el-checkbox v-model="form.emailAutoFlag" disabled :true-label="1" :false-label="0"
              style="margin-right:8px"></el-checkbox> -->
            自动发送物流动态邮件,
            <span class="page-link text-left" @click="handleView">
              点击查看示例
            </span>
            <ErpMouseoverTip effect="dark" placement="bottom" style="display: flex;align-items: center;">
              <div>
                <p class="info-text">
                  1. 仅支持部分运输方式，并以实际出运时的运输方式为准；
                </p>
                <p class="info-text">
                  2. 若手动发送了发货邮件，系统将不再自动发送发货邮件；
                </p>
              </div>
            </ErpMouseoverTip>
          </el-row>
        </template>
        <el-row>
          <el-col :span="24" style="width: 100%">
            <em style="vertical-align: top">备注：</em>
            <span>{{ form.remarks || '--' }}</span>
          </el-col>
        </el-row>
      </div>

      <div class="c-table-wrap ml-12">
        <table class="custom-table">
          <thead>
            <tr>
              <th>
                <span>国家</span>
              </th>
              <th>
                <span>收货地址</span>
              </th>
              <th>
                <span>收货人</span>
              </th>

              <th>
                <span>收货公司</span>
              </th>
              <th>
                <span>联系电话</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {{ form.country ? form.country : '--' }}
              </td>
              <td>
                {{ form.receivingAddress ? form.receivingAddress : '--' }}
              </td>
              <td>{{ form.receivingPerson ? form.receivingPerson : '--' }}</td>

              <td>
                {{ form.receivingCompany ? form.receivingCompany : '--' }}
              </td>
              <td>
                {{ form.receivingPhone ? form.receivingPhone : '--' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h4 style="display: inline-block">
        出运产品
        <!--  -->
        <el-button type="default" style="margin-left: 15px" @click="showApplyDialog" :disabled="applyDisabled"
          v-if="showOutApply">
          出库申请
        </el-button>
      </h4>
      <div class="c-table-wrap mt15 ml-12">
        <el-table border striped :data="form.shippingOrderProductDetailVOS" @selection-change="handleSelectionChange">
          <el-table-column align="center" type="selection"></el-table-column>
          <el-table-column align="center" prop="" label="出库产品" min-width="130px">
            <template slot-scope="scope">
              <el-tag size="mini" class="el-tag-other" v-if="scope.row.sampleFlag">
                {{ $t('AddOrder.PrenatalSample') }}
              </el-tag>
              <div v-if="scope.row.productImg" style="position: relative">

                <ErpImage :imgs="scope.row.productImg" @handlePreview="handlePreview" @closeViewer="closeViewer"
                  ref="ErpImage" style="height: 60px"></ErpImage>
              </div>


              <span v-else>--</span>
              <popover-ship :itemTitle="scope.row.mainFlag == 1 && scope.row.splitFlag == 1
                ? '主'
                : scope.row.mainFlag == 0
                  ? '子'
                  : ''
                " :tableData="scope.row.mainFlag == 0
    ? scope.row.parentProduct
    : scope.row.childProductList
    " triggerType="click"></popover-ship>
            </template>
          </el-table-column>

          <el-table-column align="center" prop="" label="产品编码" width="120">
            <template slot-scope="scope">
              <el-link type="primary" :underline="false" @click="skuIdClick(scope.row)">
                {{ scope.row.sku }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="" label="中文报关名/英文报关名" width="200px">
            <template slot-scope="scope">
              {{ scope.row.declareCn || scope.row.productCn }}/{{ scope.row.declareEn || scope.row.productEn }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="productSpecs" label="规格" show-overflow-tooltip="">
            <template slot-scope="scope">
              {{ scope.row.productSpecs ? scope.row.productSpecs : '--' }}
            </template></el-table-column>
          <el-table-column align="center" prop="productMaterial" label="产品材质" show-overflow-tooltip="">
            <template slot-scope="scope">
              {{ scope.row.productMaterial ? scope.row.productMaterial : '--' }}
            </template></el-table-column>
          </el-table-column>
          <el-table-column align="center" prop="productSpecs" label="出库单号" width="120px">
            <template slot-scope="scope">
              <template v-if="scope.row.shippingOrderProductStocks &&
                scope.row.shippingOrderProductStocks.length > 0
                ">
                <p v-for="(o, index) in scope.row.shippingOrderProductStocks" :key="index">
                  <el-link type="primary" :underline="false" @click="showOutStockDetail(o.warehouseOutNumber)">
                    {{ o.warehouseOutNumber ? o.warehouseOutNumber : '-' }}
                  </el-link>
                  <span v-if="scope.row.shippingOrderProductStocks &&
                    scope.row.shippingOrderProductStocks.length > 1
                    ">
                    ;
                  </span>
                </p>
              </template>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="" label="出库仓库">
            <template slot-scope="scope">
              <template v-if="scope.row.shippingOrderProductStocks &&
                scope.row.shippingOrderProductStocks.length > 0
                ">
                <p v-for="(o, index) in scope.row.shippingOrderProductStocks" :key="index">
                  <span>{{ o.warehouseName }}</span>
                  <span v-if="scope.row.shippingOrderProductStocks &&
                    scope.row.shippingOrderProductStocks.length > 1
                    ">
                    ;
                  </span>
                </p>
              </template>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="" label="买方" width="120px">
            <template slot-scope="scope">
              {{ scope.row.purchaser || '--' }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="" label="是否退税">
            <template slot-scope="scope">
              {{ scope.row.drawback | drawbackFilter }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="" label="出库状态">
            <template slot-scope="scope">
              {{ scope.row.status | statusFilter }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="shippedNum" label="出运数量"></el-table-column>

          <!-- <el-table-column
            align="center"
            prop="shippedNum"
            label="箱规尺寸(CM)"
            width="170px"
          >
            <template slot-scope="scope">
              <PackingSize
                :packingSizes="scope.row.shippingOrderProductBoxVOS"
              />
            </template>
          </el-table-column> -->

          <el-table-column align="center" prop="caseNum" label="总箱数"></el-table-column>
          <el-table-column align="center" prop="grossWeight" label="总重量"></el-table-column>
          <el-table-column align="center" prop="volume" label="总体积"></el-table-column>
          <el-table-column align="center" prop="" label="装箱尺寸">
            <template slot-scope="scope">
              <PackingSize :packingSizes="scope.row.shippingOrderProductBoxVOS" />
            </template>
          </el-table-column>
          <el-table-column align="center" prop="declaredUnitPrice" label="申报单价"></el-table-column>
          <el-table-column align="center" prop="chargedWeight" label="计费重量(kg)" width="150">
            <template slot-scope="scope">
              {{ scope.row.chargedWeight ? scope.row.chargedWeight : '--' }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="freight" label="分摊运费">
            <template slot-scope="scope">

              <span v-if="scope.row.freight || scope.row.freight === 0" class="page-link no-underline"
                @click="showFeeDetail(1, scope.row)">
                {{ scope.row.freight }}
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="productOperation" width="140" align="center" label=" 产品运营" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.productOperation

              }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="" label="唛头">
            <template slot-scope="scope">
              <span class="link" @click="setMark(scope.row)">点击查看</span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <h4>内部备注</h4>

      <p class="ml-12">{{ form.insideRemarks ? form.insideRemarks : '-' }}</p>

      <h4>附件</h4>
      <ErpUpload ref="ErpUpload" :uploadParams="uploadParams" @initUpload="initUpload"
        style="margin-bottom: 40px; background: #e4e7ed; padding: 15px"></ErpUpload>

      <h4>运费信息</h4>
      <div class="custom-detail">
        <el-row>
          <el-col>
            <em>总计费重：</em>
            <span>{{ form.totalChargedWeight || 0 }}</span>
          </el-col>
          <el-col>
            <em>总运费：</em>
            <span v-if="form.totalFreight || form.totalFreight === 0" class="page-link no-underline"
              @click="showFeeDetail(2)">
              {{ form.totalFreight }}
            </span>
            <span v-else>--</span>
          </el-col>
        </el-row>
      </div>

      <ShippingInformation :shipNos="this.form.shipNosAll || []" ref="shipInfo" />
    </el-row>

    <!-- 唛头弹窗 -->
    <el-dialog :visible.sync="showMark" title="唛头" width="700px" append-to-body :close-on-click-modal="false"
      modal-append-to-body>
      <table class="custom-table">
        <thead>
          <tr>
            <th width="100">
              <span>唛头</span>
            </th>
            <th>
              <span>内容</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>主唛</td>
            <td>
              <!-- {{ markForm.mainLabel }} -->
              <p v-for="(item, index) in getMark(markForm.mainLabel)" :key="index">
                {{ item }}
              </p>
            </td>
          </tr>
          <tr>
            <td>侧唛</td>
            <td>
              <!-- {{ markForm.sideMark }} -->
              <p v-for="(item, index) in getMark(markForm.sideMark)" :key="index">
                {{ item }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="text-center mt20">
        <el-button @click="printMark(markForm.mainLabel)">
          打印
        </el-button>
      </div>
    </el-dialog>

    <!-- 上传附件 -->
    <File ref="file" @add-file="AddFile" />
    <!-- 运单号 -->
    <waybillNo ref="waybillNo" @refresh="getDetail" :transpotwayList="transpotwayList" />
    <!-- 入库批次选择 -->
    <LotBatchSel ref="lotBatchSel" @lotlist-set="setLocList" />
    <!-- 取消出运 -->
    <CancelShipping ref="cancelShipping" @dialog-confirm="onDialogConfirm" />
    <!-- 选择大货照 -->
    <SelectBigGoodPhoto :option="selPhotoOption" :list="bigGoodsList" :shippingOrderId="shippingOrderId" />

    <!-- 出库申请弹窗 -->
    <ApplyOut ref="applyout" @refresh="getDetail" />
    <!-- 运费明细 -->
    <ShippingDetails :tableData='feeData' :viewType="viewType" :params="showShippingDetails" />
    <!-- 查看邮件模版示例 -->
    <EmailTemplate ref="EmailTemplate" :transportWay="form.shippingMethod && form.shippingMethod.toUpperCase()">
    </EmailTemplate>
  </div>
</template>

<script>
  import { ShipmentMangeInteractor, ShippingPlanInteractor } from '@/core'
  import { mapGetters } from 'vuex'
  import PackingSize from './components/packing-size'
  import popoverShip from './components/popoverShip.vue'
  import File from './components/attach-file-dialog'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'
  import WaybillNo from './components/new-waybill-no'
  import ShippingInformation from './components/shipping-information'
  import LotBatchSel from '@/components/lot-batch-sel/index.vue'
  import SelectBigGoodPhoto from './components/select-big-good-photo/index.vue'
  import ApplyOut from './components/apply-out'

  import { getStockCostConfigureVO, lotRecommend } from '@/api/order'
  import CancelShipping from './components/cancel-shipping'
  import { sendGoodsMail as sendGoodsMailRequest } from './helper/send-mail'
  import { statusListMap } from './helper/data'
  import ShippingDetails from './components/shipping-details'
  import EmailTemplate from '@/components/EmailTemplate/index'
  import { hasRight } from '@/utils/permissionBtn'

  export default {
    name: 'shipment-sheet-detail',
    components: {
      PackingSize,
      popoverShip,
      File,
      WaybillNo,
      ShippingInformation,
      LotBatchSel,
      CancelShipping,
      SelectBigGoodPhoto,
      ApplyOut,
      ShippingDetails,
      EmailTemplate
    },
    data() {
      return {
        shippingOrderId: '',
        bigGoodsList: [], //大货照列表
        selPhotoOption: {
          show: false,
        }, // 选择大货照参数
        rules: {
          storage: [
            {
              required: true,
              message: '请选择',
            },
          ],
        },
        transpotwayList: [],

        currentPro: {},
        showMark: false,
        markForm: {
          mainLabel: '',
          sideMark: '',
        },
        markFormRules: {
          mainLabel: [{ required: true, message: '请输入主唛', trigger: 'blur' }],
        },
        showApply: false,
        statusList: statusListMap,
        form: {
          portShipment: '', //发货地点
          shippingMethod: '', //货运方式
          shippingDate: '', //装运日期
          customerName: '', //客户姓名
          deadlineDate: '', //最晚交期
          businessName: '', //客户代表名称
          businessId: '', //客户代表Id
          deliveryMode: '', // 交货方式
          deliveryAddress: '', //交货地址z
          deliveryDate: '', // 送达时间
          trade: '', //贸易条款
          receivingMan: '', //收货人
          receivingAddress: '', //收货地址
          receivingCompany: '', //收货公司
          receivingPhone: '', // 联系电话
          shippingOrderProductVOS: [],
          shipNos: [],
          shipNosAll: []
        },

        shippingMethodList: [], //货运方式
        portShipments: ['义乌', '苏州', '合肥'], //发货地点
        trades: ['DDP', 'FOB'], //贸易条款
        deliveryModes: [
          //交货方式
          '自送',
          '上门取货',
          '门点做箱',
        ],
        deliveryAddresses: [], // 物流仓库地址
        storageList: [], // 出库仓库
        params: {
          show: false,
        },
        selectedPro: [],
        drawbacks: [
          {
            name: '是',
            code: 1,
          },
          {
            name: '否',
            code: 0,
          },
        ],
        multipleSelection: [],
        applyForm: {
          applyList: [],
        },
        uploadParams: {
          uploadButton: false,
        },
        warehouseOutType: '',
        viewType: 1, //明细类型 1 分摊明细 2 运费明细
        showShippingDetails: {
          show: false,
        }, // 展示运费标识
        feeData: [], // 运费数据
        ingoreShippingMethodList: [
          'AIR-UPS',
          'AIR-FEDEX',
          'OCEAN-FAST SHIP-UPS',
          'OCEAN-FAST SHIP-FEDEX',
          'OCEAN-STANDARD SHIP-UPS',
          'OCEAN-STANDARD SHIP-FEDEX',
          'EXPRESS-DHL',
          'EXPRESS-UPS',
          'EXPRESS-FEDEX IP',
          'EXPRESS-FEDEX IE',
          'US-FEDEX',
          'US-UPS',
          'EXPRESS-SHUNFENG',
        ],

        canSelectAutoEmail: false,
      }
    },

    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),

      buttonText() {
        if (!this.form.shipNos || this.form.shipNos.length === 0) {
          return '填写运单号'
        } else {
          return '更新运单号'
        }
      },

      showOutApply() {
        let pros = this.form.shippingOrderProductDetailVOS
        if (pros && pros.length > 0) {
          return pros.some((pro) => [2, 6].includes(pro.status))
        } else {
          return false
        }
      },

      applyDisabled() {
        // 未出库 出库驳回状态下置灰
        return this.multipleSelection.some(
          (item) => item.status !== 2 && item.status !== 6
        )
      },
    },

    filters: {
      statusFilter(val) {
        var map = {
          0: '出库中',
          1: '已出库',
          2: '出库驳回',
          3: '已取消',
          4: '已完成',
          5: '未知类型',
          6: '未出库',
        }
        return map[val]
      },
      domesticLogisticsFilter(val) {
        if (!val && val !== 0) {
          return '--'
        }
        const code2Name = {
          0: '快递',
          1: '工厂送货',
          2: '物流自提',
        }
        return code2Name[val]
      },
      // 是否退税过滤器
      drawbackFilter(val) {
        if (val + '' === '1') return '是'
        else if (val + '' === '0') return '否'
        else return '--'
      }
    },
    created() {
      this.getDetail()
      ShipmentMangeInteractor.transpotwayList().then((res) => {
        if (res && res.code === '000000') {
          this.transpotwayList = res.data
        }
      })
    },
    methods: {
      hasRight,
      //查看物流示例
      handleView() {
        this.$refs.EmailTemplate.dialogFormVisible = true
      },
      async whChange(val, pro) {
        pro.shippingOrderProductLotDTOList = []

        const { outStockNum: num, sku: skuId, storage } = pro
        const warehouseId = storage.storageId
        pro.shippingOrderProductLotDTOList = []

        const res = await getStockCostConfigureVO({ warehouseId })
        if (res && res.code === '000000') {
          // pro.isChoose = res.data?.warehouseInBatch === 1 ? true : false
          this.$set(
            pro,
            'isChoose',
            res.data?.warehouseInBatch === 1 ? true : false
          )
          pro.warehouseOutType = res.data?.warehouseOutType
        }

        if (pro.warehouseOutType == 1) {
          // 清空批次信息
          if (num && warehouseId) {
            lotRecommend({
              num,
              skuId,
              tenantId: this.userInfo.tenantId,
              salesOrderNumber: this.form.businessNumber,
              warehouseId,
            }).then((res) => {
              if (res && res.code === '000000') {
                pro.shippingOrderProductLotDTOList = res.data.map((item) => {
                  item.lotNumber = item.num
                  return item
                })
                // this.tableKey = Math.random()
              }
            })
          }
        }
      },
      // 获取仓库配置
      getStockCostConfig(id) {
        getStockCostConfigureVO({ warehouseId: id }).then((res) => {
          if (res && res.code === '000000') {
            this.isChoose = res.data?.warehouseInBatch === 1 ? true : false
            this.warehouseOutType = res.data?.warehouseOutType
          }
        })
      },
      setLocList(list) {
        this.applyForm.applyList[
          this.currentLotIndex
        ].shippingOrderProductLotDTOList = list.map((item) => {
          item.lockLotFlag = 'lockLotFlag' in item ? item.lockLotFlag : 0
          item.num = item.lotNumber
          return item
        })
        this.tableKey = Math.random()
      },
      selLot(index) {
        this.currentLotIndex = index
        // 判断是否选择入库仓库
        const cr = this.applyForm.applyList[index]
        if (!cr.storage?.storageId) {
          return this.$message.warning('请选择出库仓库')
        }
        // 添加orderProductUniqueId
        this.$refs.lotBatchSel.show({
          list: cr.shippingOrderProductLotDTOList, // 已经选择的批次
          sku: cr.sku,
          orderProductUniqueId: cr.orderProductUniqueId,
          productName: cr.productCn,
          warehouseId: cr.storage.storageId,
          applyNum: cr.outStockNum,
        })
      },
      // 设置默认批次
      setDefaultLotList(row) {
        const { storageId } = row.storage
        const list =
          row.shippingPlanOrderProductStockVOS.find(
            (item) => item.storageId === storageId
          )?.shippingOrderProductLotDTOList || []
        row.shippingOrderProductLotDTOList = list
      },

      _checkRights() {
        if (this.form.operateFlag != '1') {
          this.$message.warning('暂无操作权限')
          return false
        } else {
          return true
        }
      },

      // 填写运单号
      setWaybillNumber() {
        if (!this._checkRights()) {
          return
        }
        let { shipNos, shippingOrderId, shippingMethod } = this.form
        shipNos = JSON.parse(JSON.stringify(this.form.shipNos))
        this.$refs['waybillNo'].showAddEdit(
          {
            shipNos,
            shippingOrderId,
            shippingMethod,
          },
          this.$route.query.type
        )
      },
      AddFile(file) {
        ShipmentMangeInteractor.uploadAccessory({
          shipAccessoryUrl: file,
          shippingOrderId: this.form.shippingOrderId,
        }).then((res) => {
          if (res?.code === '000000') {
            this.$refs.file.dialogFormVisible = false
            this.$refs.shipInfo.initFile(file, false)
            this.form.shipAccessoryUrl = file
          }
        })
      },
      uploadOtherFile() {
        this.$refs.file.show()
        this.$nextTick().then(() => {
          if (this.form.shipAccessoryUrl) {
            this.$refs.file.initUpload(this.form.shipAccessoryUrl)
          }
        })
      },
      getTagType(val) {
        if ([3].includes(val)) {
          return 'success'
        }
        if ([2, 4].includes(val)) {
          return 'danger'
        }
        if ([1].includes(val)) {
          return ''
        }
        if ([0].includes(val)) {
          return 'warning'
        }
      },
      // 单号详情跳转
      goDetail(type, orderNumber) {
        const type2PageTypes = {
          salesOrder: PAGE_TYPES.salesOrderDetail,
          shipmentPlanDetail: PAGE_TYPES.shipmentPlanDetail,
        }
        navigateTo(this, {
          orderNumber,
          pageType: type2PageTypes[type],
        })
      },
      deletePro(index) {
        if (this.applyForm.applyList.length === 1) {
          this.$message.warning('至少保留一条数据')
        } else {
          this.applyForm.applyList.splice(index, 1)
        }
      },

      showApplyDialog() {
        if (this.multipleSelection.length === 0) {
          this.$message.warning('请选择!')
        } else {
          const applyout = this.$refs.applyout
          ShipmentMangeInteractor.applyOutStockList(
            this.multipleSelection.map((i) => i.shippingOrderProductId)
          ).then((res) => {
            if (res && res.code === '000000') {
              const applyList =
                res.data?.map((item) => {
                  item.shippingOrderProductLotDTOList = [] //设置批次
                  item.status = this.multipleSelection.find(
                    (s) => s.shipingOrderId == item.shipingOrderId
                  )?.status
                  return item
                }) || []
              let self = this
              const { businessNumber, number, shippingOrderId } = this.form
              applyout.showApply({
                list: applyList,
                option: {
                  businessNumber,
                  number,
                  shippingOrderId,
                },
              })
            }
          })
        }
      },
      handleSelectionChange(val) {
        this.multipleSelection = val
      },

      getSums(field) {
        return this.form.shippingOrderProductVOS
          .map((item) => item[field])
          .reduce((prev, next) => prev + next)
      },
      getMark(item) {
        if (!item) {
          return []
        } else {
          return item.split('\n')
        }
      },
      confirm() { },
      closeMarkDialog() { },
      onClose() { },
      selectPro() { },
      confirmMark() { },
      setMark({ sideMark, mainLabel }) {
        this.markForm.sideMark = sideMark
        this.markForm.mainLabel = mainLabel
        this.showMark = true
      },

      // 获取仓库
      getWarehouse(id) {
        if (id) {
          let name = ''
          this.storageList.forEach((item) => {
            if (item.warehouseId == id) {
              name = item.name
            }
          })
          return name
        } else {
          return '/'
        }
      },
      // 返回
      goBack() {
        this.$router.push('/order/shipmentManage/shipmentManage')
      },

      // 大图预览
      handlePreview() {
        this.$refs.ErpImage.handlePreview()
      },
      closeViewer() {
        this.$refs.ErpImage.closeViewer()
      },
      initUpload(data, closable) {
        this.$refs?.ErpUpload?.initUpload(data, closable)
      },
      getDetail() {
        const { shipingOrderId } = this.$route.query
        ShipmentMangeInteractor.getDetail(shipingOrderId).then((res) => {
          if (res && res.code === '000000') {
            this.canSelectAutoEmail = res.data.canSelectAutoEmail
            this.$set(this, 'form', res.data || {})
            this.form.shippingOrderProductDetailVOS =
              this.form.shippingOrderProductDetailVOS.map((item) => {
                return {
                  ...item,
                  parentProduct: [item.parentProduct],
                }
              })

            const { accessoryUrl, shipAccessoryUrl } = res.data
            if (accessoryUrl) {
              this.initUpload(res.data.accessoryUrl, false)
            }
            if (shipAccessoryUrl) {
              this.$refs?.shipInfo?.initFile(shipAccessoryUrl, false)
            }
            this.getShowShipNos(res.data)
          }
        })
      },

      // 获取展示的运单号列表
      getShowShipNos(detail) {

        let logistics = detail.shipNoLogistics
        let shipsNosArr = detail.shipNos?.map((item) => item.shipNo) || []
        let _arr = []
        if (logistics.length) {
          for (let i = 0; i < logistics.length; i++) {
            if (!shipsNosArr.includes(logistics[i]?.shipNo)) {
              _arr.push(logistics[i])
            }
          }
        }
        let shipNosAll = detail.shipNos.concat(_arr)
        // 处理运输方式
        shipNosAll.forEach(shipNo => {
          if (shipNo.shipType == 1) { //1：卡车
            shipNo.shippingMethod = 'Truck'
            shipNo.arriveTime = shipNo.expectArriveDate?.split(' ')[0]
          } else if (shipNo.shipType == 2) { //2：快递
            if (shipNo.carrier == '100001') {
              shipNo.shippingMethod = 'DHL'
            } else if (shipNo.carrier == '100002') {
              shipNo.shippingMethod = 'UPS'
            } else if (shipNo.carrier == '100003') {
              shipNo.shippingMethod = 'FEDEX'
            } else if (shipNo.carrier == '0') {
              shipNo.shippingMethod = 'OTHER'
            }
            shipNo.arriveTime = shipNo.expectArriveDate?.split(' ')[0]
          }
        })
        this.form.shipNosAll = shipNosAll

      },

      skuIdClick({ sku }) {
        this.getProductIdByCode(sku.split('-')[0])
      },
      //根据产品code，查询产品id
      async getProductIdByCode(id) {
        if (id.indexOf('FL') > -1) return
        let response = await ShipmentMangeInteractor.productIdByCode({
          productCode: id,
        })
        if (response.code === '000000') {
          let routeData = this.$router.resolve({
            path: '/product/ProductView',
            query: {
              proId: response.data,
              noReturn: true,
            },
          })
          window.open(routeData.href, '_blank')
        }
      },
      showOutStockDetail(orderNumber) {
        navigateTo(this, {
          orderNumber,
          pageType: PAGE_TYPES.outboundSheetDetail,
        })
      },
      _confirm(title) {
        return this.$confirm(title, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
      },
      // 手动出运
      manualShipment() {
        const { shipingOrderId } = this.$route.query
        if (!this._checkRights()) {
          return
        }
        this._confirm('是否确定货物已出运？')
          .then(() => {
            ShipmentMangeInteractor.manualShip([shipingOrderId]).then((res) => {
              if (res && res.code === '000000') {
                this.$message.success('手动出运成功')
                this.getDetail()
              }
            })
          })
          .catch(() => { })
      },
      //修改单据
      updateSheet() {
        if (!this._checkRights()) {
          return
        }
        if (this.form.wmsUpdateCancelFlag === 1) {
          this.$message({
            showClose: true,
            message: `当前出运委托已安排，请联系单证员${this.form.clerkName}取消安排后，即可修改单据或取消出运`,
            type: 'error',
            duration: 0
          })
          return false
        }

        this.$router.push({
          path: '/order/shipmentManage/update-shipment-sheet',
          query: {
            shippingOrderId: this.form.shippingOrderId,
          },
        })
      },
      // 取消出运
      cancelShipping() {
        if (!this._checkRights()) {
          return
        }
        if (this.form.wmsUpdateCancelFlag === 1) {
          this.$message({
            showClose: true,
            message: `当前出运委托已安排，请联系单证员${this.form.clerkName}取消安排后，即可修改单据或取消出运`,
            type: 'error',
            duration: 0
          })
          return false
        }

        ShipmentMangeInteractor.cancelQuery({
          shippingOrderId: this.form.shippingOrderId,
        }).then((res) => {
          if (res && res.code === '000000') {
            this.$refs['cancelShipping'].showAddEdit()

            let tableData = res.data || []
            this.$refs['cancelShipping'].cancelForm.tableData = tableData.map(
              (i) => {
                if (i.shippingOrderProductStatus == 0) {
                  i.inStockId = i.warehouseId
                }
                i.usable = '1'
                return i
              }
            )
          }
        })
      },
      onDialogConfirm(tableData, fileObj) {
        let requestData = {
          shippingOrderCancelConfirmDtos: tableData,
          ...fileObj,
          shippingOrderId: this.form.shippingOrderId,
        }
        ShipmentMangeInteractor.cancelConfirm(requestData).then((res) => {
          if (res && res.code === '000000') {
            this.$message.success('操作成功')
            this.getDetail()
          }
        })
      },
      sendGoodsMail() {
        this.shippingOrderId = this.form.shippingOrderId
        sendGoodsMailRequest(
          this.form.shippingOrderId,
          {
            tenantId: this.userInfo.tenantId,
            shipTime: this.$route.query.st,
          },

          this
        ).then((res) => {
          this.bigGoodsList = res
          this.selPhotoOption.show = true
          this.selPhotoOption.content = res[0] //邮件信息
        })
      },
      _sendMail(shippingOrderId, ctx) {
        sendGoodsMailRequest(shippingOrderId, this.userInfo.tenantId, ctx)
      },

      // 获取运费明细
      showFeeDetail(viewType, row) {
        this.viewType = viewType
        ShippingPlanInteractor[
          viewType == 1
            ? 'shippingFreightRecordProduct'
            : 'shippingFreightRecordSheet'
        ]({
          shippingOrderId: this.form.shippingOrderId,
          shippingOrderProductId: viewType == 1 ? row.shippingOrderProductId : '',
          tenantId: this.userInfo.tenantId,
        }).then((res) => {
          if (res && res.code === '000000') {
            this.feeData = res.data || []
            this.showShippingDetails.show = true
          }
        })
      },

      // 打印唛头
      printMark(data) {
        if (data) {
          window.open(
            window.location.origin +
            `#/print/orderShipMark?QRCode=${true}&rendImgDisplayed=${false}&content=${JSON.stringify(
              data.replace(/#/g, '~~~')
            )}`,
            '_blank'
          )
        } else {
          this.$message.warning('箱唛内容为空不支持打印！')
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .order-content {
    padding: 0 20px 80px;

    h4 {
      font-size: 14px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-family: 'PingFang Bold';
      color: #606266;
      margin-left: -12px;
    }

    .ml-12 {
      margin-left: -12px;
    }

    .el-input,
    .el-select {
      width: 100%;
    }

    .el-form-item {
      margin-bottom: 0;
    }

    .product-list {
      .product-title {
        display: flex;
        justify-content: flex-start;

        .el-form-item {
          width: 500px;
          margin-left: 20px;
        }
      }

      .product-table {
        display: flex;
        justify-content: space-between;

        table tbody tr {
          height: 90px;

          td {
            height: 90px;
            line-height: 16px;
            padding: 5px 10px;
          }

          .limit {
            height: 100%;
            overflow-y: auto;
          }
        }

        .l-table {
          overflow-x: auto;

          table {
            width: 100%;
            min-width: 1800px;
          }
        }

        .r-table {
          table {
            width: 200px;
          }
        }
      }

      .total-money {
        line-height: 40px;
        text-align: right;

        span {
          font-family: 'PingFang Bold';
          font-size: 18px;
          margin-left: 10px;
        }
      }
    }
  }

  .oper-btn {
    width: 100%;
    position: fixed;
    bottom: 0;
    text-align: center;
    padding: 20px 0;
    background: white;
  }

  .address-dialog {
    .container {
      .box {
        display: flex;
        justify-content: space-between;
        height: 50px;
        line-height: 50px;
        border-left: 1px solid #ececec;
        border-right: 1px solid #ececec;

        >div:first-child {
          width: 80%;
          padding: 0 20px;
          border-right: 1px solid #ececec;
          border-bottom: 1px solid #ececec;

          input {
            vertical-align: middle;
            margin-right: 5px;
            cursor: pointer;
          }
        }

        >div:last-child {
          width: 20%;
          text-align: center;
          border-bottom: 1px solid #ececec;
        }
      }

      .box:first-child {
        border-top: 1px solid #ececec;
      }
    }
  }

  td .link {
    cursor: pointer;
    color: #1890ff;
    cursor: pointer;
    text-decoration: underline;
  }

  ::v-deep .el-input-number--small {
    width: 80px !important;
  }

  ::v-deep .el-input-number--small .el-input__inner {
    padding: 0 10px !important;
  }

  ::v-deep .el-input-number--small .el-input-number__increase,
  ::v-deep .el-input-number--small .el-input-number__decrease {
    display: none !important;
  }

  ::v-deep .el-row.bg {
    margin-right: 0px !important;
    margin-left: 0px !important;
    width: calc(100% - 100px) !important;
    // background: rebeccapurple;
  }

  .detail-block {
    border: 1px solid #ebeef5;
    padding: 20px 30px 0 30px;

    .detail-item {
      height: 36px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;

      & span {
        flex: 1;
        font-size: 14px;
      }

      & .detail-label {
        color: #999999;
      }

      & .detail-content {
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-family: 'PingFang Bold';
        color: #303133;
      }
    }
  }

  .el-tag {
    position: absolute;
    top: 0;
    left: 192px;

    &.el-tag-other {
      top: 4px;
      right: 4px;
      left: auto;
      z-index: 2;
      background-color: #fff7e8;
      border-color: #ffefd1;
      color: #ffae18;
    }
  }

  .button-wrapper {
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 99;
  }
</style>