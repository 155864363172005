<template>
  <div class="custom-dialog pl30 pr30">
    <el-row :gutter="20" class="mt20 text-row">
      <el-col :span="12">
        <label>阐述备货原因</label>
        <p>{{ detail.stockUpReason || '--' }}</p>
      </el-col>
      <el-col :span="12">
        <label>销售运营计划</label>
        <p>{{ detail.salesPlan || '--' }}</p>
      </el-col>
    </el-row>

    <h4 class="mt15">产品列表</h4>
    <div class="c-table-wrap">
      <table class="custom-table">
        <thead>
          <tr>
            <th class="index">序号</th>
            <th>产品编号</th>
            <th>产品名称</th>
            <th>规格</th>
            <th>月询盘数量</th>
            <th>月订单数量</th>
            <th>月订单金额($)</th>
            <th>库存可用量</th>
            <th>
              <span>
                <i>*</i>
                需求数量
              </span>
            </th>
            <th>采购单价(元)</th>
            <th>采购开发</th>
            <th>订单支持</th>
            <th>采购预算总成本</th>
          </tr>
        </thead>
        <tbody v-if="productList.length">
          <tr v-for="(item, i) in productList" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ item.sku }}</td>
            <td>{{ item.nameCn }}</td>
            <td>
              <el-row v-if="item.specsValue1 || item.specsValue2">
                <span v-if="item.specsValue1">{{ item.specsValue1 }}</span>
                <span v-if="item.specsValue2">/{{ item.specsValue2 }}</span>
              </el-row>
              <span v-else>--</span>
            </td>
            <td>{{ item.enquiryMonthNum }}</td>
            <td>{{ item.orderMonthNum }}</td>
            <td>{{ item.orderMonthAmount }}</td>
            <td>
              {{ formatStock(item.stockWarehouseNumVOList) || '--' }}
            </td>
            <td>{{ item.numDemand || '--' }}</td>
            <td>{{ item.costPrice || '--' }}</td>
            <td>{{ item.buyerName || '--' }}</td>
            <td>{{ item.fromBusinessName || '--' }}</td>
            <td>
              {{ utils.moneyFormat(item.costPrice * item.numDemand) || '--' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <el-row class="text-right mt10 mb10" type="flex" justify="end">
      <p>
        总数量：
        <span class="gray f_s_16">{{ amount }}</span>
      </p>
      <p class="ml20">
        总成本：
        <span class="gray f_s_16">{{ utils.moneyFormat(totalCost) }}</span>
      </p>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="8">
        <p>
          <label>期望交期：</label>
          {{ detail.expectedDeliveryDate || '--' }}
        </p>
      </el-col>
      <el-col :span="16">
        <p>
          <label>收货地址：</label>
          {{ detail.receivingAddress || '--' }}
        </p>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="8">
        <p>
          <label>创建人：</label>
          {{ detail.creator || '--' }}
        </p>
      </el-col>
      <el-col :span="16">
        <p>
          <label>创建时间：</label>
          {{ detail.createTime || '--' }}
        </p>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col>
        <p>
          <label>备注：</label>
          {{ detail.remark }}
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { OrderInteractor } from '@/core'
export default {
  name: 'PurchasesDetail', 
  props: {
    detailId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      queryForm: {},
      page: {
        pageNo: 1,
        pageLe: 10,
      },
      total: 0,
      amount: 0,
      totalCost: 0, //总成本
      tableData: [],
      detail: {},
      productList: [],
      purchaseVisible: false,
    }
  },
  watch: {
    detailId: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!value) return
        this.getDetail(value)
      },
    },
  },
  methods: {
    getDetail(id) {
      let self = this
      OrderInteractor.purchasingDGetDetailForEditApi({ numberPrefix: id }).then((res) => {
        if (res && res.code == '000000') {
          self.detail = res.data ? res.data : {}
          self.productList = res.data.purchasingDemandProductEditVOList || []
          if (self.productList.length) {
            const arr = []
            self.productList.forEach((item) => arr.push(Number(item.numDemand)))

            let totalCost = []
            self.productList.forEach((item) =>
              totalCost.push(Number(item.numDemand) * Number(item.costPrice))
            )
            self.amount = arr.reduce((p, v) => p + v)
            self.totalCost = totalCost.reduce((p, v) => p + v)
          }
        }
      })
    },
    // 格式化库存数据
    formatStock(stockData) {
      if (stockData) {
        let isArray = (stockData.constructor = Array)
        let __stockInfo = []

        if (isArray && stockData.length > 0) {
          for (let i = 0; i < stockData.length; i++) {
            __stockInfo.push(
              `${stockData[i].warehouseName}:${stockData[i].num}`
            )
          }
        } else {
          __stockInfo.push(`/`)
        }
        return __stockInfo.toString()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-dialog {
  h4 {
    margin: 0 0 10px;
  }
  p {
    padding: 0 0 10px;
    line-height: 24px;
    font-size: 14px;
    word-wrap: break-word;
  }

  label {
    font-size: 14px;
    color: #666;
  }
}
.text-row {
  p {
    margin-top: 10px;
  }
}
</style>
