<template>
  <div class="custom-container" style="padding: 0 20px;">
    <el-row v-if="$route.path !== '/audit-process/audit-manage/index'">
      <el-page-header title="返回" @back="goBack()" content="成本调整详情"></el-page-header>
    </el-row>

    <h4>基本信息</h4>
    <div class="custom-detail">
      <el-row>
        <el-col>
          <em>调整类型：</em>
          <span>{{ detail.costWarehouseType | costWarehouseTypeName }}</span>
        </el-col>
        <el-col>
          <em>单据来源：</em>
          <span>{{ detail.costAdjustType | costAdjustTypeName }}</span>
        </el-col>
        <el-col>
          <em>创建人：</em>
          <span>{{ detail.creatorName || '--' }}</span>
        </el-col>
        <el-col>
          <em>创建时间：</em>
          <span>{{ detail.createTime || '--' }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col style="width: 100%">
          <em>备注说明：</em>
          <span>{{ detail.remark || '--' }}</span>
        </el-col>
      </el-row>
    </div>

    <h4>产品信息</h4>
    <el-table :data="detail.costProductAdjustVOS" border :summary-method="getSummaries" show-summary
      :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }" class="mt20 dyn-table">
      <el-table-column prop="sku" label="产品编码" width="120">
        <template slot-scope="scope">
          <div class="page-link">
            <router-link :to="`/product/ProductView?proId=${
                scope.row.productId
              }&noReturn=${true}`" target="_blank">
              {{ scope.row.sku }}
            </router-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="productCn" label="产品名称" min-width="180" show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="ell">{{ scope.row.productCn }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="specification" label="规格" width="120" show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="ell">
            {{ scope.row.specification || '--' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="warehouseNumber" label="出库单号" width="190" v-if="detail.costWarehouseType === 1">
        <template slot-scope="scope">
          <span v-if="scope.row.warehouseNumber" class="page-link"
            @click="showDetail(scope.row.warehouseNumber, PAGE_TYPES.outboundSheetDetail)"> {{ scope.row.warehouseNumber
            }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="warehouseType" :label="detail.costWarehouseType === 1 ? '出库类型' : '入库类型'" width="100">
        <template slot-scope="scope">
          {{ scope.row.warehouseType | warehouseTypeName }}
        </template>
      </el-table-column>
      <el-table-column prop="lotNo" label="入库批次" width="190"></el-table-column>
      <el-table-column prop="warehouseInNum" label="数量" width="100"></el-table-column>
      <el-table-column prop="priceExcludingTax" label="单价" width="100">
        <template slot-scope="scope">
          {{ scope.row.priceExcludingTax.toFixed(3) }}
        </template>
      </el-table-column>
      <el-table-column prop="totalPriceExcluding" label="成本" width="120">
        <template slot-scope="scope">
          {{ scope.row.totalPriceExcluding.toFixed(3) }}
        </template>
      </el-table-column>
      <el-table-column prop="totleAdjustPrice" label="已调整金额" width="100" v-if="detail.costWarehouseType === 2">
        <template slot-scope="scope">
          {{
          (scope.row.totleAdjustPrice &&
          scope.row.totleAdjustPrice.toFixed(3)) ||
          0
          }}
        </template>
      </el-table-column>
      <el-table-column prop="adjustPrice" :label="detail.costWarehouseType === 1 ? '调整金额' : '本次调整金额'" width="160">
        <template slot-scope="scope">
          <span :class="{ red: scope.row.adjustPrice < 0 }">
            {{ scope.row.adjustPrice && scope.row.adjustPrice.toFixed(3) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="priceExcludingTaxed" label="调整后单价" width="100">
        <template slot-scope="scope">
          <span>
            {{
            scope.row.priceExcludingTaxed &&
            scope.row.priceExcludingTaxed.toFixed(3)
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="totalPriceExcluded" label="调整后成本" width="120">
        <template slot-scope="scope">
          <span>
            {{
            scope.row.totalPriceExcluded &&
            scope.row.totalPriceExcluded.toFixed(3)
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="purchaseNumber" label="销售单号" width="160" v-if="detail.costWarehouseType === 1">
        <template slot-scope="scope">
          <span v-if="scope.row.orderCode" class="page-link"
            @click="showDetail(scope.row.orderCode, PAGE_TYPES.salesOrderDetail)"> {{ scope.row.orderCode }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="purchaseNumber" label="采购单号" width="160" v-else>
        <template slot-scope="scope">
          <span v-if="scope.row.purchaseNumber" class="page-link"
            @click="showDetail(scope.row.purchaseNumber, PAGE_TYPES.purchaseOrderDetail)"> {{ scope.row.purchaseNumber
            }}</span>
          <span v-else>--</span>

        </template>
      </el-table-column>
      <el-table-column prop="buyerName" label="客户代表" width="120" v-if="detail.costWarehouseType === 1">
        <template slot-scope="scope">
          {{ scope.row.businessName || '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="buyerName" label="采购开发" width="120" v-else>
        <template slot-scope="scope">
          {{ scope.row.buyerName || '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="产品备注" width="200">
        <template slot-scope="scope">
          <el-tooltip :content="scope.row.remark" placement="top">
            <div class="limit-text">
              {{ scope.row.remark || '--' }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <h4>附件</h4>
    <div v-if="detail.fileUrl">
      <div class="flie-list" v-for="(item, i) in JSON.parse(detail.fileUrl)" :key="i">
        <i class="el-icon-document"></i>
        <a class="page-link ml5">
          <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
            <span class="ell">
              <router-link :to="`/preview-file/index?name=${item.name}&url=${item.url}`" target="_blank">
                {{ item.name }}
              </router-link>
            </span>
          </el-tooltip>
        </a>
        <span class="size ml10">
          {{ utils.calculateFileSize(item.size) }}
        </span>
        <span class="ml10">{{ item.time }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import costAdjustInteractor from '@/core/interactors/storage/cost-adjustment'
  import costAdjustDicts from './dict/index'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'

  export default {
    props: {
      detailId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        costAdjustId: '', // 成本调整id
        detail: {},
        PAGE_TYPES
      }
    },
    created() {
      // detailId 优先级高 审核详情
      this.costAdjustId = this.detailId || this.$route.query.id
      this.getDetail(this.costAdjustId)
    },
    filters: {
      // 入库类型
      warehouseTypeName(val) {
        return costAdjustDicts.getWarehouseTypeName(val)
      },
      // 调整类型
      costWarehouseTypeName(val) {
        return costAdjustDicts.getCostWarehouseTypeName(val)
      },
      // 单据来源
      costAdjustTypeName(val) {
        return costAdjustDicts.getCostAdjustTypeName(val)
      },
    },
    methods: {
      showDetail(id, pageType) {
        navigateTo(this, {
          pageType,
          roleJudge: false,
          orderNumber: id
        })
      },
      // 获取详情
      async getDetail(id) {
        const res = await costAdjustInteractor.getCostAdjustDetail(id)
        if (res && res.code === '000000') {
          this.detail = res?.data
        }
      },

      // 合计
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        const _arr =
          this.detail.costWarehouseType === 1 ? [8, 9, 11] : [7, 8, 9, 11]
        columns.forEach((column, index) => {
          if (_arr.includes(index)) {
            const values = data.map((item) => Number(item[column.property]))
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)
              sums[index] = sums[index].toFixed(3)
            } else {
              sums[index] = ''
            }
          } else {
            sums[index] = ''
            return
          }
        })

        return sums
      },

      // 返回上一页
      goBack() {
        this.$router.go(-1)
      },
    },
  }
</script>

<style scoped lang="scss">
  ::v-deep {
    .el-table__footer-wrapper {
      .has-gutter {
        height: 40px;
        font-family: 'PingFang Bold';

        .required-th::before {
          display: none;
        }

        td {
          text-align: center;
        }
      }
    }
  }
</style>
