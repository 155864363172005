<!--
  功能：功能描述 生成发票
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年03月01日 11:41:02
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <el-dialog class="plan-order" :title="$t('orderList.generateinvoice')" :close-on-click-modal="false"
    :visible.sync="visible" :show-close="false" :width="lang !== 'en' ? '450px' : '550px'" :append-to-body="true">
    <el-form :model="form" :rules="rules" ref="form" label-width="105px">
      <el-form-item label="Due Amount" prop="dueAmount" v-if="printType == 4">
        <el-input v-model="form.dueAmount" maxlength="10"></el-input>
      </el-form-item>
      <el-form-item label="Due Date" prop="dueDate">
        <el-date-picker v-model="form.dueDate" type="date" :placeholder="$t('placeholder.selDate')"
          value-format="yyyy-MM-dd hh:mm:ss"></el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('orderList.servicecharge')" prop="commissionAmount">
        <el-input v-model="form.commissionAmount" maxlength="10"></el-input>
      </el-form-item>
      <el-form-item v-if="pageFrom == 'home'">
        <el-checkbox v-model="mailCheck">
          <!-- 发票通过邮件发送给客户 -->
          {{ $t('orderList.sendinvoice') }}
        </el-checkbox>
      </el-form-item>
      <p v-show="hasGeneratedInvoice">
        <!-- 注：当前订单已生成发票，可以在订单详情中查看 -->
        {{ $t('orderList.orderinvoicehasalreadybeencreated') }}
      </p>
    </el-form>
    <div class="text-center mt20">
      <el-button type="primary" @click="confirm">
        <!-- 确定 -->
        {{ $t('other.confirm') }}
      </el-button>
      <el-button @click="close">
        <!-- 取消 -->
        {{ $t('other.cancel') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { selectFinanceInvoice, selectFinanceSimpleInvoice } from '@/api/order'
  import dayjs from 'dayjs'
  import { mapGetters } from 'vuex'
  export default {
    name: 'InvoiceDialog',
    data() {
      return {
        visible: false,
        printType: 0,
        pageFrom: '',
        mailCheck: false,
        form: {
          dueAmount: '',
          dueDate: '',
          commissionAmount: '',
        },

        hasGeneratedInvoice: false, //是否已经生成发票

        rules: {
          dueAmount: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
            },
            {
              pattern: /^\d+(\.\d{1,3})?$/,
              message: this.$t('placeholder.decimals'),
              trigger: 'blur',
            },
          ],
          dueDate: [
            {
              required: true,
              message: this.$t('placeholder.plsSel'),
            },
          ],
          commissionAmount: [
            {
              required: false,
              message: this.$t('placeholder.plsInput'),
            },
            {
              pattern: /^\d+(\.\d{1,3})?$/,
              message: this.$t('placeholder.decimals'),
              trigger: 'blur',
            },
          ],
        },
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    watch: {
      lang() {
        this.rules = {
          dueAmount: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
            },
            {
              pattern: /^\d+(\.\d{1,3})?$/,
              message: this.$t('placeholder.decimals'),
              trigger: 'blur',
            },
          ],
          dueDate: [
            {
              required: true,
              message: this.$t('placeholder.plsSel'),
            },
          ],
          commissionAmount: [
            {
              required: false,
              message: this.$t('placeholder.plsInput'),
            },
            {
              pattern: /^\d+(\.\d{1,3})?$/,
              message: this.$t('placeholder.decimals'),
              trigger: 'blur',
            },
          ],
        }
      },
    },
    methods: {
      showAddEdit(hasGeneratedInvoice, code, type, from) {
        if (type == 5) {
          this.form.dueDate = dayjs(new Date())
            .add(1, 'month')
            .format('YYYY-MM-DD hh:mm:ss')
        }
        this.pageFrom = from
        this.printType = type
        this.hasGeneratedInvoice = hasGeneratedInvoice
        // 简易发票
        if (type == 5) {
          selectFinanceSimpleInvoice({ orderCode: code }).then((res) => {
            this.form.dueAmount = res?.data?.amountCollectedUs || 0
            this.form.commissionAmount = res?.data?.commissionAmount || 0
          })
          // 发票
        } else {
          selectFinanceInvoice({ orderCode: code }).then((res) => {
            this.form.dueAmount = res?.data?.amountCollectedUs || 0
            this.form.commissionAmount = res?.data?.commissionAmount || 0
          })
        }

        this.visible = true
      },
      confirm() {
        this.$refs.form.validate((flag) => {
          if (flag) {
            if (this.pageFrom === 'home') {
              this.form = {
                ...this.form,
                mailCheck: this.mailCheck,
              }
              this.$emit('confirm', { ...this.form })
            } else {
              this.$emit('confirm', { ...this.form })
            }
          }
        })
      },
      close() {
        this.form.dueDate = ''
        this.pageFrom = ''
        this.printType = ''
        this.$refs.form.resetFields()
        this.visible = false
      },
    },
  }
</script>

<style scoped lang="scss">
  ::v-deep {
    .el-date-editor.el-input {
      width: 100%;
    }
  }
</style>
