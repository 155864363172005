<template>
  <div class="detail-order">
    <div class="box-style mt20">
      <span style="font-size: 28px; font-weight: 600">{{ turnover || 0 }}</span>
      <p>订单成交量</p>
    </div>
    <div class="box-style mt20 ml15">
      <span style="font-size: 28px; font-weight: 600">
        {{ sumAmount || 0 }}
      </span>
      <p>订单总金额（￥）</p>
    </div>
    <el-row class="mt30">
      <ErpTable
        border
        :page-number.sync="pageNo"
        :page-size.sync="pageLe"
        :table-data="tableData"
        :total="total"
        @fetchData="fetchData"
        class="table-fixed"
        @query="fetchData"
        style
      >
        <el-table-column type="index" label="序号" align="center" width="60" />
        <el-table-column
          prop="number"
          align="center"
          width="120"
          label="采购订单编号"
        >
          <template slot-scope="scope">
            <span class="page-link" @click="goPurchaseDetail(scope.row.number)">
              {{ scope.row.number }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="purchasingDemandProductNumbers"
          width="160"
          align="center"
          label="采购需求单号"
        >
          <template slot-scope="scope">
            <div
              style="text-align: left"
              v-if="scope.row.purchasingDemandProductNumbers"
            >
              <p
                style="display: inline-block"
                v-for="(
                  num, i
                ) in scope.row.purchasingDemandProductNumbers.split(',')"
                :key="i"
              >
                {{ num }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="email"
          align="center"
          label="产品编号"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.productCode }}</template>
        </el-table-column>
        <el-table-column
          prop="nameCn"
          width="200"
          align="center"
          label="产品名称"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.nameCn }}</template>
        </el-table-column>
        <el-table-column prop="Skype" align="center" label="采购总量">
          <template slot-scope="scope">{{ scope.row.numTotal }}</template>
        </el-table-column>
        <el-table-column prop="WeChat" align="center" label="总金额">
          <template slot-scope="scope">
            <span>
              {{ scope.row.currency == '美元' ? '$' : '￥'
              }}{{ scope.row.amountPayable }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="amountPaid" align="center" label="已付金额">
          <template slot-scope="scope">
            <span>
              {{ scope.row.currency == '美元' ? '$' : '￥'
              }}{{ scope.row.amountPaid }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="amountInvoiced"
          align="center"
          label="已开票金额"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.currency == '美元' ? '$' : '￥'
              }}{{ scope.row.amountInvoiced }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="unAmountInvoiced"
          align="center"
          label="未开票金额"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.currency == '美元' ? '$' : '￥'
              }}{{ scope.row.unAmountInvoiced }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="stateNote"
          align="center"
          label="采购状态"
        ></el-table-column>
        <el-table-column
          prop="expectDeliveryDate"
          align="center"
          label="交货日期"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="actualDeliveryDate"
          align="center"
          width="120"
          label="实际交货日期"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="buyerName"
          align="center"
          label="采购开发"
        ></el-table-column>
      </ErpTable>
    </el-row>
  </div>
</template>

<script>
  import { SupplierManageInteractor } from '@/core'

  export default {
    name: 'ClientManageDetailOrder',
    props: {
      data: {
        type: Object,
        default: () => {},
      },
      supplier: {
        type: String,
      },
    },
    data() {
      return {
        turnover: 0,
        sumAmount: 0,
        tableData: [], // 表格数据
        total: 0, //分页总数
        pageNo: 1, //当前页
        pageLe: 10, //一页条数
        supplierData: {},
      }
    },
    components: {},

    created() {
      this.fetchData()
    },
    mounted() {},
    methods: {
      // 获取列表数据
      async fetchData() {
        let response = await SupplierManageInteractor.listInfoForSupplier({
          supplierId: this.supplier,
          pageNo: this.pageNo,
          pageLe: this.pageLe,
        })
        if (response.code === '000000') {
          this.tableData = response.data.data
          this.total = response.data.count
          this.turnover = response.data.count
          this.sumAmount = response.data.sumAmountEx
        }
      },

      // 采购订单编号跳转
      goPurchaseDetail(id) {
        let routeData = this.$router.resolve({
          path: '../../../purchase/purchase/purchaseOrder/purchaseOrderDetail',
          query: { id: id, noReturn: true },
        })
        window.open(routeData.href, '_blank')
      },
    },
  }
</script>

<style scoped lang="scss">
  .detail-order {
    padding: 10px;
    border-left: 1px solid #d7d7d7;
    .box-style {
      display: inline-block;
      width: 250px;
      height: 80px;
      border: 1px solid #d8d8d8;
      vertical-align: middle;
      padding: 15px 0 0 20px;
    }
  }
  ::v-deep {
    .el-table {
      height: 620px !important;
    }
  }
  .table-fixed {
    height: 680px !important;
  }
</style>
