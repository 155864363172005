const dataSource = {}

//订单管理
dataSource.order = [
  {
    label: '审核单号',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4', '99'],
  },
  {
    label: '销售单号',
    prop: 'orderCode',
    isShow: true,
    operation: ['1', '2', '3', '4', '99'],
  },
  {
    label: '签约日期',
    prop: 'contractDate',
    isShow: true,
    operation: ['1', '2', '3', '4', '99'],
  },
  {
    label: '产品编码',
    prop: 'productCode',
    isShow: true,
    operation: ['1', '2', '3', '4', '99'],
  },
  {
    label: '产品类型',
    prop: 'standardCustomized',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '总金额',
    prop: 'totlePrice',
    isShow: true,
    operation: ['1', '2', '3', '4', '99'],
  },
  {
    label: '客户代表',
    prop: 'businessName',
    isShow: false,
    operation: ['2', '3', '4'],
  },
  {
    label: '订单来源',
    prop: 'orderSource',
    isShow: true,
    operation: ['1', '2', '3', '4', '99'],
  },

  {
    label: '审核状态',
    prop: 'status',
    isShow: false,
    operation: ['2', '3'],
  },
  {
    label: '申请人',
    prop: 'subName',
    isShow: true,
    operation: ['1', '2', '4'],
  },
  {
    label: '申请时间',
    prop: 'subTime',
    isShow: true,
    operation: ['1', '2', '3', '4', '99'],
  },
  {
    label: '本节点审核人',
    prop: 'nowAuditUserName',
    isShow: false,
    operation: ['2'],
  },
  {
    label: '所属地区',
    prop: 'area',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
]

//采购备货
dataSource.purchaseStock = [
  {
    label: '审核单号',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '采购需求编号',
    prop: 'numberPrefix',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '期望交期',
    prop: 'expectedDeliveryDate',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '产品编码',
    prop: 'productCode',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '产品类型',
    prop: 'productStandardCustomized',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  // {
  //   label: '库存',
  //   prop: 'stockWarehouseNumVOList',
  //   isShow: true,
  //   operation: ['1', '2', '3', '4'],
  // },
  {
    label: '需求数量',
    prop: 'numDemand',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '审核状态',
    prop: 'status',
    isShow: false,
    operation: ['2', '3'],
  },
  {
    label: '申请人',
    prop: 'subName',
    isShow: true,
    operation: ['1', '2', '4'],
  },
  {
    label: '申请时间',
    prop: 'subTime',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '本节点审核人',
    prop: 'nowAuditUserName',
    isShow: false,
    operation: ['2'],
  },
  {
    label: '所属地区',
    prop: 'area',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
]

//采购订单
dataSource.purchaseOrder = [
  {
    label: '审核单号',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '采购订单编号',
    prop: 'number',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '买方',
    prop: 'purchaser',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '供应商名称',
    prop: 'supplierName',
    isShow: true,
    width: 200,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '产品编码',
    prop: 'productCode',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '产品名称',
    prop: 'productName',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '采购数量',
    prop: 'numTotal',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '总金额',
    prop: 'amountTotal',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '审核状态',
    prop: 'status',
    isShow: false,
    operation: ['2', '3'],
  },
  {
    label: '申请人',
    prop: 'subName',
    isShow: true,
    operation: ['1', '2', '4'],
  },
  {
    label: '申请时间',
    prop: 'subTime',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '本节点审核人',
    prop: 'nowAuditUserName',
    isShow: false,
    operation: ['2'],
  },
  {
    label: '所属地区',
    prop: 'area',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
]

//入库管理
dataSource.putInStorage = [
  {
    label: '审核单号',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '入库单号',
    prop: 'warehouseInNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '关联单号',
    prop: 'orderNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '入库仓库',
    prop: 'warehouseName',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '入库数量',
    prop: 'totalNum',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },

  {
    label: '不含税入库成本',
    prop: 'totalPriceExcluding',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '含税入库成本',
    prop: 'totalPriceIncluding',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '审核状态',
    prop: 'status',
    isShow: false,
    operation: ['2', '3', '4'],
  },
  {
    label: '申请人',
    prop: 'subName',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '申请时间',
    prop: 'subTime',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },

  {
    label: '本节点审核人',
    prop: 'nowAuditUserName',
    isShow: false,
    operation: ['2', '3', '4'],
  },
  {
    label: '所属地区',
    prop: 'area',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
]

//出库管理
dataSource.outStorage = [
  {
    label: '审核单号',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '出库单号',
    prop: 'warehouseOutNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '关联单号',
    prop: 'orderNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '出库仓库',
    prop: 'warehouseName',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '出库数量',
    prop: 'totalNum',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  // {
  //   label: '(无)出库单价',
  //   prop: 'outNum',
  //   isShow: true,
  //   operation: ['1', '2', '3', '4'],
  // },
  {
    label: '出库金额',
    prop: 'totalPrice',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '审核状态',
    prop: 'status',
    isShow: false,
    operation: ['2', '3', '4'],
  },
  {
    label: '申请人',
    prop: 'subName',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '申请时间',
    prop: 'subTime',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '本节点审核人',
    prop: 'nowAuditUserName',
    isShow: false,
    operation: ['2', '3', '4'],
  },
  {
    label: '所属地区',
    prop: 'area',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
]

//产品管理
dataSource.product = [
  {
    label: '审核单号',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '产品编码',
    prop: 'productCode',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '中文名称',
    prop: 'nameCn',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '英文名称',
    prop: 'nameEn',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '产品类型',
    prop: 'standardCustomized',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '产品类别',
    prop: 'category',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '产线',
    prop: 'productionLine',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '审核状态',
    prop: 'status',
    isShow: false,
    operation: ['2', '3'],
  },
  {
    label: '申请人',
    prop: 'subName',
    isShow: true,
    operation: ['1', '2', '4'],
  },
  {
    label: '申请时间',
    prop: 'subTime',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '本节点审核人',
    prop: 'nowAuditUserName',
    isShow: false,
    operation: ['2'],
  },
  {
    label: '所属地区',
    prop: 'area',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
]

//供应商管理
dataSource.supplier = [
  {
    label: '审核单号',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '供应商编码',
    prop: 'supplierCode',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '供应商名称',
    prop: 'supplierName',
    width: 200,
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '供应商性质',
    prop: 'supplierNatureName',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '供应商类型',
    prop: 'supplierTypeName',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '审核状态',
    prop: 'status',
    isShow: false,
    operation: ['2', '3'],
  },
  {
    label: '申请人',
    prop: 'subName',
    isShow: true,
    operation: ['1', '2', '4'],
  },
  {
    label: '申请时间',
    prop: 'subTime',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '本节点审核人',
    prop: 'nowAuditUserName',
    isShow: true,
    operation: ['2'],
  },
  {
    label: '所属地区',
    prop: 'area',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
]

//收付款方式
dataSource.putPayWay = [
  {
    label: '审核单号',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '类型',
    prop: 'payee',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '内容',
    prop: 'content',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '申请人',
    prop: 'subName',
    isShow: true,
    operation: ['1', '2', '4'],
  },
  {
    label: '申请时间',
    prop: 'subTime',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '审核状态',
    prop: 'status',
    isShow: false,
    operation: ['2', '3'],
  },
  {
    label: '本节点审核人',
    prop: 'nowAuditUserName',
    isShow: true,
    operation: ['2'],
  },
  {
    label: '所属地区',
    prop: 'area',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
]

//付款管理
dataSource.pay = [
  {
    label: '审核单号',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '付款单号',
    prop: 'number',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '付款类型',
    prop: 'payType',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '采购单号',
    prop: 'orderNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '采购需求编号',
    prop: 'purchasingDemandNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '买方',
    prop: 'purchaser',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '收款方',
    prop: 'supplierName',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '收款账户信息',
    prop: 'accountInfo',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '币种',
    prop: 'currency',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '付款金额',
    prop: 'amountApplication',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '审核状态',
    prop: 'status',
    isShow: false,
    operation: ['2', '3'],
  },
  {
    label: '申请人',
    prop: 'subName',
    isShow: true,
    operation: ['1', '2', '4'],
  },
  {
    label: '申请时间',
    prop: 'subTime',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '本节点审核人',
    prop: 'nowAuditUserName',
    isShow: false,
    operation: ['2'],
  },
  {
    label: '所属地区',
    prop: 'area',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
]

//费用管理
dataSource.cost = [
  {
    label: '审核单号',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },

  {
    label: '费用单号',
    prop: 'costNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '费用类型',
    prop: 'costTypeName',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '订单编号',
    prop: 'orderCode',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '收款方',
    prop: 'payUserName',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '收款账户信息',
    prop: 'bankDeposit',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '币种',
    prop: 'currency',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '支付金额',
    prop: 'totalPayAmount',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '支付时间',
    prop: 'payTime',
    isShow: true,
    operation: ['1'],
  },
  {
    label: '审核状态',
    prop: 'status',
    isShow: false,
    operation: ['2', '3'],
  },
  {
    label: '申请人',
    prop: 'subName',
    isShow: true,
    operation: ['1', '2', '4'],
  },
  {
    label: '申请时间',
    prop: 'subTime',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '所属地区',
    prop: 'area',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
]

//售后管理
dataSource.service = [
  {
    label: '审核单号',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '售后类型',
    prop: 'type',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '订单编号',
    prop: 'orderCode',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '退款金额',
    prop: 'amount',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '退款账户',
    prop: 'refundAccount',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '审核状态',
    prop: 'status',
    isShow: false,
    operation: ['2', '3'],
  },
  {
    label: '申请人',
    prop: 'subName',
    isShow: true,
    operation: ['1', '2', '4'],
  },
  {
    label: '申请时间',
    prop: 'subTime',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '本节点审核人',
    prop: 'nowAuditUserName',
    isShow: true,
    operation: ['2'],
  },
  {
    label: '所属地区',
    prop: 'area',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
]

dataSource.settle = [
  {
    label: '审核单号',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '结算单号',
    prop: 'settlementOrder',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '结算订单总数',
    prop: 'totalSalesOrder',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '审核状态',
    prop: 'status',
    isShow: false,
    operation: ['2', '3'],
  },
  {
    label: '地区',
    prop: 'salesOrderArea',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '总提成金额',
    prop: 'totalCommission',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '申请人',
    prop: 'applyer',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '申请时间',
    prop: 'applyTime',
    isShow: true,
    width: 180,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '所属地区',
    prop: 'auditUserArea',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
]

// 结算调整settleAdjust
dataSource.settleAdjust = [
  {
    label: '审核单号',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '结算单号',
    prop: 'settlementOrder',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '销售订单编号',
    prop: 'orderCode',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '地区',
    prop: 'salesOrderArea',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '总提成金额',
    prop: 'totalCommission',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '申请人',
    prop: 'applyer',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '申请时间',
    prop: 'applyTime',
    isShow: true,
    width: 180,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '审核状态',
    prop: 'status',
    isShow: false,
    operation: ['2', '3'],
  },
  {
    label: '所属地区',
    prop: 'auditUserArea',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
]

// 收款管理
dataSource.payeeManage = [
  {
    label: '审核单号',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4', '99'],
  },
  {
    label: '收款单号',
    prop: 'financeCode',
    isShow: true,
    operation: ['1', '2', '3', '4', '99'],
  },
  {
    label: '收款类型',
    prop: 'financeType', // financeType=1 && entryType=1 销售退款，financeType=1 && entryType=0 销售订单
    isShow: true,
    operation: ['1', '2', '3', '4', '99'],
  },
  {
    label: '审核状态',
    prop: 'status',
    isShow: false,
    operation: ['2', '3'],
  },
  {
    label: '销售单号',
    prop: 'orderCode',
    isShow: true,
    operation: ['1', '2', '3', '4', '99'],
  },
  {
    label: '付款方',
    prop: 'shroffAccountId',
    isShow: true,
    operation: ['1', '2', '3', '4', '99'],
  },
  {
    label: '收款方式',
    prop: 'financeMethod',
    isShow: true,
    operation: ['1', '2', '3', '4', '99'],
  },
  {
    label: '票据单号',
    prop: 'ticketNumber',
    isShow: true,
    operation: ['1', '2', '3', '4', '99'],
  },

  {
    label: '币种',
    prop: 'currency',
    isShow: true,
    operation: ['1', '2', '3', '4', '99'],
  },
  {
    label: '收款金额',
    prop: 'amountCollected',
    isShow: true,
    operation: ['1', '2', '3', '4', '99'],
  },
  {
    label: '申请人',
    prop: 'creatorName',
    isShow: true,
    operation: ['1', '2', '3', '4', '99'],
  },
  {
    label: '申请时间',
    prop: 'createTime',
    isShow: true,
    operation: ['1', '2', '3', '4', '99'],
  },
  {
    label: '所属地区',
    prop: 'area',
    isShow: false,
    operation: ['1', '2', '3', '4'],
  },
]

// 成本调整
dataSource.costAdjust = [
  {
    label: '审核单号',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '调整单号',
    prop: 'costAdjustNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '调整类型',
    prop: 'costWarehouseType',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '备注说明',
    prop: 'remark',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '审核状态',
    prop: 'status',
    isShow: true,
    operation: ['2', '3', '4'],
  },
  {
    label: '申请人',
    prop: 'subName',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '申请时间',
    prop: 'appAuditTime',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '所属地区',
    prop: 'area',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
]


// 客评中心
dataSource.customerCenter = [
  {
    label: '审核单号',
    labelEn: 'Review Number',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '客评编号',
    labelEn: 'Feedback Number',
    prop: 'evaluationCode',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '所属地区',
    labelEn: 'Region',
    prop: 'area',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '申请人',
    labelEn: 'Applicant',
    prop: 'subName',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '申请时间',
    labelEn: 'Application Date',
    prop: 'appAuditTime',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
]


// 客评中心

// 客户审核
dataSource.customerAudit = [
  {
    label: '审核单号',
    labelEn: 'Review Number',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '客户编码',
    labelEn: 'Customer Code',
    prop: 'customerCode',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '所属地区',
    labelEn: 'Region',
    prop: 'area',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '申请人',
    labelEn: 'Applicant',
    prop: 'subName',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '申请时间',
    labelEn: 'Application Date',
    prop: 'subTime',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '审核状态',
    prop: 'status',
    isShow: false,
    operation: ['2', '3'],
  },
]

// 公海审核
dataSource.seaAudit = [
  {
    label: '审核单号',
    labelEn: 'Review Number',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '客户编码',
    labelEn: 'Customer Code',
    prop: 'customerCode',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '所属地区',
    labelEn: 'Region',
    prop: 'area',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '申请人',
    labelEn: 'Applicant',
    prop: 'subName',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '申请时间',
    labelEn: 'Application Date',
    prop: 'subTime',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '审核状态',
    prop: 'status',
    isShow: false,
    operation: ['2', '3'],
  },
]
// 客户黑名单审核
dataSource.customerBlackListAudit = [
  {
    label: '审核单号',
    labelEn: 'Review Number',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '客户编码',
    labelEn: 'Customer Code',
    prop: 'customerCode',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '所属地区',
    labelEn: 'Region',
    prop: 'area',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '申请人',
    labelEn: 'Applicant',
    prop: 'subName',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '申请时间',
    labelEn: 'Application Date',
    prop: 'subTime',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '审核状态',
    prop: 'status',
    isShow: false,
    operation: ['2', '3'],
  },
]


// 盘点审核
dataSource.stockCheck = [
  {
    label: '审核单号',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '盘点单号',
    prop: 'stockTakingCode',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '盘点仓库',
    prop: 'warehouseName',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '盘点人',
    prop: 'takingUser',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '创建人',
    prop: 'creator',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '创建时间',
    prop: 'createTime',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '盘点时间',
    prop: 'takingTime',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },

  {
    label: '备注',
    prop: 'remark',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '申请人',
    prop: 'subName',
    isShow: true,
    operation: ['1', '2', '4'],
  },
  {
    label: '申请时间',
    prop: 'subTime',
    isShow: true,
    operation: ['1', '2', '3', '4', '99'],
  },

  {
    label: '所属地区',
    prop: 'area',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
]


//调拨审核
dataSource.transfer = [
  {
    label: '审核单号',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '调拨单号',
    prop: 'transferOrderNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '出库方式',
    prop: 'warehouseOutType',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '调出仓库',
    prop: 'warehouseOutName',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '调入仓库',
    prop: 'warehouseInName',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '预计到达时间',
    prop: 'arriveTime',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '交货方式',
    prop: 'deliveryMode',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '物流单号',
    prop: 'shipNo',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '创建人',
    prop: 'creator',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },



  {
    label: '操作人',
    prop: 'updator',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '调拨数量',
    prop: 'num',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '创建日期',
    prop: 'createTime',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '申请人',
    prop: 'subName',
    isShow: true,
    operation: ['1', '2', '4'],
  },
  {
    label: '申请时间',
    prop: 'subTime',
    isShow: true,
    operation: ['1', '2', '3', '4', '99'],
  },

  {
    label: '所属地区',
    prop: 'area',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
]


// 账期审核
dataSource.accountPeroid = [
  {
    label: '审核单号',
    labelEn: 'Review Number',
    prop: 'auditNumber',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '客户编码',
    labelEn: 'Customer Code',
    prop: 'customerCode',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '所属地区',
    labelEn: 'Region',
    prop: 'area',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '申请人',
    labelEn: 'Applicant',
    prop: 'subName',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '申请时间',
    labelEn: 'Application Date',
    prop: 'subTime',
    isShow: true,
    operation: ['1', '2', '3', '4'],
  },
  {
    label: '审核状态',
    prop: 'status',
    isShow: false,
    operation: ['2', '3'],
  },
]



export default dataSource
