<template>
  <div class="custom-wrap">
    <div class="custom-top">
      <div class="flex">
        <el-page-header :title="$t('other.back')" :content="orderCode" @back="goBack()"
          v-if="!$route.query.noReturn"></el-page-header>
        <div v-else class="no-return">
          {{ orderCode }}
        </div>
        <el-tag class="ml10" size="mini">
          {{ statusNames(status) }}
        </el-tag>
      </div>
      <div v-if="lang != 'en'">
        <el-button @click="changeAddress" v-if="
            hasRight('ORDER:DETAIL:UPDATE:ADDRESS') &&
            ['4', '5', '6'].includes(orderInfo.orderStatus)
          ">
          修改收货信息
        </el-button>
        <el-button v-show="['9'].includes(orderInfo.orderStatus)" plain @click="manualEnd">
          手动完结
        </el-button>
        <el-button v-show="
            ['1', '2', '3', '5', '6', '8', '9'].includes(orderInfo.orderStatus)
          " plain @click="doEdit">
          编辑
        </el-button>

        <!-- 作废状态不显示在线支付 -->
        <el-button v-show="orderInfo.financeFlag == 1 && isSettlementStatus" plain @click="payee(1)">
          收款登记
        </el-button>
        <el-button v-show="
            ['5', '6', '7', '8', '9', '10'].includes(orderInfo.orderStatus) &&
            isSettlementStatus
          " @click="costApply" plain>
          费用申请
        </el-button>
        <el-button v-show="
            ['4', '5', '6', '8', '9', '10'].includes(orderInfo.orderStatus) &&
            isSettlementStatus
          " @click="refundApply" plain>
          退款申请
        </el-button>
        <el-button v-show="orderInfo.financeFlag == 2" type="success"
          @click="payee(2, orderInfo.receiptRegistrationUrl)" plain>
          客户已付待入账
        </el-button>
        <!-- <el-button v-show="isSettlementStatus" plain @click="eidtOrderSupport">
          管理订单支持
        </el-button> -->
        <el-button class="ml10" plain @click="previewContract">
          <!-- 查看合同 -->
          {{ $t('orderDetails.ViewContract') }}
        </el-button>
        <el-button class="ml10" plain v-if="hasGeneratedInvoice" @click="previewInvoiceInfo">
          查看发票
        </el-button>
        <el-button class="ml10" plain v-if="
            !hasGeneratedInvoice &&
            isInclude(orderInfo.orderStatus, [5, 6, 7, 8, 9, 10]) &&
            hasRight('ORDERPRINTINVOICE')
          " @click="createInvoiceInfo(4)">
          <!-- 打印发票 -->
          {{ $t('orderList.invoice') }}
        </el-button>
        <el-button class="ml10" plain v-if="
            !hasSimpleInvoice &&
            isInclude(orderInfo.orderStatus, [5, 6, 7, 8, 9, 10])
          " @click="createInvoiceInfo(5)">
          打印简易发票
        </el-button>
        <el-button class="ml10" plain v-if="hasSimpleInvoice" @click="previewSimpleInvoiceInfo">
          查看简易发票
        </el-button>
        <el-button class="ml10" plain @click="previewMarks()">
          查看Marks
        </el-button>
        <el-button class="ml10" plain @click="previewMarks(true)">
          下载Marks
        </el-button>
        <el-button v-show="
            status != 11 && isSettlementStatus && orderInfo.currency === '美元'
          " type="primary" plain @click="payment">
          <!-- 在线支付 -->
          {{ $t('orderList.onlinePayment') }}
        </el-button>
        <!-- <el-button type="danger" plain @click="refund">申请退款</el-button> -->
        <!-- <el-button
          type="danger"
          plain
          @click="del"
          v-show="status == 1 || status == 2 || status == 3"
        >
          删除
        </el-button> -->
        <!-- <el-button @click="goBack()">关闭订单</el-button> -->
      </div>
      <div v-else>
        <el-button v-show="
            ['1', '2', '3', '5', '6', '8', '9'].includes(orderInfo.orderStatus)
          " plain @click="doEdit">
          <!-- 编辑 -->
          {{ $t('orderDetails.Edit') }}
        </el-button>
        <el-button class="ml10" plain @click="previewContract">
          <!-- 查看合同 -->
          {{ $t('orderDetails.ViewContract') }}
        </el-button>
        <el-button class="ml10" plain v-if="
            !hasGeneratedInvoice &&
            isInclude(orderInfo.orderStatus, [5, 6, 7, 8, 9, 10]) &&
            hasRight('ORDERPRINTINVOICE')
          " @click="createInvoiceInfo(4)">
          <!-- 打印发票 -->
          {{ $t('orderList.invoice') }}
        </el-button>
        <el-button v-show="
            status != 11 && isSettlementStatus && orderInfo.currency === '美元'
          " type="primary" plain @click="payment">
          <!-- 在线支付 -->
          {{ $t('orderList.onlinePayment') }}
        </el-button>
      </div>
    </div>
    <div class="collection-tip" v-if="collectionTipVisible && orderInfo.shippingReminderFlag == 1">
      <i class="el-icon-warning" />
      该订单出运前款项未收齐，请尽快安排催收，以免影响出运或供应商款项支付。
      <i class="el-icon-close" @click="handleCloseCollectionTip" />
    </div>

    <el-tabs v-model="active">
      <!-- 订单信息 -->
      <el-tab-pane :label="$t('orderDetails.OrderInformation')" name="1">
        <!-- 基本信息 -->
        <h4 class="mt5">{{ $t('orderDetails.BasicInformation') }}</h4>

        <div class="custom-detail" :class="lang === 'en' ? 'en' : ''">
          <el-row>
            <el-col>
              <!-- 订单编号 -->
              <em>{{ $t('orderDetails.OrderNumber') }}：</em>
              <span>{{ orderInfo.orderCode || '--' }}</span>
            </el-col>
            <el-col>
              <!-- 报价单号 -->
              <em>{{ $t('orderDetails.QuotationNumber') }}：</em>
              <span @click="
                  viewOffer(orderInfo.enquiryId, orderInfo.priceSheetCode)
                " v-if="orderInfo.priceSheetCode" class="page-link">
                {{ orderInfo.priceSheetCode }}
              </span>
              <span v-else>--</span>
            </el-col>
            <el-col class="w_90">
              <!-- 签约日期 -->
              <em>{{ $t('orderDetails.DateOfSigning') }}：</em>
              <span>{{ orderInfo.contractDate || '--' }}</span>
            </el-col>
            <el-col class="w_90">
              <!-- 出口抬头 -->
              <em>{{ $t('orderDetails.ExportsRise') }}：</em>
              <span>{{ orderInfo.exportsRise || '--' }}</span>
            </el-col>
          </el-row>

          <el-row>
            <el-col>
              <!-- 订单来源 -->
              <em>{{ $t('orderDetails.OrderSource') }}：</em>
              <span>{{ orderInfo.orderSource || '--' }}</span>
            </el-col>
            <el-col>
              <!-- 客户代表 -->
              <em>{{ $t('orderDetails.CustomerRepresentative') }}：</em>
              <span>
                {{
                lang === 'en'
                ? orderInfo.businessNameEn || '--'
                : orderInfo.businessName || '--'
                }}
              </span>
            </el-col>
            <el-col>
              <!-- 地区 -->
              <em>{{ $t('orderDetails.Area') }}：</em>
              <span>
                {{
                lang === 'en'
                ? orderInfo.area || '--'
                : orderInfo.area || '--'
                }}
              </span>
            </el-col>
            <!-- <el-col class="w_90">
              <em>订单支持：</em>
              <span>{{ orderInfo.fromBusinessName || '--' }}</span>
            </el-col> -->
            <el-col>
              <!-- 创建人 -->
              <em>{{ $t('orderDetails.Creator') }}：</em>
              <span>
                {{
                lang === 'en'
                ? orderInfo.creatorNameEn || '--'
                : orderInfo.creatorName || '--'
                }}
              </span>
            </el-col>
          </el-row>

          <el-row>
            <el-col>
              <!-- 创建时间 -->
              <em>{{ $t('orderDetails.CreationTime') }}：</em>
              <span>{{ orderInfo.creatorTime || '--' }}</span>
            </el-col>
          </el-row>
        </div>

        <!-- 客户信息 -->
        <h4>{{ $t('orderDetails.CustomerInformation') }}</h4>
        <div class="custom-detail" :class="lang === 'en' ? 'en' : ''">
          <el-row>
            <el-col>
              <!-- 客户名称 -->
              <em>{{ $t('orderDetails.CustomerName') }}：</em>
              <span>{{ orderInfo.customerName || '--' }}</span>
            </el-col>
            <el-col class="w_90">
              <!-- 客户邮箱 -->
              <em>{{ $t('orderDetails.CustomerMailbox') }}：</em>
              <span>{{ orderInfo.customerEmail || '--' }}</span>
            </el-col>
            <el-col>
              <!-- 公司名称 -->
              <em>{{ $t('orderDetails.CompanyName') }}：</em>
              <span>
                {{ orderInfo.companyName || '--' }}
              </span>
            </el-col>
            <el-col>
              <!-- 公司详细地址 -->
              <template v-if="lang === 'zh'">
                <em style="width: 100px">
                  {{ $t('companyAddress.CompanyDetailedAddress') }}：
                </em>
                <span style="width: calc(100% - 100px)" class="disable-color">
                  {{ computedCompanyDetailedAddress || '--' }}
                </span>
              </template>
              <template v-else>
                <em style="width: 180px">
                  {{ $t('companyAddress.CompanyDetailedAddress') }}：
                </em>
                <span style="width: calc(100% - 180px)">
                  {{ computedCompanyDetailedAddress || '--' }}
                </span>
              </template>
            </el-col>
          </el-row>

          <el-row>
            <el-col>
              <!-- 联系人 -->
              <em>{{ $t('orderDetails.Contacts') }}：</em>
              <span>{{ orderInfo.linkman || '--' }}</span>
            </el-col>
            <el-col>
              <!-- 联系电话 -->
              <em>{{ $t('orderDetails.ContactNumber') }}：</em>
              <span>{{ orderInfo.customerPhone || '--' }}</span>
            </el-col>
            <el-col class="w_90">
              <!-- 客户订单号 -->
              <em>{{ $t('orderDetails.CustomerOrderNumber') }}：</em>
              <span>{{ orderInfo.customerOrderCode || '--' }}</span>
            </el-col>
            <el-col>
              <!-- 运输方式 -->
              <em>{{ $t('orderDetails.ShippingMethod') }}：</em>
              <span>{{ orderInfo.shippingMethod || '--' }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <!-- 装运日期 -->
              <em>{{ $t('orderDetails.ShipmentDate') }}：</em>
              <span>{{ orderInfo.shippingDate || '--' }}</span>
            </el-col>
            <el-col>
              <!-- 交货日期 -->
              <em>{{ $t('orderDetails.DeliveryDate') }}：</em>
              <span>{{ orderInfo.deliveryDate || '--' }}</span>
            </el-col>
            <el-col>
              <!-- 装运港 -->
              <em>{{ $t('orderDetails.ShippingPort') }}：</em>
              <span>{{ orderInfo.portOfLoading || '--' }}</span>
            </el-col>
            <el-col>
              <!-- 目的港 -->
              <em>{{ $t('orderDetails.Destination') }}：</em>
              <span>{{ orderInfo.destination || '--' }}</span>
            </el-col>
          </el-row>
        </div>

        <!-- 产品列表 -->
        <h4>{{ $t('orderDetails.ProductList') }}</h4>
        <div class="c-table-wrap">
          <table class="custom-table">
            <thead>
              <tr>
                <th class="index">#</th>
                <!-- 产品图 -->
                <th :class="lang === 'en' ? 'xl' : ''">
                  {{ $t('orderDetails.ProductImage') }}
                </th>
                <!-- 产品信息 -->
                <th class="more">
                  {{ $t('orderDetails.ProductInformation') }}
                </th>
                <!-- 库存 -->
                <th class="more">{{ $t('orderDetails.Inventory') }}</th>
                <!-- 订单数量 -->
                <th>{{ $t('orderDetails.OrderQuantity') }}</th>
                <!-- 退货数量 -->
                <th>{{ $t('orderDetails.ReturnQuantity') }}</th>
                <!-- 产品类型 -->
                <th>{{ $t('orderDetails.ProductType') }}</th>
                <!-- 售价 -->
                <th>{{ $t('orderDetails.SellingPrice') }}</th>
                <!-- 印刷效果图 -->
                <th :class="lang === 'en' ? 'more' : ''">
                  {{ $t('orderDetails.PrintingRenderings') }}
                </th>
                <!-- 印刷方式 -->
                <th>{{ $t('orderDetails.PrintingMethod') }}</th>
                <!-- 产品描述 -->
                <th class="more">
                  {{ $t('orderDetails.ProductDescription') }}
                </th>
                <!-- 印刷描述 -->
                <th class="more">
                  {{ $t('orderDetails.PrintingDescription') }}
                </th>
                <!-- Setup Charge($) -->
                <th>{{ $t('orderDetails.SetupCharge') }}</th>
                <!-- 印刷文件 -->
                <th>{{ $t('orderDetails.ImprintDocument') }}</th>
                <!-- Shipping Fee -->
                <th>{{ $t('orderDetails.ShippingFee') }}</th>
                <!-- 其他费用($) -->
                <th>{{ $t('orderDetails.OtherFee') }}</th>
                <!-- 费用说明 -->
                <th>{{ $t('orderDetails.FeeDescription') }}</th>
                <!-- 备注 -->
                <th>{{ $t('orderDetails.Remark') }}</th>
                <!-- 客户折扣 -->
                <th :class="lang === 'en' ? 'more' : ''">
                  {{ $t('orderDetails.CustomerDiscounts') }}
                </th>
                <!-- 客户折扣说明 -->
                <th :class="lang === 'en' ? 'more' : ''">
                  {{ $t('orderDetails.CustomerDiscountsDescription') }}
                </th>
                <!-- 订单支持 -->
                <th>{{ $t('orderDetails.OrderSupport') }}</th>
                <!-- 总计 -->
                <th>{{ $t('orderDetails.Total') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in orderInfo.orderProductVOS" :key="i">
                <td>{{ i + 1 }}</td>
                <td>
                  <div class="tag-wrap" :class="{ 'mark-box': item.sampleFlag }">
                    <el-tag size="mini">
                      {{ getLangProductType(item.standardCustomized) }}
                    </el-tag>
                    <el-tag size="mini" class="el-tag-other" v-if="item.sampleFlag">
                      {{ $t('AddOrder.PrenatalSample') }}
                    </el-tag>
                    <el-image v-if="item.image || item.imgMain" :src="(item.image || item.imgMain).concat(URL_COMPRESS)"
                      class="c_pointer" @click="handlePreview(item.image || item.imgMain)"></el-image>
                    <span v-else>--</span>
                  </div>
                </td>

                <td class="text-left">
                  <!-- 产品编码 -->
                  <p>
                    {{ $t('orderDetails.ProductCode') }}：{{ item.sku || '--' }}
                  </p>
                  <!-- 产品名称 -->
                  <p>
                    {{ $t('orderDetails.ProductName') }}：{{
                    item.productEn || '--'
                    }}
                  </p>
                  <!-- 规格 -->
                  <p>
                    {{ $t('orderDetails.Specification') }}：{{
                    item.specification == 'undefined'
                    ? '--'
                    : item.specification || '--'
                    }}
                  </p>
                  <!-- 产品运营 -->
                  <p>
                    {{ $t('orderDetails.ProductOperations') }}：{{
                    lang === 'en'
                    ? item.productBelongerEn || '--'
                    : item.productBelonger || '--'
                    }}
                  </p>
                </td>
                <td>
                  {{
                  lang === 'en'
                  ? item.warehouseNumberEn || '--'
                  : item.warehouseNumber || '--'
                  }}
                </td>
                <td>{{ item.amount || 0 }}</td>
                <td>{{ item.refundProductAmount || 0 }}</td>
                <td>
                  {{ getLangProductType(item.standardCustomized) }}
                </td>
                <td>
                  <div v-if="isViewCost">
                    {{ item.netPrice || '--' }}
                  </div>
                  <NoViewCost v-else />
                </td>
                <td class="c_pointer print-image">
                  <el-row type="flex" align="middle" justify="center" v-if="item.rendingsUrl">
                    <el-image :src="
                        item.rendingsUrl
                          .split(',')[0]
                          .concat('?x-oss-process=image/resize,p_10')
                      " class="c_pointer" @click="handlePreview(item.rendingsUrl.split(','))"
                      style="width: 50px; height: 50px"></el-image>
                    <span class="fileAnnex-span" v-if="item.rendingsUrl.split(',').length > 1">
                      {{ item.rendingsUrl.split(',').length }}
                    </span>
                  </el-row>
                  <span v-else>--</span>
                </td>
                <td>{{ item.printingWayEn || item.printingWay || '--' }}</td>
                <td>
                  <ErpWrapText :text="item.productDescription" />
                </td>
                <td>
                  <ErpWrapText :text="item.printingEffect" />
                </td>
                <td>
                  <div v-if="isViewCost">
                    {{ item.setUpCharge || '--' }}
                  </div>
                  <NoViewCost v-else />
                </td>
                <td>
                  <div v-if="item.printedDocumentsUrl">
                    <div class="flie-list" v-for="(item, i) in JSON.parse(item.printedDocumentsUrl)" :key="i"
                      style="width: 300px">
                      <i class="el-icon-document"></i>
                      <a class="page-link ml5">
                        <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                          <span class="ell" style="max-width: 120px">
                            <router-link :to="`/preview-file/index?name=${item.name}&url=${item.url}`" target="_blank">
                              {{ item.name }}
                            </router-link>
                          </span>
                        </el-tooltip>
                      </a>
                      <span class="size ml10" style="white-space: nowrap">
                        {{ utils.calculateFileSize(item.size) }}
                      </span>
                      <span class="ml10" style="white-space: nowrap">
                        {{ item.time }}
                      </span>
                    </div>
                  </div>
                  <div v-else>
                    {{ lang === 'en' ? 'Not uploaded' : '未上传' }}
                  </div>
                </td>
                <td>
                  <div v-if="isViewCost">
                    {{ item.shippingFee || '--' }}
                  </div>
                  <NoViewCost v-else />
                </td>
                <td>
                  <div v-if="isViewCost">
                    {{ item.otherCharge || '--' }}
                  </div>
                  <NoViewCost v-else />
                </td>
                <td>
                  <div v-if="isViewCost">
                    <el-tooltip :content="item.chargeFees" placement="top">
                      <div class="limit-text">
                        {{ item.chargeFees || '--' }}
                      </div>
                    </el-tooltip>
                  </div>
                  <div v-else>--</div>
                </td>
                <td>
                  <el-tooltip :content="item.remark" placement="top">
                    <div class="limit-text">
                      {{ item.remark || '--' }}
                    </div>
                  </el-tooltip>
                </td>
                <td>
                  <div v-if="isViewCost">
                    {{ item.discountAmount || '--' }}
                  </div>
                  <NoViewCost v-else />
                </td>
                <td>
                  <div v-if="isViewCost">
                    <el-tooltip :content="item.discountReason" placement="top">
                      <div class="limit-text">
                        {{ item.discountReason || '--' }}
                      </div>
                    </el-tooltip>
                  </div>
                  <div v-else>--</div>
                </td>
                <!-- 订单支持 -->
                <td>
                  <div v-if="!isRight">{{ item.fromBusinessName || '--' }}</div>
                  <div v-else>
                    <div v-if="status == 11 || status == 10">
                      {{ item.fromBusinessName || '--' }}
                    </div>
                    <div v-else-if="status != 11 && status != 10" class="blue-text">
                      <span class="blue-text" v-if="item.fromBusinessName" @click="eidtOrderSupport(i)">
                        {{
                        lang === 'en'
                        ? item.fromBusinessNameEn
                        : item.fromBusinessName
                        }}
                      </span>
                      <span type="text" @click="eidtOrderSupport(i)" v-else>
                        {{ lang === 'en' ? 'Please set up' : '请设置' }}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <div v-if="isViewCost">
                    ￥{{ item.rmbTotle | orderMoneyFormat }}
                    <br />
                    ${{ item.usTotle | orderMoneyFormat }}
                  </div>
                  <NoViewCost v-else />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="total-money">
          <!-- 总数量 -->
          {{ $t('orderDetails.TotalQuantity') }}
          <span class="mr20">
            {{ orderInfo.totleAmount || 0 }}
          </span>
          <!-- 总折扣 -->
          {{ $t('orderDetails.TotalDiscount') }}
          <span v-if="isViewCost">
            <span class="mr20">
              {{ orderInfo.discountAmountTotle > 0 ? '-' : '' }}
              {{ orderInfo.discountAmountTotle | orderMoneyFormat }}
            </span>
            <span class="red">
              ${{ orderInfo.productTotlePrice | orderMoneyFormat }}
            </span>
          </span>
          <NoViewCost v-else />
        </div>

        <el-row :gutter="100" class="remark-revise">
          <el-col :span="12">
            <!-- 客户备注 -->
            <h4>
              {{ $t('orderDetails.CustomerNotes') }}
              <span v-show="status != 10" @click="isCustomerNotes = true">
                {{ $t('other.revise') }}
              </span>
            </h4>
            <div v-if="isCustomerNotes">
              <el-input v-model="orderInfo.customerRemark" :placeholder="$t('placeholder.plsInput')" type="textarea"
                limit="500" :rows="3" :maxlength="500" show-word-limit></el-input>
              <!-- 保存 -->
              <em @click="remarkSave(1, orderInfo.customerRemark)">
                {{ $t('other.save') }}
              </em>
            </div>
            <div v-else>{{ orderInfo.customerRemark }}</div>
          </el-col>
          <el-col :span="12">
            <!-- 内部备注 -->
            <h4 class="remark-revise">
              {{ $t('orderDetails.InternalRemarks') }}
              <span v-show="status != 10" @click="isRemarks = true">
                {{ $t('other.revise') }}
              </span>
            </h4>
            <div v-if="isRemarks">
              <el-input v-model="orderInfo.remark" :placeholder="$t('placeholder.plsInput')" type="textarea" limit="500"
                :rows="3" :maxlength="500" show-word-limit></el-input>
              <!-- 保存 -->
              <em @click="remarkSave(2, orderInfo.remark)">
                {{ $t('other.save') }}
              </em>
            </div>
            <div v-else>{{ orderInfo.remark }}</div>
          </el-col>
        </el-row>

        <el-row :gutter="100" class="remark-revise">
          <el-col :span="12">
            <!-- 订单历史问题备注 -->
            <h4 class="remark-revise">
              {{ $t('orderList.NotesOnOrder') }}
              <span @click="historyRemarkSave('', 1)">
                {{ $t('other.revise') }}
              </span>
            </h4>
            <div v-if="isHistoryRemarks">
              <el-input v-model="orderInfo.historyRemark" :placeholder="$t('placeholder.plsInput')" type="textarea"
                limit="500" :rows="3" :maxlength="500" show-word-limit></el-input>
              <!-- 保存 -->
              <em @click="historyRemarkSave(orderInfo.historyRemark)">
                {{ $t('other.save') }}
              </em>
            </div>
            <div v-else>{{ orderInfo.historyRemark }}</div>
          </el-col>
        </el-row>

        <!-- 附件 -->
        <h4>{{ $t('orderDetails.Appendix') }}</h4>
        <div v-show="fileList.length">
          <div class="flie-list" v-for="(item, i) in fileList" :key="i">
            <i class="el-icon-document"></i>
            <a class="page-link ml5">
              <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                <span class="ell">
                  <router-link :to="`/preview-file/index?name=${item.name}&url=${item.url}`" target="_blank">
                    {{ item.name }}
                  </router-link>
                </span>
              </el-tooltip>
            </a>
            <span class="size ml10">
              {{ utils.calculateFileSize(item.size) }}
            </span>
            <span class="ml10">{{ item.time }}</span>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="交易/成本" name="2" v-if="lang != 'en'">
        <h4 class="mt5">交易情况</h4>

        <div class="detail-style">
          <div class="line">
            <div class="item">
              <em>
                订单总金额
                <el-tooltip placement="top" effect="light" content="订单生成时的实际金额，审核通过后不会发生任何变动">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </em>
              <span class="bold" v-if="isViewCost">
                {{ orderInfo.currency == '美元' ? '$' : '￥' }}
                {{
                (orderInfo[
                orderInfo.currency === '美元'
                ? 'totlePrice'
                : 'rmbTotlePrice'
                ] *
                1 +
                orderInfo.discountAmountTotle * 1)
                | orderMoneyFormat
                }}
              </span>

              <NoViewCost v-else />
            </div>
            <div class="item">
              <em>
                应收金额
                <el-tooltip placement="top" effect="light" content="订单总金额 - 抵扣金额 - 折扣金额">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </em>
              <span v-if="isViewCost">
                <span class="green f_s_16 bold">
                  {{ orderInfo.currency == '美元' ? '$' : '￥' }}
                  {{ orderInfo.receivablePrice | orderMoneyFormat }}
                </span>
              </span>
              <NoViewCost v-else />
            </div>
            <div class="item">
              <em>
                已收金额
                <el-tooltip placement="top" effect="light" content="已完成的收款单金额总和">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </em>
              <!-- 实收金额≥应收金额时，颜色转为绿色 -->
              <span v-if="isViewCost" :class="
                  orderInfo.currency == '美元'
                    ? orderInfo.receivedPrice
                    : orderInfo.receivedPrice * exchangeRate >=
                      orderInfo.receivablePrice
                    ? 'green'
                    : 'black'
                ">
                {{ orderInfo.currency == '美元' ? '$' : '￥' }}
                {{
                (orderInfo.receivedPrice
                ? orderInfo.currency == '美元'
                ? orderInfo.receivedPrice
                : orderInfo.receivedPrice * exchangeRate
                : 0) | orderMoneyFormat
                }}
              </span>
              <NoViewCost v-else />
            </div>

            <div class="item">
              <em>
                退款金额
                <el-tooltip placement="top" effect="light" content="已完成的付款单金额总和">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </em>
              <span v-if="isViewCost">
                <span v-if="orderInfo.refundAmount > 0" class="red">
                  {{ orderInfo.currency == '美元' ? '$' : '￥' }}
                  {{ orderInfo.refundAmount | orderMoneyFormat }}
                </span>
                <span v-else class="bold">
                  {{ orderInfo.currency == '美元' ? '$' : '￥' }}
                  {{ (orderInfo.refundAmount || 0) | orderMoneyFormat }}
                </span>
              </span>
              <NoViewCost v-else />
            </div>
            <div class="item">
              <em>
                实收金额
                <el-tooltip placement="top" effect="light" content="已收金额 - 退款金额">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </em>

              <!-- 应收金额 ！= 实收金额时，红色标识 -->
              <span v-if="isViewCost" :class="
                  orderInfo.receivablePrice !=
                  (orderInfo.currency == '美元'
                    ? orderInfo.netReceipts
                    : orderInfo.netReceipts * exchangeRate)
                    ? 'red'
                    : 'green'
                ">
                {{ orderInfo.currency == '美元' ? '$' : '￥' }}
                {{
                (orderInfo.currency == '美元'
                ? orderInfo.netReceipts
                : orderInfo.netReceipts * exchangeRate) | orderMoneyFormat
                }}
              </span>
              <NoViewCost v-else />

              <template v-if="orderInfo.refundVOS && orderInfo.refundVOS.length > 0">
                <div v-for="(i, index) in orderInfo.refundVOS" :key="index">
                  <p class="refund-p">
                    {{ i.refundStatusName }}&nbsp;&nbsp;
                    {{ i.refundTypeName }}&nbsp;&nbsp;{{ i.amount }}
                  </p>
                  <br />
                </div>
              </template>
            </div>
          </div>

          <div class="line">
            <div class="item">
              <em>
                抵扣金额
                <el-tooltip placement="top" effect="light" content="订单生成时输入的金额，审核通过后不会发生任何变动">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </em>
              <span v-if="isViewCost">
                ${{ (orderInfo.usMoney || 0) | orderMoneyFormat }}
              </span>
              <NoViewCost v-else />
            </div>
            <div class="item">
              <em>抵扣人民币</em>
              <span v-if="isViewCost">
                ￥{{ (orderInfo.rmbMoney || 0) | orderMoneyFormat }}
              </span>
              <NoViewCost v-else />
            </div>
            <div class="item">
              <em>抵扣说明</em>
              <span v-if="isViewCost" v-html="orderInfo.discountRemark || '--'"></span>
              <span v-else>--</span>
            </div>
            <div class="item">
              <em>未结算原因</em>
              <span v-html="orderInfo.noSettlementRemark || '--'"></span>
            </div>
            <div class="item">
              <em>
                {{ orderInfo.settlementFlag == 1 ? '驳回时间' : '调整时间' }}
              </em>
              <span v-html="orderInfo.settlementTime || '--'"></span>
            </div>
            <div class="item">
              <em>
                {{ orderInfo.settlementFlag == 1 ? '驳回原因' : '调整原因' }}
              </em>
              <span v-html="orderInfo.settlementRemark || '--'"></span>
            </div>
          </div>
        </div>

        <div class="c-table-wrap">
          <table class="custom-table mt10">
            <thead>
              <tr>
                <th>类型</th>
                <th>交易记录</th>
                <th>金额</th>
                <th>预计交易时间</th>
                <th>实际交易金额</th>
                <th>实际交易时间</th>
                <th>交易单号</th>
                <th>交易状态</th>
                <th>收款方式</th>
                <th>票据单号</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody v-if="orderInfo.otherOrderVOS">
              <!-- type: 1收款，2退款，3：费用 -->
              <tr v-for="(item, i) in orderInfo.otherOrderVOS" :key="i">
                <td>{{ item.typeName || '--' }}</td>
                <td>{{ item.typeMemo || '--' }}</td>
                <td>
                  <div v-if="isViewCost">
                    <div v-if="item.amount > 0">
                      <span v-if="item.type == 1">
                        <em class="bold f_s_16">+</em>
                        {{ orderInfo.currency == '美元' ? '$' : '￥' }}
                      </span>
                      <span v-else-if="item.type == 2">
                        <em class="bold f_s_16">-</em>
                        {{ orderInfo.currency == '美元' ? '$' : '￥' }}
                      </span>
                      <span v-else>
                        <em class="bold f_s_16">
                          {{ item.entryType == 0 ? '-' : '+' }}
                        </em>
                        {{ item.currency == '美元' ? '$' : '￥' }}
                      </span>
                      {{ (item.amount || 0) | orderMoneyFormat }}
                    </div>
                    <div v-else>--</div>
                  </div>
                  <NoViewCost v-else />
                </td>
                <td>{{ item.transactionDate || '--' }}</td>
                <td>
                  <div v-if="isViewCost">
                    <div v-if="item.reAmount > 0">
                      <span v-if="item.type == 1" class="green">
                        <em class="bold f_s_16">+</em>
                        {{ orderInfo.currency == '美元' ? '$' : '￥' }}
                        {{
                        getCurrencyMoney(item.reAmount, orderInfo.currency)
                        | orderMoneyFormat
                        }}
                      </span>
                      <span v-else-if="item.type == 2" class="red">
                        <em class="bold f_s_16">-</em>
                        {{ orderInfo.currency == '美元' ? '$' : '￥' }}
                        {{
                        getCurrencyMoney(item.reAmount, orderInfo.currency)
                        | orderMoneyFormat
                        }}
                      </span>
                      <span v-else>
                        <span v-if="item.entryType == 0" class="red">
                          <em class="bold f_s_16">-</em>
                          {{ item.currency == '美元' ? '$' : '￥' }}
                          {{ item.reAmount | orderMoneyFormat }}
                        </span>
                        <span v-else class="green">
                          <em class="bold f_s_16">+</em>
                          {{ item.currency == '美元' ? '$' : '￥' }}
                          {{ item.reAmount | orderMoneyFormat }}
                        </span>
                      </span>
                    </div>
                    <div v-else>--</div>
                  </div>
                  <NoViewCost v-else />
                </td>
                <td>{{ item.reTransactionDate || '--' }}</td>
                <td>
                  <span v-if="item.transactionCode" class="page-link" @click="
                      transClick(item.transactionCode, item.transactionCode)
                    ">
                    {{ item.transactionCode }}
                  </span>
                  <span v-else>--</span>
                </td>
                <td>
                  <div v-if="item.statusName">
                    <!-- 审核中点击显示审核进度 -->
                    <div v-if="item.statusName == '审核中'">
                      <el-link type="primary" @click="statusTypeClick(item)">
                        {{ item.statusName }}
                      </el-link>
                    </div>
                    <div v-else-if="item.statusName == '审核驳回'" class="red">
                      <el-link type="danger" @click="statusTypeClick(item)">
                        {{ item.statusName }}
                      </el-link>
                    </div>
                    <div v-else>
                      <!-- 驳回显示红色移动上去显示提示信息 -->
                      <el-tooltip v-if="
                          item.statusName == '付款驳回' ||
                          item.statusName == '已撤销'
                        " placement="top" effect="light">
                        <div slot="content">
                          <div>
                            {{
                            item.statusName == '付款驳回'
                            ? '驳回人：'
                            : '撤销人：'
                            }}
                            {{ item.updateName || '--' }}
                          </div>

                          <div style="display: flex">
                            <p>
                              {{
                              item.statusName == '付款驳回'
                              ? '驳回原因：'
                              : '撤销原因：'
                              }}
                            </p>
                            <span style="
                                display: inline-block;
                                max-width: 200px;
                                word-break: break-all;
                              ">
                              {{ item.statusMemo || '--' }}
                            </span>
                          </div>

                          <div>
                            {{
                            item.statusName == '付款驳回'
                            ? '驳回时间：'
                            : '撤销时间：'
                            }}
                            {{ item.updateTime || '--' }}
                          </div>
                        </div>
                        <span v-if="item.statusName.includes('驳回')" class="red">
                          {{ item.statusName }}
                        </span>
                        <span v-else>{{ item.statusName }}</span>
                      </el-tooltip>
                      <span v-else>{{ item.statusName }}</span>
                      <erp-mouseover-tip v-if="item.payStatus == 4">
                        <template name="content">
                          <div>银行退票，请联系财务处理</div>
                        </template>
                      </erp-mouseover-tip>
                    </div>
                  </div>
                  <div v-else>--</div>
                </td>
                <td>{{ item.financeMethod || '--' }}</td>
                <td>{{ item.financeMethod !== '信用卡' ? item.ticketNumber || '--' : '--' }}</td>
                <td>
                  <div v-if="item.receiptFile || (item.statusName ==='待业务确认' && showCollectConfirm && item.confirmFlag !== 1)">
                    <div class="page-link" v-if="item.receiptFile">
                      <router-link :to="`/preview-file/index?name=${
                          item.selfCode + '.pdf'
                        }&url=${item.receiptFile}`" target="_blank">
                        电子回单
                      </router-link>
                    </div>

                    <span  v-if="item.statusName ==='待业务确认' && showCollectConfirm && item.confirmFlag !== 1" class="page-link-noline" @click="collectionConfirm(item)">收款确认</span>
                  </div>

                  <span v-else>--</span>
                </td>
              </tr>
            </tbody>
            <tbody v-else class="empty">
              <tr>
                <td colspan="99">暂无数据</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="product-cost mt20" v-if="costProfitInfo.length > 0">
          <h3 class="title">产品收入成本利润</h3>
          <el-table border :data="costProfitInfo" row-key="rowKey" :tree-props="{
              children: 'costProfitChildVO',
              hasChildren: 'hasChildren',
            }" lazy>
            <el-table-column label="产品编码" align="center" prop="sku" :width="180">
              <template slot-scope="scope">
                {{ scope.row.sku }}
                <em v-show="[0, 1].includes(scope.row.mainFlag)" class="ml5" :class="
                    scope.row.mainFlag == 1 ? 'main-split' : 'child-split'
                  ">
                  {{ scope.row.mainFlag == 1 ? '主' : '子' }}
                </em>
              </template>
            </el-table-column>
            <el-table-column label="产品名称" align="center" prop="productCn"></el-table-column>
            <el-table-column label="规格" align="center" prop="specification">
              <template slot-scope="scope">
                {{ scope.row.specification || '--' }}
              </template>
            </el-table-column>
            <el-table-column label="产品类型" align="center" prop="standardCustomized">
              <template slot-scope="scope">
                {{ getLangProductType(scope.row.standardCustomized) }}
              </template>
            </el-table-column>
            <el-table-column label="订单数量" align="center" prop="amount"></el-table-column>
            <el-table-column label="订单金额" align="center" prop="rmbTotle">
              <template slot-scope="scope">
                <div v-if="isViewCost">
                  {{ scope.row.usTotle | formatPrice2 }}
                </div>
                <NoViewCost v-else />
              </template>
            </el-table-column>

            <el-table-column label="出库数量" align="center" prop="delyveryAmount"></el-table-column>
            <el-table-column label="产品成本" align="center" prop="productCost">
              <template slot-scope="scope">
                {{ scope.row.productCost | formatPrice2 }}
              </template>
            </el-table-column>

            <!-- 2.0.7出库成本暂时隐藏 -->
            <!-- <el-table-column align="center" prop="delyveryAmountCollected">
            <template slot="header">
              出库成本
              <el-tooltip
                placement="top"
                effect="light"
                content="出库成本=子产品出库成本之和+出库成本调整金额"
              >
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <el-tooltip
                placement="top"
                effect="light"
                popper-class="tooltip-cost"
                :disabled="
                  scope.row.delyveryAmountCollected == 0 ||
                  scope.row.delyveryAmountCollected == null
                "
              >
                <div slot="content">
                  <div
                    class="pre-wrap"
                    v-html="getWarehousePrice(scope.row)"
                  ></div>
                </div>
                <span v-if="isViewCost" class="blue c_pointer">
                  {{ scope.row.delyveryAmountCollected | formatPrice2 }}
                </span>
                <NoViewCost v-else />
              </el-tooltip>
            </template>
          </el-table-column> -->
            <el-table-column label="费用分摊金额" align="center" prop="costContribution" width="110">
              <template slot-scope="scope">
                <el-tooltip placement="top" effect="light" popper-class="tooltip-cost" :disabled="
                    scope.row.costContribution == 0 ||
                    scope.row.costContribution == null
                  ">
                  <div slot="content" style="max-height: 600px; overflow-y: auto">
                    <div v-for="(obj, idx) in scope.row.costDetailVOS" :key="idx" class="tooltip-info">
                      <div>
                        费用来源：
                        <span v-if="obj.costSource == 1">销售订单</span>
                        <span v-else-if="obj.costSource == 2">采购订单</span>
                        <span v-else-if="obj.costSource == 3">出运委托</span>
                      </div>
                      <div>
                        <span v-if="obj.costSource == 3">委托单号:</span>
                        <span v-else>费用单号:</span>
                        {{ obj.transactionCode }}
                      </div>
                      <div>费用类型：{{ obj.typeMemo }}</div>
                      <div>
                        分摊金额：
                        <span v-if="isViewCost" :class="obj.entryType == 1 ? 'red' : ''">
                          {{ obj.entryType == 0 ? '+' : '-' }}￥{{
                          obj.costContribution | formatPrice2
                          }}
                        </span>
                        <NoViewCost v-else />
                      </div>
                    </div>
                  </div>
                  <span v-if="isViewCost" :class="scope.row.costContribution < 0 ? 'red' : 'blue'">
                    {{ scope.row.costContribution || 0 | formatPrice2 }}
                  </span>
                  <NoViewCost v-else />
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="amountCollectedUs" width="120">
              <template slot="header">
                收款金额($)
                <el-tooltip placement="top" effect="light" content="收款金额=（子产品出库成本/产品出库成本之和）*主产品收款金额">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <div v-if="isViewCost">
                  {{ scope.row.amountCollectedUs | formatPrice2 }}
                </div>
                <NoViewCost v-else />
              </template>
            </el-table-column>

            <el-table-column label="汇率" align="center" prop="exchangeRate"></el-table-column>
            <el-table-column label="收款金额(￥)" align="center" prop="amountCollectedRmb" width="110">
              <template slot-scope="scope">
                <div v-if="isViewCost">
                  {{ scope.row.amountCollectedRmb | formatPrice2 }}
                </div>
                <NoViewCost v-else />
              </template>
            </el-table-column>
            <!-- 2.0.7 【收款手续费】字段更名为【手续费金额】-->
            <el-table-column label="手续费金额" align="center" prop="commissionAmount" width="110">
              <template slot-scope="scope">
                <div v-if="isViewCost">
                  {{ scope.row.commissionAmount | formatPrice2 }}
                </div>
                <NoViewCost v-else />
              </template>
            </el-table-column>
            <el-table-column label="实际成本" align="center" prop="actualCost">
              <template slot="header">
                实际成本
                <el-tooltip placement="top" effect="light" content="实际成本=产品成本+费用分摊金额">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <div v-if="isViewCost">
                  {{ scope.row.actualCost || '--' }}
                </div>
                <NoViewCost v-else />
              </template>
            </el-table-column>
            <el-table-column label="净利润" align="center" prop="netMargin">
              <template slot="header">
                净利润
                <el-tooltip placement="top" effect="light" content="净利润=收款金额-手续费金额-实际成本">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <div v-if="isViewCost">
                  {{ scope.row.netMargin || '--' }}
                </div>
                <NoViewCost v-else />
              </template>
            </el-table-column>
          </el-table>

          <div class="total">
            <span>
              实际总成本
              <strong v-if="isViewCost">
                ￥
                {{
                costProfitInfo.reduce((total, item) => {
                return (total += item.actualCost * 1 || 0)
                }, 0) | formatPrice2
                }}
              </strong>
              <NoViewCost v-else />
            </span>
            <span>
              实际净利润
              <strong v-if="isViewCost">
                ￥
                {{
                costProfitInfo.reduce((total, item) => {
                return (total += item.netMargin * 1 || 0)
                }, 0) | formatPrice2
                }}
              </strong>
              <NoViewCost v-else />
            </span>
          </div>
        </div>
      </el-tab-pane>
      <!-- 出库/出运 -->
      <el-tab-pane :label="$t('orderDetails.OutboundShipment')" name="3">
        <div class="tip_wrapper">
          <h4>
            <!-- 出运信息 -->
            {{ $t('orderDetails.ShippingInformation') }}
            <!-- <span style="color: #999; font-weight: 500">
              Tips：若产品是组装好一起发货，请勾选主产品安排出运；若子产品是分箱或分批出运，请勾选子产品安排出运；
            </span> -->
          </h4>
          <div v-show="tipVisible" class="tip">
            {{ $t('orderDetails.Tip') }}
          </div>
        </div>
        <div v-for="(item, i) in transPlanData" :key="item.id" class="mb10">
          <div class="receiver-info">
            <div class="info-item">
              <!-- 收货地址 -->
              <span class="label">
                {{ $t('orderDetails.ShippingAddress') }}：
              </span>
              <el-tooltip class="item" :content="item.country + '/' + item.destination" placement="top" :disabled="
                  !item.destination ||
                  (item.destination && item.destination.length < 10)
                ">
                <span class="value">
                  {{ item.country }}/{{ item.destination }}
                </span>
              </el-tooltip>
            </div>
            <div class="info-item">
              <!-- 收货公司 -->
              <span class="label">
                {{ $t('orderDetails.ReceivingCompany') }}：
              </span>
              <el-tooltip class="item" :content="item.receivingCompany" placement="top" :disabled="
                  !item.receivingCompany ||
                  (item.receivingCompany && item.receivingCompany.length < 10)
                ">
                <span class="value">{{ item.receivingCompany }}</span>
              </el-tooltip>
            </div>
            <div class="info-item">
              <!-- 收货人 -->
              <span class="label">{{ $t('orderDetails.Receiver') }}：</span>
              <el-tooltip class="item" :content="item.receivingPerson" placement="top" :disabled="
                  !item.receivingPerson ||
                  (item.receivingPerson && item.receivingPerson.length < 10)
                ">
                <span class="value">{{ item.receivingPerson }}</span>
              </el-tooltip>
            </div>
            <div class="info-item">
              <!-- 联系电话 -->
              <span class="label">
                {{ $t('orderDetails.ContactNumber') }}：
              </span>
              <span class="value">{{ item.receivingPhone }}</span>
            </div>

            <!-- 出运操作按钮 -->
            <div class="btns" v-show="lang != 'en'">
              <el-tooltip class="item" content="修改出运计划" placement="top">
                <el-button plain circle size="mini" type="primary" icon="el-icon-edit" v-show="isSettlementStatus"
                  @click="editTransPlan(item)"></el-button>
              </el-tooltip>
              <el-tooltip class="item" content="安排出运" placement="top">
                <el-button plain circle size="mini" type="primary" icon="el-icon-document-add"
                  v-show="isSettlementStatus" @click="arrangeShip(item, i)"></el-button>
              </el-tooltip>
            </div>
          </div>
          <el-table border ref="shipMultipleTable" :data="item.productScrollVOS"
            @selection-change="shipSelectionChange($event, i)" row-key="id" :tree-props="{
              children: 'productScrollVOS',
              hasChildren: 'hasChildren',
            }" lazy :header-cell-class-name="cellClass">
            <el-table-column type="selection" width="40" align="center" :selectable="checkSelectable"></el-table-column>
            <!-- 出运计划单号 -->
            <el-table-column :label="$t('orderDetails.ShipmentPlanNumber')" align="center" prop="planNumber"
              width="180">
              <template slot-scope="scope">
                {{ scope.row.planNumber }}
                <em class="ml5" :class="
                    scope.row.mainFlag == 1 && scope.row.splitFlag == 1
                      ? 'main-split'
                      : scope.row.mainFlag == 0 && scope.row.splitFlag == 1
                      ? 'child-split'
                      : ''
                  ">
                  {{
                  scope.row.mainFlag == 1 && scope.row.splitFlag == 1
                  ? lang === 'en'
                  ? 'Main'
                  : '主'
                  : scope.row.mainFlag == 0 && scope.row.splitFlag == 1
                  ? lang === 'en'
                  ? 'Child'
                  : '子'
                  : ''
                  }}
                </em>
              </template>
            </el-table-column>
            <!-- 产品编码 -->
            <el-table-column :label="$t('orderDetails.ProductCode')" prop="sku" min-width="130px" align="center"
              class="sampleFlag-box">
              <template slot-scope="scope">
                <div>
                  <div v-if="scope.row.sampleFlag" class="sampleFlag-box-item">
                    <span>
                      {{ $t('AddOrder.PrenatalSample') }}
                    </span>
                  </div>
                  <div>{{ scope.row.sku }}</div>
                </div>
              </template>
            </el-table-column>
            <!-- 产品名称 -->
            <el-table-column :label="$t('orderDetails.ProductName')" prop="productEn" align="center">
              <template slot-scope="scope">
                {{ scope.row.productEn }}
              </template>
            </el-table-column>
            <!-- 规格 -->
            <el-table-column :label="$t('orderDetails.Specification')" prop="productSpecs" align="center">
              <template slot-scope="scope">
                {{ scope.row.productSpecs || '--' }}
              </template>
            </el-table-column>
            <!-- 产品类型 -->
            <el-table-column :label="$t('orderDetails.ProductType')" prop="standardCustomized" align="center">
              <template slot-scope="scope">
                {{
                scope.row.standardCustomized == '辅料'
                ? $t('orderDetails.Excipients')
                : scope.row.standardCustomized == 2
                ? $t('orderDetails.CustomizedProduct')
                : $t('orderDetails.StandardProduct')
                }}
              </template>
            </el-table-column>
            <!-- 是否需要印刷 -->
            <el-table-column :label="$t('orderDetails.DoesItNeedToBePrinted')" prop="isPrint" align="center">
              <template slot-scope="scope">
                {{
                scope.row.isPrint == '1'
                ? lang === 'en'
                ? 'yes'
                : '是'
                : lang === 'en'
                ? 'no'
                : '否'
                }}
              </template>
            </el-table-column>
            <!-- 订单数量 -->
            <el-table-column :label="$t('orderDetails.OrderQuantity')" prop="totalNum" align="center"></el-table-column>
            <!-- 待出运数量 -->
            <el-table-column :label="$t('orderDetails.QuantityToBeShipped')" prop="unShippedNum"
              align="center"></el-table-column>
            <!-- 是否退税 -->
            <el-table-column :label="$t('orderDetails.WhetherToRefundTax')" prop="drawback" align="center">
              <template slot-scope="scope">
                {{
                scope.row.drawback == 1
                ? lang === 'en'
                ? 'yes'
                : '是'
                : lang === 'en'
                ? 'no'
                : '否'
                }}
              </template>
            </el-table-column>
            <!-- 库存可用量 -->
            <el-table-column :label="$t('orderDetails.RemainingStock')" align="center" width="150">
              <template slot-scope="scope">
                <div v-if="
                    scope.row.shippingPlanOrderProductStockVOS &&
                    scope.row.shippingPlanOrderProductStockVOS.length > 0
                  ">
                  <div v-for="(stock, i) in scope.row
                      .shippingPlanOrderProductStockVOS" :key="i">
                    {{
                    lang === 'en' ? stock.storageNameEn : stock.storageName
                    }}:{{ stock.stockNum }}
                  </div>
                </div>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <!-- 产品状态 -->
            <el-table-column :label="$t('orderDetails.ProductStatus')" prop="productState" align="center">
              <template slot-scope="scope">
                {{ scope.row.productState || '--' }}
              </template>
            </el-table-column>
            <!-- 总箱数 -->
            <el-table-column :label="$t('orderDetails.TotalNumberOfBoxes')" prop="caseNum"
              align="center"></el-table-column>
            <!-- 总体积(M³) -->
            <el-table-column :label="$t('orderDetails.TotalVolume')" prop="volume" align="center"></el-table-column>
            <!-- 总重量(KGS) -->
            <el-table-column :label="$t('orderDetails.TotalWeight')" prop="grossWeight"
              align="center"></el-table-column>
          </el-table>
        </div>
        <el-table border ref="multipleTable" :data="transData" tooltip-effect="dark"
          :cell-style="{ textAlign: 'center' }" :header-cell-style="{ textAlign: 'center' }"
          :span-method="objectSpanMethod2">
          <!-- 出运委托单号 -->
          <el-table-column width="120" :label="$t('orderDetails.ShippingOrderNumber')" prop="number">
            <template slot-scope="scope">
              <div v-if="lang === 'en'">{{ scope.row.number }}</div>
              <div class="page-link" v-else>
                <router-link :to="`../../order/shipment-sheet-detail?shipingOrderId=${
                    scope.row.shippingOrderId
                  }&noReturn=${true}`" target="_blank">
                  {{ scope.row.number }}
                </router-link>
              </div>
            </template>
          </el-table-column>
          <!-- 贸易方式 -->
          <el-table-column prop="trade" :label="$t('orderDetails.TradeWay')"></el-table-column>
          <!-- 运输方式 -->
          <el-table-column :label="$t('orderDetails.ShippingMethod')" prop="shippingMethod"></el-table-column>
          <!-- 出运状态 -->
          <el-table-column :label="$t('orderDetails.ShippingStatus')" prop="status">
            <template slot-scope="scope">
              {{ getLangShipState(scope.row.status) }}
            </template>
          </el-table-column>
          <!-- 运单号 -->
          <el-table-column prop="shipNo" :label="$t('orderDetails.WaybillNumber')" :width="180">
            <template slot-scope="scope">
              <div v-if="scope.row.shipNo">
                <p v-for="(item, i) in scope.row.shipNo.split('/')" :key="i" class="blue-text" @click="
                    logisticsProgress({
                      shippingOrderId: scope.row.shippingOrderId,
                      shipNo: item,
                    })
                  ">
                  {{ item }}
                </p>
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <!-- 出运时间 -->
          <el-table-column prop="shipTime" :label="$t('orderDetails.ShippingDate')" width="180">
            <template slot-scope="scope">
              <div v-if="scope.row.shipTime">
                {{ scope.row.shipTime }}
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <!-- 预计到达时间 -->
          <el-table-column prop="arriveTime" :label="$t('orderDetails.EstimatedArrivalTime')" width="120">
            <template slot-scope="scope">
              <div v-if="scope.row.arriveTime">
                {{ scope.row.arriveTime }}
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <!-- 产品编码 -->
          <el-table-column :label="$t('orderDetails.ProductCode')" show-overflow-tooltip min-width="130px">
            <template slot-scope="scope">
              <div>
                <div v-if="scope.row.sampleFlag" class="sampleFlag-box-item">
                  <span>{{ $t('AddOrder.PrenatalSample') }}</span>
                </div>
                <div>{{ scope.row.sku }}</div>
              </div>
            </template>
          </el-table-column>
          <!-- 产品名称 -->
          <el-table-column :label="$t('orderDetails.ProductName')" prop="productEn" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.productEn }}
            </template>
          </el-table-column>
          <!-- 规格 -->
          <el-table-column :label="$t('orderDetails.Specification')" prop="productSpecs" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="scope.row.productSpecs">
                {{ scope.row.productSpecs }}
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <!-- 产品类型 -->
          <el-table-column :label="$t('orderDetails.ProductType')" prop="standardCustomized">
            <template slot-scope="scope">
              {{
              scope.row.sonProductType == 1
              ? $t('orderDetails.Excipients')
              : scope.row.standardCustomized == 1
              ? $t('orderDetails.StandardProduct')
              : $t('orderDetails.CustomizedProduct')
              }}
            </template>
          </el-table-column>
          <!-- 是否需要印刷 -->
          <el-table-column width="120" :label="$t('orderDetails.DoesItNeedToBePrinted')" prop="printingWay">
            <template slot-scope="scope">
              {{
              scope.row.printingWay &&
              scope.row.printingWay.toUpperCase() === 'BLANK'
              ? lang === 'en'
              ? 'no'
              : '否'
              : lang === 'en'
              ? 'yes'
              : '是'
              }}
            </template>
          </el-table-column>
          <!-- 本次出运数量 -->
          <el-table-column :label="$t('orderDetails.QuantityOfThisShipment')" prop="shippedNum"
            width="120"></el-table-column>
          <!-- 是否退税 -->
          <el-table-column :label="$t('orderDetails.WhetherToRefundTax')" prop="drawback">
            <template slot-scope="scope">
              {{
              scope.row.drawback == 1
              ? lang === 'en'
              ? 'yes'
              : '是'
              : lang === 'en'
              ? 'no'
              : '否'
              }}
            </template>
          </el-table-column>
          <!-- 总箱数 -->
          <el-table-column :label="$t('orderDetails.TotalNumberOfBoxes')" prop="caseNum"></el-table-column>
          <!-- 总体积(M³) -->
          <el-table-column :label="$t('orderDetails.TotalVolume')" prop="volume"></el-table-column>
          <!-- 总重量(KGS) -->
          <el-table-column :label="$t('orderDetails.TotalWeight')" prop="grossWeight"></el-table-column>
        </el-table>

        <div class="flex-btn mt20 mb10">
          <!-- 待安排 -->
          <h4>{{ $t('orderDetails.PendingArrangement') }}</h4>
          <el-button @click="planProduct(1)" v-show="planProductVisible && isSettlementStatus && lang != 'en'">
            <!-- 批量安排 -->
            {{ $t('orderDetails.BulkArrangement') }}
          </el-button>
        </div>
        <el-table border ref="multipleTable" :data="waitTable" tooltip-effect="dark"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <!-- 产品编码 -->
          <el-table-column :label="$t('orderDetails.ProductCode')" align="center" min-width="130px">
            <template slot-scope="scope">
              <div>
                <div v-if="scope.row.sampleFlag" class="sampleFlag-box-item">
                  <span>{{ $t('AddOrder.PrenatalSample') }}</span>
                </div>
                <div>{{ scope.row.sku }}</div>
              </div>
            </template>
          </el-table-column>
          <!-- 产品名称 -->
          <el-table-column prop="productEn" :label="$t('orderDetails.ProductName')" show-overflow-tooltip
            align="center"></el-table-column>
          <!-- 产品运营 -->
          <el-table-column prop="productBelonger" :label="$t('orderDetails.ProductOperations')" show-overflow-tooltip
            align="center">
            <template slot-scope="scope">
              {{
              lang === 'en'
              ? scope.row.productBelongerEn || '--'
              : scope.row.productBelonger || '--'
              }}
            </template>
          </el-table-column>
          <!-- 规格 -->
          <el-table-column :label="$t('orderDetails.Specification')" prop="specification"
            align="center"></el-table-column>
          <!-- 产品类型 -->
          <el-table-column :label="$t('orderDetails.ProductType')" prop="productType" align="center">
            <template slot-scope="scope">
              {{ getLangProductType(scope.row.productType) }}
            </template>
          </el-table-column>
          <!-- 是否需要印刷 -->
          <el-table-column :label="$t('orderDetails.DoesItNeedToBePrinted')" prop="printing" align="center">
            <template slot-scope="scope">
              {{
              scope.row.printingWay &&
              scope.row.printingWay.toUpperCase() === 'BLANK'
              ? lang === 'en'
              ? 'no'
              : '否'
              : lang === 'en'
              ? 'yes'
              : '是'
              }}
            </template>
          </el-table-column>
          <!-- 订单数量 -->
          <el-table-column :label="$t('orderDetails.OrderQuantity')" prop="amount" align="center"></el-table-column>
          <!-- 效果图状态 -->
          <el-table-column :label="$t('orderDetails.RenderingsStatus')" prop="rendingsStatus" align="center">
            <template slot-scope="scope">
              <el-row v-if="scope.row.picStatus.length > 1">
                <div v-if="!isSettlementStatus">
                  {{
                  lang === 'en'
                  ? $t('orderDetails.CustomerConfirmation')
                  : scope.row.picStatus
                  }}
                </div>
                <div v-else>
                  <span v-if="scope.row.picStatus === '待确认'" @click="picStatusClick(scope.row, 'wait', true)"
                    class="wait-span">
                    {{ $t('orderDetails.ToBeConfirmed') }}
                  </span>
                  <span class="comp-span" v-if="scope.row.picStatus === '已确认'"
                    @click="picStatusClick(scope.row, 'comp', true)">
                    {{ $t('orderDetails.HasBeenConfirmed') }}
                  </span>
                </div>
              </el-row>
              <span v-else>
                {{ $t('orderDetails.NoFile') }}
              </span>
            </template>
          </el-table-column>
          <!-- 印刷文件 -->
          <el-table-column :label="$t('orderDetails.ImprintDocument')" align="center"
            :width="lang === 'en' ? 480 : 400">
            <template slot-scope="scope">
              <!-- <div v-if="scope.row.printedDocumentsUrl">
                <div
                  class="flie-list"
                  v-for="(item, i) in JSON.parse(scope.row.printedDocumentsUrl)"
                  :key="i"
                  style="width: 300px"
                >
                  <i class="el-icon-document"></i>
                  <a class="page-link ml5">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="item.name"
                      placement="top-start"
                    >
                      <span class="ell" style="max-width: 120px">
                        <router-link
                          :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                          target="_blank"
                        >
                          {{ item.name }}
                        </router-link>
                      </span>
                    </el-tooltip>
                  </a>
                  <span class="size ml10" style="white-space: nowrap">
                    {{ utils.calculateFileSize(item.size) }}
                  </span>
                  <span class="ml10" style="white-space: nowrap">
                    {{ item.time }}
                  </span>
                </div>
              </div>
              <div v-else>未上传</div> -->
              <!-- <file-upload :ref="'ErpUploadLogoInfo' + scope.$index" :index="scope.$index" :limit="9"
                @uploadList="multiUploadList" @initUpload="initUploadLogoInfo"></file-upload> -->
              <FileUpload v-model="waitTable[scope.$index].printedDocumentsUrl"
                @change="handleFileUploadChange($event, scope.$index)" />
            </template>
          </el-table-column>
          <!-- 操作 -->
          <el-table-column :label="$t('orderDetails.Operate')" align="center">
            <template slot-scope="scope">
              <el-tooltip content="安排订单" placement="top" v-if="
                  (scope.row.picStatus == '-' ||
                    scope.row.picStatus == '已确认') &&
                  isSettlementStatus &&
                  lang != 'en'
                ">
                <el-button plain type="primary" style="margin: 0 1.5px" @click="planProduct(2, scope.row)" size="mini"
                  icon="el-icon-connection" circle></el-button>
              </el-tooltip>
              <span v-else>-</span>
            </template>
          </el-table-column>
        </el-table>

        <div v-if="
            shipInfo.alreadShipProductVOS &&
            shipInfo.alreadShipProductVOS.length
          ">
          <!-- 已安排 -->
          <h4>{{ $t('orderDetails.HasBeenArrangement') }}</h4>
          <el-table border :data="shipInfo.alreadShipProductVOS" :header-cell-style="{ 'text-align': 'center' }"
            :cell-style="{ 'text-align': 'center' }" row-key="rowKey" :tree-props="{
              children: 'orderProductChildVOS',
              hasChildren: 'hasChildren',
            }" lazy>
            <!-- 产品编码 -->
            <el-table-column :label="$t('orderDetails.ProductCode')" :width="180">
              <template slot-scope="scope">
                {{ scope.row.sku }}
                <div v-if="scope.row.sampleFlag" class="sampleFlag-box-item">
                  <span>{{ $t('AddOrder.PrenatalSample') }}</span>
                </div>
                <em class="ml5" :class="
                    scope.row.mainFlag == 1
                      ? 'main-split'
                      : scope.row.mainFlag == 0
                      ? 'child-split'
                      : ''
                  ">
                  {{
                  scope.row.mainFlag == 1
                  ? lang === 'en'
                  ? 'Main'
                  : '主'
                  : scope.row.mainFlag == 0
                  ? lang === 'en'
                  ? 'Child'
                  : '子'
                  : ''
                  }}
                </em>
              </template>
            </el-table-column>
            <!-- 产品名称 -->
            <el-table-column prop="productEn" :label="$t('orderDetails.ProductName')" width="180">
              <template slot-scope="scope">
                {{ scope.row.productEn }}
              </template>
            </el-table-column>
            <!-- 产品运营 -->
            <el-table-column prop="productBelonger" :label="$t('orderDetails.ProductOperations')">
              <template slot-scope="scope">
                {{
                lang === 'en'
                ? scope.row.productBelongerEn || '--'
                : scope.row.productBelonger || '--'
                }}
              </template>
            </el-table-column>
            <!-- 规格 -->
            <el-table-column prop="specification" :label="$t('orderDetails.Specification')">
              <template slot-scope="scope">
                {{ scope.row.specification || '--' }}
              </template>
            </el-table-column>
            <!-- 产品类型 -->
            <el-table-column prop="productType" :label="$t('orderDetails.ProductType')" :width="160">
              <template slot-scope="scope">
                {{
                getLangProductType(
                scope.row.productType || scope.row.standardCustomized
                )
                }}
              </template>
            </el-table-column>
            <!-- 是否需要印刷 -->
            <el-table-column :label="$t('orderDetails.DoesItNeedToBePrinted')" width="120">
              <template slot-scope="scope">
                {{
                scope.row.printingWay &&
                scope.row.printingWay.toUpperCase() === 'BLANK'
                ? lang === 'en'
                ? 'no'
                : '否'
                : lang === 'en'
                ? 'yes'
                : '是'
                }}
              </template>
            </el-table-column>
            <!-- 订单数量 -->
            <el-table-column prop="amount" :label="$t('orderDetails.OrderQuantity')"></el-table-column>
            <!-- 期望交期 -->
            <el-table-column prop="expectedDeliveryDate" :label="$t('orderDetails.ExpectedDeliveryDate')"
              width="150"></el-table-column>
            <!-- 采购开发 -->
            <el-table-column prop="salesName" :label="$t('orderDetails.PurchasingDevelopment')" width="150">
              <template slot-scope="scope">
                {{
                lang === 'en' ? scope.row.salesNameEn : scope.row.salesName
                }}
              </template>
            </el-table-column>
            <!-- 地区 -->
            <el-table-column prop="area" :label="$t('orderDetails.Area')">
              <template slot-scope="scope">
                <span v-if="scope.row.area === '合肥'">
                  {{ lang === 'en' ? $t('orderList.Hefei') : '合肥' }}
                </span>
                <span v-else-if="scope.row.area === '苏州'">
                  {{ lang === 'en' ? $t('orderList.Suzhou') : '苏州' }}
                </span>
                <span v-else-if="scope.row.area === '义乌'">
                  {{ lang === 'en' ? $t('orderList.Yiwu') : '义乌' }}
                </span>
              </template>
            </el-table-column>
            <!-- 订单备注 -->
            <el-table-column prop="remark" :label="$t('orderDetails.OrderRemarks')">
              <template slot-scope="scope">
                {{ scope.row.remark || '--' }}
              </template>
            </el-table-column>
            <!-- 类型 -->
            <el-table-column prop="remark" :label="$t('orderDetails.Type')" width="150">
              <template slot-scope="scope">
                <span v-if="scope.row.optionType == 1">
                  {{
                  lang === 'en'
                  ? $t('orderDetails.ProcessingDelivery')
                  : '加工出库'
                  }}
                </span>
                <span v-else-if="scope.row.optionType == 2">
                  {{
                  lang === 'en'
                  ? $t('orderDetails.OrdinaryDelivery')
                  : '普通出库'
                  }}
                </span>
                <span v-else-if="scope.row.optionType == 3">
                  {{
                  lang === 'en'
                  ? $t('orderDetails.PurchaseOrder')
                  : '采购订单'
                  }}
                </span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <!-- 效果图状态 -->
            <el-table-column prop="remark" :label="$t('orderDetails.RenderingsStatus')" :width="150">
              <template slot-scope="scope">
                <div v-if="scope.row.mainFlag == 0">--</div>
                <div v-else>
                  <el-row v-if="scope.row.picStatus && scope.row.picStatus.length > 1">
                    <div v-if="isSettlementStatus">
                      <span v-if="scope.row.picStatus === '待确认'" @click="picStatusClick(scope.row, 'wait', false)"
                        class="wait-span">
                        {{ $t('orderDetails.ToBeConfirmed') }}
                      </span>
                      <span class="comp-span" v-if="scope.row.picStatus === '已确认'"
                        @click="picStatusClick(scope.row, 'comp', false)">
                        {{ $t('orderDetails.HasBeenConfirmed') }}
                      </span>
                    </div>
                    <div v-else>
                      {{
                      lang === 'en'
                      ? $t('orderDetails.CustomerConfirmation')
                      : scope.row.picStatus
                      }}
                    </div>
                  </el-row>
                  <span v-else>
                    {{ $t('orderDetails.NoFile') }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <!-- 状态 -->
            <el-table-column prop="remark" :label="$t('orderDetails.Status')" width="120">
              <template slot-scope="scope">
                <div v-if="
                    scope.row.productCourseVOList &&
                    scope.row.productCourseVOList.length
                  ">
                  <div v-if="
                      scope.row.productCourseVOList[
                        scope.row.productCourseVOList.length - 1
                      ].remark
                    ">
                    <el-tooltip placement="top" effect="light">
                      <div slot="content" class="tip-content">
                        <el-row class="state_content d_block line-h18" v-for="(tip, tipIndex) of scope.row
                            .productCourseVOList" :key="tipIndex">
                          <template v-if="tip.remark">
                            <p v-for="(text, n) in tip.remark.split(',')" :key="n">
                              <span>{{ text.split(':')[0] }}：</span>
                              <span :class="
                                  text.split(':')[0] == '出库单号' ||
                                  text.split(':')[0] == '采购单号'
                                    ? 'page-link'
                                    : ''
                                " @click="
                                  goToPage(
                                    text.split(':')[0],
                                    scope.row.productCourseVOList[
                                      scope.row.productCourseVOList.length - 1
                                    ].businessModuleCourseId,
                                    text.split(':')[1]
                                  )
                                ">
                                {{ text.split(':')[1] }}
                              </span>
                            </p>
                          </template>
                        </el-row>
                      </div>
                      <span v-if="
                          scope.row.productStatus == 0 &&
                          (scope.row.optionType == 1 ||
                            scope.row.optionType == 2)
                        " class="c_primary c_pointer">
                        {{ $t('orderDetails.PendingDelivery') }}
                      </span>
                      <span v-else class="c_primary c_pointer">
                        <span v-if="lang === 'en'">
                          {{ getLangState(scope.row.productStatusDesc) }}
                        </span>
                        <span v-else>
                          {{ scope.row.productStatusDesc }}
                        </span>
                      </span>
                    </el-tooltip>
                  </div>
                  <div v-else>
                    <span class="c_primary">
                      <span v-if="lang === 'en'">
                        {{ getLangState(scope.row.productStatusDesc) }}
                      </span>
                      <span v-else>
                        {{ scope.row.productStatusDesc }}
                      </span>
                    </span>
                  </div>
                </div>
                <div v-else>
                  <span v-if="lang === 'en'">
                    {{ getLangState(scope.row.productStatusDesc) }}
                  </span>
                  <span v-else>
                    {{ scope.row.productStatusDesc }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <!-- 操作 -->
            <el-table-column prop="remark" :label="$t('orderDetails.Operate')" width="120">
              <template slot-scope="scope">
                <el-button v-show="
                    isSettlementStatus &&
                    scope.row.mainFlag != 0 &&
                    scope.row.childFlag &&
                    lang != 'en'
                  " @click="revocationArrange(scope.row)">
                  撤销安排
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="操作日志" name="4" v-if="lang != 'en'">
        <OperateLogs ref="operateLogs" v-if="active == '4'" :showModule="false" :bizId="orderInfo.orderId" />
      </el-tab-pane>
    </el-tabs>

    <!-- 安排订单 -->
    <el-dialog title="安排订单" :visible.sync="planOrderVisible" width="1200px" @close="resetSales">
      <el-form :model="form" ref="ruleForm">
        <div class="c-table-wrap">
          <table class="custom-table no-space">
            <thead>
              <tr>
                <th width="200">Item</th>
                <th width="200">规格</th>
                <th width="100">Qty</th>
                <th width="200">印刷方式</th>
                <th width="200">
                  <span>
                    <i>*</i>
                    期望交期
                  </span>
                </th>
                <th width="200">
                  <span>
                    <i>*</i>
                    采购开发
                  </span>
                </th>
                <th width="200">订单备注</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in form.planOrderList" :key="i">
                <td>
                  <span v-show="item.productCode">
                    {{ item.productCode }} /
                  </span>
                  <span v-show="item.productEn">{{ item.productEn }}</span>
                </td>
                <td>{{ item.specification }}</td>
                <td>{{ item.amount || '--' }}</td>
                <td>{{ item.printingWayEn || '--' }}</td>
                <td>
                  <el-form-item :prop="'planOrderList.' + i + '.expectedDeliveryDate'" :rules="[
                      {
                        required: true,
                        message: '期望交期必填',
                        trigger: 'blur',
                      },
                    ]">
                    <el-date-picker v-model="item.expectedDeliveryDate" type="date" placeholder="选择日期"
                      value-format="yyyy-MM-dd"></el-date-picker>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item :prop="'planOrderList.' + i + '.salesName'" :rules="[
                      {
                        required: true,
                        message: '采购开发必填',
                        trigger: 'change',
                      },
                    ]">
                    <UserSelect roleName="采购员" @user-choose="(user) => chooseSales(user, i)" :ref="'userSelect' + i">
                    </UserSelect>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item :prop="'planOrderList.' + i + '.remark'" :rules="[
                      {
                        max: 500,
                        message: '不能超过500字符',
                        trigger: 'blur',
                      },
                    ]">
                    <el-input v-model="item.remark" placeholder="请输入"></el-input>
                  </el-form-item>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-form>
      <el-checkbox class="mt10" v-model="sendEmailFlag">发送邮件</el-checkbox>
      <p class="mt5 f_s_12" style="color: #999; margin-left: 23px">
        勾选后，采购订单初次审核通过后，会自动发送“安排生产”邮件给客户
      </p>
      <div class="text-center mt20">
        <el-button type="primary" @click="planOrderSave()" :loading="showLoading">
          确认
        </el-button>
        <el-button @click="planOrderCancel()">取消</el-button>
      </div>
    </el-dialog>

    <!-- 效果图待确认 -->
    <el-dialog :title="
        picStatusStr === 'wait'
          ? lang === 'en'
            ? 'Rendering Confirmation'
            : '效果图确认'
          : lang === 'en'
          ? $t('orderDetails.ViewTheRenderings')
          : '效果图查看'
      " :visible.sync="producPictVisible" width="900px">
      <el-table :data="producPicData" style="width: 100%" border>
        <el-table-column type="index" width="50" label="#" align="center"></el-table-column>
        <!-- 产品编号 -->
        <el-table-column prop="productCode" :label="$t('orderDetails.ProductCode')" width="120"
          align="center"></el-table-column>
        <!-- 产品名称 -->
        <el-table-column prop="productEn" :label="$t('orderDetails.ProductName')" width="250" align="center"
          show-overflow-tooltip></el-table-column>
        <!-- 效果图(点击图片可修改) -->
        <el-table-column prop="address" :label="$t('orderDetails.Rendering')" align="center">
          <template slot-scope="scope">
            <!-- 无效果图需上传 -->
            <el-badge v-show="
                scope.row.rendingsUrl &&
                scope.row.rendingsUrl.split(',').length > 1
              " :value="
                scope.row.rendingsUrl
                  ? scope.row.rendingsUrl.split(',').length
                  : ''
              "></el-badge>
            <div class="wrapper-box c_pointer" @click="
                choseRendingImages(
                  scope.$index,
                  scope.row.rendingsUrl,
                  scope.row.rendingsStatus,
                  scope.row
                )
              ">
              <div class="wrapper-image" v-show="scope.row.rendingsUrl">
                <img :src="getFirstImgUrl(scope.row.rendingsUrl)" width="50%" alt />
              </div>
              <div class="wrapper-add" v-show="!scope.row.rendingsUrl">
                <i class="el-icon-plus"></i>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 状态 -->
        <el-table-column prop="picStatus" :label="$t('orderDetails.Status')" align="center" width="100">
          <template slot-scope="scope">
            <div v-if="lang === 'en'">
              {{ getLangImageState(scope.row.picStatus) }}
            </div>
            <div v-else>
              {{ scope.row.picStatus }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-center mt20">
        <!-- 取消 -->
        <el-button @click="producPictVisible = false">
          {{ $t('inquiryDetails.Cancel') }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 撤销弹窗 -->
    <el-dialog title="撤销原因" :visible="cancelVisible" append-to-body width="500px" @close="onCancel"
      modal-append-to-body>
      <span style="color: red; position: absolute; left: 10px">*</span>
      <el-input type="textarea" :rows="3" placeholder="请输入撤销原因" maxlength="500" show-word-limit
        v-model="cancelReason"></el-input>
      <div class="footer mt30 t_a_c">
        <el-button type="primary" @click="cancelSure">确 认</el-button>
        <el-button @click="onCancel">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 添加成员 -->
    <!-- <UserChoose ref="UserChoose" @choose-user="chooseUser" /> -->
    <UserChoose ref="UserChoose" />

    <!-- 报价单预览 -->
    <el-dialog :visible.sync="offerSheetVisible" width="1210px">
      <div class="offer-container">
        <offerSheet v-if="offerSheetVisible" :offerSheetInfo="offerSheetInfo"></offerSheet>
      </div>
      <div class="text-center mt20">
        <el-button @click="offerSheetVisible = false">关闭</el-button>
      </div>
    </el-dialog>

    <!-- 图片预览 -->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="imgUrl" />

    <!-- 选择印刷效果图 -->
    <choose-images :visible.sync="chooseImagesDialogVisible" :default-file-list="currentDefaultRendingList"
      :default-rendings-status.sync="currentDefaultRendingStatus" :default-row="currentDefaultRow"
      :default-plan="currentPlan" @confirm="setRendingImages"></choose-images>

    <!-- 审核进度Drawer -->
    <el-drawer title="审核进度" :visible.sync="statusDrawer" :before-="statusClose">
      <Audit ref="Audit" :auditObjectId="auditObjectId" :auditType="auditType" @cancelClick="statusClose" />
    </el-drawer>

    <!-- 在线支付 -->
    <online-pay @createLink="createLink" ref="payment"></online-pay>

    <!-- 付款链接 -->
    <pay-link ref="payLink"></pay-link>

    <!-- 申请退款 -->
    <apply-refund ref="refund"></apply-refund>

    <!-- 收款详情 -->
    <sk-model ref="skModel"></sk-model>

    <!-- 付款详情 -->
    <fk-model ref="fkModel"></fk-model>

    <!-- 费用详情 -->
    <fy-model ref="fyModel"></fy-model>

    <!-- 费用申请 -->
    <costApply ref="costApply" @success="getDetail()" :isViewCost="isViewCost"></costApply>

    <!-- 收款登记 -->
    <payee-model ref="payee"></payee-model>

    <!-- 退款申请 -->
    <RefundApplyModel ref="refundApply"></RefundApplyModel>

    <!-- 审核模板-->
    <AuditChoose ref="AuditChoose" @auditData="saveClick" />

    <!-- 修改出运计划 -->
    <UpdateTransportationPlan ref="UpdateTransportationPlan" @success="getShipmentPlan" />

    <!-- 费用单产品分摊详情 -->
    <CostBillModel ref="CostBillModel" />

    <!-- 手动完结 -->
    <manualEndDModel :manualVisible="manualVisible" :manualData="manualData" :orderId="orderInfo.orderId"
      @closeModel="closeModel" @submitModel="submitModel"></manualEndDModel>

    <!-- 发票弹窗 -->
    <InvoiceDialog ref="invoiceDialog" @confirm="invoiceConfirm" />
    <!-- 物流进度查询 -->
    <logistics-progress ref="logistics"></logistics-progress>
    <!-- 修改地址信息 -->
    <OrderAddreeEdit ref="orderAddreeEdit" @address-edit-success="refresh"></OrderAddreeEdit>
    <!-- 收款确认 -->
    <CollectionConfirmDialog ref="collectionConfirmDialog" @refresh="getDetail('','2')"/>
  </div>
</template>

<script>
  import offerSheet from '@/views/order/inquiryManage/inquiryPrint.vue'
  import CollectionConfirmDialog from '@/components/CollectionConfirmDialog'

  import {
    orderNewGetDetailByOrderCode,
    orderShipInfo,
    orderAlignOrderList,
    orderUpdateReding,
    orderGetPurchasingOrderVO,
    machiningApplicationPay,
    orderApplicationPayOrderInsertPO,
    orderBatchDelete,
    purchasingDemandVerify,
    shippingPlanByOrderNumber,
    revocationAlignOrder,
    getOrderInvoice,
    upFromBusinessUsingPOST,
    orderPrintOrder,
    orderWhetherEditor,
    checkOrderEnd,
    costProfit,
    upPrintedDocuments,
    checkOrderInvoice,
    updateOrderRemark,
    updateHistoryRemark,
  } from '@/api/order'

  import {
    arrangeShipQuery,
    getShippingOrderById,
  } from '@/api/shipment/shipment-plan'
  import { getDetailForShowByCode } from '@/api/finance/finance-payment'
  import { getAuditUserByTypeAndOrderId } from '@/api/audit/auditMange'
  import { getAuditObject, cancel } from '@/api/stock-center/inbound-sheet'
  import UserChoose from '@/components/userChoose'
  import ChooseImages from './components/chooseImages'
  import onlinePay from './components/onlinePay.vue'
  import CostBillModel from './components/costBillModel.vue'
  import payLink from './components/payLink.vue'
  import costApply from '@/components/costApply'
  import UpdateTransportationPlan from './components/updateTransportationPlan.vue'
  import applyRefund from './components/applyRefund'
  import skModel from './components/receipt-detail-model.vue'
  import fkModel from '@/views/finance-center/payment-manage/components/payment-detail-model2.vue'
  import fyModel from '@/views/finance-center/cost-manage/components/payment-detail-model'
  import payeeModel from './components/payeeModel.vue'
  import RefundApplyModel from './components/refundApplyModel'
  import AuditChoose from '@/components/AuditChoose'
  import Audit from '@/components/Audit/ApprovalsDrawer'
  import { mapGetters } from 'vuex'
  import { dataMethod } from '@/utils/table-merge.js'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'
  import { listUserByRoleName } from '@/api/finance/credit-manage-list'
  import manualEndDModel from './components/manualEndDModel.vue'
  import fileUpload from './components/fileUpload'
  import FileUpload from '@/components/FileUpload'
  import InvoiceDialog from './components/invoice-dialog'
  import viewCost from '@/views/order/orderList/util'
  import NoViewCost from '@/views/purchase/purchaseOrder/components/no-view-cost'
  import { OrderListInteractor } from '@/core/interactors/order/order-list'
  import UserSelect from '@/components/user-select'
  import LogisticsProgress from '@/views/order/shipmentManage/components/logistics-progress'
  import OperateLogs from '@/components/operate-logs'
  import OrderAddreeEdit from './orderAddreeEdit'
  import { hasRight } from '@/utils/permissionBtn'
  import { OrderSettingInteractor, FreightSettingInteractor } from '@/core'

  import { URL_COMPRESS } from '@/config'
  import { decode } from 'kits'

  export default {
    components: {
      UserChoose,
      offerSheet,
      ChooseImages,
      onlinePay,
      payLink,
      applyRefund,
      AuditChoose,
      Audit,
      skModel,
      fkModel,
      fyModel,
      payeeModel,
      UpdateTransportationPlan,
      CostBillModel,
      costApply,
      RefundApplyModel,
      manualEndDModel,
      fileUpload,
      FileUpload,
      InvoiceDialog,
      NoViewCost,
      UserSelect,
      LogisticsProgress,
      OperateLogs,
      OrderAddreeEdit,
      CollectionConfirmDialog
    },
    data() {
      return {
        collectionTipVisible: true, // 【该订单出运前款项未收齐，请尽快安排催收，以免影响出运或供应商款项支付。】是否显示
        isViewCost: viewCost(),
        waringTip: '应收金额 < 实收金额',
        active: '1',
        orderId: '',
        orderCode: '',
        status: '',
        orderInfo: {},
        shipInfo: {},
        form: {
          planOrderList: [],
        },
        offerSheetInfo: {
          enquiryIds: '',
          priceSheetCode: '',
        },
        planOrderIndex: '',
        exchangeRate: 1, // 汇率
        businessArray: [],
        bindProductList: [],
        waitTable: [],
        selectRows: [], //table勾选
        transData: [],
        transPlanData: [], // 出运计划列表
        arrangeProductList: [], // 安排出运选中的列表
        planOrderVisible: false,
        offerSheetVisible: false,
        planProductVisible: true,
        dialogImageUrl: '',
        dialogVisible: false,
        fileList: [],
        cancelVisible: false,
        statusName: '', // 状态
        cancelReason: '', // 撤销原因
        auditData: {}, // 审核数据
        auditObjectId: '', // 审核id（产品id）
        auditType: 8, // 审核状态
        statusDrawer: false, // 审核进度,
        auditObjectDTO: [], // 审核人员
        showViewer: false, //图片预览参数
        imgUrl: [], //图片预览url
        producPictVisible: false, //效果图确认弹框
        producPicData: [], //效果图确认数据
        picStatusStr: '', //效果图状态
        chooseImagesDialogVisible: false, // 选择印刷效果图弹窗
        currentRendingIndex: -1, // 当前选择印刷效果图的产品，对应的 index
        currentDefaultRendingList: [], // 当前选中的图片
        currentDefaultRendingStatus: '1', // 当前选中的图片状态
        currentDefaultRow: {}, // 当前选中的图片数据
        currentPlan: false,
        sendEmailFlag: false, // 是否默认发送邮件 1:发送 0不发送
        isSettlementStatus: true, // 订单是否结算中
        showLoading: false,
        manualVisible: false, //手动完结弹窗显示
        manualData: {},
        costProfitInfo: [], // 产品收入成本利润
        isCustomerNotes: false, // 客户备注是否显示
        isRemarks: false, // 内部备注是否显示
        printType: 0,
        isHistoryRemarks: false,
        addressVisible: false, // 地址弹窗
        hasRight,
        computedCompanyDetailedAddress: '',
        countryOptions: [],
        URL_COMPRESS: URL_COMPRESS,
        tipVisible: false, // 提示是否显示
      }
    },
    watch: {
      lang() {
        this.getDetailedAddress()
      },
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        permissions: 'user/permissions',
        lang: 'settings/language',
      }),

      // 1 查看发票 0 生成发票
      hasGeneratedInvoice() {
        return this.orderInfo.checkInvoiceFlag == 1
      },
      // 1 查看简易发票 0 生成简易发票
      hasSimpleInvoice() {
        return this.orderInfo.checkSimpleInvoiceFlag == 1
      },


      showAbromalIcon() {
        const hasRefund =
          Array.isArray(this.orderInfo.refundVOS) &&
          this.orderInfo.refundVOS.length > 0 &&
          this.orderInfo.refundVOS.find(
            (item) => item.refundTypeName === '当前订单收入'
          )
        let {
          receivablePrice,
          netReceipts,
          refundVOS,
          orderAuditPass,
          handleFlag,
          orderStatus,
        } = this.orderInfo
        receivablePrice = receivablePrice * 1000
        netReceipts = netReceipts * 1000
        if (hasRefund) {
          for (let i = 0, l = refundVOS.length; i < l; i++) {
            if (refundVOS[i].refundTypeName === '当前订单收入') {
              receivablePrice = receivablePrice + refundVOS[i].amount * 1000
            }
          }
        }
        if (
          (receivablePrice < netReceipts &&
            orderAuditPass > 1 &&
            handleFlag !== 1) ||
          (receivablePrice < netReceipts &&
            orderAuditPass > 1 &&
            orderStatus === '4')
        ) {
          return true
        } else {
          return false
        }
      },
      // true 有订单支持的权限  false 没有权限
      isRight() {
        return this.permissions.indexOf('Order:Support') > -1
      },

      // 收款确认按钮
      showCollectConfirm() {
        return this.userInfo?.userName === this.orderInfo?.businessName
      }
    },
    created() {
      this._getDetail()
    },
    methods: {
      // 获取国家列表
      async countryList() {
        let data = { level: 0 }
        let res = await OrderListInteractor.listCountryApi(data)
        if (res?.code === '000000') this.countryOptions = res.data
      },
      // 根据国家获取州
      async getStateFn(val) {
        let data = { parentId: val, level: 1 }
        const res = await OrderListInteractor.listCountryApi(data)
        if (res?.code === '000000') {
          return res.data
        } else {
          return []
        }
      },
      // 获取名称
      getDetailedAddress() {
        let countryObj = this.countryOptions?.filter(
          (item) => item.code === this.orderInfo.companyCountry
        )?.[0]
        if (!countryObj) {
          this.computedCompanyDetailedAddress = this.orderInfo.companyAddress
          return
        }
        this.getStateFn(countryObj.id).then((data) => {
          let ProvinceObj = data.filter(
            (item) => item.code === this.orderInfo.companyProvince
          )?.[0]
          let companyCountry = countryObj.name
          let companyProvince = ProvinceObj?.name
          let addressArr = [
            companyCountry,
            companyProvince || this.orderInfo.companyProvince,
            this.orderInfo.companyCity,
            this.orderInfo.companyAddress,
          ]
          this.computedCompanyDetailedAddress = addressArr
            .filter((item) => item)
            .join('/')
        })
      },
      _getDetail() {
        const { orderCode, orderId } = this.$route.query
        this.orderCode = orderCode
        this.orderId = orderId
        this.getDetail(orderCode, '')
      },
      // 刷新
      refresh(activeName) {
        setTimeout(() => {
          let refreshEl = document.querySelector('.icon-dibudaohanglan-')
          refreshEl && refreshEl.click()
        }, 500)
      },
      // 修改地址信息
      changeAddress() {
        this.$refs.orderAddreeEdit.show(this.orderId)
      },
      // 订单历史问题备注
      historyRemarkSave(remark, type) {
        if (type == 1) {
          this.isHistoryRemarks = true
          return
        }
        const params = {
          historyRemark: remark,
          orderId: this.orderInfo.orderId,
        }
        updateHistoryRemark(params).then((res) => {
          if (res && res.code === '000000') {
            if (type == 1) {
              this.isHistoryRemarks = true
            } else {
              this.orderInfo.historyRemark = remark
              this.isHistoryRemarks = false
            }
          }
        })
      },
      // 跳转到销售订单编辑页
      doEdit() {
        const { orderId, orderCode } = this.orderInfo,
          isNewTab = false
        orderWhetherEditor({ orderId }).then((res) => {
          if (res && res.code == '000000') {
            navigateTo(this, {
              orderId,
              orderCode,
              isNewTab,
              pageType: PAGE_TYPES.editSalesOrder,
            })
          }
        })
      },

      // 安排订单选择采购开发
      chooseSales(user, index) {
        const list = this.form.planOrderList[index]

        list.salesId = user ? user.userId : ''
        list.salesName = user ? user.userName : ''
        list.salesNameEn = user ? user.englishName : ''
      },

      // 获取产品收入成本利润
      getCostProfit(id) {
        costProfit({ orderId: id }).then((res) => {
          if (res && res.code === '000000') {
            if(Array.isArray(res?.data)) {
              this.costProfitInfo = res?.data?.map((item, index) => {
                if (Array.isArray(item.costProfitChildVO)) {
                  item.costProfitChildVO = item.costProfitChildVO.map(
                    (s, sIndex) => {
                      s.rowKey = Math.random()
                      return s
                    }
                  )
                }
                item.rowKey = Math.random()
                return item
              })
            }

          }
        })
      },

      // 费用申请
      costApply() {
        let data = {
          ...this.orderInfo,
          actualReceivablePrice: this.orderInfo.receivedPrice,
          outstandingAmount: this.orderInfo.outstandingAmount,
        }
        this.$refs.costApply.initLoading(1, [data])
      },

      // 管理订单支持
      eidtOrderSupport(index) {
        if (!this.isSettlementStatus || this.status == 11 || this.status == 10)
          return
        if (!(this.permissions.indexOf('Order:Support') > -1)) {
          return this.$baseMessage(
            '暂无操作权限，请联系管理员',
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        const {
          fromBusinessId,
          fromBusinessName,
          fromBusinessNameEn,
          orderProductId,
          orderProductUniqueId,
        } = this.orderInfo.orderProductVOS[index]
        this.$userChoose(this)
          .showPromisedUserAddEdit({
            str: '',
            roleId: fromBusinessId
              ? [
                {
                  id: fromBusinessId,
                  name: fromBusinessName,
                  englishName: fromBusinessNameEn,
                },
              ]
              : '',
            singleSelect: true,
            canSelectZeroUser: false,
          })
          .then((users) => {
            let fromBusinessId = '',
              fromBusinessName = '',
              fromBusinessNameEn = ''
            if (Array.isArray(users) && users.length > 0) {
              const { id, name, englishName } = users[0]
              fromBusinessId = id
              fromBusinessName = name
              fromBusinessNameEn = englishName
              this.orderInfo.orderProductVOS[index].fromBusinessId = id
              this.orderInfo.orderProductVOS[index].fromBusinessName = name
              this.orderInfo.orderProductVOS[index].fromBusinessNameEn =
                englishName
            } else {
              this.orderInfo.orderProductVOS[index].fromBusinessId = ''
              this.orderInfo.orderProductVOS[index].fromBusinessName = ''
              this.orderInfo.orderProductVOS[index].fromBusinessNameEn = ''
            }
            upFromBusinessUsingPOST({
              fromBusinessId,
              fromBusinessName,
              fromBusinessNameEn,
              orderProductId,
              orderProductUniqueId,
            }).then((res) => {
              if (res && res.code === '000000') {
                // 操作成功
                this.$message.success(this.$t('reqmsg.$7'))
              }
            })
          })
      },
      // 查看合同
      previewContract() {
        navigateTo(this, {
          pageType: PAGE_TYPES.orderContract,
          orderId: this.orderInfo.orderId,
        })
      },
      // 查看marks
      previewMarks(isDownload) {
        if (isDownload) {
          //下载唛头
          let filename = `${this.orderCode}-唛头-${this.utils.timeFormat(
            new Date()
          )}`
          OrderSettingInteractor.exportMarksWordApi(
            this.orderInfo.orderId,
            filename
          )
        } else {
          // 查看唛头
          navigateTo(this, {
            pageType: PAGE_TYPES.orderShipMark,
            orderId: this.orderInfo.orderId,
          })
        }
      },
      // 查看发票
      previewInvoiceInfo() {
        getOrderInvoice(this.orderInfo.orderId).then((res) => {
          if (res && res.code === '000000') {
            if (res.data && res.data) {
              const invoiceInfo = res.data
              navigateTo(this, {
                pageType: PAGE_TYPES.previewFile,
                url: invoiceInfo.invoicePath,
                name: invoiceInfo.invoiceFileName,
              })
            }
          }
        })
      },
      // 生成发票
      createInvoiceInfo(type) {
        this.printType = type
        checkOrderInvoice(this.orderInfo.orderId).then((res) => {
          if (res && res.code === '000000') {
            this.$refs.invoiceDialog.showAddEdit(
              res.data,
              this.orderInfo.orderCode,
              this.printType
            )
          } //是否生成发票（1：是；0否）
        })
      },
      async previewSimpleInvoiceInfo() {
        let res = await OrderListInteractor.getSimpleInvoice(
          this.orderInfo.orderId
        )
        if (res && res.code === '000000') {
          if (res.data && res.data) {
            const invoiceInfo = res.data
            navigateTo(this, {
              pageType: PAGE_TYPES.previewFile,
              url: invoiceInfo.invoicePath,
              name: invoiceInfo.invoiceFileName,
            })
          }
        }
      },
      // 点击发票确定事件
      invoiceConfirm(form) {
        orderPrintOrder({
          ...form,
          printType: this.printType,
          orderId: this.orderInfo.orderId,
          checkFlag: this.isViewCost ? 1 : 0,
        }).then((res) => {
          if (res && res.code === '000000') {
            this.$refs.invoiceDialog.close()
            if (res.data && res.data.printInvoiceVO) {
              const invoiceInfo = res.data.printInvoiceVO
              navigateTo(this, {
                pageType: PAGE_TYPES.previewFile,
                url: invoiceInfo.invoicePath,
                name: invoiceInfo.invoiceFileName,
              })
            }
          }
        })
      },

      // 获取详情
      async getDetail(code, active) {
        await this.countryList()
        // 订单信息
        orderNewGetDetailByOrderCode({ orderCode: this.orderCode }).then(
          (res) => {
            if (res?.code === '000000') {
              this.orderInfo = res?.data ?? {}
              this.tipVisible = res?.data?.orderExtend?.addressReminded == 1 // 1=显示 2=隐藏
              this.fileList = res?.data?.accessoryUrl
                ? JSON.parse(res.data.accessoryUrl)
                : []
              // 汇率
              this.exchangeRate = res.data.exchangeRate
              // 订单结算状态
              this.isSettlementStatus =
                res.data.settlementStatus > 1 || res.data.settlementStatus == 4
                  ? false
                  : true
              // 赋值订单状态
              this.status = res.data.orderStatus
              // 收款/发票-交易状态为已撤销返回信息
              if (res.data.otherOrderVOS) {
                let flag = res.data.otherOrderVOS.some(
                  (item) => item.status == '4'
                )
                if (flag) this.getRevocation(res.data.otherOrderVOS)
              }
              this.getCostProfit(this.orderInfo.orderId)
              this.getDetailedAddress()
              if(active) {
                this.active = active
              }
            }

          }
        )

        // 出库/出运信息
        orderShipInfo({ orderCode: this.orderCode }).then((res) => {
          if (res?.code !== '000000') {
            return
          }
          this.shipInfo = res?.data ?? {}

          // 已安排出运信息
          if (this.shipInfo?.alreadShipProductVOS?.length) {
            this.shipInfo.alreadShipProductVOS.forEach((item) => {
              item.rowKey = Math.random()
              if (item.orderProductChildVOS) {
                item.orderProductChildVOS = item.orderProductChildVOS.map(
                  (s) => {
                    s.rowKey = Math.random()
                    return s
                  }
                )
              }
              if (item.productCourseVOList?.length) {
                item.productCourseVOList.forEach((item2, index2) => {
                  if (item2.remark && item2.statusName === '1') {
                    item.remarkArr = []
                    item2.remark.split(',').forEach((item2, index3) => {
                      let obj = {
                        label: item2.split(':')[0],
                        value: item2.split(':')[1],
                      }
                      item.remarkArr.push(obj)
                    })
                    item.businessCourseId = item2.businessCourseId
                  }
                })
              }
            })
          }
          this.waitTable = res?.data?.shipProductVOS?.length
            ? res.data.shipProductVOS
            : []
          // this.$nextTick(() => {
          //   this.waitTable?.forEach((item, index) => {
          //     this.initUploadLogoInfo(item.printedDocumentsUrl, index)
          //   })
          // })
        })
        this.getShipmentPlan()
      },
      getShipmentPlan() {
        shippingPlanByOrderNumber({ piNumber: this.orderCode }).then((res) => {
          if (res.code === '000000') {
            this.transPlanData = res.data || []
          }
        })
        getShippingOrderById({ piNumber: this.orderCode }).then((res) => {
          if (res && res.code === '000000') {
            let list = res.data || []
            this.transData = []
            list.forEach((item) => {
              item.shippingOrderProductDetailVOS.forEach((product) => {
                let obj = {
                  ...product,
                  ...item,
                }
                this.transData.push(obj)
              })
            })
          }
        })
      },
      // 手动塞入已撤销信息
      getRevocation(data) {
        data.forEach((item, i) => {
          if (item.status == '4') {
            const params = {
              auditType: 9,
              orderId: item.orderId,
            }
            if (params.orderId) {
              getAuditUserByTypeAndOrderId(params).then((res) => {
                if (res.data.length) {
                  const info = res.data[0]
                  this.orderInfo.otherOrderVOS[i].statusMemo =
                    '撤销人:' +
                    info.userName +
                    '\n撤销时间:' +
                    info.auditTime +
                    '\n撤销原因:' +
                    info.remarks +
                    ''
                }
              })
            }
          }
        })
      },

      // 修改出运计划
      editTransPlan(item) {
        this.$refs.UpdateTransportationPlan.show(item)
      },
      // 安排出运
      async arrangeShip(item, i) {
        let selection = this.$refs.shipMultipleTable[i].selection
        let arr = []
        selection.forEach((item) => {
          arr.push(item.shippingPlanOrderProductId)
          item.productScrollVOS?.forEach((ele) => {
            arr.push(ele.shippingPlanOrderProductId)
          })
        })
        if (!selection.length) {
          this.$message.warning('请勾选要出运的产品')
          return false
        }
        selection = selection.map((item) => {
          ; (item.arrangeChildProducts = item.productScrollVOS),
            delete item.productScrollVOS
          return {
            ...item,
          }
        })

        const params = {
          shippingPlanOrderId: item.shippingPlanOrderId,
          arrangeProductList: selection,
        }
        arrangeShipQuery(params).then((res) => {
          if (res && res.code === '000000') {
            if (
              res.data.productArranges &&
              res.data.productArranges.length > 0
            ) {
              const push = () => {
                this.$router.push({
                  path: '/order/new-shipment-sheet',
                  query: {
                    shippingPlanOrderId: item.shippingPlanOrderId,
                    shippingPlanOrderProductIds: arr.toString(),
                    arrangeProductList: JSON.stringify(selection),
                  },
                })
              }
              this.checkShowTip(push)
            } else {
              this.$confirm('产品无库存，无法安排出运。', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then(() => { })
                .catch(() => { })
            }
          } else {
            this.getDetail(this.orderCode)
            this.active = '3'
          }
        })
      },
      // 在跳转前判断要不要显示弹框提示，0:不提醒，1：提醒
      async checkShowTip(push) {
        try {
          const { code, data } = await OrderSettingInteractor.getOrderShippingReminderFlagApi(this.orderCode)
          if (code === '000000') {
            if (data == 1) {
              this.$confirm('请尽快安排催收，以免影响出运或供应商款项支付。', '该订单装运前款项未收齐', {
                confirmButtonText: '安排出运',
                cancelButtonText: '取消出运',
                type: 'warning',
                customClass: 'arrange-shipment-tip',
              }).then(() => {
                push()
              })
            } else {
              push()
            }
          } else {
            push()
          }
        } catch (e) {
          push()
          console.log(e)
        }
      },
      // 合并单元格
      objectSpanMethod({ row, column, rowIndex, columnIndex }, item) {
        let _row = item.spanData[column.property]
          ? item.spanData[column.property][rowIndex]
          : 1
        let _col = _row > 0 ? 1 : 0
        if (['id'].includes(column.property)) {
          _row = item.spanData['planNumber'][rowIndex]
          _col = _row > 0 ? 1 : 0
        }
        return {
          rowspan: _row,
          colspan: _col,
        }
      },
      // 合并单元格
      objectSpanMethod2({ row, column, rowIndex, columnIndex }) {
        let spanData = dataMethod(this.transData, ['number'])
        let _row = spanData[column.property]
          ? spanData[column.property][rowIndex]
          : 1
        let _col = _row > 0 ? 1 : 0
        if (
          [
            'trade',
            'shippingMethod',
            'status',
            'shipNo',
            'shipTime',
            'arriveTime',
          ].includes(column.property)
        ) {
          _row = spanData['number'][rowIndex]
          _col = _row > 0 ? 1 : 0
        }
        return {
          rowspan: _row,
          colspan: _col,
        }
      },
      // 出运委托
      transportation() {
        let self = this
        if (self.orderId) {
          orderGetPurchasingOrderVO({ orderId: self.orderId }).then((res) => {
            let data = res.data.purchasingAddressVOS || []
            let trans = self.$refs.transportation
            let receivingList = []
            let list = []
            let productList = []

            if (data.length == 1) {
              list = data[0].purchasingAddressVOS
              receivingList = self.getAddressList(data[0])
            } else if (data.length > 1) {
              let address = ''

              data.forEach((item, i) => {
                if (item.address == address) {
                  list = data[i].purchasingAddressVOS
                  receivingList = self.getAddressList(item)
                }
              })
            } else {
              self.$message.warning('未查到匹配的数据！')
              return false
            }
            // 筛选符合条件的产品
            list.forEach((item, i) => {
              if (
                (item.reamount > 0 &&
                  item.productStatus != 3 &&
                  item.productStatus != 4 &&
                  item.alignOrder == 1) ||
                (item.reamount > 0 && item.standardCustomized == '订制品')
              ) {
                productList.push(item)
              }
            })
            if (!productList.length) {
              self.$message.warning('当前地址不存在可出运的产品！')
              return false
            }
            productList = productList.map((item) => {
              return {
                ...item,
                warehouseId:
                  item.standardCustomized != '标准品' ? '微沃云仓' : '',
                drawback: '0', // 是否退税默认否
                shippingOutNum: item.reamount, // 出运数量
                giftNum: '', // 赠品数量
                caseNum: item.quantTotle
                  ? Math.ceil(item.quantTotle / item.numberPerBox)
                  : '', // 总箱数 = 出运数量 ÷ 装箱数量 （向上取整）
                volume: item.quantTotle
                  ? parseFloat(
                    item.boxVolumeM * (item.quantTotle / item.numberPerBox)
                  ).toFixed(3)
                  : '', // 总体积 = 装箱体积*总装箱数量
                grossWeight: item.quantTotle
                  ? parseFloat(
                    (item.quantTotle / item.numberPerBox) * item.boxWeightKgs
                  ).toFixed(3)
                  : '', // 总重量 = 总箱数 * 毛重
              }
            })
            trans.isDelivery = !productList.every(
              (item) => item.productStatus == 1
            ) // 判断是否是加工出库
            trans.form.shippingOrderReceivingDTOS = [...receivingList]
            trans.form.shippingOrderProductDTOS = [...productList]
            trans.orderCode = res.data.orderCode
            trans.salesOrderId = res.data.orderId
            trans.form.businessId = res.data.businessId
            trans.form.businessNumber = res.data.orderCode
            trans.form.customerName = res.data.customerName
            trans.form.businessName = res.data.businessName
            trans.form.deliveryDate = res.data.deliveryDate
            trans.form.insideRemarks = res.data.remark
            trans.form.shippingDate = res.data.shippingDate
            trans.type = 1
            trans.calculateNum()
            trans.transportationVisible = true
          })
        } else {
          self.$message.warning('请选择一条数据！')
        }
      },

      // 获取收货地址集合
      getAddressList(item) {
        return [
          {
            receivingAddress: item.address, // 收货地址
            receivingCompany: item.companyName, // 收货公司
            receivingMan: item.consigneeName, // 收货人
            receivingPhone: item.consigneePhone, // 联系电话
          },
        ]
      },

      // 查看报价单
      viewOffer(id, code) {
        this.offerSheetInfo.enquiryIds = id
        this.offerSheetInfo.priceSheetCode = code
        this.offerSheetVisible = true
      },

      // 安排订单
      planOrderSave() {
        this.showLoading = true
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            orderAlignOrderList({
              alignOrderDTOS: this.form.planOrderList,
              sendEmailFlag: this.sendEmailFlag ? 1 : 0,
            }).then((res) => {
              if (res && res.code == '000000') {
                this.$message.success('保存成功！')
                this.getDetail(this.orderCode)
                this.active = '3'
                this.planOrderVisible = false
                this.resetSales()
              }
              this.showLoading = false
            })
          } else {
            this.showLoading = false
          }
        })
      },

      // 安排订单取消
      planOrderCancel() {
        this.resetSales()
        this.planOrderVisible = false
        this.sendEmailFlag = false
      },

      // 安排订单关闭时重新设置人员
      resetSales() {
        this.$refs['ruleForm'].resetFields()
        this.form.planOrderList.forEach((item, index) => {
          console.log()
          this.$refs['userSelect' + index][0]?.clearUser()
        })
      },

      // 出运多选事件
      shipSelectionChange(arr, i) { },

      // 多选事件
      handleSelectionChange(val) {
        this.selectRows = val
        // 效果图状态已确认和没有文件可以安排订单
        if (this.selectRows.length) {
          this.planProductVisible = this.selectRows.every(
            (item) => item.picStatus == '-' || item.picStatus == '已确认'
          )
        } else {
          this.planProductVisible = true
        }
      },

      // 安排产品
      planProduct(type, row) {
        this.form.planOrderList = []
        if (type == 1) {
          if (!this.selectRows.length) {
            this.$message.warning('至少选择一条！')
            return false
          } else {
            this.form.planOrderList = this.selectRows.map((item, i) => {
              return {
                ...item,
                expectedDeliveryDate:
                  this.utils.cutDate(this.shipInfo.shippingDate) || '',
                salesId: '',
                salesName: '',
              }
            })
          }
        } else {
          this.form.planOrderList.push({
            ...row,
            expectedDeliveryDate:
              this.utils.cutDate(this.shipInfo.shippingDate) || '',
            salesId: '',
            salesName: '',
          })
        }
        this.planOrderVisible = true
      },

      //效果图待确认弹框
      picStatusClick(row, str, boo) {
        this.producPicData = []
        this.producPicData.push(row)
        this.picStatusStr = str
        this.producPictVisible = true
        this.currentPlan = boo
      },

      // 显示 选择印刷效果图 弹窗
      choseRendingImages(index, urls, status, row) {
        // 判断效果图能否被修改
        purchasingDemandVerify({
          orderProductId: row.orderProductId,
          orderProductUniqueId: row.orderProductUniqueId,
        }).then((res) => {
          if (res?.code == '000000') {
            this.chooseImagesDialogVisible = true
            this.currentRendingIndex = index
            this.currentDefaultRendingStatus = status || '3'
            this.currentDefaultRendingList = []
            this.currentDefaultRow = row
            let rendingsName = row.rendingsName
              ? JSON.parse(row.rendingsName)
              : []
            if (urls) {
              let _urls = urls.split(',')
              for (let i = 0; i < _urls.length; i++) {
                this.currentDefaultRendingList.push({
                  url: `${_urls[i]}${URL_COMPRESS}`,
                  name: rendingsName[i]?.name
                    ? decode(rendingsName[i]?.name)
                    : '',
                  encode: false, // 添加解码标识
                })
              }
            }
          }
        })
      },

      // 设置对应产品的 印刷效果图
      setRendingImages(data) {
        if (data.urls) {
          data.urls = data.urls.replaceAll(URL_COMPRESS, '')
        }
        let rendingsName = JSON.stringify(data.rendingsName || [])

        this.producPicData[this.currentRendingIndex].rendingsUrl = data.urls
        this.producPicData[this.currentRendingIndex].rendingsStatus =
          data.status
        // 效果图确认
        orderUpdateReding({
          orderProductId: data.orderProductId,
          rendingsUrl: data.urls,
          rendingsStatus: data.status,
          orderProductUniqueId: data.orderProductUniqueId,
          rendingsName,
        }).then((res) => {
          if (res.code === '000000') {
            // 确认成功
            const msg = this.lang === 'en' ? 'Confirm Success' : '确认成功'
            this.$message.success(msg)
            this.producPictVisible = false
            this.getDetail(this.orderCode)
            this.active = '3'
          }
        })
      },

      // 获取第一张图片
      getFirstImgUrl(urls) {
        if (urls) {
          urls = urls.replace(URL_COMPRESS, '')
        }
        let url = urls ? urls.split(',')[0] : ''
        if (url) {
          url = `${url}${URL_COMPRESS}`
        }
        return url
      },

      // 返回上一页
      goBack() {
        this.$router.go(-1)
      },

      // 大图预览
      handlePreview(item) {
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.imgUrl = [item]
        }
        this.showViewer = true
      },
      closeViewer() {
        this.showViewer = false
      },

      //出运-出库单号，采购单号跳转
      goToPage(str, val, number) {
        let routeData = null
        if (str === '采购单号') {
          routeData = this.$router.resolve({
            name: 'purchaseOrderDetail',
            query: {
              id: val,
              // number: number,
              // state: 5,
            },
          })
        } else if (str === '出库单号') {
          routeData = this.$router.resolve({
            name: 'outbound-sheet-detail',
            query: {
              warehouseOutId: val,
            },
          })
        }
        window.open(routeData.href, '_blank')
      },

      // 判断包含某个数据
      isInclude(val, arr) {
        arr = arr.map((item) => Number(item))
        return arr.includes(Number(val))
      },

      // 返回状态名称
      statusNames(n) {
        // 1录入中 2已驳回 3已撤销 4审核中 5已通过 6待发货 7已发货 8待收货 9已收货 10已完成 11已作废
        switch (Number(n)) {
          case 1:
            return this.$t('orderList.Inputting')
          case 2:
            return this.$t('orderList.Rejected')
          case 3:
            return this.$t('orderList.Revoked')
          case 4:
            return this.$t('orderList.InReview')
          case 5:
            return this.$t('orderList.Passed')
          case 6:
            return this.$t('orderList.PendingShipment')
          case 8:
            return this.$t('orderList.PendingDelivery')
          case 9:
            return this.$t('orderList.Delivered')
          case 10:
            return this.$t('orderList.Completed')
          case 11:
            return this.$t('orderList.Voided')
        }
      },

      // 获取印刷效果图
      getRendingImg(urls) {
        let _html = '--'
        if (urls) {
          _html = `<img src="${urls.split(',')[0]}" width="100" />`
        }
        return _html
      },

      // 收款/发票/费用操作
      operateStatus(item) {
        let self = this
        if ((item.type == 1 || item.type == 2) && item.status == 4) {
          // 撤销4，
          let auditType
          if (item.type == 1) {
            auditType = 9
          } else if (item.type == 2) {
            auditType = 8 // 费用
          }
          getAuditObject({
            orderId: item.orderId,
            auditType,
            tenantId: self.userInfo.tenantId,
          }).then((res) => {
            if (res && res.code === '000000') {
              // 获取提交审核（撤销所用）auditObjectId
              self.auditObjectId = res.data.auditObjectId
              self.auditType = auditType
              self.cancelVisible = true
            }
          })
        }
        if (item.operation == 5) {
          //申请付款5
          machiningApplicationPay({ refundOrderId: item.orderId }).then(
            (res) => {
              self.auditData = res.data
              // 配置审核模板
              let auditParams = {
                auditType: 7,
                status: 0,
                tenantId: self.userInfo.tenantId,
              }
              self.pubApi.getAuditTemplates(auditParams).then((response) => {
                if (!response.data.length) {
                  self.$baseMessage(
                    '请联系管理员配置审核模板',
                    'warning',
                    false,
                    'erp-hey-message-warning'
                  )
                } else {
                  self.$refs['AuditChoose'].showAddEdit(auditParams)
                }
              })
            }
          )
        }
        if (item.type == 2 && item.status == 2) {
          // 费用查看
          this.$refs.CostBillModel.show(item, this.orderInfo.orderId)
        }
        if (item.type == 2 && item.status == 6) {
          // 费用 — — 申请付款7
          //(this.orderInfo)
          let data = {
            ...this.orderInfo,
            actualReceivablePrice: this.orderInfo.receivedPrice,
            outstandingAmount: this.orderInfo.outstandingAmount,
          }
          this.$refs.costApply.initLoading(1, [data])
        }
      },

      // 保存审核模板信息
      saveClick(data) {
        let self = this
        self.auditObjectDTO = data
        self.$refs['AuditChoose'].close()
        const params = {
          ...self.auditData,
          auditObjectDTO: self.auditObjectDTO,
        }
        orderApplicationPayOrderInsertPO(params).then((res) => {
          if (res && res.code == '000000') {
            self.$message.success('保存成功')
            self.getDetail(self.orderCode)
            self.active = '2'
            self.close()
          }
        })
      },

      // 交易单号展示详情
      transClick(link, code) {
        if (link.includes('SK')) {
          this.$refs.skModel.showReceiptDetail(link, '4')
        } else if (link.includes('FK')) {
          getDetailForShowByCode({ number: code }).then((res) => {
            this.$refs.fkModel.amendRow = res.data.payOrderId
            this.$refs.fkModel.showReceiptDetail()
          })
        } else if (link.includes('FY')) {
          this.$refs.fyModel.amendRow = link
          this.$refs['fyModel'].showReceiptDetail()
        } else {
          return
        }
      },

      // 在线支付
      payment() {
        this.$refs.payment.getDetail(this.orderCode, this.orderInfo.orderId)
      },

      // 生成付款链接
      createLink(link) {
        this.$refs.payLink.init(link, this.orderInfo.customerEmail)
      },

      // 申请退款
      refund() {
        this.$refs.refund.orderId = this.orderInfo.orderId
        this.$refs.refund.currency = this.orderInfo.currency
        const { receivablePrice, netReceipts, currency, orderId, orderCode } =
          this.orderInfo
        this.$refs.refund.getDetail({
          receivablePrice,
          netReceipts,
          currency,
          orderId,
          orderCode,
        })
        // this.getDetail(this.orderCode, 2)
      },

      //审核进度查看
      statusTypeClick(row) {
        row.type == 2 ? (this.auditType = 13) : (this.auditType = 8)

        this.auditObjectId = row.orderId
        this.statusDrawer = true
      },

      //审核进度关闭
      statusClose() {
        this.statusDrawer = false
      },

      // 删除
      del() {
        this.$confirm(`是否确认删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            orderBatchDelete({ orderIds: [this.orderInfo.orderId] }).then(
              (res) => {
                if (res.code == '000000') {
                  this.$message.success('删除成功!')
                  this.$router.push('./orderList')
                  this.$parent.$parent.initLoadingList()
                }
              }
            )
          })
          .catch(() => { })
      },

      //确定撤销
      cancelSure() {
        if (!this.cancelReason) {
          return this.$message.warning('请输入撤销原因')
        }
        const {
          auditObjectId,
          auditStatus = 4,
          auditType = 1,
          cancelReason,
        } = this
        const { tenantId, userId, userName } = this.userInfo
        cancel({
          auditObjectId,
          auditStatus: 4,
          auditType,
          tenantId,
          userId,
          userName,
          remarks: cancelReason,
        }).then((res) => {
          if (res.code === '000000') {
            this.$message.success('撤销成功')
            setTimeout(() => {
              // 获取太快，后端数据还没改变
              this.getDetail(this.orderCode)
            }, 1000)
            this.cancelVisible = false
            this.active = '2'
          }
        })
      },

      //关闭撤销弹框
      onCancel() {
        this.cancelVisible = false
        setTimeout(() => {
          this.cancelReason = ''
        }, 400)
      },



      // 收款登记
      payee(type, url) {
        // type:1-新增 type:2-详情
        this.$refs.payee.payeeVisible = true
        if (type == 1) {
          this.$refs.payee.orderCode = this.orderCode
          this.$refs.payee.orderId = this.orderInfo.orderId
        } else {
          this.$refs.payee.receiptRegistrationUrl = url
        }
      },

      // 撤销安排
      revocationArrange(row) {
        let params = {
          alignId: row.alignId,
          orderId: this.orderInfo.orderId,
          orderProductId: row.orderProductId,
          sku: row.sku,
          orderProductUniqueId: row.orderProductUniqueId,
        }
        revocationAlignOrder(params).then((res) => {
          if (res && res.code === '000000') {
            this.$message.success('撤销安排成功！')
            this.getDetail(this.orderCode)
            this.active = '3'
          }
        })
      },

      // 使用getRowClass针对每一行添加类
      checkSelectable(row) {
        if (row.mainFlag === 1 && row.splitFlag === 1) {
          return false
        } else {
          return true
        }
      },

      // 隐藏出运信息全选按钮
      cellClass(row) {
        if (row.columnIndex === 0) {
          return 'selection-hide'
        }
      },

      // 根据币种返回金额
      getCurrencyMoney(money, cur) {
        if (cur === '美元') {
          return money
        } else {
          return (money * this.exchangeRate).toFixed(3) || 0
        }
      },

      // 退款申请
      refundApply() {
        let orderInfo = this.orderInfo
        let rows = [
          {
            orderId: orderInfo.orderId, // 订单Id
            orderCode: orderInfo.orderCode, // 订单编号
            customerName: orderInfo.customerName, // 客户姓名
            companyName: orderInfo.companyName, // 公司名称
            businessId: orderInfo.businessId, // 业务员id
            businessName: orderInfo.businessName, // 业务员
            area: orderInfo.area, // 所属地区
            currency: orderInfo.currency, // 币种
            totlePrice: orderInfo.totlePrice, // 订单金额美元
            rmbTotlePrice: orderInfo.rmbTotlePrice, // 订单金额人民币
            deductionAmount:
              orderInfo.currency === '美元'
                ? orderInfo.usMoney
                : orderInfo.rmbMoney, // 抵扣金额
            actualReceivablePrice: this.getCurrencyMoney(
              orderInfo.receivedPrice,
              orderInfo.currency
            ), // 已收金额
            actualReceivedAmount:orderInfo.actualReceivedAmount, //实收金额含手续费
            outstandingAmount: orderInfo.outstandingAmount, // 未收金额
            commissionAmount: 0, // 国际手续费
            settleFee: 0, // 结算手续费
            orderType: 1, //主订单
            infoId: orderInfo.infoId, //客户id
          },
        ]

        this.$refs.refundApply.init(rows, 2)
      },
      // 手动完结
      manualEnd() {
        checkOrderEnd({ orderId: this.orderInfo.orderId }).then((res) => {
          if (res?.code === '000000') {
            this.manualData = res.data
            this.manualVisible = true
          }
        })
      },
      closeModel() {
        this.manualVisible = false
        this.manualData = {}
      },
      submitModel() {
        this.closeModel()
        this.getDetail()
      },

      handleFileUploadChange(fileList, index) {
        this.setPrintDoc(
          JSON.stringify(fileList),
          this.waitTable[index].orderProductId
        )
      },
      setPrintDoc(url, id) {
        let dt = {
          orderProductId: id,
          printedDocumentsUrl: url,
        }
        upPrintedDocuments(dt).then((res) => { })
      },

      /**
       * 显示出库成本信息
       * @param {rows} 当前行信息
       * @param {_type} 1:出库 2:成本调整 3:退货入库 4:入库
       * @param {_wType} 1:退货入库 3:销售出库
       */
      getWarehousePrice(rows) {
        if (rows?.warehousePriceVOS?.length) {
          const _arr = rows.warehousePriceVOS
          let _text = ''

          for (let i = 0; i < _arr.length; i++) {
            const _type = _arr[i].orderType
            const _wType = _arr[i].warehouseType

            switch (_type) {
              case 1:
                _text += `
                <p>出库单号：${_arr[i].orderNumber}</p>
                <p>出库数量：${_arr[i].warehouseNum}</p>
                <p>出库成本：${_arr[i].warehouseTotlePrice}</p>\n
              `
                break
              case 2:
                let title = ''
                if (_wType === 1) {
                  title = '退货入库成本调整单'
                } else if (_wType === 3) {
                  title = '销售出库成本调整单'
                } else {
                  title = '加工入库成本调整'
                }
                _text += `
                <p>${title}：${_arr[i].orderNumber}</p>
                <p>调整金额：${_arr[i].warehouseTotlePrice}</p>
                <p>产品备注：${_arr[i].remark}</p>\n
                `
                break
              case 3:
                _text += `
                <p>退货入库单号：${_arr[i].orderNumber}</p>
                <p>入库数量：${_arr[i].warehouseNum}</p>
                <p>入库成本：${_arr[i].warehouseTotlePrice}</p>\n
              `
                break
              case 4:
                _text += `
                <p>加工入库单号：${_arr[i].orderNumber}</p>
                <p>入库数量：${_arr[i].warehouseNum}</p>
                <p>入库成本：${_arr[i].warehouseTotlePrice}</p>
                <p>备注：${_arr[i].remark}</p>\n
              `
            }
          }

          return _text
        } else {
          return null
        }
      },

      // 产品类型中英文翻译
      getLangProductType(state) {
        let text = ''
        switch (state) {
          case '标准品':
            text = this.$t('orderDetails.StandardProduct')
            break
          case '订制品':
            text = this.$t('orderDetails.CustomizedProduct')
            break
          case '辅料':
            text = this.$t('orderDetails.Excipients')
            break
        }
        return text
      },

      // 效果图状态中英文翻译
      getLangImageState(state) {
        let text = ''
        switch (state) {
          case '待确认':
            text = this.$t('orderDetails.ToBeConfirmed')
            break
          case '已确认':
            text = this.$t('orderDetails.HasBeenConfirmed')
            break
          case '客户确认中':
            text = this.$t('orderDetails.CustomerConfirmation')
            break
          case '没有文件':
            text = this.$t('orderDetails.NoFile')
            break
        }
        return text
      },

      // 已安排状态中英文翻译
      getLangState(state) {
        let text = ''
        switch (state) {
          case '待出库':
            text = this.$t('orderDetails.PendingDelivery')
            break
          case '部分出库':
            text = this.$t('orderDetails.PartiallyDelivery')
            break
          case '已出库':
            text = this.$t('orderDetails.OutOfWarehouse')
            break
          case '部分采购':
            text = this.$t('orderDetails.PartiallyPurchase')
            break
          case '采购中':
            text = this.$t('orderDetails.InProcurement')
            break
          case '部分采购完成':
            text = this.$t('orderDetails.PartiallyPurchaseCompleted')
            break
          case '采购完成':
            text = this.$t('orderDetails.PurchaseCompleted')
            break
        }
        return text
      },

      // 出运状态中英文翻译
      getLangShipState(state) {
        let text = ''
        switch (state) {
          // 出库中
          case 0:
            text = this.$t('orderDetails.InDelivery')
            break
          // 已出库
          case 1:
            text = this.$t('orderDetails.OutOfWarehouse')
            break
          // 出库驳回
          case 2:
            text = this.$t('orderDetails.RejectedOutOfWarehouse')
            break
          // 已取消
          case 4:
            text = this.$t('orderDetails.Cancelled')
            break
          // 已完成
          case 3:
            text = this.$t('orderDetails.Completed')
            break
          // 已出运
          case 5:
            text = this.$t('orderDetails.Shipped')
            break
          // 未出库
          case 6:
            text = this.$t('orderDetails.NotoutOfstock')
            break
          default:
            break
        }
        return text
      },

      // 备注保存
      remarkSave(type, remark) {
        let params = {
          orderId: this.orderInfo.orderId,
          remark: remark,
          remarkType: type,
        }
        updateOrderRemark(params).then((res) => {
          if (res && res.code === '000000') {
            this.$message.success('保存成功')
            if (type === 1) {
              this.orderInfo.customerRemark = remark
              this.isCustomerNotes = false
            } else {
              this.orderInfo.remark = remark
              this.isRemarks = false
            }
          }
        })
      },
      // 物流进度追踪
      logisticsProgress(params) {
        this.$refs.logistics.init(params, 2)
      },
      // 关闭提示
      async handleCloseCollectionTip() {
        const { code } = await OrderSettingInteractor.closeShippingReminderFlagApi(this.orderCode)
        if (code === '000000') {
          this.collectionTipVisible = false
        }
      },
      // 收款确认
      collectionConfirm(item) {
        const {transactionCode:financeCode, remark} = item
        this.$refs.collectionConfirmDialog.showAddEdit({
          financeCode,
          remark,
          orderCode:this.orderInfo?.orderCode
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .collection-tip {
    background-color: #FEF0F0;
    color: #F56C6C;
    padding: 0 20px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    .el-icon-warning {
      font-size: 24px;
      margin-right: 10px;
    }
    .el-icon-close {
      color: #ccc;
      cursor: pointer;
      margin-left: auto;
      font-size: 20px;
    }
  }

  ::v-deep .el-table__cell {
    .sampleFlag-box-item {
      position: absolute;
      right: 0;
      top: 0;

      span {
        font-size: 12px;
        line-height: 12px;
        padding: 0 3px;
        background-color: #fff7e8;
        border-color: #ffefd1;
        color: #ffae18;
        border-radius: 4px;
      }
    }
  }

  .refund-p {
    background-color: #ecf5ff;
    height: 32px;
    padding: 0 10px;
    line-height: 30px;
    font-size: 12px;
    color: #409eff;
    border: 1px solid #d9ecff;
    border-radius: 4px;
    box-sizing: border-box;
    display: inline-block;
    margin-bottom: 5px;
  }

  .text-left {
    text-align: left;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .tag-wrap {
    position: relative;
    display: flex;
    justify-content: center;

    .el-tag {
      position: absolute;
      top: -10px;
      right: 0;
      z-index: 2;
      white-space: normal;

      &.el-tag-other {
        background-color: #fff7e8;
        border-color: #ffefd1;
        color: #ffae18;
      }
    }

    .el-image {
      width: 50px;
      height: 50px;
      border-radius: 2px;
    }
  }

  .receiver-info {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #ebeef5;
    background: #f5f7fa;
    color: #606266;
    font-size: 14px;
    height: 40px;
    padding: 10px;
    margin-bottom: -1px;
    position: relative;

    .info-item {
      margin-right: 40px;
      display: flex;

      .label {
        white-space: nowrap;
      }

      .value {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .btns {
      position: absolute;
      right: 15px;
      top: 4px;
    }
  }

  .no-return {
    font-size: 18px;
    font-family: PingFang Bold;
    color: #303133;
    margin-bottom: 20px;
    line-height: 25px;
  }

  .product-cost {
    .title {
      font-size: 16px;
      color: #333;
      line-height: 2;
      margin-bottom: 10px;
      font-weight: bold;
    }

    .total {
      font-size: 16px;
      margin-top: 10px;
      display: flex;
      justify-content: flex-end;

      span {
        margin-left: 20px;
      }
    }
  }

  .tooltip-cost {
    .tooltip-info {
      line-height: 20px;
      font-size: 14px;
      padding: 4px 0;

      &+.tooltip-info {
        border-top: 1px dashed #ebeef5;
      }
    }
  }

  .total-money {
    line-height: 40px;
    text-align: right;

    span {
      font-family: 'PingFang Bold';
      font-size: 18px;
      margin-left: 5px;
    }
  }

  .fileAnnex-span {
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    background: #9e9e9e;
    color: #fff;
    border-radius: 50%;
    margin-left: 5px;
    font-size: 12px;
  }


  .wait-span {
    cursor: pointer;
    color: red;
    text-decoration: underline;
  }

  .comp-span {
    cursor: pointer;
    color: #008000;
    text-decoration: underline;
  }

  .wrapper-box {
    .wrapper-image {
      width: 50%;
      margin: 0 auto;
    }

    .wrapper-add {
      display: inline-block;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border: 1px dotted #c0cbda;
      border-radius: 3px;
      cursor: pointer;

      .el-icon-plus {
        position: static;
      }
    }
  }

  .state_content {
    border-bottom: 1px dashed #ddd;
    padding: 5px 0;
  }

  .state_content:last-child {
    border: none;
    padding: 5px 0 0;
  }

  .pre-wrap {
    max-height: 500px;
    padding-right: 10px;
    overflow-y: auto;
  }

  ::v-deep {
    .el-badge {
      position: absolute;

      .el-badge__content {
        left: 55px;
        top: 25px;
        background: #9e9e9e;
      }
    }

    .el-upload--picture-card {
      width: 100px;
      height: 100px;
    }

    .upload-images {
      .el-icon-plus {
        position: relative;
        top: -20px;
      }
    }

    .el-upload-list__item {
      width: 100px;
      height: 100px;
    }

    .el-upload-list__item-thumbnail {
      width: 100px;
      height: 100px;
    }

    .el-upload-list__item-actions {
      width: 100px;
      height: 100px;
    }

    .el-table__row--level-1 {
      .cell {
        .el-table__indent {
          display: none;
        }

        .el-table__expand-icon {
          display: none;
        }
      }
    }

    .selection-hide>.cell .el-checkbox__inner {
      display: none;
    }

    .remark-revise {
      h4 span {
        font-weight: 500;
        margin-left: 10px;
        cursor: pointer;
        color: #409eff;
      }

      em {
        line-height: 30px;
        display: block;
        text-align: right;
        color: #409eff;
        cursor: pointer;
      }
    }
  }

  .tip_wrapper {
    display: flex;
    align-items: baseline;

    .tip {
      margin-left: 10px;
      color: #ff4d4f;
      font-family: 'PingFang Bold';
      font-size: 12px;
    }
  }
</style>
<style lang="scss">
  .el-message-box.arrange-shipment-tip {
    .el-icon-warning {
      color: red;
    }
  }
</style>
