<template>
  <div class="pro-container" v-if="$route.meta.showParent">
    <el-row class="page-header align_center pub-h-36" type="flex">
      <el-col :xs="14" :sm="16" :md="18" :lg="20" :xl="20" :span="20" class="page-header-left">
        <el-row type="flex" class="align_center flex_row_y flex_wrap" v-show="!selectRows.length">
          <el-button icon="el-icon-plus" type="primary" class="mr10 pub-h-36" @click="goToPage('add')"
            v-allowed="['Product:Operate']">
            <!-- 新增 -->
            {{ $t('productCenter.Add') }}
          </el-button>
          <el-form ref="form" :inline="true" :model="queryForm" label-width="49px">
            <el-form-item>
              <div style="display: flex">
                <el-select class="mr5" v-model="type" style="width: 120px" @change="changeType">
                  <!-- 产品编码 产品名称 -->
                  <el-option :label="$t('productDetail.ProductCode')" value="codes"></el-option>
                  <el-option :label="$t('productDetail.ProductName')" value="names"></el-option>
                </el-select>

                <MultilineSearch ref="multilineSearch" :defaultMutipleVal="multipleValue"
                  @multiple-change="multipleChange" @enter-trigger="multipleChange" entered />
              </div>
            </el-form-item>
            <!-- 请选择审核状态 -->
            <el-select v-model="queryForm.auditProcess" :placeholder="$t('placeholder.plsProductState')"
              class="mr10 pub-h-36" clearable @change="query2">
              <el-option v-for="item in auditStateList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form>
          <el-button @click="query2" class="pub-h-36">
            <i class="iconfont icon-fangdajing f_s_14"></i>
            <!-- 搜索 -->
            {{ $t('productCenter.Search') }}
          </el-button>
          <el-button @click="productFilterClick" class="pub-h-36">
            <!-- 高级筛选 -->
            {{ $t('productCenter.AdvancedFilter') }}
          </el-button>
          <!-- <el-button
            @click="fastPriceClick"
            plain
            type="primary"
            class="pub-h-36"
          >
            快速报价
          </el-button> -->
          <el-button type="danger" plain @click="proDel('Batch')" class="pub-h-36" v-allowed="['Product:Operate']">
            <!-- 删除 -->
            {{ $t('productCenter.Delete') }}
          </el-button>
          <el-button @click="transferClick" class="pub-h-36" v-allowed="['Product:Operate']">
            <!-- 转让归属权 -->
            {{ $t('productCenter.TransferOwnership') }}
          </el-button>
          <el-button plain @click="goToClonePage('clone')" class="pub-h-36">
            <!-- 克隆 -->
            {{ $t('productCenter.Clone') }}
          </el-button>
          <!-- <el-button @click="resetQuery">重置</el-button> -->
        </el-row>
        <el-row v-show="selectRows.length" type="flex" class="align_center pub-h-36">
          <p class="mr10">
            <!-- 已选 -->
            {{ settings == 'en' ? 'Selected' : '已选' }}
            <strong class="f_s_16">{{ selectRows.length }}</strong>
            <!-- 个产品 -->
            {{ settings == 'en' ? 'count products' : '个产品' }}
          </p>
          <!-- <p class="mr10">
            {{ $t('productCenter.ProductsSelected') }}
            <strong class="f_s_16">{{ selectRows.length }}</strong>
          </p> -->
          <el-button v-show="isDel" type="danger" plain @click="proDel('Batch')" class="pub-h-36"
            v-allowed="['Product:Operate']">
            <!-- 删除 -->
            {{ $t('productCenter.Delete') }}
          </el-button>
          <el-button v-show="isTransfer" type="primary" plain @click="transferClick" class="pub-h-36"
            v-allowed="['Product:Operate']">
            <!-- 转让归属权 -->
            {{ $t('productCenter.TransferOwnership') }}
          </el-button>
          <el-button type="primary" plain @click="goToClonePage('clone')" class="pub-h-36">
            <!-- 克隆 -->
            {{ $t('productCenter.Clone') }}
          </el-button>
        </el-row>
      </el-col>
      <el-col :span="4" :xs="10" :sm="8" :md="6" :lg="4" :xl="4">
        <el-row type="flex" justify="end">
          <!-- 切换视图按钮 -->
          <el-radio-group v-model="isTbalePic" size="mini" class="mr30" @change="flgView">
            <el-radio-button :label="true">
              <i class="iconfont icon-liebiaoxianshi f_s_14"></i>
            </el-radio-button>
            <el-radio-button :label="false">
              <i class="iconfont icon-icon-tupianxianshi f_s_14"></i>
            </el-radio-button>
          </el-radio-group>

          <!-- 自定义展示列 -->
          <el-popover v-show="isTbalePic" ref="showOption" popper-class="custom-table-checkbox" trigger="hover">
            <erp-draggable v-bind="dragOptions" :list="columns">
              <el-checkbox v-for="(item, index) in columns" :key="item + index" v-model="item.isShow"
                :disabled="item.checkable === false" :label="item.label">
                <!-- {{ item.label }} -->
                {{ $t(item.langKey) }}
              </el-checkbox>
            </erp-draggable>
            <!-- 保存自定义显示列 -->
            <div class="save-btn">
              <el-button type="primary" class="ml10" size="mini" plain @click="operateSave">
                <!-- 保存 -->
                {{ $t('productCenter.Save') }}
              </el-button>
            </div>
            <template #reference>
              <!-- 列表可拖动排序 -->
              <el-tooltip effect="dark" :content="$t('productCenter.ListDraggableToSort')" placement="top-start">
                <el-button plain type="primary" class="pub-h-36">
                  <!-- 自定义显示 -->
                  {{ $t('productCenter.CustomDisplay') }}
                </el-button>
              </el-tooltip>
            </template>
          </el-popover>
        </el-row>
      </el-col>
    </el-row>
    <!-- table or pic -->
    <el-row class="mt20 main-height">
      <el-row v-if="isTbalePic">
        <ErpTable :page-number.sync="pageNo" :page-size.sync="pageLe" :table-data="tableData" :total="total"
          @query="query" class="table-fixed" @selection-change="setSelectRows" :key="customTableKey"
          tooltip-effect="dark">
          <el-table-column align="center" type="selection" width="55" />
          <el-table-column v-for="(item, index) in finallyColumns" :key="index" :label="$t(item.langKey)"
            show-overflow-tooltip :sortable="item.sortable" :min-width="settings === 'zh' ? 120 : 160"
            :resizable="false" align="center" :prop="item.prop">
            <!-- <template slot="header">
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t(item.langKey)"
                placement="top"
                :disabled="$t(item.langKey).length < 13"
              >
                <span>{{ $t(item.langKey) }}</span>
              </el-tooltip>
            </template> -->
            <template #default="{ row }">
              <el-row v-if="item.prop === 'imgMain'">
                <el-popover v-if="row.imgMain" class="c_pointer" placement="right" width="300" trigger="hover">
                  <el-image :src="row.imgMain" />
                  <el-image slot="reference" :src="row.imgMain" />
                </el-popover>
                <span v-else>--</span>
              </el-row>

              <template v-else-if="item.prop === 'nameCn'">
                <span class="c_pointer" @click="celldblclick(row.nameCn)">
                  {{ row.nameCn || '--' }}
                </span>
              </template>
              <template v-else-if="item.prop === 'nameEn'">
                <span class="c_pointer" @click="celldblclick(row.nameEn)">
                  {{ row.nameEn || '--' }}
                </span>
              </template>
              <!-- 产品类型 -->
              <template v-else-if="item.prop === 'standardCustomized'">
                <el-tag size="default" v-if="row.standardCustomized"
                  :type="row.standardCustomized === '订制品' ? 'success' : ''">
                  {{ row.standardCustomizedDesc || '--' }}
                </el-tag>
                <span v-else>--</span>
              </template>
              <!-- 产品类别 -->
              <template v-else-if="item.prop === 'category'">
                <span>
                  {{ settings === 'zh' ? row.category : row.categoryEn }}
                </span>
              </template>
              <!-- 产线 -->
              <template v-else-if="item.prop === 'productionLine'">
                <span>
                  {{
                  settings === 'zh'
                  ? row.productionLine
                  : row.productionLineEn
                  }}
                </span>
              </template>

              <template v-else-if="item.prop === 'creator'">
                <span>
                  {{ getName(row, 'creator') }}
                </span>
              </template>
              <template v-else-if="item.prop === 'belonger'">
                <span>
                  {{ getName(row, 'belonger') }}
                </span>
              </template>
              <!-- 平台状态 -->
              <el-row v-else-if="['SAGE', 'ESP', 'DC', 'website'].includes(item.prop)">
                {{ typeFilter(row[item.prop]) }}
              </el-row>

              <el-row v-else-if="item.prop === 'productCode'">
                <span @click="productView(row.productId, row.productCode)" class="prod_code">
                  {{ row.productCode }}
                </span>
                <i class="iconfont icon-guige sku-i" @click="skuClick(row.productId)"></i>
              </el-row>
              <!-- 主要供应商 -->
              <span v-else-if="item.prop === 'mainSupplierName'" @click="celldblclick(row.mainSupplierName)"
                class="c_pointer">
                {{ row.mainSupplierName || '--' }}
              </span>
              <!-- 库存 -->
              <template v-else-if="item.prop === 'stockWarehouseNumVOList'">
                <el-row v-if="
                    row.stockWarehouseNumVOList &&
                    row.stockWarehouseNumVOList.length
                  ">
                  <el-row class="text-hidden" v-for="(stock, stockIndex) of row.stockWarehouseNumVOList"
                    :key="stockIndex">
                    {{ getName(stock, 'warehouseName') }}：{{ stock.num }}
                  </el-row>
                </el-row>
                <span v-else>--</span>
              </template>
              <!-- 修改时间 -->
              <template v-else-if="item.prop === 'updateTime'">
                <span>
                  {{ row.updateTime | dateFormat }}
                </span>
              </template>

              <!-- 创建时间 -->
              <template v-else-if="item.prop === 'createTime'">
                <span>
                  {{ row.createTime | dateFormat }}
                </span>
              </template>

              <!-- 审核状态 -->
              <template v-else-if="item.prop === 'auditProcess'">
                <el-tag :type="row.auditProcess | statusFilter" class="c_pointer" @click="statusTypeClick(row)">
                  {{ auditStatus(row.auditProcess) }}
                </el-tag>
              </template>

              <!-- 商城状态 -->
              <!-- <template v-else-if="item.prop === 'mallStatus'">
                <span>{{ auditMallStatus(row.mallStatus) }}</span>
              </template> -->

              <!-- 创建方式 -->
              <template v-else-if="item.prop === 'createWay'">
                <span>
                  <!-- {{
                  row.createWay === 0
                    ? '自行新增'
                    : row.createWay === 1
                    ? '生产系统'
                    : '询盘生成'
                }} -->
                  {{
                  row.createWay === 0
                  ? $t('productCenter.SelfAdd')
                  : row.createWay === 1
                  ? $t('productCenter.ProductionSystem')
                  : $t('productCenter.InquiryGeneration')
                  }}

                  <!-- {{ row.createWay | createWayFilter }} -->
                </span>
              </template>

              <!-- 审核通过时间 -->
              <template v-else-if="item.prop === 'auditTime'">
                {{ row.auditProcess === 2 ? row.auditTime : '--' }}
              </template>
              <!-- 上架平台 -->
              <template v-else-if="item.prop === 'platformCodes'">
                {{
                settings === 'zh'
                ? row.platformNames || '--'
                : row.platformNameEns || '--'
                }}
              </template>
              <!-- 其他 -->
              <span v-else>{{ row[item.prop] || '--' }}</span>
            </template>
          </el-table-column>
          <!-- 操作 -->
          <el-table-column align="center" :label="$t('productCenter.Operate')" width="160" fixed="right"
            :resizable="false">
            <template slot-scope="scope">
              <el-row v-if="
                  userInfo.userId === scope.row.belongerId ||
                  scope.row.editProduct === '1' ||
                  scope.row.offShelfProduct === '1'
                ">
                <!-- 编辑 -->
                <el-tooltip :content="$t('productCenter.Edit')" placement="top">
                  <el-button v-allowed="['Product:Operate']" type="primary" plain icon="el-icon-edit" circle size="mini"
                    @click="goToPage('edit', scope.row.productId)" v-show="
                      scope.row.auditProcess !== 1 &&
                      scope.row.editProduct === '1'
                    "></el-button>
                </el-tooltip>
                <!-- 商城下架 -->
                <!-- <el-tooltip
                  :content="$t('productCenter.RemovedFromShelves')"
                  placement="top"
                >
                  <el-button
                    v-allowed="['Product:Operate']"
                    type="primary"
                    plain
                    circle
                    icon="el-icon-bottom"
                    size="mini"
                    @click="removedFromShelves(scope.row)"
                    v-if="
                      scope.row.mallStatus === 1 &&
                      scope.row.offShelfProduct === '1'
                    "
                  ></el-button>
                </el-tooltip> -->
                <!-- 撤销 -->
                <el-tooltip :content="$t('productCenter.Revoke')" placement="top" v-if="
                    scope.row.auditProcess === 1 &&
                    scope.row.editProduct === '1'
                  ">
                  <el-button v-allowed="['Product:Operate']" type="primary" plain circle size="mini"
                    @click="revokeClick(scope.row)">
                    <i class="iconfont icon-quxiao f_s_12"></i>
                  </el-button>
                </el-tooltip>
                <!-- 上架 -->
                <el-dropdown v-if="
                    scope.row.auditProcess === 2 &&
                    scope.row.standardCustomized === '标准品'
                  ">
                  <el-button type="primary" plain icon="el-icon-top" v-allowed="['Product:Putaway']" size="mini" circle
                    style="margin-left: 7px"></el-button>
                  <el-dropdown-menu slot="dropdown" placement="bottom">
                    <el-dropdown-item style="width: 140px">
                      <p @click="onAndOffShelvesFn(1, scope.row)" style="color: #409eff">
                        {{ $t('productCenter.addToTheMall') }}
                      </p>
                    </el-dropdown-item>
                    <el-dropdown-item style="width: 140px">
                      <p style="color: #409eff" @click="listedOnThirdPartyPlatformsFn(scope.row)">
                        {{ $t('productCenter.listedOnThirdPartyPlatforms') }}
                      </p>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <!-- 下架 -->
                <el-dropdown v-if="
                    scope.row.standardCustomized === '标准品' &&
                    scope.row.platformCodes &&
                    (scope.row.platformCodes.includes('RP') ||
                      scope.row.platformCodes.includes('SAGE') ||
                      scope.row.platformCodes.includes('RG') ||
                      scope.row.platformCodes.includes('SW') 
                    )
                  ">
                  <el-button type="primary" plain icon="el-icon-bottom" v-allowed="['Product:Soldout']" size="mini"
                    circle style="margin-left: 7px"></el-button>
                  <el-dropdown-menu slot="dropdown" placement="bottom">
                    <el-dropdown-item style="width: 140px" v-if="
                        scope.row.platformCodes &&
                        (scope.row.platformCodes.includes('RP') || scope.row.platformCodes.includes('RG') || scope.row.platformCodes.includes('SW'))
                      ">
                      <p @click="onAndOffShelvesFn(2, scope.row)" style="color: #409eff">
                        {{ $t('productCenter.removedFromTheMall') }}
                      </p>
                    </el-dropdown-item>
                    <el-dropdown-item style="width: 140px" v-if="
                        scope.row.platformCodes &&
                        scope.row.platformCodes.includes('SAGE')
                      ">
                      <p @click="onAndOffShelvesFn(3, scope.row)" style="color: #409eff">
                        {{ $t('productCenter.removedFromThirdPartyPlatforms') }}
                      </p>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-row>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </ErpTable>
      </el-row>
      <el-row v-else>
        <ProductPic ref="productPic" :passProData="tableData" :passTotal="total" :processList="auditStateList"
          :pageInfo="pageInfo" @query="queryPro" @productView="productView" @goToPage="goToPage" @proDel="proDel"
          @picOperate="setSelectRows" />
      </el-row>
    </el-row>

    <!-- 高级筛选Drawer -->
    <el-drawer :title="$t('productCenter.AdvancedFilter')" :visible.sync="drawer" :before-="productFilterClose">
      <ProductSearch @cancelClick="productFilterClose" @searchClick="searchClick" />
    </el-drawer>

    <!-- 审核进度Drawer -->
    <el-drawer :title="$t('productCenter.AuditProgress')" :visible.sync="statusDrawer" :before-="statusClose">
      <Audit ref="Audit" :auditObjectId="auditObjectId" :auditType="auditType" @cancelClick="statusClose" />
    </el-drawer>

    <!-- 快速报价 -->
    <FastQuotedPrice ref="FastQuotedPrice" />

    <!-- 转让归属权-订制品 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
    <!-- 转让归属权-标准品 -->
    <TransferModel ref="TransferModel" @fetch-data="query" />

    <!-- sku列表 -->
    <ProSkuModel ref="ProSkuModel" />

    <!-- 撤销弹窗  撤销原因-->
    <el-dialog :title="$t('productCenter.ReasonForRevocation')" :visible="cancelVisible" append-to-body width="500px"
      @close="onCancel" modal-append-to-body>
      <span style="color: red; position: absolute; left: 10px">*</span>
      <!-- 请输入撤销原因 -->
      <el-input type="textarea" :rows="3" :placeholder="$t('placeholder.plsRevocation')" maxlength="500" show-word-limit
        v-model="cancelReason"></el-input>
      <div class="footer mt30 t_a_c">
        <!-- 确认 -->
        <el-button type="primary" @click="cancelSure">
          {{ $t('productCenter.Confirm') }}
        </el-button>
        <!-- 取消 -->
        <el-button @click="onCancel">
          {{ $t('productCenter.Cancel') }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 上下架弹窗 --> 
    <OnAndOffShelves :onAndOffShelvesShow.sync="onAndOffShelvesShow" :fromType="fromType" :platformCodes="platformCodes"
      :productId="productId" @onAndOffShelvesSuccess="query"></OnAndOffShelves>
  </div>
  <div v-else class="pro-container">
    <router-view />
  </div>
</template>

<script>
  import {
    getList,
    logicDeleteByIds,
    transferBelongBatch,
    changeMallStatus,
  } from '@/api/product/productList'
  import { getAuditObject, cancel } from '@/api/stock-center/inbound-sheet'
  import ProductPic from './components/ProductPic'
  import ProductSearch from './components/ProductSearch'
  import TransferModel from './components/TransferModel'
  import ProSkuModel from './components/ProSkuModel'
  import ErpDraggable from 'vuedraggable'
  import Audit from '@/components/Audit/ApprovalsDrawer'
  import FastQuotedPrice from '@/components/FastQuotedPrice'
  import UserChoose from '@/components/userChoose'
  import CustomCache from '@/utils/custom-cache'
  import nameMixin from '@/utils/name-mixin'
  import { mapGetters } from 'vuex'
  import customTableMixin from '@/utils/custom-table-mixin'
  import MultilineSearch from '@/components/multiline-search'
  import { getKeyIds } from '@/utils/pagelist-util'
  import { hasRight } from '@/utils/permissionBtn'

  import OnAndOffShelves from './components/OnAndOffShelves'
  import { PlatformProductsInteractor } from '@/core'
  export default {
    name: 'ProductList',
    mixins: [nameMixin, customTableMixin],
    components: {
      ErpDraggable,
      ProductPic,
      ProductSearch,
      Audit,
      FastQuotedPrice,
      UserChoose,
      TransferModel,
      ProSkuModel,
      MultilineSearch,
      OnAndOffShelves,
    },
    props: {},

    data() {
      return {
        onAndOffShelvesShow: false, //上下架弹窗
        fromType: 1, //弹窗类型
        productId: '', //点击的产品id
        platformCodes: [], //上架的平台code
        queryForm: {},
        selectRows: [], //table勾选
        isTbalePic: true, //默认展示列表
        tableData: [],
        drawer: false, //高级筛选抽屉
        columns: [
          {
            label: '产品图片',
            prop: 'imgMain',
            width: '',
            enWidth: '120',
            isShow: true,
            checkable: false,
            langKey: 'productCenter.ProductImage',
          },
          {
            label: '产品编码',
            prop: 'productCode',
            isShow: true,
            langKey: 'productCenter.ProductCode',
            width: '',
            enWidth: '160',
          },
          {
            label: '中文名称',
            prop: 'nameCn',
            isShow: true,
            langKey: 'productCenter.ChineseName',
            width: '',
            enWidth: '160',
          },
          {
            label: '英文名称',
            prop: 'nameEn',
            isShow: true,
            langKey: 'productCenter.EnglishName',
            width: '',
            enWidth: '160',
          },
          {
            label: '产品类型',
            prop: 'standardCustomized',
            isShow: true,
            langKey: 'productCenter.ProductType',
            width: '',
            enWidth: '160',
          },
          {
            label: '产品来源',
            prop: 'createWay',
            isShow: true,
            langKey: 'productCenter.ProductSource',
            width: '',
            enWidth: '160',
          },
          {
            label: '产品类别',
            prop: 'category',
            isShow: true,
            langKey: 'productCenter.ProductCategory',
            width: '',
            enWidth: '160',
          },
          {
            label: '主要供应商',
            prop: 'mainSupplierName',
            isShow: true,
            langKey: 'productCenter.MajorSuppliers',
            width: '',
            enWidth: '160',
          },
          {
            label: '状态',
            prop: 'auditProcess',
            isShow: true,
            langKey: 'productCenter.Status',
            width: '',
            enWidth: '160',
          },
          {
            label: '上架平台',
            prop: 'platformCodes',
            isShow: true,
            langKey: 'productCenter.listingPlatform',
            width: '',
            enWidth: '220',
          },
          // {
          //   label: '商城状态',
          //   prop: 'mallStatus',
          //   isShow: true,
          //   langKey: 'productCenter.MallStatus',
          //   width: '',
          //   enWidth: '160',
          // },
          {
            label: '库存',
            prop: 'stockWarehouseNumVOList',
            isShow: true,
            langKey: 'productCenter.Inventory',
            width: '',
            enWidth: '160',
          },
          {
            label: '产线',
            prop: 'productionLine',
            isShow: true,
            langKey: 'productCenter.ProductionLine',
            width: '',
            enWidth: '160',
          },
          {
            label: '询盘数',
            prop: 'inquiryCount',
            isShow: false,
            langKey: 'productCenter.NumberOfEnquiries',
            width: '',
            enWidth: '160',
          },
          {
            label: '下单数',
            prop: 'contractCount',
            isShow: false,
            langKey: 'productCenter.NumberOfOrders',
            width: '',
            enWidth: '160',
          },
          {
            label: '产品运营',
            prop: 'belonger',
            isShow: true,
            langKey: 'productCenter.ProductOperations',
            width: '',
            enWidth: '160',
          },
          // {
          //   label: 'SAGE',
          //   prop: 'SAGE',
          //   isShow: false,
          //   langKey: 'productCenter.Sage',
          //   width: '',
          //   enWidth: '160',
          // },
          // {
          //   label: 'ESP',
          //   prop: 'ESP',
          //   isShow: false,
          //   langKey: 'productCenter.ESP',
          //   width: '',
          //   enWidth: '160',
          // },
          // {
          //   label: 'DC',
          //   prop: 'DC',
          //   isShow: false,
          //   langKey: 'productCenter.DC',
          //   width: '',
          //   enWidth: '160',
          // },
          // {
          //   label: '官网',
          //   prop: 'website',
          //   isShow: false,
          //   langKey: 'productCenter.website',
          //   width: '',
          //   enWidth: '160',
          // },
          {
            label: '创建人',
            prop: 'creator',
            isShow: false,
            langKey: 'productCenter.Creator',
            width: '',
            enWidth: '160',
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            isShow: true,
            langKey: 'productCenter.ModifiedTime',
            width: '',
            enWidth: '160',
          },
          {
            label: '创建时间',
            prop: 'createTime',
            isShow: true,
            langKey: 'productCenter.CreationTime',
            width: '',
            enWidth: '160',
          },
          {
            label: '审核通过时间',
            prop: 'auditTime',
            isShow: true,
            langKey: 'productCenter.ApprovalTime',
            width: '',
            enWidth: '160',
          },
        ],
        userCustomizeColumnId: '', //自义定保存id
        //----------------------------------
        pageNo: 1,
        pageLe: 10,
        total: 0,
        list: [],
        statusDrawer: false, //审核进度,
        auditObjectId: '', //审核id（产品id）
        auditType: 6,
        auditManageTags: [],
        cancelVisible: false,
        cancelReason: '', //撤销原因
        isDel: true, //产品是否显示删除按钮
        isTransfer: true, //产品是否可转让
        type: 'codes',
        multipleValue: '',
        pageInfo: {
          pageNo: 1,
          pageLe: 10,
        },
      }
    },

    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        settings: 'settings/language',
      }),
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        return this.columns.filter((item) => item.isShow === true)
      },
      auditStateList() {
        return [
          { label: this.$t('productCenter.Inputting'), value: 0 },
          { label: this.$t('productCenter.InReview'), value: 1 },
          { label: this.$t('productCenter.PassedTheReview'), value: 2 },
          { label: this.$t('productCenter.ReviewDismissed'), value: 3 },
          { label: this.$t('productCenter.Revoked'), value: 4 },
          { label: this.$t('productCenter.NoNeedToAudit'), value: 5 },
          { label: this.$t('productCenter.Deleted'), value: 6 },
        ]
      },
    },

    // 状态过滤
    filters: {
      statusFilter(status) {
        const statusMap = {
          0: 'info',
          1: 'primary',
          2: 'success',
          3: 'danger',
          4: 'info',
          5: 'success',
          6: 'danger',
        }
        return statusMap[status]
      },
      createWayFilter(status) {
        const createWay = {
          0: '自行新增',
          1: '生产系统',
          2: '询盘生成',
        }
        return createWay[status]
      },
    },

    created() {
      // //获取自定义保存
      this.initGetcolumn()
      //产品列表
      this.query()
      console.log('created')
    },
    activated() {
      //产品列表
      //this.query()
      console.log('activated')
    },

    watch: {
      $route: function (val) {
        if (val.meta && val.meta.showParent) {
          this.query()
        }
      },
      settings() {
        this.query()
      },
    },
    methods: {
      hasRight,
      // 获取上架成功平台
      async upShelfPlatformCodesFn(id, fn) {
        const params = { erpProductId: id }
        let res = await PlatformProductsInteractor.upShelfPlatformCodesApi(
          params
        )
        if (res?.code === '000000' && res?.data) {
          fn(res.data)
        }
      },
      // 上架到三方平台
      listedOnThirdPartyPlatformsFn(row) {
        this.upShelfPlatformCodesFn(row.productId, (arr) => {
          if (!arr.includes('SAGE')) {
            this.$router.push({
              name: 'platformProductPutaway',
              query: {
                erpProductCode: row.productCode,
                erpProductId: row.productId,
                form: 'erp',
              },
            })
          } else {
            return this.$baseMessage(
              this.$t('reqmsg.$67'),
              'warning',
              false,
              'erp-hey-message-warning'
            )
          }
        })
      },
      // 上下架弹窗
      onAndOffShelvesFn(num, row) {
        this.fromType = num
        this.productId = row?.productId ?? ''
        if (num === 1) {
          this.upShelfPlatformCodesFn(this.productId, (arr) => {
            this.platformCodes = arr
            this.onAndOffShelvesShow = true
          })
        } else {
          this.platformCodes = row?.platformCodes?.split(',') ?? []
          this.onAndOffShelvesShow = true
        }
      },
      typeFilter(val) {
        let obj = {
          // 1: '上架',
          // 2: '下架',
          1: this.settings === 'zh' ? '已上架' : 'Activated',
          2: this.settings === 'zh' ? '已下架' : 'Deactivated',
        }
        return obj[val] || this.$t('productDetail.NotLaunched')
      },
      //撤销
      revokeClick(row) {
        getAuditObject({
          orderId: row.productId,
          auditType: 6,
          tenantId: this.userInfo.tenantId,
        }).then((res) => {
          if (res && res.code === '000000') {
            // 获取提交审核（撤销所用）auditObjectId
            this.auditObjectId = res.data.auditObjectId
            this.cancelVisible = true
          }
        })
      },
      // 商城下架
      removedFromShelves(row) {
        this.$confirm(
          this.$t('reqmsg.mallStatusTip'),
          this.$t('reqmsg.M2005'),
          {
            confirmButtonText: this.$t('reqmsg.M2006'),
            cancelButtonText: this.$t('reqmsg.M2007'),
            type: 'warning',
          }
        )
          .then(() => {
            changeMallStatus({
              mallStatus: 0,
              productId: row.productId,
            }).then((res) => {
              if (res && res.code === '000000') {
                // 获取提交审核（撤销所用）auditObjectId
                this.$message.success(this.$t('reqmsg.mallStatus'))
                this.query()
              }
            })
          })
          .catch(() => { })
      },
      //关闭撤销弹框
      onCancel() {
        this.cancelVisible = false
        setTimeout(() => {
          this.cancelReason = ''
        }, 400)
      },
      //确定撤销
      cancelSure() {
        if (!this.cancelReason) {
          // 请输入撤销原因
          return this.$message.warning(this.$t('reqmsg.plsRevocation'))
        }
        const {
          auditObjectId,
          auditStatus = 4,
          auditType = 6,
          cancelReason,
        } = this
        const { tenantId, userId, userName } = this.userInfo
        cancel({
          auditObjectId,
          auditStatus: 4,
          auditType,
          tenantId,
          userId,
          userName,
          remarks: cancelReason,
        }).then((res) => {
          if (res.code === '000000') {
            // 撤销成功
            this.$message.success(this.$t('reqmsg.M2012'))
            this.cancelVisible = false
            this.query()
          }
        })
      },

      //快速报价
      fastPriceClick() {
        this.$refs['FastQuotedPrice'].showAddEdit()
      },
      //重置
      resetQuery() {
        this.queryForm = {}
        this.query()
      },
      changeType() {
        this.queryForm.codes = ''
        this.queryForm.names = ''
        this.multipleValue = ''
        this.$refs.multilineSearch.resetMutipleVal()
      },

      multipleChange(val) {
        this.multipleValue = val
        this.query2()
      },
      query2() {
        this.pageNo = 1
        this.query()
      },
      initGetcolumn() {
        CustomCache.getColumns(4, this.userInfo.userId).then((res) => {
          if (res && res.columns) {
            this.userCustomizeColumnId = res.userCustomizeColumnId
            this.columns = res.columns
          }
        })
      },

      // 查询
      async query() {
        this.pageInfo.pageNo = this.pageNo
        this.pageInfo.pageLe = this.pageLe
        this.$set(this.queryForm, this.type, this.multipleValue)
        try {
          let response = await getList({
            ...this.queryForm,
            pageNo: this.pageNo,
            pageLe: this.pageLe,
            keyIds: getKeyIds(this),
          })
          this.tableData = response.data.data.map((item) => {
            return {
              ...item,
              standardCustomizedDesc:
                item.standardCustomized === '标准品'
                  ? this.$t('productCenter.StandardProduct')
                  : item.standardCustomized === '订制品'
                    ? this.$t('productCenter.CustomizedProduct')
                    : '',

              ...JSON.parse(item.platformJson),
            }
          })
          this.total = response.data.total
          this.selectRows = []
        } catch (error) {
          console.log(error)
        } finally {
          console.log('finally')
          //this.isRequesting = false
        }
      },
      queryPro(pageLe, pageNo) {
        this.pageLe = pageLe
        this.pageNo = pageNo
        this.query()
      },

      //审核进度查看
      statusTypeClick(row) {
        //不需要查看审核进度（录入中，无需审核）
        if (row.auditProcess === 0 || row.auditProcess === 5) {
          return
        }
        this.auditObjectId = row.productId
        this.statusDrawer = true
      },
      //审核进度关闭
      statusClose() {
        this.statusDrawer = false
      },

      // 新增、编辑、克隆
      // 新增、编辑、克隆
      goToPage(str, productId) {
        if (str === 'clone' && this.selectRows.length > 1) {
          // 产品克隆只能选择一个
          return this.$baseMessage(
            this.$t('reqmsg.M200_1'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        this.$router.push({
          path: '/product/ProductAddEdit/ProductAddEdit',
          query: { str: str, proId: productId },
        })
      },
      //克隆
      goToClonePage(str) {
        if (this.selectRows.length === 0) {
          // 请先选择要克隆的产品
          return this.$baseMessage(
            this.$t('reqmsg.M2002'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        if (this.selectRows.length > 1) {
          // 产品克隆只能单选一个
          return this.$baseMessage(
            this.$t('reqmsg.M2003'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }

        let selectProId = ''
        if (this.selectRows[0].productId) {
          selectProId = this.selectRows[0].productId
        } else {
          selectProId = this.selectRows[0]
        }
        //let selectProId = this.selectRows[0].productId ?: this.selectRows[0].productId : this.selectRows
        this.$router.push({
          path: '/product/ProductAddEdit/ProductAddEdit',
          query: { str: str, proId: selectProId },
        })
      },

      // 查看
      productView(productId, productCode) {
        this.$router.push({
          path: '/product/ProductView',
          query: {
            proId: productId,
            productCode: productCode,
          },
        })
      },

      //查看sku
      skuClick(id) {
        this.$refs['ProSkuModel'].showAddEdit(id)
      },

      // 高级筛选
      productFilterClick() {
        this.drawer = true
      },
      // 高级筛选关闭
      productFilterClose() {
        this.drawer = false
      },
      // 高级筛选触发
      searchClick(val) {
        this.pageNo = 1
        this.queryForm = val
        this.query()
        this.productFilterClose()
      },
      // 保存自定义显示列
      operateSave() {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 4,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000') {
            this.userCustomizeColumnId = res.data.userCustomizeColumnId
            // 操作成功
            return this.$baseMessage(
              this.$t('reqmsg.$7'),
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },
      // 表格勾选
      setSelectRows(val) {
        this.selectRows = val

        //操作他人权限deleteProduct，录入中0，无需审核5，已撤销4，审核驳回3———可删除
        this.isDel = this.selectRows.every(
          (item) =>
            item.deleteProduct === '1' &&
            (item.auditProcess === 0 ||
              item.auditProcess === 5 ||
              item.auditProcess === 4 ||
              item.auditProcess === 3)
        )

        //操作他人权限editProductBelonger，产品运营是自己,审核通过，无需审核
        this.isTransfer = this.selectRows.every(
          (item) =>
            (item.editProductBelonger === '1' ||
              this.userInfo.userName === item.belonger) &&
            (item.auditProcess === 2 || item.auditProcess === 5)
        )
      },

      // 行内单个删除
      proDel(str, productId) {
        let selectIds
        if (str === 'Batch') {
          if (this.selectRows.length === 0) {
            // 请先选择要删除的产品
            return this.$baseMessage(
              this.$t('reqmsg.M2004'),
              'warning',
              false,
              'erp-hey-message-warning'
            )
          }
          selectIds = this.selectRows.map((item) => {
            return item.productId
          })
          if (selectIds.length) {
            selectIds = selectIds.join(',')
          }
        } else {
          selectIds = productId
        }
        // 是否确认删除？ 提示 确定 取消
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          confirmButtonText: this.$t('reqmsg.M2006'),
          cancelButtonText: this.$t('reqmsg.M2007'),
          type: 'warning',
        }).then(async () => {
          let response = await logicDeleteByIds({ ids: selectIds })
          if (response.code == '000000') {
            // 删除成功
            this.$baseMessage(
              this.$t('reqmsg.M2008'),
              'success',
              false,
              'erp-hey-message-success'
            )
            this.query()
          }
        })
      },

      //转让归属权
      transferClick() {
        if (this.selectRows.length === 0) {
          return this.$baseMessage(
            this.$t('reqmsg.M2009'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        let proLineIds = []
        let proIds = []
        let isProType1 = null //标准品
        let isProType2 = null //订制品
        isProType1 = this.selectRows.every(function (item) {
          return item.standardCustomized === '标准品'
        })
        isProType2 = this.selectRows.every(function (item) {
          return item.standardCustomized === '订制品'
        })
        //标准品转让-产线成员及产品运营
        if (isProType1) {
          this.selectRows.forEach((item) => {
            proLineIds.push(item.productionLineId)
            proIds.push(item.productId)
          })
          this.$refs['TransferModel'].showAddEdit(
            proLineIds.join(','),
            proIds.join(',')
          )
        }
        //订制品
        if (isProType2) {
          this.$refs['UserChoose'].showAddEdit('1', this.auditManageTags, true)
        }

        if (!isProType1 && !isProType2) {
          // 请勾选同一产品类型数据
          return this.$baseMessage(
            this.$t('reqmsg.M2010'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
      },
      chooseUser(userArr) {
        this.auditManageTags = []
        this.auditManageTags = userArr
        this.transferBelongClick(userArr)
      },

      //转让归属权
      async transferBelongClick(userArr) {
        let selected = this.selectRows
        let productIds = []
        selected.forEach((item) => {
          productIds.push(item.productId)
        })

        let params = {
          userId: userArr[0].id,
          userName: userArr[0].name,
          productIds: productIds.toString(),
        }
        let response = await transferBelongBatch(params)
        if (response.code === '000000') {
          this.query()
          return this.$baseMessage(
            // '归属权转让成功',
            this.$t('productCenter.SuccessfulTransferOfOwnership'),
            'success',
            false,
            'erp-hey-message-success'
          )
        }
      },

      //如果input为空
      searchInput(val) {
        if (val === '') {
          this.query()
        }
      },
      // 切换列表和图片视图
      flgView() {
        // this.pageNo = 1
        // this.pageLe = 10
        this.query()
        // 切换时清空选中
        this.selectRows = []
      },
      // 复制
      celldblclick(text) {
        let _this = this
        _this.$copyText(text).then(
          function (e) {
            _this.copyClick = true
            _this.$baseMessage(
              // '复制成功',
              _this.$t('productCenter.CopySucceeded'),
              'success',
              false,
              'erp-hey-message-success'
            )
          },
          function (e) {
            _this.copyClick = true
            // 复制失败，请手动复制
            _this.$baseMessage(
              _this.$t('reqmsg.M2011'),
              'error',
              false,
              'erp-hey-message-error'
            )
          }
        )
      },

      //审核状态匹配
      auditStatus(val) {
        let obj = this.auditStateList.find((item) => {
          return item.value == val
        })
        return obj.label
      },
      // 商城状态
      auditMallStatus(val) {
        if (this.settings === 'zh') {
          return val === 1 ? '已上架' : '未上架'
        } else {
          return val === 1 ? 'It has been added to' : 'Not on the shelves'
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .main-height {
    height: calc(100% - 56px);
  }

  .pro-container {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    // 产品编码
    .prod_code,
    .sku-i {
      color: #409eff;
      text-decoration: underline;
      cursor: pointer;
    }

    .sku-i {
      text-decoration: none;
      margin-left: 5px;
    }

    // // 产品状态
    // .prod_state {
    //   text-decoration: underline;
    // }
    // 操作里面的按钮
    .btn-span {
      display: inline-block;
      width: 100%;
      height: 100%;
      line-height: 26px;
    }
  }

  //自定义显示
  .custom-table-checkbox {
    .el-checkbox {
      display: block !important;
      margin: 8px 0 8px 10px !important;
    }
  }

  .save-btn {
    border-top: 1px solid #ececec;
    padding-top: 10px;
    text-align: center;
  }

  ::v-deep {
    .page-header {
      .el-form-item {
        margin-bottom: 0;
      }
    }

    .el-dropdown-menu__item {
      width: 80px;
      text-align: center;
    }

    .el-dropdown .el-button-group {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .el-icon-arrow-down {
      font-size: 14px;
    }

    .el-radio-button__orig-radio:checked+.el-radio-button__inner {
      background-color: #e8f4ff;
      color: #1890ff;
    }

    .el-radio-button__inner {
      padding: 0;
      width: 34px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // 操作里面的按钮
    .el-dropdown-menu__item {
      line-height: 30px !important;

      a {
        width: 100%;
      }
    }

    .el-dropdown {
      .el-button-group {
        .el-button:first-child {
          width: 40px;
          height: 30px;
          padding: 2px;
        }
      }
    }

    .el-table--border th.gutter:last-of-type {
      display: table-cell !important;
    }
  }
</style>
