<template>
  <el-dialog width="85%" :title="$t('AddOrder.ShippingInfo')" :visible.sync="visible" :close-on-click-modal="false">
    <div class="custom-container">
      <div class="order-content">
        <el-form :model="form" :rules="rules" ref="ruleForm">
          <div v-show="showInfo">
            <!-- 基本信息 -->
            <h4>{{ $t('AddOrder.BasicInfo') }}</h4>
            <el-row :gutter="24" class="mt20">
              <el-col :span="6">
                <!-- 报价单号 -->
                <el-form-item :label="$t('AddOrder.QuoteNumber')" prop="priceSheetCode" label-width="110px">
                  <el-select v-model="form.priceSheetCode" :placeholder="$t('placeholder.plsSel')" :disabled="canEdit"
                    clearable @change="priceSheetCodeChange">
                    <el-option v-for="(item, i) in priceSheetList" :key="i" :label="item" :value="item" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <!-- 签约日期 -->
                <el-form-item :label="$t('AddOrder.OrderDate')" prop="contractDate" label-width="100px" clearable
                  :rules="[
                    {
                      required: true,
                      trigger: 'change',
                      message: $t('placeholder.plsSel'),
                    },
                  ]">
                  <el-date-picker v-model="form.contractDate" type="date" :placeholder="$t('placeholder.selDate')"
                    format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <!-- 客户订单号 -->
                <el-form-item :label="$t('AddOrder.CustomerPO')" prop="customerOrderCode" label-width="120px" clearable
                  :rules="[
                    {
                      required: true,
                      trigger: 'change',
                      message: $t('placeholder.plsInput'),
                    },
                  ]">
                  <el-input v-model="form.customerOrderCode" :placeholder="$t('placeholder.plsInput')"
                    maxlength="50"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <!-- 出口抬头 -->
                <el-form-item :label="$t('AddOrder.ExportsRise')" prop="exportsRise" label-width="120px" :rules="[
                    {
                      required: true,
                      trigger: 'change',
                      message: $t('placeholder.plsSel'),
                    },
                  ]">
                  <!-- 根据订单编号生成规则判断出头抬头编辑状态是否可以编辑 -->
                  <el-select v-model="form.exportsRise" :disabled="isEdit" :placeholder="$t('placeholder.plsSel')"
                    clearable @change="exportsRiseChange">
                    <el-option v-for="item in exportsRiseList" :key="item.amountId" :label="item.amountShort"
                      :value="item.amountId" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mt15">
              <el-col :span="6">
                <!-- 客户代表 -->
                <el-form-item :label="$t('AddOrder.SalesRep')" prop="businessName" label-width="110px" clearable :rules="[
                    {
                      required: true,
                      trigger: 'change',
                      message: $t('placeholder.plsSel'),
                    },
                  ]"></el-form-item>
              </el-col>

              <el-col :span="6">
                <!--订单来源  -->
                <el-form-item :label="$t('AddOrder.OrderFrom')" prop="orderSource" label-width="100px" clearable>
                  <el-select v-model="form.orderSourceId" @change="orderSourceChange"
                    :placeholder="$t('placeholder.plsSel')" clearable>
                    <el-option v-for="item in orderSourceList" :key="item.amountId" :label="item.amountName"
                      :value="item.amountId" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-divider></el-divider>

            <h4>
              <!-- 客户信息 -->
              {{ $t('AddOrder.CustomerInfo') }}
              <span class="red ml10" v-show="refundNum > 0">
                {{
                lang === 'en'
                ? `Customer's order history includes a total of ${refundNum} refund. Please communicate thoroughly and
                increase our service efforts!`
                : `当前客户历史一共有${refundNum}单退款，请仔细沟通和加大服务力度！`
                }}
              </span>
            </h4>
            <table class="custom-table">
              <thead>
                <tr>
                  <th width="200">
                    <span>
                      <i>*</i>
                      <!-- 客户邮箱 -->
                      {{ $t('AddOrder.CustomerEmail') }}
                    </span>
                  </th>
                  <th width="200">
                    <span>
                      <i>*</i>
                      <!-- 客户姓名 -->
                      {{ $t('AddOrder.CustomerName') }}
                    </span>
                  </th>
                  <th width="300">
                    <span>
                      <i>*</i>
                      <!-- 公司名称 -->
                      {{ $t('AddOrder.CompanyName') }}
                    </span>
                  </th>
                  <th width="200">
                    <span>
                      <i>*</i>
                      <!-- 联系人 -->
                      {{ $t('AddOrder.Contacts') }}
                    </span>
                  </th>
                  <th width="200">
                    <span>
                      <i>*</i>
                      <!-- 联系电话 -->
                      {{ $t('AddOrder.ContactNumber') }}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <el-form-item prop="customerEmail" :rules="[
                        {
                          required: true,
                          trigger: 'change',
                          message: $t('placeholder.plsInput'),
                        },
                        {
                          message: $t('placeholder.formatError'),
                          type: 'email',
                          trigger: 'blur',
                        },
                      ]">
                      <el-autocomplete v-model.trim="form.customerEmail" :disabled="canEdit || isCustomerDisabled"
                        :fetch-suggestions="querySearchAsyncEmail" :placeholder="$t('placeholder.plsInput')"
                        @select="handleSelect" @blur="customerEmailBlackList(form.customerEmail, 2)"
                        clearable></el-autocomplete>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item prop="customerName" :rules="[
                        {
                          required: true,
                          trigger: 'blur',
                          message: $t('placeholder.plsInput'),
                        },
                      ]">
                      <el-input v-model.trim="form.customerName" :disabled="canEdit || clientDisabled"
                        :placeholder="$t('placeholder.plsInput')" clearable maxlength="50"
                        @blur="removeSpace('customerName', form.customerName)"></el-input>
                    </el-form-item>
                  </td>
                  <td>
                    <div class="company-name">
                      <el-form-item prop="companyName" :rules="[
                          {
                            required: true,
                            trigger: 'blur',
                            message: $t('placeholder.plsInput'),
                          },
                        ]">
                        <el-input v-model.trim="form.companyName" :disabled="canEdit || clientDisabled"
                          @blur="companyNameBlackList(form.companyName)" :placeholder="$t('placeholder.plsInput')"
                          clearable type="textarea" maxlength="500" show-word-limit></el-input>
                      </el-form-item>

                      <!-- 公司名称模糊匹配数据 -->
                      <div class="list" v-if="isCompanyNameList">
                        <p class="mb10">
                          {{
                          lang === 'en'
                          ? 'The system searches for companies you may be looking for based on the entered email
                          address'
                          : '系统根据录入的邮箱检索你可能要找的公司'
                          }}
                          <i class="el-icon-close" @click="isCompanyNameList = false"></i>
                        </p>
                        <ul>
                          <li v-for="(item, i) in companyNameList" :key="i" @click="selectCompanyName(item)"
                            :class="{ red: item.companyStatus === 2 }">
                            <el-tooltip :content="item.companyName" placement="top">
                              <span class="ell text-left">
                                {{ item.companyName }}
                              </span>
                            </el-tooltip>
                            <span class="ell text-right">
                              {{ item.email }}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                  <td>
                    <el-form-item prop="linkman" :rules="[
                        {
                          required: true,
                          trigger: 'blur',
                          message: $t('placeholder.plsInput'),
                        },
                      ]">
                      <el-input v-model.trim="form.linkman" @blur="removeSpace('linkman', form.linkman)"
                        :disabled="canEdit || clientDisabled" :placeholder="$t('placeholder.plsInput')"
                        clearable></el-input>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item prop="customerPhone" :rules="[
                        {
                          required: true,
                          trigger: 'blur',
                          message: $t('placeholder.plsInput'),
                        },
                      ]">
                      <el-input v-model.trim="form.customerPhone"
                        @blur="removeSpace('customerPhone', form.customerPhone)" :disabled="canEdit || clientDisabled"
                        :placeholder="$t('placeholder.plsInput')" maxlength="50" clearable></el-input>
                    </el-form-item>
                  </td>
                </tr>
              </tbody>
            </table>

            <el-divider></el-divider>

            <div class="product-list">
              <div class="flex-btn mt20">
                <!-- 产品列表 -->
                <h4>{{ $t('AddOrder.ProductList') }}</h4>
                <el-button type="primary" @click="productListVisible = true">
                  <!-- 选择产品 -->
                  {{ $t('AddOrder.SelectProduct') }}
                </el-button>
                <!-- 新增产品 -->
                <el-button @click="addProduct()">
                  {{ $t('AddOrder.CreateProduct') }}
                </el-button>
              </div>
              <el-table class="no-space product-table mt15" :data="form.orderProductDTOS" border :key="tablekey"
                max-height="580px" :header-cell-class-name="star">
                <!-- 序号 -->
                <el-table-column align="center" width="55" :label="$t('AddOrder.No')" fixed="left">
                  <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <!-- 产品图 -->
                <el-table-column align="center" :width="lang === 'en' ? 140 : 120" :label="$t('AddOrder.ProductImage')"
                  fixed="left">
                  <template slot-scope="scope">
                    <div class="tag-wrap">
                      <el-tag size="mini">
                        {{ getLangProductType(scope.row.standardCustomized) }}
                      </el-tag>
                      <el-image v-if="scope.row.imgMain" :src="scope.row.imgMain" class="c_pointer"></el-image>
                      <span v-else>--</span>
                    </div>
                  </template>
                </el-table-column>
                <!-- 产品信息 -->
                <el-table-column align="center" width="240" :label="$t('AddOrder.ProductInfo')" fixed="left">
                  <template slot-scope="scope">
                    <div class="text-sty">
                      <!-- 产品编码-产品名称-规格-产品运营 -->
                      <p>
                        {{ $t('orderDetails.ProductCode') }}：{{
                        scope.row.sku
                        }}
                      </p>
                      <el-tooltip class="item" effect="dark" :content="scope.row.productEn" placement="top-start">
                        <p class="ell">
                          {{ $t('orderDetails.ProductName') }}：{{
                          scope.row.productEn
                          }}
                        </p>
                      </el-tooltip>
                      <p class="ell">
                        {{ $t('AddOrder.Specification') }}：{{
                        scope.row.specification
                        }}
                      </p>
                      <p>
                        {{ $t('orderDetails.ProductOperations') }}：{{
                        lang === 'en'
                        ? scope.row.productBelongerEn || '--'
                        : scope.row.productBelonger || '--'
                        }}
                      </p>
                    </div>
                  </template>
                </el-table-column>
                <!-- 规格 -->
                <el-table-column align="center" width="180" :label="$t('AddOrder.Specification')" prop="skuId">
                  <template slot="header">
                    <span style="color: red">*</span>
                    <span>{{ $t('AddOrder.Specification') }}</span>
                  </template>
                  <template slot-scope="scope">
                    <el-form-item v-if="scope.row.specificationList.length"
                      :prop="'orderProductDTOS.' + [scope.$index] + '.skuId'" :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsSel'),
                          trigger: 'change',
                        },
                      ]">
                      <el-select :placeholder="$t('placeholder.plsSel')"
                        v-model="form.orderProductDTOS[scope.$index].skuId" :disabled="
                          scope.row.deleteFlag === 0 || orderAuditPass > 0
                        " @change="
                          specificationChange(scope.row.skuId, scope.$index)
                        ">
                        <el-option v-for="item in scope.row.specificationList" :key="item.id" :label="item.label"
                          :value="item.id" />
                      </el-select>
                    </el-form-item>

                    <span v-else>--</span>
                  </template>
                </el-table-column>
                <!-- 库存 -->
                <el-table-column align="center" width="200" :label="$t('AddOrder.Stock')">
                  <template slot-scope="scope">
                    <div v-if="lang === 'en'">
                      <div v-if="scope.row.warehouseNumberEn">
                        <p v-for="(item, i) in scope.row.warehouseNumberEn.split(
                            ';'
                          )" :key="i">
                          {{ item }}
                        </p>
                      </div>
                      <div v-else>--</div>
                    </div>
                    <div v-else>
                      <div v-if="scope.row.warehouseNumber">
                        <p v-for="(item, i) in scope.row.warehouseNumber.split(
                            ';'
                          )" :key="i">
                          {{ item }}
                        </p>
                      </div>
                      <div v-else>--</div>
                    </div>
                  </template>
                </el-table-column>
                <!-- 数量 -->
                <el-table-column align="center" prop="amount" width="120" :label="$t('AddOrder.Quantity')">
                  <template slot-scope="scope">
                    <el-form-item :prop="'orderProductDTOS.' + [scope.$index] + '.amount'" :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsInput'),
                          trigger: 'blur',
                        },
                        {
                          pattern: /^[1-9]\d*$/,
                          message: $t('reqmsg.M2034'),
                          trigger: 'blur',
                        },
                        {
                          trigger: 'blur',
                          validator: (rule, value, callback) => {
                            const useAmount =
                              form.orderProductDTOS[scope.$index].useAmount

                            if (!useAmount) {
                              callback()
                            } else {
                              if (value < useAmount) {
                                callback(
                                  new Error(
                                    lang === 'en'
                                      ? 'Quantity of products must >= Quantity of goods out/shipped'
                                      : '产品数量必须>=出库/出运数量'
                                  )
                                )
                              } else {
                                callback()
                              }
                            }
                          },
                        },
                      ]">
                      <el-input maxlength="10" v-model="form.orderProductDTOS[scope.$index].amount"
                        @input="calculateTotal(scope.$index)" @blur="
                          amountBlur(
                            form.orderProductDTOS[scope.$index].amount,
                            form.orderProductDTOS[scope.$index].productId,
                            form.orderProductDTOS[scope.$index].sku,
                            scope.$index
                          )
                        " :placeholder="$t('placeholder.plsInput')" clearable></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <!-- 售价 -->
                <el-table-column align="center" prop="netPrice" width="120" :label="$t('AddOrder.UnitPrice')">
                  <template slot-scope="scope">
                    <el-form-item :prop="'orderProductDTOS.' + [scope.$index] + '.netPrice'" :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsInput'),
                          trigger: 'blur, change',
                        },
                        {
                          pattern: /^\d+(\.\d{1,3})?$/,
                          message: $t('placeholder.decimals'),
                          trigger: 'blur',
                        },
                      ]">
                      <el-input maxlength="10" v-model="form.orderProductDTOS[scope.$index].netPrice"
                        @input="calculateTotal(scope.$index)" :placeholder="$t('placeholder.plsInput')"
                        clearable></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <!-- 印刷效果图 -->
                <el-table-column align="center" prop="rendingsUrl" width="150" :label="$t('AddOrder.PrintingProof')">
                  <template slot-scope="scope">
                    <div class="wrapper-box" @click="
                        choseRendingImages(
                          scope.$index,
                          scope.row.rendingsUrl,
                          scope.row.rendingsStatus
                        )
                      ">
                      <div class="wrapper-image" v-show="scope.row.rendingsUrl">
                        <img :src="getFirstImgUrl(scope.row.rendingsUrl)" width="80%" alt />
                      </div>
                      <div class="wrapper-add" v-show="!scope.row.rendingsUrl">
                        <i class="el-icon-plus"></i>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <!-- 印刷方式 -->
                <el-table-column align="center" prop="printingWay" width="150" :label="$t('AddOrder.ImprintMethod')">
                  <template slot-scope="scope">
                    <el-form-item :prop="
                        'orderProductDTOS.' + [scope.$index] + '.printingWayEn'
                      " :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsSel'),
                          trigger: 'change',
                        },
                      ]">
                      <el-select v-if="
                          form.orderProductDTOS[scope.$index]
                            .printingWaySelect &&
                          form.orderProductDTOS[scope.$index].printingWaySelect
                            .length
                        " @change="
                          printingWayChange(
                            form.orderProductDTOS[scope.$index]
                              .standardCustomized,
                            form.orderProductDTOS[scope.$index].printingWayEn,
                            scope.$index
                          )
                        " v-model="
                          form.orderProductDTOS[scope.$index].printingWayEn
                        " :placeholder="$t('placeholder.plsSel')">
                        <el-option v-for="(item, index) in form.orderProductDTOS[
                            scope.$index
                          ].printingWaySelect" :key="item.value + index" :label="item.labelEn"
                          :value="item.labelEn"></el-option>
                      </el-select>
                      <el-row v-else>
                        <el-input v-model="
                            form.orderProductDTOS[scope.$index].printingWayEn
                          " @blur="
                            form.orderProductDTOS[scope.$index].printingWay =
                              form.orderProductDTOS[scope.$index].printingWayEn
                          " :placeholder="$t('placeholder.plsInput')"></el-input>
                      </el-row>
                    </el-form-item>
                  </template>
                </el-table-column>
                <!-- 产品描述 -->
                <el-table-column align="center" prop="productDescription" :label="$t('AddOrder.ProductDescription')"
                  width="200">
                  <template slot-scope="scope">
                    <el-input type="textarea" maxlength="500" show-word-limit :rows="3" v-model="
                        form.orderProductDTOS[scope.$index].productDescription
                      " :placeholder="$t('placeholder.plsInput')" clearable></el-input>
                  </template>
                </el-table-column>
                <!-- 印刷描述 -->
                <el-table-column align="center" prop="printingEffect" :label="$t('AddOrder.ImprintDescription')"
                  width="200">
                  <template slot-scope="scope">
                    <el-input type="textarea" maxlength="500" show-word-limit :rows="3" v-model="
                        form.orderProductDTOS[scope.$index].printingEffect
                      " :placeholder="$t('placeholder.plsInput')" clearable></el-input>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="setUpCharge" width="130" label="Setup Charge($)">
                  <template slot-scope="scope">
                    <el-form-item :prop="
                        'orderProductDTOS.' + [scope.$index] + '.setUpCharge'
                      " :rules="[
                        {
                          pattern: /^\d+(\.\d{1,3})?$/,
                          message: $t('placeholder.decimals'),
                          trigger: 'blur',
                        },
                      ]">
                      <el-input maxlength="10" v-model="
                          form.orderProductDTOS[scope.$index].setUpCharge
                        " @input="calculateTotal(scope.$index)" :placeholder="$t('placeholder.plsInput')"
                        clearable></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <!-- 印刷文件 -->
                <el-table-column align="center" width="400" prop="printedDocumentsUrl" :label="$t('AddOrder.ArtFile')"
                  show-overflow-tooltip>
                  <template slot-scope="scope"></template>
                </el-table-column>
                <el-table-column align="center" prop="shippingFee" width="150" label="Shipping Fee">
                  <template slot-scope="scope">
                    <el-form-item :prop="
                        'orderProductDTOS.' + [scope.$index] + '.shippingFee'
                      " :rules="[
                        {
                          pattern: /^\d+(\.\d{1,3})?$/,
                          message: $t('placeholder.decimals'),
                          trigger: 'blur',
                        },
                      ]">
                      <el-input maxlength="10" @input="calculateTotal(scope.$index)" v-model="
                          form.orderProductDTOS[scope.$index].shippingFee
                        " :placeholder="$t('placeholder.plsInput')" clearable></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <!-- 其他费用 -->
                <el-table-column align="center" prop="otherCharge" width="150" :label="$t('AddOrder.OtherFees')">
                  <template slot-scope="scope">
                    <el-form-item :prop="
                        'orderProductDTOS.' + [scope.$index] + '.otherCharge'
                      " :rules="[
                        {
                          pattern: /^\d+(\.\d{1,3})?$/,
                          message: $t('placeholder.decimals'),
                          trigger: 'blur',
                        },
                      ]">
                      <el-input maxlength="10" @input="calculateTotal(scope.$index)" v-model="
                          form.orderProductDTOS[scope.$index].otherCharge
                        " :placeholder="$t('placeholder.plsInput')" clearable></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <!-- 费用说明 -->
                <el-table-column align="center" prop="chargeFees" width="200" :label="$t('AddOrder.FeeExplanation')">
                  <template slot-scope="scope">
                    <el-form-item>
                      <el-input type="textarea" maxlength="500" show-word-limit :rows="3"
                        v-model="form.orderProductDTOS[scope.$index].chargeFees"
                        :placeholder="$t('placeholder.plsInput')" clearable></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <!-- 备注 -->
                <el-table-column align="center" prop="remark" :label="$t('AddOrder.Remark')" width="200"
                  show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-form-item>
                      <el-input type="textarea" maxlength="500" show-word-limit :rows="3"
                        v-model="form.orderProductDTOS[scope.$index].remark" :placeholder="$t('placeholder.plsInput')"
                        clearable></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <!-- 客户折扣 -->
                <el-table-column align="center" prop="discountAmount" :label="$t('AddOrder.Discount')" width="150">
                  <template slot-scope="scope">
                    <div class="discount-sty">
                      <el-button :class="
                          form.orderProductDTOS[scope.$index].discountReason
                            ? 'icon-b'
                            : 'icon-y'
                        " icon="el-icon-edit-outline" circle></el-button>
                      <el-form-item :prop="
                          'orderProductDTOS.' +
                          [scope.$index] +
                          '.discountAmount'
                        " :rules="[
                          {
                            pattern: /^\d+(\.\d{1,3})?$/,
                            message: $t('placeholder.decimals'),
                            trigger: 'blur',
                          },
                        ]">
                        <el-input maxlength="10" @input="calculateTotal(scope.$index)" v-model="
                            form.orderProductDTOS[scope.$index].discountAmount
                          " :placeholder="$t('placeholder.plsInput')" clearable></el-input>
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>
                <!-- 订单支持 -->
                <el-table-column align="center" :label="$t('AddOrder.OrderAssistant')" width="180" fixed="right"
                  label-class-name="required-th">
                  <template slot-scope="scope">
                    <el-form-item :prop="
                        'orderProductDTOS.' + [scope.$index] + '.fromBusinessId'
                      " :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsSel'),
                          trigger: 'change',
                        },
                      ]"></el-form-item>
                  </template>
                </el-table-column>
                <!-- 总计 -->
                <el-table-column align="center" :label="$t('AddOrder.Total')" width="150" fixed="right">
                  <template slot-scope="scope">
                    <div>
                      <span v-if="scope.row.usTotle">
                        ￥{{
                        parseFloat(scope.row.usTotle * exchangeRate).toFixed(
                        3
                        )
                        }}
                      </span>
                      <span v-else>￥0</span>
                    </div>
                    <div>
                      <span v-if="scope.row.usTotle">
                        ${{ parseFloat(scope.row.usTotle).toFixed(3) }}
                      </span>
                      <span v-else>$ 0</span>
                    </div>
                  </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column align="center" :width="lang === 'en' ? 100 : 55" :label="$t('AddOrder.Operation')"
                  fixed="right">
                  <template slot-scope="scope">
                    <el-button class="icon-r" @click="delProduct(scope.row, scope.$index)" size="mini"
                      icon="el-icon-delete" circle></el-button>
                  </template>
                </el-table-column>
              </el-table>

              <div class="total-money" v-if="form.orderProductDTOS.length">
                <!-- 总数量 -->
                {{ $t('AddOrder.TotalQuantity') }}
                <span class="mr20">{{ totalNum }}</span>
                <!-- 总折扣 -->
                {{ $t('AddOrder.TotalDiscount') }}
                <span class="mr20">
                  {{ totalDiscount > 0 ? '-' : '' }}
                  {{ totalDiscount }}
                </span>
                <!-- 产品总金额 -->
                {{ $t('AddOrder.OrderAmount') }}
                <span class="red">${{ totalMoney }}</span>
              </div>
              <div class="deduction">
                <!-- 客户抵扣金额 -->
                <el-form-item :label="$t('AddOrder.OffsetAmount')" label-width="110px">
                  <!-- 点击选择 -->
                  <el-input :placeholder="$t('AddOrder.ClickToSelect')" readonly v-model="form.usMoney"
                    @click.native="getDeduction"></el-input>
                </el-form-item>
                <div class="total-deduction">
                  <!-- 订单总金额 $ -->
                  {{ $t('AddOrder.OrderTotalAmount') }} $
                  <span class="red f_s_18">{{ totalMoney | formatPrice }}</span>
                </div>
              </div>
            </div>
          </div>

          <el-row :gutter="24">
            <el-col :span="24">
              <div class="mt10 mb15">
                <!-- 货物分配方式 -->
                <el-form-item :label="$t('AddOrder.ModeOfCargoDistribution')" prop="quantType"
                  v-if="form.receivingAddressDTOS.length > 1" :rules="[
                    {
                      required: true,
                      trigger: 'change',
                      message: $t('placeholder.plsSel'),
                    },
                  ]">
                  <el-radio v-model="form.quantType" @change="quantTypeChange" :label="1">
                    <!-- 平均分配 -->
                    {{ $t('AddOrder.EqualDistribution') }}
                  </el-radio>
                  <el-radio v-model="form.quantType" @change="quantTypeChange" :label="2">
                    <!-- 自行分配 -->
                    {{ $t('AddOrder.SelfDistribution') }}
                  </el-radio>
                  <!-- 绑定产品 -->
                  <el-tooltip v-show="form.quantType == 2" effect="dark" :content="$t('AddOrder.BindingProduct')"
                    placement="top">
                    <i @click="bindProductShow" class="iconfont icon-fenpei"></i>
                  </el-tooltip>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="6">
              <!-- 货运方式 -->
              <el-form-item :label="$t('AddOrder.ShippingMethods')" prop="shippingMethodId"
                :label-width="lang === 'en' ? '140px' : '80px'" :rules="[
                  {
                    required: true,
                    trigger: 'change',
                    message: $t('placeholder.plsSel'),
                  },
                ]">
                <el-select v-model="form.shippingMethodId" :placeholder="$t('placeholder.plsSel')" clearable
                  value-key="id" @change="changeShipMethod">
                  <el-option v-for="item in shippingMethodList" :key="item.id" :label="item.transportWayCn"
                    :value="item.id" />
                </el-select>
              </el-form-item>
            </el-col>
            <!-- 装运日期 -->
            <el-col :span="6">
              <el-form-item :label="$t('AddOrder.EstimatedShipOutDate')" prop="shippingDate"
                :label-width="lang === 'en' ? '180px' : '90px'" clearable :rules="[
                  {
                    required: true,
                    trigger: 'change',
                    message: $t('placeholder.plsSel'),
                  },
                ]">
                <el-date-picker v-model="form.shippingDate" type="date" :placeholder="$t('placeholder.selDate')"
                  value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
            </el-col>
            <!-- 交货日期 -->
            <el-col :span="6">
              <el-form-item :label="$t('AddOrder.EstimatedDeliveryDate')" prop="deliveryDate"
                :label-width="lang === 'en' ? '180px' : '90px'" clearable :rules="[
                  {
                    required: true,
                    trigger: 'change',
                    message: $t('placeholder.plsSel'),
                  },
                ]">
                <el-date-picker v-model="form.deliveryDate" type="date" :placeholder="$t('placeholder.selDate')"
                  value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="mt15">
            <!-- 装运港 -->
            <el-col :span="6">
              <el-form-item :label="$t('AddOrder.LoadingPort')" :label-width="lang === 'en' ? '140px' : '80px'"
                clearable>
                <el-input :maxlength="50" v-model="form.portOfLoading"
                  :placeholder="$t('placeholder.plsInput')"></el-input>
              </el-form-item>
            </el-col>
            <!-- 目的港 -->
            <el-col :span="6">
              <el-form-item :label="$t('AddOrder.DestinationPort')" :label-width="lang === 'en' ? '180px' : '90px'"
                clearable>
                <el-input :maxlength="50" v-model="form.destination"
                  :placeholder="$t('placeholder.plsInput')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="c-table-wrap mt15">
            <table class="custom-table">
              <thead>
                <tr>
                  <!-- 序号 -->
                  <th class="index">{{ $t('AddOrder.No') }}</th>
                  <!-- 收货地址 -->
                  <th width="500">
                    <span>
                      <i>*</i>
                      {{ $t('AddOrder.ShippingAddress') }}
                    </span>
                  </th>
                  <!-- 收货公司 -->
                  <th width="300">{{ $t('AddOrder.Company') }}</th>
                  <!-- 收货人 -->
                  <th width="200">{{ $t('AddOrder.Consignee') }}</th>
                  <!-- 联系电话 -->
                  <th width="200">{{ $t('AddOrder.ContactPhone') }}</th>
                  <!-- 操作 -->
                  <th class="operate">{{ $t('AddOrder.Operation') }}</th>
                </tr>
              </thead>
              <tbody v-if="form.receivingAddressDTOS.length">
                <tr v-for="(item, i) in form.receivingAddressDTOS" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>
                    <el-form-item :prop="'receivingAddressDTOS.' + i + '.address'" :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsSel'),
                          trigger: 'change',
                        },
                      ]">
                      <el-select v-model="form.receivingAddressDTOS[i].address" :placeholder="$t('placeholder.plsSel')"
                        @change="addressChange($event, i)" clearable :disabled="item.updateFlag === 0" filterable>
                        <el-option v-for="item in form.receivingAddressConfigDTOS" :key="item.addressConfigId"
                          :label="item.address" :value="item.addressConfigId" :disabled="item.disabled"></el-option>
                      </el-select>
                    </el-form-item>
                  </td>
                  <td>{{ item.companyName }}</td>
                  <td>{{ item.consigneeName }}</td>
                  <td>{{ item.consigneePhone }}</td>
                  <td>
                    <el-button v-show="form.receivingAddressDTOS.length != 1" class="icon-r"
                      @click="delSelectAddress(i, item.deleteFlag)" size="mini" icon="el-icon-delete"
                      circle></el-button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr class="empty">
                  <!-- 暂无数据 -->
                  <td colspan="6">{{ $t('other.noData') }}</td>
                </tr>
              </tbody>
            </table>
            <div class="address-btns">
              <!-- 添加一行 -->
              <span @click="addRow">{{ $t('AddOrder.AddMore') }}</span>
              <!-- 新增地址 -->
              <span @click="addAddress">
                {{ $t('AddOrder.CreateAddress') }}
              </span>
            </div>
          </div>
          <div v-show="tipVisible" class="tip">{{ $t('orderDetails.Tip') }}</div>

          <!-- 收款方式 -->
          <h4 v-if="showInfo">{{ $t('AddOrder.CreateAddress') }}</h4>
          <el-row class="deduction" v-if="showInfo">
            <el-col :span="6">
              <!-- 订单币种 -->
              <el-form-item prop="currency" :label="$t('AddOrder.Currency')" label-width="80px" class="mr20" :rules="[
                  {
                    required: true,
                    trigger: 'change',
                    message: $t('placeholder.plsSel'),
                  },
                ]">
                <el-select :disabled="canEdit" v-model="form.currency" :placeholder="$t('placeholder.plsSel')" clearable
                  @change="currencyChange">
                  <el-option v-for="(c, index) in currencyList" :label="lang === 'en' ? c.code : c.financeName"
                    :value="c.financeName" :key="index"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!-- 收款方式 -->
              <el-form-item prop="payId" :label="$t('AddOrder.PaymentTerms')" label-width="125px" :rules="[
                  {
                    required: true,
                    trigger: 'change',
                    message: $t('placeholder.plsSel'),
                  },
                ]">
                <el-select v-model="form.payId" :placeholder="$t('placeholder.plsSel')" clearable @change="payMethod">
                  <el-option v-for="item in moneyAreaList" :key="item.paymentId" :label="item.payway"
                    :value="item.paymentId" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="50" v-if="showInfo">
            <el-col :span="12">
              <!-- 客户备注 -->
              <p class="mt20 mb10">{{ $t('AddOrder.CustomerRemark') }}</p>
              <el-input type="textarea" v-model="form.customerRemark" maxlength="500" show-word-limit :rows="3"
                :placeholder="$t('placeholder.plsInput')"></el-input>
            </el-col>
            <el-col :span="12">
              <!-- 内部备注 -->
              <p class="mt20 mb10">{{ $t('AddOrder.InternalRemark') }}</p>
              <el-input type="textarea" v-model="form.remark" maxlength="500" show-word-limit :rows="3"
                :placeholder="$t('placeholder.plsInput')"></el-input>
            </el-col>
          </el-row>

          <!-- 附件 -->
          <p class="mt20 accessory" v-if="showInfo">
            {{ $t('AddOrder.Attachment') }}
          </p>
        </el-form>
      </div>

      <div class="oper-btn">
        <!-- 确认 -->
        <el-button @click="submit()" type="primary" :loading="submitLoading">
          <!-- {{ $t('AddOrder.Confirm') }} -->
          {{ $t('AddOrder.Save') }}
        </el-button>
        <!-- 保存 -->
        <!-- <el-button @click="save()" type="primary" :loading="saveLoading">
          {{ $t('AddOrder.Save') }}
        </el-button> -->
        <!-- 取消 -->
        <el-button @click="goBack()">
          {{ $t('AddOrder.Cancel') }}
        </el-button>
      </div>
      <div style="margin: 10px">
        提示：本次修改无审核，但是会记录日志和钉钉消息提醒。
      </div>

      <!-- 新增收货地址 -->
      <addressAdd ref="address" :addressList="form.receivingAddressConfigDTOS" @addressConfigSave="addressConfigSave">
      </addressAdd>

      <!-- 选取产品 -->
      <el-dialog width="85%" :title="$t('AddOrder.SelectProduct')" :visible.sync="productListVisible"
        :close-on-click-modal="false">
        <product-list v-if="productListVisible" :requestType="true" @getProductData="getProductData"
          @productClose="productListVisible = false"></product-list>
      </el-dialog>

      <!-- 绑定产品 -->
      <el-dialog width="1200px" :title="$t('AddOrder.BindingProduct')" v-if="bindProductVisible"
        :visible.sync="bindProductVisible" :close-on-click-modal="false" :append-to-body="true"
        :modal-append-to-body="true">
        <el-form :model="productForm" ref="productForm">
          <div v-for="(item, i) in form.receivingAddressDTOS" :key="i">
            <table class="custom-table no-space">
              <thead>
                <!-- 收货地址 -->
                <th>{{ $t('AddOrder.ShippingAddress') }}</th>
                <!-- 收货公司 -->
                <th>{{ $t('AddOrder.Company') }}</th>
                <!-- 收货人 -->
                <th width="200">{{ $t('AddOrder.Consignee') }}</th>
                <!-- 联系电话 -->
                <th width="200">{{ $t('AddOrder.ContactPhone') }}</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <el-tooltip :content="item.address">
                      <div class="ell" style="width: 450px">
                        {{ item.address }}
                      </div>
                    </el-tooltip>
                  </td>
                  <td>
                    <el-tooltip :content="item.companyName">
                      <div class="ell" style="width: 300px">
                        {{ item.companyName }}
                      </div>
                    </el-tooltip>
                  </td>
                  <td>{{ item.consigneeName }}</td>
                  <td>{{ item.consigneePhone }}</td>
                </tr>
              </tbody>
            </table>
            <table class="custom-table no-space mb10">
              <thead>
                <th width="600">Item</th>
                <!-- 订单数量 -->
                <th width="100">{{ $t('AddOrder.OrderQuantity') }}</th>
                <!-- 印刷方式 -->
                <th width="200">{{ $t('AddOrder.ImprintMethod') }}</th>
                <!-- 售价 -->
                <th width="100">{{ $t('AddOrder.UnitPrice') }}</th>
                <th width="100">
                  <span>
                    <i>*</i>
                    <!-- 分配数量 -->
                    {{ $t('AddOrder.QuantityAllotted') }}
                  </span>
                </th>
              </thead>
              <tbody>
                <tr v-for="(obj, k) in form.orderProductDTOS" :key="k">
                  <td>
                    <div>
                      <span v-show="obj.productCode">
                        {{ obj.productCode }}
                      </span>
                      <span v-show="obj.productEn">/{{ obj.productEn }}</span>
                      <span v-show="obj.specification">
                        /{{ obj.specification }}
                      </span>
                    </div>
                  </td>
                  <td>{{ obj.amount }}</td>
                  <td>{{ obj.printingWayEn || obj.printingWay || '--' }}</td>
                  <td>{{ obj.netPrice }}</td>
                  <td>
                    <el-form-item :prop="
                        'cargoList.' +
                        [k + i * form.orderProductDTOS.length] +
                        '.quantTotle'
                      " :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsInput'),
                          trigger: 'blur',
                        },
                        {
                          pattern: /^[0-9]+?$/,
                          message: $t('placeholder.M2026'),
                          trigger: 'blur',
                        },
                        {
                          validator: (rule, value, callback) => {
                            callback()
                          },
                        },
                      ]">
                      <el-input maxlength="10" @input="verifyQuantTotle(i, k)" v-model="
                          productForm.cargoList[
                            k + i * form.orderProductDTOS.length
                          ].quantTotle
                        " :placeholder="$t('placeholder.plsInput')" clearable></el-input>
                    </el-form-item>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </el-form>
        <div class="text-center mt20">
          <!-- 确认 -->
          <el-button type="primary" @click="bindProduct">
            {{ $t('AddOrder.Confirm') }}
          </el-button>
          <!-- 取消 -->
          <el-button @click="bindProductCancel">
            {{ $t('AddOrder.Cancel') }}
          </el-button>
        </div>
      </el-dialog>

      <!-- 审核模板-->

      <!-- 选择印刷效果图 -->
      <choose-images :isEdit="isEdit" :visible.sync="chooseImagesDialogVisible"
        :default-file-list="currentDefaultRendingList" :default-rendings-status.sync="currentDefaultRendingStatus"
        @confirm="setRendingImages"></choose-images>
    </div>
  </el-dialog>
</template>

<script>
  import {
    orderListOrderPriceSheetCode,
    orderShipType,
    orderGetDict,
    selectAllCustomer,
    orderSalesInsertPO,
    orderGetDetail,
    orderCloneDetail,
    orderSaleOrderByPriceSheetCode,
    merchantsAddressConfigListPage,
    salesOrderSave,
    enquiryProduceOrder,
    checkDeleteOrderProduct,
    checkOrderStockLock,
    checkBlackReceivingCompanyAndAddress,
  } from '@/api/order'
  import { getDetailForEditShow } from '@/api/client-supplier/client-manage'
  import { getStepByProductId } from '@/api/stock-center/shipment'
  import UserSelect from '@/components/user-select'
  import userSelectMixin from '@/components/user-select/mixin'

  import addressAdd from './components/addressAdd'
  import productList from '@/components/productList'
  import ChooseImages from './components/chooseImages'
  import printContract from '@/views/print/orderContract.vue'
  import fileUpload from './components/fileUpload'
  import { mapGetters } from 'vuex'
  import { deepCopy } from 'kits'
  import { clientOrderInstance } from '@/core/interactors/client-supplier/client-order'
  import { isEmail } from '@/utils/validate'
  import { orderLogFields, collectLogFields } from '@/utils/log-utils'
  export default {
    name: 'order-add',
    mixins: [userSelectMixin],
    components: {
      UserSelect,
      addressAdd,
      productList,
      ChooseImages,
      fileUpload,
      printContract,
    },
    data() {
      return {
        Error: Error,
        tablekey: Math.random(),
        orderVersion: '',
        form: {
          /**
           * 基本信息
           */
          priceSheetCode: '', //报价单号
          contractDate: !this.$route.query.orderId
            ? this.utils.timeFormat(new Date())
            : '', //签约日期
          exportsRise: '', //出口抬头
          exportsRiseId: '', //出口抬头id
          orderSource: '', //订单来源
          orderSourceId: '', //订单来源id
          businessId: '', //客户代表id
          businessName: '', //客户代表Name
          businessNameEn: '', //客户代表英文Name
          fromBusinessId: '', //订单支持id
          fromBusinessName: '', //订单支持名称
          fromBusinessNameEn: '', //订单支持英文名称
          customerOrderCode: '', //客户订单号
          /**
           * 客户信息
           */
          infoId: '', // 客户信息id
          customerName: '', //客户姓名
          customerCode: '', //客户编码
          customerEmail: '', //客户邮箱
          companyName: '', //公司名称
          linkman: '', //联系人
          customerPhone: '', //联系电话
          shippingMethod: '', //货运方式
          shippingMethodId: '', //货运方式
          shippingMethodType: '', //货运方式
          deliveryDate: '', //交货日期
          shippingDate: '', //装运日期
          companyAddress: '', //公司地址
          /**
           * 收款方式
           */
          payId: '', //金额区间id
          amountInterval: '', //支付区间
          paymentProportion: '', //支付比列
          currency: '美元', //币种
          usMoney: '', //抵扣金额
          rmbMoney: '', //抵扣人民币
          discountRemark: '', //抵扣说明
          abdDTOS: [], //抵扣ID和金额
          customerRemark: '', //客户备注
          remark: '', //内部备注
          /**
           * 产品集合
           */
          quantType: 1,
          orderProductDTOS: [],
          /**
           * 收货地址配置集合
           */
          receivingAddressConfigDTOS: [],
          /**
           * 收货地址集合
           */
          receivingAddressDTOS: [],
          /**
           * 货物分配集合
           */
          cargoDistributionDTOS: [],
          /**
           * 审核模板
           */
          auditObjectDTO: {},
          /**
           * 附件
           */
          accessoryUrl: '',
          portOfLoading: '', //装运港
          destination: '', //目的港
          updateRemark: '', //修改原因
          loading: false,
        },
        productForm: {
          cargoList: [],
        },
        enquiryId: '', // 询盘id
        totalNum: 0, //总数量
        totalMoney: 0, //总金额
        totalDiscount: 0, //总折扣
        refundProductAmount: 0, //退款金额
        exchangeRate: 1, // 汇率
        orderAuditPass: 0, // >0不能编辑规格
        isEdit: false,
        isClone: false,
        addressInfo: {},
        priceSheetList: [],
        shippingMethodList: [],
        moneyAreaList: [],
        moneyAreaListAll: [],
        orderId: '',
        orderCode: '',
        businessType: '',
        businessArray: [],
        formBusinessArray: [],
        exportsRiseList: [],
        orderSourceList: [],
        customResult: [], // 客户信息集合
        currencyList: [], // 币种
        productList: {},
        productListVisible: false,
        clientDisabled: false,
        bindProductVisible: false,
        codeId: false,
        rules: {
          usMoney: [
            {
              required: false,
              message: '抵扣金额格式不正确',
              pattern: /^\d+(\.\d{1,3})?$/,
              trigger: 'change',
            },
          ],
        },
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        contractVisoble: false,
        chooseImagesDialogVisible: false, // 选择印刷效果图弹窗
        currentRendingIndex: -1, // 当前选择印刷效果图的产品，对应的 index
        currentDefaultRendingList: [], // 当前选中的图片
        currentDefaultRendingStatus: '1', // 当前选中的图片状态
        isCustomerDisabled: false, // 客户信息是否为不可修改，默认可修改
        showViewer: false, //图片预览参数
        imgUrl: '', //图片预览url
        canEdit: true,
        orderSupportIndex: 1, //订单支持
        defaultPrint: [
          {
            label: 'Blank',
            labelEn: 'Blank',
            value: 'Blank',
          },
          {
            label: 'Sample',
            labelEn: 'Sample',
            value: 'Sample',
          },
        ],
        refundNum: 0, // 退款数量
        isCompanyNameList: false,
        companyNameList: [],
        prevObj: {}, // 操作日志换成对象
        saveLoading: false,
        submitLoading: false,
        visible: false,
        showInfo: false,
        tipVisible: false, // 提示是否显示
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        permissions: 'user/permissions',
        lang: 'settings/language',
      }),
      // true 有订单支持的权限  false 没有权限
      isRight() {
        return this.permissions.indexOf('Order:Support') > -1
      },
    },

    watch: {
      'form.customerEmail': function (val) {
        if (!val) {
          this.refundNum = 0
          this.clientDisabled = false
          this.isCompanyNameList = false
        }
      },
      // 校验收货地址是否触发风险
      'form.receivingAddressDTOS': {
        handler(list) {
          this.validateRisk(list)
        },
        deep: true
      }
    },

    methods: {
      show(orderId) {
        this.visible = true
        this.getDict()
        this.getCustomResult()
        this.orderId = orderId
        this.getEdit(this.orderId)
      },
      onAuditClose() {
        const updateRemarkDialog = this.$refs.updateSheetRemark
        updateRemarkDialog.visible = false
        updateRemarkDialog.form.updateRemark = ''
        this.form.updateRemark = ''
      },

      // 询盘默认设置订单支持
      defaultFromBusiness() {
        const { userId, userName, englishName } = this.userInfo
        const { businessId } = this.form
        if (userId != businessId) {
          this.form.fromBusinessId = userId
          this.form.fromBusinessName = userName
          this.form.fromBusinessNameEn = englishName
        }
      },

      //切换运输方式
      changeShipMethod() {
        this.shippingMethodList.forEach((item) => {
          if (this.form.shippingMethodId == item.id) {
            this.form.shippingMethodType = item.transportType
            this.form.shippingMethod = item.transportWayCn
          }
        })
      },
      // 获取字典项
      getDict() {
        // 获取出口抬头
        orderGetDict({ amountType: 1 }).then((res) => {
          res.data.forEach((item) => {
            if (item.amountStatus == 1) {
              this.exportsRiseList.push({ ...item })
            }
          })
        })
        // 获取订单来源
        orderGetDict({ amountType: 3 }).then((res) => {
          this.orderSourceList = res.data
        })
        // 获取货运方式
        orderShipType().then((res) => {
          this.shippingMethodList = res.data
        })
        // 获取报价单号
        orderListOrderPriceSheetCode().then((res) => {
          this.priceSheetList = res.data
        })

        this.getCurrency()
        this.getPaymentList()
        this.getExchangeRate()
      },

      // 获取币种
      async getCurrency() {
        this.currencyList = await this.Dicts.getCurrency()
      },

      // 获取汇率
      async getExchangeRate() {
        this.exchangeRate = await this.Dicts.getExchange('美元')
      },

      // 默认获取所有的客户信息
      getCustomResult() {
        selectAllCustomer({}).then((res) => {
          this.customResult = res.data.map((item) => {
            return {
              ...item,
            }
          })
        })
      },
      // 默认获取所有的客户信息
      async getCustomResult() {
        const res = await clientOrderInstance.getAllCustomerOrder('')

        if (res.code === '000000') {
          this.customResult =
            res?.data.map((item) => {
              return {
                ...item,
                value: item.email,
              }
            }) || []
        }
      },

      // 获取金额区间下拉框
      getPaymentList() {
        const params = {
          payee: 1,
          paymentId: '',
        }
        clientOrderInstance.getListForSelectByOrder(params).then((res) => {
          this.moneyAreaList = res.data
          this.moneyAreaListAll = res.data
        })
      },

      // 选择出口抬头
      exportsRiseChange(val) {
        this.exportsRiseList.find((item) => {
          if (item.amountId == val) {
            this.form.exportsRise = item.amountShort
            this.form.exportsRiseId = item.amountId
          }
        })
      },

      // 显示 选择印刷效果图 弹窗
      choseRendingImages(index, urls, status) {
        this.chooseImagesDialogVisible = true
        this.currentRendingIndex = index
        this.currentDefaultRendingStatus = status || '3'
        this.currentDefaultRendingList = []
        if (urls) {
          let _urls = urls.split(',')
          for (let i = 0; i < _urls.length; i++) {
            this.currentDefaultRendingList.push({
              url: _urls[i],
              name: '',
            })
          }
        }
      },

      // 设置对应产品的 印刷效果图
      setRendingImages(data) {
        this.form.orderProductDTOS[this.currentRendingIndex].rendingsUrl =
          data.urls
        this.form.orderProductDTOS[this.currentRendingIndex].rendingsStatus =
          data.status
      },

      // 获取第一张图片
      getFirstImgUrl(urls) {
        return urls ? urls.split(',')[0] : ''
      },

      // 编辑初始化数据
      getEdit(id) {
        orderGetDetail({ orderId: id }).then((res) => {
          this.formatData(res.data || {})
        })
      },

      // 克隆初始化数据
      getCloneData(id) {
        orderCloneDetail({ orderId: id }).then((res) => {
          this.formatData(res.data || {})
        })
      },

      // 报价单号选择事件
      priceSheetCodeChange(code) {
        // 如果选择了“报价订单号”，则带出的客户信息不可修改
        this.isCustomerDisabled = this.clientDisabled = !!code
        orderSaleOrderByPriceSheetCode({ priceSheetCode: code }).then((res) => {
          this.codeId = true
          this.formatData(res.data || {})
          this.form.contractDate = this.utils.timeFormat(new Date())
          this.defaultFromBusiness()
        })
      },

      // 询盘管理生成订单
      noPriceSheetCodeData(id) {
        enquiryProduceOrder({ enquiryId: id }).then((res) => {
          this.codeId = true
          this.formatData(res.data || {})
          this.form.contractDate = this.utils.timeFormat(new Date())
        })
      },

      // 数据回显
      formatData(data) {
        let form = this.form
        this.enquiryId = data?.enquiryId
        this.refundProductAmount = data.refundProductAmount
        this.orderAuditPass = data.orderAuditPass
        form.infoId = data.infoId
        form.companyAddress = data.companyAddress
        form.priceSheetCode = data.priceSheetCode
        if (data.mallId) {
          form.mallId = data.mallId
        }

        if (this.$route.query.priceSheetCode) {
          this.form.contractDate = this.utils.timeFormat(new Date())
        } else {
          form.contractDate = data.contractDate
        }
        form.exportsRise = data.exportsRise
        form.exportsRiseId = data.exportsRiseId
        form.orderSource = data.orderSource
        form.orderSourceId = data.orderSourceId
        form.businessId = data.businessId
        form.businessName = data.businessName
        form.businessNameEn = data.businessNameEn
        form.portOfLoading = data.portOfLoading
        form.destination = data.destination
        form.orderAuditPass = data.orderAuditPass
        form.fromBusinessId = data.fromBusinessId
        form.fromBusinessName = data.fromBusinessName
        form.fromBusinessNameEn = data.fromBusinessNameEn
        form.orderVersion = data.orderVersion
        form.customerOrderCode = data.customerOrderCode
        form.customerName = data.customerName
        form.customerCode = data.customerCode
        form.customerEmail = data.customerEmail
        form.companyName = data.companyName
        form.linkman = data.linkman
        form.customerPhone = data.customerPhone
        form.shippingMethod = data.shippingMethod
        form.shippingMethodId = Number(data.shippingMethodId) || ''
        form.shippingMethodType = data.shippingMethodType
        form.deliveryDate = data.deliveryDate
        form.shippingDate = data.shippingDate
        form.currency = data.currency || '美元'
        form.quantType = data.quantType
        form.payId = data.payId
        form.amountInterval = data.amountInterval
        form.paymentProportion = data.paymentProportion
        form.customerRemark = data.customerRemark
        form.remark = data.remark
        form.cargoDistributionDTOS = data.cargoDistributionVOS
        const {
          businessId: userId,
          businessName: userName,
          businessNameEn: englishName,
        } = form
        this.initDefaultUser({
          userId,
          userName,
          englishName,
        })
        form.orderProductDTOS = data.orderProductVOS
          ? data.orderProductVOS.map((item, index) => {
            return {
              ...item,
              skuId: item.specification ? item.skuId || item.sku || '' : '',
              specificationList: item.productSkuVOS
                ? this.specificationFormat(item.productSkuVOS)
                : '',
              orderProductUniqueId: this.isCreateCode(
                item.orderProductUniqueId
              ),
            }
          })
          : []

        // 设置地址是否可以修改和删除
        if (data.receivingAddressConfigVOS && data.receivingAddressVOS) {
          data.receivingAddressVOS.forEach((item) => {
            const itemInDtoIndex = data.receivingAddressConfigVOS.findIndex(
              (subItem) => subItem.configFlag === item.configFlag
            )

            if (itemInDtoIndex >= 0) {
              data.receivingAddressConfigVOS[itemInDtoIndex].updateFlag =
                item.updateFlag
            }
          })
        }
        form.receivingAddressConfigDTOS = data?.receivingAddressConfigVOS || []
        form.receivingAddressDTOS = data?.receivingAddressVOS || []
        // 回显附件
        form.accessoryUrl = data.accessoryUrl

        // 赋值金额区间
        if (data.infoId) {
          this.getCustomMoneyArea(data.companyName)
          this.getRefundTip(data.infoId)
        }

        form.orderStatus = data.orderStatus
        if (!this.isEdit) {
          this.canEdit = false
        } else {
          if (this.form.orderStatus === '1' && !this.form.orderAuditPass) {
            this.canEdit = false
          } else {
            this.canEdit = true
          }
        }
        if (form.orderProductDTOS && form.orderProductDTOS.length) {
          form.orderProductDTOS.forEach((item, i) => {
            // 对印刷方式printingWay改造
            if (item.printingWay) {
              let printingWaySelect = []
              if (item.productPrintingWayVOList?.length) {
                printingWaySelect = item.productPrintingWayVOList
                  .map((pro) => {
                    return {
                      label: pro.printingWaySelect,
                      labelEn: pro.printingWaySelectEn,
                      value: pro.printingWaySelectId,
                    }
                  })
                  .concat(this.defaultPrint)
              } else {
                printingWaySelect = this.defaultPrint
              }
              item.printingWaySelect = printingWaySelect
            }
          })

          this.getTotalMoney()
        }
        // 绑定产品数据渲染，需要放在cargoDistributionVOS赋值之后
        this.productForm.cargoList = data.cargoDistributionVOS
        // 缓存操作日志对象
        this.prevObj = deepCopy(this.form)
        setTimeout(() => {
          this.payMethod(form.payId)
          if (!this.isClone) {
            // 克隆的订单不克隆抵扣金额
            form.usMoney = data.usMoney
            form.rmbMoney = data.rmbMoney
            form.discountRemark = data.discountRemark
            form.abdDTOS = data.abdDTOS || []
          }
        }, 0)
      },

      // 获取上传文件地址
      uploadList(fileList) {
        this.form.accessoryUrl =
          fileList.length === 0 ? '' : JSON.stringify(fileList)
      },

      // 客户姓名模糊匹配
      querySearchAsync(queryString, cb) {
        if (queryString != '') {
          selectAllCustomer({ linkman: queryString }).then((res) => {
            let results = res.data.map((item) => {
              return {
                ...item,
                value: item.linkman,
              }
            })
            cb(results)
          })
        } else {
          let list = this.customResult.map((item) => {
            return {
              ...item,
              value: item.linkman,
            }
          })
          cb(list)
        }
      },

      // 邮箱模糊查询
      async querySearchAsyncEmail(queryString, cb) {
        if (!!queryString) {
          const _res = await clientOrderInstance.getAllCustomerOrder(
            queryString.trim()
          )

          if (_res.code === '000000') {
            if (_res.data.length) {
              let results = _res.data.map((item) => {
                return {
                  ...item,
                  value: item.email,
                }
              })
              cb(results)
            } else {
              // 精确查询不到结果，走模糊查询
              const _mail = this.form.customerEmail

              if (isEmail(_mail)) {
                const _res = await clientOrderInstance.getAllCustomerByEmail(
                  _mail
                )

                this.companyNameList = _res.data
                  .filter((item) => item.companyStatus != 2)
                  .map((item) => {
                    return {
                      companyName: item.companyName,
                      email: item.email,
                      companyStatus: item.companyStatus,
                    }
                  })
                this.companyNameList.length
                  ? (this.isCompanyNameList = true)
                  : (this.isCompanyNameList = false)
              } else {
                cb([])
              }
            }
          }
        } else {
          this.form.payId = ''
          cb(this.customResult)
        }
      },

      // 订单来源匹配id
      orderSourceChange(val) {
        this.orderSourceList.forEach((item) => {
          if (item.amountId == val) {
            this.form.orderSource = item.amountName
          }
        })
      },

      // 输入客户姓名
      clientInput() {
        this.clientDisabled = false
      },

      /**
       * @description 校验邮箱黑名单
       * @param {type} 1:选择邮箱 2：输入邮箱
       */
      async customerEmailBlackList(data, type) {
        const email = type === 1 ? data.email : data

        // 输入邮箱清空客户Id
        if (type === 2) this.form.infoId = ''
        if (!email) {
          this.companyNameList = []
          this.isCompanyNameList = false
          return false
        }
        if (isEmail(email)) {
          const _res = await clientOrderInstance.getInfoTypeByEmail(email)
          // 黑名单用户
          if (_res.data === 2) {
            this.$message.warning(this.$t('reqmsg.M2072'))
            this.isCompanyNameList = false
            this.form.customerEmail = ''
          } else {
            if (_res.data === null)
              this.$message.warning(this.$t('reqmsg.M2070'))
            type === 1 ? this.handleSelect(data, true) : ''
          }
        }
      },
      /**
       * @description 校验公司名称黑名单
       * @param {companyName} 公司名称
       */
      async companyNameBlackList(companyName) {
        if (companyName) {
          const _res = await clientOrderInstance.getCompanyTypeByName(
            companyName
          )

          // 黑名单用户
          if (_res.data === 2) {
            this.$message.warning(this.$t('reqmsg.M2071'))
            this.form.companyName = ''
          }
          this.getCustomMoneyArea(companyName, true)
          this.isCompanyNameList = false
        } else {
          if (this.companyNameList.length) this.isCompanyNameList = true
          this.form.receivingAddressConfigDTOS = []
          this.form.payId = ''
          this.moneyAreaList = []
        }

        this.form.receivingAddressDTOS = [{}]
      },

      // 客户姓名选择
      // 客户姓名选择
      handleSelect(item, bool) {
        if (bool) {
          this.form.infoId = item.infoId
          this.form.customerName = item.linkman
          this.form.linkman = item.linkman
          this.form.customerCode = item.customerCode
          this.form.customerEmail = item.email
          this.form.companyName = item.companyName
          this.form.customerPhone = item.phone
          this.form.customerRemark = item.remark
          this.form.companyAddress = item.companyAddress
          this.clientDisabled = true
          this.isCompanyNameList = false
          this.getRefundTip(item.infoId)
          this.getAddressList(item.infoId)
          this.getCustomMoneyArea(item.companyName)
          this.$refs['ruleForm'].clearValidate([
            'customerEmail',
            'companyName',
            'linkman',
            'customerPhone',
          ])
        } else {
          this.customerEmailBlackList(item, 1)
        }
      },

      // 查询客户退款数量
      async getRefundTip(id) {
        const _res = await clientOrderInstance.getRefundTip(id)

        if (_res && _res.code === '000000') {
          this.refundNum = _res.data
        }
      },

      // 选择公司名称
      selectCompanyName(item) {
        // companyStatus:2 黑名单公司
        if (item.companyStatus === 2) {
          this.$confirm(this.$t('reqmsg.M2068'), this.$t('reqmsg.M2005'), {
            type: 'warning',
          }).then(() => {
            this.form.companyName = item.companyName
            this.isCompanyNameList = false
          })
        } else {
          this.form.companyName = item.companyName
          this.isCompanyNameList = false
        }
        this.form.receivingAddressDTOS = [{}]
        this.getCustomMoneyArea(item.companyName)
      },

      /**
       * 选择客户姓名调取金额区间
       * @param {companyName} 公司名称
       * @param {bool} true:重新赋值收货地址
       */
      getCustomMoneyArea(companyName, bool) {
        getDetailForEditShow({ companyName }).then((res) => {
          this.moneyAreaList =
            res.data.pays?.length > 0 ? res.data.pays : this.moneyAreaListAll
          bool &&
            (this.form.receivingAddressConfigDTOS =
              res.data?.addressConfigVOS || [])
        })
      },

      // 选择客户姓名带出地址
      getAddressList(id) {
        merchantsAddressConfigListPage({ infoId: id }).then((res) => {
          const data = res?.data || []

          if (data) {
            this.form.receivingAddressDTOS = []
            this.form.receivingAddressConfigDTOS = JSON.parse(
              JSON.stringify(data)
            )

            // 带出默认地址
            data.forEach((item) => {
              if (item.defaultAdress == 1) {
                this.form.receivingAddressDTOS.push(item)
              }
            })

            // 已有默认地址跳出
            if (this.form.receivingAddressDTOS.length > 0) {
              return false
            }

            // 多个地址取默认地址，一个地址直接带出
            if (data.length === 0) {
              this.form.receivingAddressDTOS = [{}]
            } else if (data.length === 1) {
              this.form.receivingAddressDTOS = data
            } else {
              this.form.receivingAddressDTOS = data.filter(
                (item) => item.defaultAddressFlag === 1
              )
            }
          }
        })
      },

      // 添加一行
      addRow() {
        this.form.receivingAddressDTOS.push({})
      },

      // 新增地址
      addAddress() {
        this.$refs.address.addressAddVisible = true
      },

      // 选择地址
      addressChange(val, i) {
        let list = this.form.receivingAddressDTOS
        let array = []

        // 当前执行的是清空收货地址的操作
        if (!val) {
          this.$set(this.form.receivingAddressDTOS, i, {})
          return false
        }

        // 不能选择重复的收货地址
        if (list.length) {
          list.forEach((item) => array.push(item.addressConfigId))
          if (array.includes(val) && val != list[i].addressConfigId) {
            const msg =
              this.lang === 'en'
                ? 'Delivery address can not be selected twice!'
                : '收货地址不能重复选择！'
            this.$message.warning(msg)
            this.$set(this.form.receivingAddressDTOS, i, {})
            return false
          }
        }

        // 收货地址赋值
        this.form.receivingAddressConfigDTOS.find((item) => {
          if (item.addressConfigId === val) {
            this.$set(this.form.receivingAddressDTOS, i, {
              // addressId: list[i].addressId,
              addressId: item.addressId,
              addressConfigId: val,
              ...item,
            })
          }
        })

        // 黑名单校验
        this.checkBlackAddress(val, i)
      },

      // 校验地址黑名单
      async checkBlackAddress(val, i) {
        const obj = this.form.receivingAddressConfigDTOS.find(
          (item) => item.addressConfigId === val
        )
        const res = await checkBlackReceivingCompanyAndAddress({
          address: obj.address,
          companyName: obj.companyName,
        })

        if (res.data) {
          this.$set(this.form.receivingAddressDTOS, i, {})
          return this.$message.error(res.data)
        }
      },

      // 配置收货地址保存
      addressConfigSave(val) {
        if (val.hasOwnProperty('index')) {
          this.form.receivingAddressConfigDTOS[val.index] = { ...val }
          delete this.form.receivingAddressConfigDTOS[val.index].index
        } else {
          this.form.receivingAddressConfigDTOS.push(val)
        }
      },

      // 选择金额区间
      payMethod(val) {
        this.moneyAreaList.forEach((item, i) => {
          if (item.paymentId == val) {
            this.form.amountInterval = item.payway.split('/')[0]
            this.form.paymentProportion = item.payway.split('/')[1]
          }
        })
      },

      // 选择币种
      currencyChange() {
        this.clearDeduc()
      },
      // 清除抵扣金额
      clearDeduc() {
        this.form.usMoney = ''
        this.form.rmbMoney = ''
        this.form.discountRemark = ''
        this.form.abdDTOS = []
      },

      // 返回上一页
      goBack() {
        this.visible = false
      },

      // 保存订单
      save() {
        this.$refs['ruleForm'].validateField('exportsRise', async (errMsg) => {
          if (!errMsg) {
            const params = this.dataProcessing(2)
            const _res = await this.isCheckStock(params)

            this.saveOrSubmitCallBack(_res, params, 2)
          }
        })
      },

      /**
       * 保存确认校验是否有锁定库存回调
       * @param {_res} 接口返回值
       * @param {params} 保存or提交参数
       * @param {type} 1:提交 2:保存
       */
      saveOrSubmitCallBack(_res, params, type) {
        this.saveOrSubmit(params, 3)
      },

      /**
       * 保存确认数据处理
       * @param {type}  1:提交 2:保存
       */
      dataProcessing(type) {
        this.form.logList = this.saveLogFields()
        const params = {
          actType: type,
          enquiryId: this.enquiryId,
          orderId: this.isClone ? '' : this.orderId,
          ...this.form,
        }

        return params
      },

      /**
       * 编辑销售订单判断有没有关联锁定库存
       */
      async isCheckStock(params) {
        if (this.isEdit) {
          return await checkOrderStockLock(params)
        } else {
          return false
        }
      },

      /**
       * 判断是否需要生成唯一编码
       * @desc 新增克隆(克隆自行分配同步更新)生成，编辑不生成(编辑添加新产品生成)
       * @param {codeId}  除编辑外,其他入口需要添加唯一标识
       * @param {id} 产品唯一编码，编辑不更新
       */
      isCreateCode(id) {
        const code = this.utils.createSoleCode()

        if (this.isClone) {
          const _cargos = this.form.cargoDistributionDTOS
          if (_cargos?.length) {
            for (let i = 0; i < _cargos.length; i++) {
              if (_cargos[i].orderProductUniqueId === id) {
                this.form.cargoDistributionDTOS[i].orderProductUniqueId = code
              }
            }
          }
          return code
        } else {
          return this.codeId ? code : id || ''
        }
      },

      // 验证保存提交订单
      submit() {
        // this.$refs['ruleForm'].validate(async (valid) => {
        //   if (valid) {
        if (!this.form.receivingAddressDTOS.length) {
          this.$message.error(
            this.lang === 'en'
              ? 'Add at least one shipping address!'
              : '至少添加一条收货地址！'
          )
          return false
        }
        if (!this.validAllocation()) {
          return false
        }
        if (
          !this.form.cargoDistributionDTOS?.length &&
          this.form.quantType == 2
        ) {
          this.$message.error(
            this.lang === 'en'
              ? 'Choose your own distribution of binding products!'
              : '选择自行分配需绑定产品！'
          )
          return false
        }


        const params = this.dataProcessing(3)
        this.saveOrSubmitCallBack([], params, 3)
        //   }
        // })
      },

      // 确认回调
      async submitCallBack() {
        // 显示修改原因
        const needShowRemak = this.form.orderAuditPass > 0
        let auditParams = {
          auditType: 1,
          status: 0,
          tenantId: this.userInfo.tenantId,
        }
        if (needShowRemak && this.isEdit) {
          let updateRemark = await this.$refs.updateSheetRemark.showAddAndEdit(
            this.form.updateRemark
          )
          if (updateRemark) {
            this.form.updateRemark = updateRemark
            this.pubApi.getAuditTemplates(auditParams).then((response) => {
              if (!response.data.length) {
                this.$baseMessage(
                  this.$t('reqmsg.M2015'),
                  'warning',
                  false,
                  'erp-hey-message-warning'
                )
              } else {
              }
            })
          } else {
            return
          }
        } else {
          this.pubApi.getAuditTemplates(auditParams).then((response) => {
            if (!response.data.length) {
              this.$baseMessage(
                this.$t('reqmsg.M2015'),
                'warning',
                false,
                'erp-hey-message-warning'
              )
            } else {
            }
          })
        }
      },

      //验证产品是否全部分配
      validAllocation() {
        if (this.form.receivingAddressDTOS?.length === 1) { //只有一条地址时，改成平均分配
          this.form.quantType = 1
          return true
        }
        let flag = 1
        // 地址超过1条，且是自行分配
        if (
          this.form.receivingAddressDTOS.length > 1 &&
          this.form.quantType == 2
        ) {
          // 获取已分配地址的ids
          const addrIds = this.productForm.cargoList
            .filter((item) => {
              return item.quantTotle
            })
            .map((item) => {
              return item.addressConfigId
            })
          const ids = Array.from(new Set(addrIds))
          const products = this.form.orderProductDTOS

          for (let i = 0; i < this.form.orderProductDTOS.length; i++) {
            const totalAmount = this.productForm.cargoList.reduce(
              (total, product) => {
                if (
                  products[i].productId == product.productId &&
                  products[i].sku == product.sku &&
                  products[i].orderProductUniqueId ==
                  product.orderProductUniqueId
                ) {
                  return (total += Number(product.quantTotle))
                } else {
                  return total
                }
              },
              0
            )

            //对比产品数量和分配数量，不相等即报错
            if (products[i].amount != totalAmount) {
              this.$message.warning(
                this.lang === 'en'
                  ? 'Product distribution is incorrect!'
                  : '产品分配不正确！'
              )
              flag = 0
              return false
            } else {
              flag = 1
            }
          }

          // 已分配地址长度小于所有地址长度，说明有地址未分配
          if (ids.length != this.form.receivingAddressDTOS.length) {
            const msg =
              this.lang === 'en'
                ? 'The total number of assigned addresses cannot be 0'
                : '一个地址分配数量总和不能为0'

            this.$message.warning(msg)
            flag = 0
          }
        } else {
          flag = 1
        }
        return flag == 1 ? true : false
      },

      // 保存审核模板信息
      saveClick(data) {
        this.form.auditObjectDTO = data
        this.submitOffer()
      },

      // 提交订单
      async submitOffer() {
        const params = this.dataProcessing(1)
        this.saveOrSubmit(params, 1)
      },

      /**
       * 保存or确认回调
       * @param {params} 入参
       * @param {type} 1:提交 2:保存
       */
      async saveOrSubmit(params, type) {
        let requestUrl = null
        if (type === 1 || type == 3) {
          requestUrl = orderSalesInsertPO
          this.submitLoading = true
        } else {
          requestUrl = salesOrderSave
          this.saveLoading = true
        }

        const res = await requestUrl(params)

        if (res && res.code == '000000') {
          this.$message.success('修改地址成功')
          // this.$router.push('/order/orderList/orderList')
          this.$emit('address-edit-success')
        }
        this.saveLoading = false
        this.submitLoading = false
      },

      // 计算抵扣人民币
      usMoneyBlur() {
        this.form.rmbMoney = (this.form.usMoney * this.exchangeRate).toFixed(3)
        if (
          Number(this.form.usMoney) > Number(this.totalMoney) &&
          this.totalMoney > 0
        ) {
          this.form.usMoney = this.totalMoney
          this.$message.warning(
            this.lang === 'en'
              ? 'The deduction amount cannot be greater than the total amount!'
              : '抵扣金额不能大于总金额！'
          )
          return false
        }
      },

      //根据数量计算售价
      amountBlur(val, pro, sku, index) {
        getStepByProductId({
          num: Number(val),
          productId: pro,
          sku: sku,
        }).then((res) => {
          if (res && res.code === '000000') {
            if (res.data) {
              const data = res.data

              this.$set(
                this.form.orderProductDTOS[index],
                'netPrice',
                data.netPrice
              )
              this.$set(
                this.form.orderProductDTOS[index],
                'warehouseNumber',
                data.warehouseNumber
              )
              this.calculateTotal(index)
            }
          }
        })
      },

      // 计算totalPrice = amount*netPrice + setUpCharge + otherCharge + ShippingFee - discountAmount
      calculateTotal(i) {
        let products = this.form.orderProductDTOS
        if (products[i].amount) {
          let amount = null
          amount =
            Number(products[i].amount * products[i].netPrice || 0) +
            Number(products[i].setUpCharge) +
            Number(products[i].otherCharge) +
            Number(products[i].shippingFee || 0) -
            Number(products[i].discountAmount || 0)
          products[i].usTotle = parseFloat(amount).toFixed(3)
        } else {
          products[i].usTotle = 0
        }

        this.getTotalMoney()
      },

      // 获取总数量，总金额，总折扣
      getTotalMoney() {
        let [totalNumAry, totalMoneyAry] = [[], []]
        this.form.orderProductDTOS.forEach((item) => {
          totalNumAry.push(Number(item.amount))
          totalMoneyAry.push(Number(item.usTotle))
        })

        this.totalNum = totalNumAry.reduce((prev, cur) => {
          return prev + cur
        })
        this.totalMoney =
          parseFloat(
            totalMoneyAry.reduce((prev, cur) => {
              return prev + cur
            })
          ).toFixed(3) - Number(this.refundProductAmount)

        this.totalDiscount = this.utils.amountTotal(
          this.form.orderProductDTOS,
          'discountAmount',
          3
        )
      },

      // 格式化处理印刷方式
      printWayFormat(_arr) {
        let printingWaySelect = []
        if (_arr.length) {
          printingWaySelect = _arr
            .map((pro) => {
              return {
                label: pro.printingWaySelect,
                labelEn: pro.printingWaySelectEn,
                value: pro.printingWaySelectId,
              }
            })
            .concat(this.defaultPrint)
        } else {
          printingWaySelect = this.defaultPrint
        }
        return printingWaySelect
      },

      // 保存产品信息
      getProductData(data) {
        const _arr = data.map((item, i) => {
          return {
            productBelonger: item.productBelonger,
            productBelongerEn: item.productBelongerEn,
            productBelongerId: item.productBelongerId,
            productCode: item.productCode,
            productEn: item.nameEn,
            specification: item.specification,
            amount: '',
            netPrice: '',
            printingEffect: '',
            setUpCharge: '',
            rendingsUrl: '',
            otherCharge: '',
            chargeFees: '',
            remark: '',
            usTotle: 0,
            productId: item.productId,
            productCn: item.nameCn,
            sku: item.skuId,
            skuId: item.skuId,
            imgMain: item.image || item.imgMain,
            warehouseNumber: item.warehouseNumber,
            warehouseNumberEn: item.warehouseNumberEn,
            stockWarehouseNumVOList: item.stockWarehouseNumVOList,
            standardCustomized: item.standardCustomized,
            printingWay: '',
            printingWaySelect: this.printWayFormat(
              item.productPrintingWayVOList || []
            ),
            optionType: 0,
            productSkuVOS: item.productSkuVOS,
            sizeUnit: item.sizeUnit,
            productLengthIn: item.productLengthIn,
            productWidthIn: item.productWidthIn,
            productHeightIn: item.productHeightIn,
            productLengthCm: item.productLengthCm,
            productWidthCm: item.productWidthCm,
            productHeightCm: item.productHeightCm,
            specificationList: this.specificationFormat(item.productSkuVOS), // 手动塞入规格字典项
            productDescription:
              'Material:' +
              item.material +
              '; ProductSize:' +
              this.getProductSize(item.sizeUnit, item),
            orderProductUniqueId: this.utils.createSoleCode(),
          }
        })

        this.form.orderProductDTOS = this.form.orderProductDTOS.concat(_arr)
        this.productListVisible = false
      },

      // 规格字典项处理
      specificationFormat(array, type) {
        if (array.some((item) => item.specsType)) {
          const list = array.map((item) => {
            return {
              id: item.skuId,
              label: item.specsType.includes(',')
                ? item.specsType.split(',')[0] +
                ':' +
                item.specsValue1 +
                '，' +
                item.specsType.split(',')[1] +
                ':' +
                item.specsValue2
                : item.specsType + ':' + item.specsValue1,
            }
          })
          // type == 1 选择规格替换产品信息规格 else 获取规格字典项
          if (type == 1) {
            return list[0].label
          } else {
            return list
          }
        } else {
          return []
        }
      },

      /**
       * @description 选择印刷方式判断产品类型
       * @param type:产品类型 way:是否需要印刷 i:索引
       * @param optionType:产品类型 1加工出库 2普通出库 3采购订单
       * 标准品是:加工出库，标准品否普通出库，订制品是采购订单
       */
      printingWayChange(type, way, i) {
        let products = this.form.orderProductDTOS[i]
        const ways = products.printingWaySelect.find(
          (item) => item.labelEn === way
        )
        products.printingWay = ways.label
        products.printingWayEn = ways.labelEn

        if (type == '标准品' && way.toUpperCase() != 'BLANK') {
          products.optionType = 1
        } else if (type == '标准品' && way.toUpperCase() === 'BLANK') {
          products.optionType = 2
        } else if (type == '订制品') {
          products.optionType = 3
        } else {
          products.optionType = 0
        }
      },

      // 当存在产品数量为1的情况只允许自行分配
      quantTypeChange() {
        if (this.form.orderProductDTOS.some((item) => item.amount == 1)) {
          const msg =
            this.lang === 'en'
              ? 'If the number of products is 1, it can only be allocated by itself'
              : '产品数量为1只能自行分配'
          this.$message.warning(msg)
          this.form.quantType = 2
        }
      },

      // 显示绑定产品
      bindProductShow() {
        let cargoList = []
        let orderProduct = this.form.orderProductDTOS
        // 至少添加一条产品
        if (!orderProduct.length) {
          this.$message.error(this.$t('other.addedProduct'))
          return false
        }
        // 产品数量不能为空
        if (orderProduct.some((item) => item.amount == '')) {
          const msg =
            this.lang === 'en'
              ? 'The number of products is empty and cannot be allocated by itself'
              : '产品数量为空不能自行分配'
          this.$message.warning(msg)
          return false
        }
        this.form.receivingAddressDTOS.forEach((item, i) => {
          orderProduct.forEach((obj, k) => {
            cargoList.push({
              address: item.address, // 收货地址
              companyName: item.companyName, // 收货公司
              consigneeName: item.consigneeName, // 收货人名称
              consigneePhone: item.consigneePhone, // 收货人电话
              addressId: item.addressId, // 收货地址id
              amount: obj.amount, // 总数量
              orderId: this.orderId, // 订单id
              productCode: obj.productCode, // 产品编码
              productEn: obj.productEn, // 产品英文名称
              productId: obj.productId, // 产品id
              quantTotle: this.getQuantTotle(
                item.addressConfigId,
                obj.sku,
                obj.orderProductUniqueId
              ), // 分配数量
              sku: obj.sku, // 规格编号
              specification: obj.specification, // 规格
              addressConfigId: item.addressConfigId,
              orderProductUniqueId: obj.orderProductUniqueId || '',
            })
          })
        })
        this.productForm.cargoList = cargoList
        this.bindProductVisible = true
      },

      // 获取分配数量
      getQuantTotle(id, sku, orderProductUniqueId) {
        if (this.productForm.cargoList && this.productForm.cargoList.length) {
          let quantTotle = 0
          this.productForm.cargoList.find((item) => {
            if (
              item.addressConfigId == id &&
              item.sku == sku &&
              item.orderProductUniqueId == orderProductUniqueId
            ) {
              quantTotle = item.quantTotle
            }
          })
          return quantTotle
        } else {
          return 0
        }
      },

      // 校验分配数量
      verifyQuantTotle(i, k) {
        let index = k + i * this.form.orderProductDTOS.length // 当前产品的索引
        let amount = Number(this.form.orderProductDTOS[k].amount) // 当前产品的总数
        let useAmount = Number(this.form.orderProductDTOS[k].useAmount) // 当前产品已分配数量
        // 分配数量不能大于订单数量
        if (Number(this.productForm.cargoList[index].quantTotle) > amount) {
          const msg =
            this.lang === 'en'
              ? 'The assigned quantity cannot be greater than the order quantity!'
              : '分配数量不能大于订单数量！'

          this.productForm.cargoList[index].quantTotle = amount
          this.$message.warning(msg)
        }
        //旧地址判断 不能小于已分配数量
        if (
          this.form.receivingAddressDTOS[i].isOldAddress &&
          Number(this.productForm.cargoList[index].quantTotle) < useAmount
        ) {
          const msg =
            this.lang === 'en'
              ? 'The number of products at the original address must be ≥ the total number of products in the delivery order/delivery order!'
              : '原地址的产品数量必须≥出运委托单/出库单中该产品的总数！'

          this.productForm.cargoList[index].quantTotle = useAmount
          this.$message.warning(msg)
        }
      },

      // 绑定产品
      bindProduct() {
        let cargoList = []
        let falg = false
        this.$refs.productForm.validate((valid) => {
          if (valid) {
            // 一个地址的多个货物，数量总和不能为0
            this.productForm.cargoList.forEach((item) =>
              cargoList.push(item.addressConfigId)
            )
            cargoList = Array.from(new Set(cargoList))
            cargoList.forEach((item) => {
              let amount = 0
              this.productForm.cargoList.forEach((obj) => {
                if (item == obj.addressConfigId) {
                  amount += obj.quantTotle
                }
              })
              if (amount == 0) falg = true
            })
            if (falg) {
              const msg =
                this.lang === 'en'
                  ? 'The total number of assigned addresses cannot be 0'
                  : '一个地址分配数量总和不能为0'

              this.$message.warning(msg)
              return false
            }
            // 分配数量累加等于订单数量
            let [amount, quantTotle, amountArr, quantTotleArr] = [0, 0, [], []]
            this.form.orderProductDTOS.forEach((item) =>
              amountArr.push(Number(item.amount))
            )
            this.productForm.cargoList.forEach((item) =>
              quantTotleArr.push(Number(item.quantTotle))
            )
            amount = amountArr.reduce((p, v) => p + v)
            quantTotle = quantTotleArr.reduce((p, v) => p + v)
            if (amount != quantTotle) {
              const msg =
                this.lang === 'en'
                  ? 'The total quantity allocated must equal the order quantity!'
                  : '分配数量总数必须等于订单数量！'

              this.$message.warning(msg)
              return false
            }
            this.form.cargoDistributionDTOS = [...this.productForm.cargoList]
            this.bindProductVisible = false
          }
        })
      },
      // 绑定产品取消
      bindProductCancel() {
        this.bindProductVisible = false
      },

      // table添加必填样式
      star({ row, rowIndex, column, columnIndex }) {
        if (columnIndex == 5 || columnIndex == 6 || columnIndex == 8) {
          return 'star'
        }
      },

      // 删除选中地址 deleteFlag:0不能删除
      delSelectAddress(i, deleteFlag) {
        if (deleteFlag === 0) {
          const msg =
            this.lang === 'en'
              ? 'The address of the delivery order or warehouse order that has been generated cannot be deleted'
              : '已经生成出运委托单/出库单的地址不可以删除'

          return this.$message.warning(msg)
        }
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        })
          .then(() => {
            // 清空货物分配信息
            this.form.cargoDistributionDTOS = []
            this.productForm.cargoList = []
            this.form.receivingAddressDTOS.splice(i, 1)
            if (this.form.receivingAddressDTOS.length == 1) {
              this.form.quantType = null
            }
            this.$message.success(this.$t('reqmsg.M2008'))
          })
          .catch(() => { })
      },

      // 删除产品列表
      delProduct(row, i) {
        const deleteFlag = this.form.orderProductDTOS[i].deleteFlag
        if (deleteFlag === 0) {
          const msg =
            this.lang === 'en'
              ? 'The product cannot be deleted because it has been bound with a purchase order or an exit order'
              : '产品已绑定采购订单或者有出库单，无法删除'

          return this.$message.warning(msg)
        }
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        })
          .then(async () => {
            if (this.isEdit) {
              const params = {
                sku: row.sku,
                salesOrderNumber: this.orderCode,
                orderProductUniqueId: row.orderProductUniqueId,
              }
              const res = await checkDeleteOrderProduct(params)

              if (res.code === '000000') this.clearProduct(i)
            } else {
              this.clearProduct(i)
            }
          })
          .catch(() => { })
      },

      // 删除产品清空数据
      clearProduct(i) {
        this.form.cargoDistributionDTOS = []
        this.productForm.cargoList = []
        this.form.orderProductDTOS.splice(i, 1)
        this.getTotalMoney()
        this.$message.success(this.$t('reqmsg.M2008'))
      },

      // 删除客户代表
      clearBusinessName() {
        this.form.businessId = ''
        this.form.businessName = ''
        this.businessArray = []
        // 修改客户代表，清除抵扣金额
        this.clearDeduc()
      },

      // 删除订单支持
      clearFromBusinessName() {
        this.form.fromBusinessId = ''
        this.form.fromBusinessName = ''
        this.form.fromBusinessNameEn = ''
        this.formBusinessArray = []
      },

      // 文件上传
      multiUploadList(fileList, index) {
        this.form.orderProductDTOS[index].printedDocumentsUrl =
          JSON.stringify(fileList)
      },

      // 新增产品
      addProduct() {
        const routeData = this.$router.resolve({
          path: '/product/ProductAddEdit/ProductAddEdit?str=add&from=order',
        })
        window.open(routeData.href, '_blank')
      },

      // 选取规格
      specificationChange(id, i) {
        let productList = this.form.orderProductDTOS
        let selectData = {}
        // 获取切换规格所需的数据源
        productList[i].productSkuVOS.map((item) => {
          if (item.skuId == id) {
            selectData = { ...item }
          }
        })
        // 选中规格替换当前产品数据
        productList[i].imgMain = selectData.image || selectData.imgMain
        productList[i].sku = selectData.skuId
        productList[i].productEn = selectData.nameEn
        productList[i].stockWarehouseNumVOList =
          selectData.stockWarehouseNumVOList
        productList[i].specification = this.specificationFormat([selectData], 1)
        this.amountBlur(
          productList[i].amount,
          selectData.productId,
          selectData.skuId,
          i,
          selectData.material
        )
      },

      // 获取产品尺寸
      getProductSize(unit, data) {
        if (unit === 'IN') {
          return (
            data.productLengthCm +
            '"x' +
            data.productWidthCm +
            '"x' +
            data.productHeightCm +
            '"'
          )
        } else if (unit === 'CM') {
          return (
            data.productLengthCm +
            'cm x ' +
            data.productWidthCm +
            'cm x ' +
            data.productHeightCm +
            'cm'
          )
        } else {
          return (
            data.productLengthCm +
            "'x" +
            data.productWidthCm +
            "'x" +
            data.productHeightCm +
            "'"
          )
        }
      },

      // 日志变更字段请求
      saveLogFields() {
        // 抵扣金额单独处理
        let _abdDTOS = []
        if (this.form.abdDTOS?.length) {
          _abdDTOS = this.form.abdDTOS.filter((item) => item.collectionAmountUs)
        }
        this.form.abdDTOS = _abdDTOS

        const logFields = collectLogFields(
          this.prevObj,
          this.form,
          orderLogFields
        )
        return logFields
      },
      // 删除产品清空数据
      clearProduct(i) {
        this.form.cargoDistributionDTOS = []
        this.productForm.cargoList = []
        this.form.orderProductDTOS.splice(i, 1)
        this.getTotalMoney()
        this.$message.success(this.$t('reqmsg.M2008'))
      },

      // 删除客户代表
      clearBusinessName() {
        this.form.businessId = ''
        this.form.businessName = ''
        this.businessArray = []
        // 修改客户代表，清除抵扣金额
        this.clearDeduc()
      },

      // 删除订单支持
      clearFromBusinessName() {
        this.form.fromBusinessId = ''
        this.form.fromBusinessName = ''
        this.form.fromBusinessNameEn = ''
        this.formBusinessArray = []
      },

      // 文件上传
      multiUploadList(fileList, index) {
        this.form.orderProductDTOS[index].printedDocumentsUrl =
          JSON.stringify(fileList)
      },

      // 新增产品
      addProduct() {
        const routeData = this.$router.resolve({
          path: '/product/ProductAddEdit/ProductAddEdit?str=add&from=order',
        })
        window.open(routeData.href, '_blank')
      },

      // 选取规格
      specificationChange(id, i) {
        let productList = this.form.orderProductDTOS
        let selectData = {}
        // 获取切换规格所需的数据源
        productList[i].productSkuVOS.map((item) => {
          if (item.skuId == id) {
            selectData = { ...item }
          }
        })
        // 选中规格替换当前产品数据
        productList[i].imgMain = selectData.image || selectData.imgMain
        productList[i].sku = selectData.skuId
        productList[i].productEn = selectData.nameEn
        productList[i].stockWarehouseNumVOList =
          selectData.stockWarehouseNumVOList
        productList[i].specification = this.specificationFormat([selectData], 1)
        this.amountBlur(
          productList[i].amount,
          selectData.productId,
          selectData.skuId,
          i,
          selectData.material
        )
      },

      // 获取产品尺寸
      getProductSize(unit, data) {
        if (unit === 'IN') {
          return (
            data.productLengthCm +
            '"x' +
            data.productWidthCm +
            '"x' +
            data.productHeightCm +
            '"'
          )
        } else if (unit === 'CM') {
          return (
            data.productLengthCm +
            'cm x ' +
            data.productWidthCm +
            'cm x ' +
            data.productHeightCm +
            'cm'
          )
        } else {
          return (
            data.productLengthCm +
            "'x" +
            data.productWidthCm +
            "'x" +
            data.productHeightCm +
            "'"
          )
        }
      },

      // 产品类型中英文翻译
      getLangProductType(state) {
        let text = ''
        switch (state) {
          case '标准品':
            text = this.$t('orderDetails.StandardProduct')
            break
          case '订制品':
            text = this.$t('orderDetails.CustomizedProduct')
            break
          case '辅料':
            text = this.$t('orderDetails.Excipients')
            break
        }
        return text
      },
      // 校验收货地址是否触发风险
      async validateRisk(list = []) {
        if (list && !list.length) return this.tipVisible = false
        const isEmptyObj = list.every(n => Object.keys(n).length === 0)
        if (isEmptyObj) return this.tipVisible = false // 如果数组中的对象是空对象，如[{}, {}]，直接返回，不请求接口
        try {
          const { code, data } = await clientOrderInstance.validateRiskApi(list)
          if (code === '000000') {
            this.tipVisible = data
          }
        } catch (err) {
          console.log(err)
        }
      }
    },
  }
</script>

<style scoped lang="scss">
  .custom-container {
    padding-bottom: 0 !important;

    .order-content {
      padding: 0 10px 0px;

      .el-divider {
        margin: 20px 0;
        background-color: #ececec;
      }

      h4 {
        font-size: 14px;
      }

      .text-sty {
        text-align: left;

        p {
          color: #606266;
          line-height: 20px;
        }
      }

      .el-input,
      .el-select {
        width: 100%;
      }

      .el-form-item {
        margin-bottom: 0;
      }

      .product-list {
        .product-table {
          /deep/ {
            .cell {
              overflow: initial;

              .el-form-item__content {
                padding: 5px 0;

                .el-form-item__error {
                  top: 42px;
                }
              }
            }
          }
        }

        .total-money {
          line-height: 40px;
          text-align: right;

          span {
            font-family: 'PingFang Bold';
            font-size: 18px;
            margin-left: 5px;
          }
        }
      }

      .icon-fenpei {
        font-size: 24px;
        color: #409eff;
        vertical-align: middle;
        cursor: pointer;
      }

      .accessory {
        position: relative;
        padding-left: 10px;
      }

      .accessory::before {
        position: absolute;
        content: '*';
        color: red;
        font-size: 16px;
        top: 0;
        left: 0;
      }

      .tag-wrap {
        position: relative;
        display: flex;
        justify-content: center;

        .el-tag {
          position: absolute;
          top: -10px;
          right: 0;
          z-index: 2;
        }

        .el-image {
          width: 64px !important;
          height: 64px !important;
        }
      }
    }

    .oper-btn {
      position: sticky;
      bottom: 0;
      text-align: center;
      background: white;
      padding: 10px 0;
    }

    .bind-btn {
      width: 28px;
      height: 28px;
      text-align: center;

      i {
        font-size: 12px;
      }
    }

    .address-dialog {
      .container {
        .box {
          display: flex;
          justify-content: space-between;
          height: 50px;
          line-height: 50px;
          border-left: 1px solid #ececec;
          border-right: 1px solid #ececec;

          >div:first-child {
            width: 80%;
            padding: 0 20px;
            border-right: 1px solid #ececec;
            border-bottom: 1px solid #ececec;

            input {
              vertical-align: middle;
              margin-right: 5px;
              cursor: pointer;
            }
          }

          >div:last-child {
            width: 20%;
            text-align: center;
            border-bottom: 1px solid #ececec;
          }
        }

        .box:first-child {
          border-top: 1px solid #ececec;
        }
      }
    }

    /deep/.el-table table th.star div:before {
      content: '*';
      color: red;
    }

    .wrapper-box {
      .wrapper-add {
        display: inline-block;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border: 1px dotted #c0cbda;
        border-radius: 3px;
        cursor: pointer;
      }

      .wrapper-image img {
        height: 64px;
        width: 64px;
        margin: 0 auto;
      }
    }

    .deduction {
      display: flex;
      align-items: center;
      margin-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #ececec;

      .total-deduction {
        display: inline-block;
        margin-left: 20px;
        line-height: 30px;
      }
    }

    .address-btns {
      margin: 10px 0 20px;
      text-align: center;

      span {
        margin: 0 10px;
        color: #409eff;
        cursor: pointer;
      }
    }

    .discount-sty {
      position: relative;

      .el-button {
        position: absolute;
        top: 8px;
        right: -5px;
        z-index: 2;
        height: 28px;
        width: 28px;
        margin: 0;

        /deep/ i {
          font-size: 16px;
          margin: -3px;
        }
      }

      /deep/ {
        .el-input {
          width: 100px;
          display: flex;
        }
      }
    }
  }

  .custom-container {
    padding-bottom: 0 !important;

    .order-content {
      padding: 0 10px 0px;

      .el-divider {
        margin: 20px 0;
        background-color: #ececec;
      }

      h4 {
        font-size: 14px;
      }

      .text-sty {
        text-align: left;

        p {
          color: #606266;
          line-height: 20px;
        }
      }

      .el-input,
      .el-select {
        width: 100%;
      }

      .el-form-item {
        margin-bottom: 0;
      }

      .product-list {
        .product-table {
          /deep/ {
            .cell {
              overflow: initial;

              .el-form-item__content {
                padding: 5px 0;

                .el-form-item__error {
                  top: 42px;
                }
              }
            }
          }
        }

        .total-money {
          line-height: 40px;
          text-align: right;

          span {
            font-family: 'PingFang Bold';
            font-size: 18px;
            margin-left: 5px;
          }
        }
      }

      .icon-fenpei {
        font-size: 24px;
        color: #409eff;
        vertical-align: middle;
        cursor: pointer;
      }

      .accessory {
        position: relative;
        padding-left: 10px;
      }

      .accessory::before {
        position: absolute;
        content: '*';
        color: red;
        font-size: 16px;
        top: 0;
        left: 0;
      }

      .tag-wrap {
        position: relative;
        display: flex;
        justify-content: center;

        .el-tag {
          position: absolute;
          top: -10px;
          right: 0;
          z-index: 2;
        }

        .el-image {
          width: 64px !important;
          height: 64px !important;
        }
      }
    }

    .company-name {
      position: relative;

      .list {
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 12px 0 #ccc;
        border: 1px solid #e4e7ed;
        background: white;
        padding: 5px 0;

        p {
          font-size: 12px;
          color: #999;
          text-align: left;
          display: flex;
          justify-content: space-between;

          i {
            font-size: 18px;
            color: #333;
            cursor: pointer;
          }
        }

        ul {
          min-height: 200px;
          max-height: 200px;
          overflow-y: auto;

          li {
            padding: 0 10px 5px;
            height: 34px;
            line-height: 34px;
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            span {
              width: 45%;
            }
          }

          li:hover {
            background: #f5f7fa;
          }
        }
      }
    }

    .oper-btn {
      position: sticky;
      bottom: 0;
      text-align: center;
      background: white;
      padding: 10px 0;
    }

    .bind-btn {
      width: 28px;
      height: 28px;
      text-align: center;

      i {
        font-size: 12px;
      }
    }

    .address-dialog {
      .container {
        .box {
          display: flex;
          justify-content: space-between;
          height: 50px;
          line-height: 50px;
          border-left: 1px solid #ececec;
          border-right: 1px solid #ececec;

          >div:first-child {
            width: 80%;
            padding: 0 20px;
            border-right: 1px solid #ececec;
            border-bottom: 1px solid #ececec;

            input {
              vertical-align: middle;
              margin-right: 5px;
              cursor: pointer;
            }
          }

          >div:last-child {
            width: 20%;
            text-align: center;
            border-bottom: 1px solid #ececec;
          }
        }

        .box:first-child {
          border-top: 1px solid #ececec;
        }
      }
    }

    /deep/.el-table table th.star div:before {
      content: '*';
      color: red;
    }

    .wrapper-box {
      .wrapper-add {
        display: inline-block;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border: 1px dotted #c0cbda;
        border-radius: 3px;
        cursor: pointer;
      }

      .wrapper-image img {
        height: 64px;
        width: 64px;
        margin: 0 auto;
      }
    }

    .deduction {
      display: flex;
      align-items: center;
      margin-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #ececec;

      .total-deduction {
        display: inline-block;
        margin-left: 20px;
        line-height: 30px;
      }
    }

    .address-btns {
      margin: 10px 0 20px;
      text-align: center;

      span {
        margin: 0 10px;
        color: #409eff;
        cursor: pointer;
      }
    }

    .discount-sty {
      position: relative;

      .el-button {
        position: absolute;
        top: 8px;
        right: -5px;
        z-index: 2;
        height: 28px;
        width: 28px;
        margin: 0;

        /deep/ i {
          font-size: 16px;
          margin: -3px;
        }
      }

      /deep/ {
        .el-input {
          width: 100px;
          display: flex;
        }
      }
    }
  }

  .tip {
    color: #ff4d4f;
    font-family: 'PingFang Bold';
    padding-left: 70px;
  }
</style>
