<template>
  <div class="mail-user-item">
    <div v-if="!editFlag" @dblclick="doubleClick" class="mail-user-tag">
      <span>
        {{ value | transformMailToName($store.state.mail.latestContacts) }}
      </span>

      <i class="el-icon-close" @click.stop="deleteUser"></i>
    </div>
    <el-input
      class="mail-user-input"
      v-else
      v-model="value"
      @blur="sureUser"
    ></el-input>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editFlag: false,
    }
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {},
  filters: {
    transformMailToName(val, arr) {
      let obj = arr.find((item) => val == item.contactEmail)
      if (obj !== undefined) {
        return obj.noteName
          ? `${obj.noteName}<${obj.contactEmail}>`
          : obj.contactEmail
      } else {
        return val
      }
    },
  },
  methods: {
    doubleClick() {
      this.editFlag = true
    },
    sureUser() {
      let mailReg =
        /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
      if (this.value) {
        if (!mailReg.test(this.value)) {
          this.$message({
            type: 'warning',
            // message: '请输入正确的邮箱格式',
            message: this.$t('reqmsg.$55'),
          })
        } else {
          this.$emit('update', this.value)
        }
      } else {
      }
      this.editFlag = false
    },
    deleteUser() {
      this.$emit('delete')
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.mail-user-item {
  padding-bottom: 10px;
}
.mail-user-tag {
  background: #e8f4ff;
  border-radius: 3px;
  border: 1px solid #badeff;
  line-height: 30px;
  font-size: 12px;
  color: #303030;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  margin-right: 5px;
  i {
    line-height: 30px;
    cursor: pointer;
    margin-left: 20px;
  }
}
.mail-user-input {
  background: #e8f4ff;
  border-radius: 3px;
  border: 1px solid #badeff;
  min-width: 120px;
}
</style>
