<template>
  <el-dialog
    :title="lang === 'en' ? 'Email Example' : '邮件示例'"
    :visible.sync="dialogFormVisible"
    :width="isPad ? '850px' : '50%'"
    :close-on-click-modal="false"
    append-to-body
    class="dialog-fixed"
    @close="dialogFormVisible = false"
  >
    <div class="dialog-content">
      <!-- 发货邮件 -->
      <div>
        <el-row type="flex" align="center">
          <h4 style="color: #333">发货邮件</h4>
          <span style="color: red">（仅作为示例，不作为真实内容）</span>
        </el-row>
        <br />
        <el-row type="flex" align="center">
          <span style="text-indent: 15px; color: #999999">邮件标题：</span>
          <span>Order Shipped: PO 12344-3 | PI- ORP230721830</span>
        </el-row>
        <br />
        <el-row type="flex" align="center">
          <span style="text-indent: 15px; color: #999999">邮件内容：</span>
          <span>Hello,</span>
        </el-row>
        <br />
        <template
          v-if="
            transportWay == 'AIR-TRUCK' ||
            transportWay == 'OCEAN-FAST SHIP-TRUCK' ||
            transportWay == 'OCEAN-STANDARD SHIP-TRUCK'
          "
        >
          <p>
            We are pleased to inform you that your order, PO 12344-3, associated
            with our PI No. ORP230721830, has been shipped on 2023-06-08.
          </p>
          <br />

          <p>
            Your order is shipped in 1 carton(s), and the estimated in-hand date
            is 2023-06-29.
          </p>
          <br />

          <p>
            Since the local delivery is handled by a trucking company with local
            logistics, it can be difficult for you to check on the
          </p>
          <p>
            delivery status. Please don't hesitate to contact us if you would
            like an update on the delivery status. Thank you.
          </p>
          <br />

          <p>Attached is the final invoice for your reference.</p>
          <br />

          <p>
            If you encounter any issues, contact us immediately for assistance
            at:
          </p>
          <p>Sophia Zhang</p>
          <p>sales@riversgarment.com</p>
          <br />

          <p>Thank you!</p>
          <br />
        </template>
        <template
          v-if="transportWay == 'AIR-UPS' || transportWay == 'AIR-FEDEX'"
        >
          <p>
            We are pleased to inform you that your order, PO 12344-3, associated
            with our PI No. ORP230721830, has been shipped on 2023-06-08.
          </p>
          <br />

          <p>
            Please find below your tracking number. You could check the status
            of your order using the tracking number below
          </p>
          <p>
            <b>approx. 4-5 Working Days after shipped,</b>
            as we send this order by overseas air freight.
          </p>
          <br />
          <p>780149057076 by FedEx.</p>
          <br />
          <p>Shipping Address:</p>
          <p>519 N. Sycamore St Unit B Spokane, WA 99202</p>
          <emailTemplateTable />
          <p>
            Your order is shipped in 1 carton(s), and the estimated in-hand date
            is 2023-06-29.
          </p>
          <br />

          <p>Attached is the final invoice for your reference.</p>
          <br />

          <p>
            If you encounter any issues, contact us immediately for assistance
            at:
          </p>
          <p>Sophia Zhang</p>
          <p>sales@riversgarment.com</p>
          <br />

          <p>Thank you!</p>
          <br />
        </template>
        <template
          v-if="
            transportWay == 'OCEAN-FAST SHIP-UPS' ||
            transportWay == 'OCEAN-FAST SHIP-FEDEX'
          "
        >
          <p>
            We are pleased to inform you that your order, PO 12344-3, associated
            with our PI No. ORP230721830, has been shipped on 2023-06-08.
          </p>
          <br />

          <p>
            Please find below your tracking number(s). You could check the
            status of your order using the tracking number below
          </p>
          <p>
            <b>approx. 15-18 Calendar Days after shipped,</b>
            as we send this order by fast ocean shipment.
          </p>
          <br />

          <p>780149057076 by FedEx.</p>
          <br />
          <p>Shipping Address:</p>
          <p>519 N. Sycamore St Unit B Spokane, WA 99202</p>
          <emailTemplateTable />
          <p>
            Your order is shipped in 1 carton(s), and the estimated in-hand date
            is 2023-06-29.
          </p>
          <br />

          <p>Attached is the final invoice for your reference.</p>
          <br />

          <p>
            If you encounter any issues, contact us immediately for assistance
            at:
          </p>
          <p>Sophia Zhang</p>
          <p>sales@riversgarment.com</p>
          <br />

          <p>Thank you!</p>
          <br />
        </template>
        <template
          v-if="
            transportWay == 'OCEAN-STANDARD SHIP-UPS' ||
            transportWay == 'OCEAN-STANDARD SHIP-FEDEX'
          "
        >
          <p>
            We are pleased to inform you that your order, PO 12344-3, associated
            with our PI No. ORP230721830, has been shipped on 2023-06-08.
          </p>
          <br />

          <p>
            Please find below your tracking number(s). You could check the
            status of your order using the tracking number below
          </p>
          <p>
            <b>approx. 22-27 Calendar Days after shipped,</b>
            as we send this order by Standar ocean shipment.
          </p>
          <br />

          <p>780149057076 by FedEx.</p>
          <br />
          <p>Shipping Address:</p>
          <p>519 N. Sycamore St Unit B Spokane, WA 99202</p>
          <emailTemplateTable />
          <p>
            Your order is shipped in 1 carton(s), and the estimated in-hand date
            is 2023-06-29.
          </p>
          <br />

          <p>Attached is the final invoice for your reference.</p>
          <br />

          <p>
            If you encounter any issues, contact us immediately for assistance
            at:
          </p>
          <p>Sophia Zhang</p>
          <p>sales@riversgarment.com</p>
          <br />

          <p>Thank you!</p>
          <br />
        </template>
        <template
          v-if="
            transportWay == 'EXPRESS-DHL' ||
            transportWay == 'EXPRESS-UPS' ||
            transportWay == 'EXPRESS-FEDEX IP' ||
            transportWay == 'EXPRESS-FEDEX IE' ||
            transportWay == 'US-FEDEX' ||
            transportWay == 'US-UPS' ||
            transportWay == 'EXPRESS-SHUNFENG'
          "
        >
          <p>
            We are pleased to inform you that your order, PO 12344-3, associated
            with our PI No. ORP230721830, has been shipped on 2023-06-08.
          </p>
          <br />

          <p>Please find below your tracking number:</p>
          <p>780149057076 by FedEx.</p>
          <br />
          <p>Shipping Address:</p>
          <p>519 N. Sycamore St Unit B Spokane, WA 99202</p>
          <emailTemplateTable />
          <p>
            Your order is shipped in 1 carton(s), and the estimated in-hand date
            is 2023-06-29.
          </p>
          <br />

          <p>Attached is the final invoice for your reference.</p>
          <br />

          <p>
            If you encounter any issues, contact us immediately for assistance
            at:
          </p>
          <p>Sophia Zhang</p>
          <p>sales@riversgarment.com</p>
          <br />

          <p>Thank you!</p>
          <br />
        </template>
        <br />
        <p style="border-bottom: 2px dashed gray"></p>
        <br />

        <p>Best Regards,</p>
        <p>Rivers Garment, Inc.</p>
        <p>13360 Molette St.</p>
        <p>Santa Fe Springs, CA 90670</p>
        <p>https://www.riversgarment.com</p>
      </div>
      <!-- 收货邮件 -->
      <div>
        <br />
        <br />
        <br />
        <template v-if="!receiveEmailList.includes(transportWay)">
          <div>
            <el-row type="flex" align="center">
              <h4 style="color: #333">收货邮件</h4>
              <span style="color: red">（仅作为示例，不作为真实内容）</span>
            </el-row>
            <br />
            <el-row type="flex" align="center">
              <span style="text-indent: 15px; color: #999999">邮件标题：</span>
              <span>
                Your Order 12344-3 | PI- ORP230721830 Has Been Delivered. Please
                Review.
              </span>
            </el-row>
            <br />
            <el-row type="flex" align="center">
              <span style="text-indent: 15px; color: #999999">邮件内容：</span>
              <span>Hello,</span>
            </el-row>
            <br />

            <p>
              Our tracking number 780149057076 indicates that order ORP230721830
              has been delivered.
            </p>
            <emailTemplateTable />

            <p>
              Please follow up with your customer and share their feedback with
              us. If you encounter any issues, contact us
            </p>
            <p>immediately for assistance at:</p>
            <p>Tony@greenpearle.com</p>
            <p>Sophia Zhang</p>
            <br />

            <p>Thank you!</p>
            <br />
            <br />
            <p style="border-bottom: 2px dashed gray"></p>
            <br />

            <p>Best Regards,</p>
            <p>Rivers Garment, Inc.</p>
            <p>13360 Molette St.</p>
            <p>Santa Fe Springs, CA 90670</p>
            <p>https://www.riversgarment.com</p>
          </div>
        </template>
      </div>
    </div>

    <template #footer>
      <el-row class="text-right">
        <el-button type="primary" @click="dialogFormVisible = false">
          {{ lang === 'en' ? 'Confirm' : '确定' }}
        </el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import emailTemplateTable from './components/emailTemplateTable.vue'
  export default {
    name: 'EmailTemplate',
    components: {
      emailTemplateTable,
    },
    inject: ['isPad'],
    props: {
      transportWay: {
        //点击确定按钮是否自动关闭弹窗
        type: String,
        default: '',
      },
    },
    data() {
      return {
        dialogFormVisible: false,
        receiveEmailList: [
          'AIR-TRUCK',
          'OCEAN-FAST SHIP-TRUCK',
          'OCEAN-STANDARD SHIP-TRUCK',
        ], //不显示收货邮件的运输方式
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    methods: {},
  }
</script>

<style scoped lang="scss">
  .dialog-fixed {
    /deep/ {
      .el-dialog__header {
        border-bottom: 1px solid #d8d8d8;
      }
      .el-dialog__footer {
        border-top: 1px solid #d8d8d8;
        padding-top: 20px;
      }
    }
  }

  .dialog-content {
    height: 100%;
    overflow-y: auto;
    p,
    span {
      color: #666666;
    }
    p {
      margin-left: 85px;
      margin-right: 10px;
      word-break: break-word;
    }
  }
</style>
