<template>
  <el-dialog title="费用申请" width="1200px" class="custom-dialog" :visible.sync="costVisible" :close-on-click-modal="false"
    :before-close="close" append-to-body>
    <el-form ref="ruleForm" :model="form" :rules="rules">
      <div class="mb-10">
        <el-radio-group v-model="form.entryType" :disabled="notChange">
          <el-radio :label="0">费用申请</el-radio>
          <el-radio :label="1">费用退款</el-radio>
        </el-radio-group>
      </div>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item class="label-left" label="单据类型" prop="orderType" label-width="90px">
            <el-select v-model="form.orderType" placeholder="请选择" @change="orderTypeChange">
              <el-option label="销售订单" value="1" />
              <el-option label="采购订单" value="2" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item class="label-left" label="费用类型" prop="costTypeId" label-width="90px">
            <el-tooltip placement="top" effect="light">
              <i class="el-icon-warning-outline"></i>
              <div slot="content">
                <p>国内物流费：包括国内的快递、货拉拉、国内货运费用</p>
                <p>人工费：包含临时工的打包费、验货人工费用</p>
                <p>手续费：包含美金收款手续费、国内美金结汇手续费</p>
              </div>
            </el-tooltip>
            <el-select v-model="form.costTypeId" placeholder="请选择" @change="costTypeChange">
              <el-option v-for="item in costypeOptions" :key="item.financeSetupId" :label="item.financeName"
                :value="item.financeSetupId" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="支付方式" prop="payId" label-width="80px">
            <el-select v-model="form.payId" placeholder="请选择" @change="payChange()">
              <el-option v-for="item in paymentWay" :key="item.financeSetupId" :label="item.financeName"
                :value="item.financeSetupId" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="付款时间" prop="payTime" label-width="80px">
            <el-date-picker v-model="form.payTime" style="width: 100%" type="date" placeholder="年/月/日"
              value-format="yyyy-MM-dd" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="16">
          <el-form-item label="收款方" prop="shroffAccountId" label-width="90px">
            <el-autocomplete v-model.trim="codeOrName" :fetch-suggestions="querySearchAsync" placeholder="请输入"
              class="w100" @select="costtHandleSelect" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="费用币种" prop="currency" label-width="80px">
            <el-select v-model="form.currency" placeholder="请选择" @change="calcPayAmount">
              <el-option label="人民币" value="人民币" />
              <el-option label="美元" value="美元" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-table class="input-table" border width="100%" :data="form.costOrderList">
        <el-table-column label="#" width="60" align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="salesOrder" :label="form.orderType == 1 ? '销售订单号' : '采购订单号'" width="180" align="center"
          label-class-name="required-th">
          <template slot-scope="scope">
            <el-form-item v-if="form.orderType == 1" :prop="'costOrderList.' + scope.$index + '.salesOrderNumber'"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: 'blur',
                },
              ]">
              <el-input v-model="scope.row.salesOrderNumber" placeholder="请输入" @blur="getOrderMsg(scope)"
                @clear="clearNumber(scope)" clearable></el-input>
            </el-form-item>
            <el-form-item v-if="form.orderType == 2" :prop="'costOrderList.' + scope.$index + '.purchaseOrderNumber'"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: 'blur',
                },
              ]">
              <el-input v-model="scope.row.purchaseOrderNumber" placeholder="请输入" @blur="getOrderMsg(scope)"
                @clear="clearNumber(scope)" clearable></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column :prop="form.orderType == 1 ? 'businessName' : 'buyerName'"
          :label="form.orderType == 1 ? '客户代表' : '采购开发'" width="120" align="center"></el-table-column>
        <el-table-column prop="area" label="所属地区" align="center"></el-table-column>
        <el-table-column prop="currency" label="币种" align="center"></el-table-column>
        <el-table-column prop="orderTotalAmount" label="订单总金额" align="center">
          <template slot-scope="scope">
            {{

            scope.row.orderTotalAmount | orderMoneyFormat,

            }}
          </template>
        </el-table-column>
        <el-table-column prop="collectionAmount" :label="form.orderType == 1 ? '已收金额' : '已付金额'" align="center">
          <template slot-scope="scope">
            {{ scope.row.collectionAmount | orderMoneyFormat }}
          </template>
        </el-table-column>
        <el-table-column prop="uncollectedAmount" :label="form.orderType == 1 ? '未收金额' : '未付金额'" align="center">
          <template slot-scope="scope">
            {{ scope.row.uncollectedAmount | orderMoneyFormat }}
          </template>
        </el-table-column>
        <el-table-column :label="form.entryType == 0 ? '支付金额' : '退款金额'" align="center" label-class-name="required-th"
          width="180">
          <template slot-scope="scope">
            <el-form-item :prop="'costOrderList.' + scope.$index + '.payAmount'" :rules="[
                {
                  required: true,
                  pattern: /^[0-9]+(.[0-9]{1,2})?$/,
                  message: '>=0最多两位小数',
                  trigger: 'blur',
                },
              ]">
              <el-input v-model="form.costOrderList[scope.$index].payAmount" maxlength="10" placeholder="请输入" clearable
                @input="calcPayAmount" @change="calcProductMoney(scope.row)" />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80" align="center">
          <template slot-scope="scope">
            <el-button class="icon-r" icon="el-icon-delete" circle @click="deleteOrder(scope)" />
          </template>
        </el-table-column>
      </el-table>
      <div style="width: 100%; text-align: center">
        <el-button type="text" size="medium" @click="addLine">
          新增一行
        </el-button>
      </div>
      <p class="mt10 mb20 text-right">
        {{ form.entryType == 0 ? '支付' : '退款' }}总金额：
        <span class="f_s_16">
          ${{ form.totalPayAmount | orderMoneyFormat }}
        </span>
        <span class="f_s_16 ml10">
          ￥{{ form.totalPayRmbAmount | orderMoneyFormat }}
        </span>
      </p>
      <!-- 产品分摊设置 -->
      <div>
        <div style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          ">
          <div>
            <h4 style="display: inline-block; margin-right: 10px">
              产品分摊设置
            </h4>
            <!-- <small>Tips：若产品不分摊费用，请填写“0”</small> -->
            <small v-if="form.orderType == 1">
              Tips：若产品不分摊费用，请填写“0”
            </small>
            <small v-if="form.orderType == 2">
              Tips：费用审核通过后，按需采购的费用会关联到销售订单产品内，备货采购费用计入库存内；
            </small>
          </div>
          <el-form-item prop="expenseMethod" label="分摊方式" label-width="80px">
            <el-select v-model="form.expenseMethod" placeholder="请选择" @change="changeExpense">
              <el-option v-for="item in expenseMethodOptions" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="product-table">
          <el-table class="input-table" :data="form.productList" style="width: 100%" border>
            <el-table-column label="#" width="60" align="center">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column prop="salesOrder" label="销售单号" width="180" align="center"
              v-if="form.orderType == 1"></el-table-column>
            <el-table-column prop="purchaseOrder" label="采购订单号" width="120" align="center"
              v-if="form.orderType == 2"></el-table-column>
            <el-table-column prop="purchasingDemandNumber" label="采购需求单号" width="180" align="center"
              v-if="form.orderType == 2" :key="tableKey"></el-table-column>
            <el-table-column label="产品" width="180" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <div style="overflow: hidden">
                  {{ scope.row && scope.row.sku }}/{{ scope.row && scope.row.productNameCn }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="numPurchase" label="本次采购数量" align="center"
              v-if="form.orderType == 2"></el-table-column>
            <el-table-column prop="amountIncludingTax" label="总计含税" align="center" v-if="form.orderType == 2">
              <template slot-scope="scope">
                {{ utils.numberFormat(scope.row.amountIncludingTax, 2) }}
              </template>
            </el-table-column>
            <el-table-column prop="amountExcludingTax" label="总计未税" align="center" v-if="form.orderType == 2">
              <template slot-scope="scope">
                {{ utils.numberFormat(scope.row.amountExcludingTax, 2) }}
              </template>
            </el-table-column>
            <el-table-column prop="productNum" label="订单数量" align="center" v-if="form.orderType == 1"></el-table-column>
            <el-table-column prop="totalProductAmount" label="产品金额总计" align="center" v-if="form.orderType == 1">
              <template slot-scope="scope">
                {{ scope.row.totalProductAmount | orderMoneyFormat }}
              </template>
            </el-table-column>
            <el-table-column label="分摊费用" align="center">
              <template slot-scope="scope">
                <el-form-item :prop="'productList.' + scope.$index + '.expense'" :rules="[
                    {
                      required: false,
                      pattern: /^[0-9]+(.[0-9]{1,2})?$/,
                      message: '>=0最多两位小数',
                      trigger: 'blur',
                    },
                  ]">
                  <el-input v-model="scope.row.expense" placeholder="请输入"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="12">
          <el-form-item prop="remark" label="备注" :rules="[
              {
                required: form.entryType == 1,
                message: '请输入',
                trigger: 'blur',
              },
            ]">
            <el-input v-model="form.remark" type="textarea" maxlength="500" show-word-limit :rows="3"
              placeholder="请输入500个字符以内" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <p>凭证/附件</p>
          <ErpUpload ref="ErpUpload" :upload-params="uploadParams" @uploadList="uploadList" />
        </el-col>
      </el-row>
    </el-form>

    <div class="text-center mt30">
      <el-button type="primary" @click="save">确认</el-button>
      <el-button @click="close()">取消</el-button>
    </div>

    <!-- 审核模板-->
    <AuditChoose ref="AuditChoose" @auditData="saveClick" />
  </el-dialog>
</template>

<script>
  import {
    listShorffAccount,
    listShorffAccountFee,
  } from '@/api/finance/finance-cost'
  import { orderCostApply, preExpenseApplication } from '@/api/order'
  import { CostManageInteractor } from '@/core/interactors/finance/cost-manage'
  import AuditChoose from '@/components/AuditChoose'

  import NoViewCost from '@/views/purchase/purchaseOrder/components/no-view-cost'
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  export default {
    name: 'CostApply',
    components: {
      AuditChoose,
      NoViewCost,
    },
    props: {
      isViewCost: {
        type: Boolean,
        default: false,
      },
      costVisible: {
        type: Boolean,
        default: false,
      },
      costCode: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        form: {
          /**
           * 基本信息
           */
          orderType: '1', // 订单类型(1销售订单，2采购订单)
          costTypeId: '', // 费用类型id
          costTypeName: '', // 费用类型名称
          payId: '', // 支付方式id
          payName: '', // 支付方式名称
          payTime: '', // 付款时间
          annex: '', // 附件
          remark: '', // 备注
          currency: '', // 币种
          shroffAccountId: '', // 收款方id
          costOrderList: [], // 费用申请列表
          totalPayAmount: 0, // 支付总金额
          totalPayRmbAmount: 0, //	支付总金额(人民币)
          auditObjectDTO: {}, // 审核模板
          entryType: 0,
          expenseMethod: '', // 分摊方式
          productList: [], //产品分摊列表
        },
        source: '', // 入口来源
        exchangeRate: null, // 汇率
        costypeOptions: [], // 费用类型
        paymentWay: [], // 支付方式
        payeeOptions: [], // 收款方下拉数据
        rules: {
          currency: [
            { required: true, message: '请选择费用币种', trigger: 'change' },
          ],
          costTypeId: [
            { required: true, message: '请选择费用类型', trigger: 'change' },
          ],
          payId: [
            { required: true, message: '请选择支付方式', trigger: 'change' },
          ],
          payTime: [
            { required: true, message: '请选择付款时间', trigger: 'change' },
          ],
          shroffAccountId: [
            { required: true, message: '请选择收款方', trigger: 'change' },
          ],
          expenseMethod: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
          orderType: [{ required: true, message: '请选择', trigger: 'change' }],
        },
        uploadParams: {
          amount: 9,
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        codeOrName: '',
        triggerBol: false,
        expenseMethodOptions: [
          // 分摊方式
          {
            label: '按数量',
            value: 1,
          },
          {
            label: '按金额比例',
            value: 2,
          },
          {
            label: '手动分摊',
            value: 3,
          },
        ],
        notChange: false,
        costId: '',
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      tableKey() {
        return Math.random()
      },
    },
    watch: {
      costVisible(newval, oldval) {
        if (newval) {
          this.initLoading()
          this.notChange = false
        }
      },
      // 是否是重新发起
      costCode(newval, oldval) {
        if (newval) {
          this.notChange = true
          this.getDetail()
        }
      },
    },
    methods: {
      // 初始化加载数据
      initLoading() {
        // 获取费用类型
        this.getCostType()
        // 获取支付方式
        this.getPaymentWay()
        // 获取汇率
        this.getExchangeRate()
        // 获取收款人
        this.getPayeeOptions()
      },
      // 重新提交 获取详情
      async getDetail() {
        let res = await CostManageInteractor.getDetailForReissue(this.costCode)
        if (res?.code === '000000') {
          for (const key in this.form) {
            this.form[key] = res.data[key]
          }
          let form = res.data
          const nameArr = [
            form.supplierName,
            form.accountName,
            form.branchName,
            form.bankAccount,
            form.dutyAccount,
          ].filter((item) => item)
          this.codeOrName = nameArr.join('/')
          let productArr = []
          form.costOrderList.forEach((it) => {
            productArr = productArr.concat(it.costProductExpenseVOList)
          })
          this.$set(this.form, 'productList', productArr)
          // this.form.productList.forEach((it) => {
          //   this.$set(it, 'expense', '')
          // })
          this.form.costOrderList.forEach((it) => {
            this.$set(
              it,
              'costProductExpenseDTOList',
              it.costProductExpenseVOList
            )
          })
          this.costId = form.costId

          this.$refs.ErpUpload.initUpload(form.annex)
        }

      },

      //收款方搜索
      async querySearchAsync(queryString, cb) {
        this.triggerBol = true
        //if (!this.codeOrName) return
        await listShorffAccountFee({ cusName: this.codeOrName }).then((res) => {
          let results = res.data.map((item) => {
            return {
              ...item,
              value: item.assembleStr,
            }
          })
          cb(results)
        })
      },

      // 选择付款方
      costtHandleSelect(item) {
        if (item.shroffAccountId) {
          this.form.shroffAccountId = item.shroffAccountId
        }
      },

      // 费用类型
      async getCostType() {
        this.costypeOptions = await this.Dicts.getFinanceData(5)
      },

      // 支付方式
      async getPaymentWay() {
        this.paymentWay = await this.Dicts.getFinanceData(3)
      },

      // 收款人
      async getPayeeOptions() {
        let response = await listShorffAccount({ cusName: '' })
        this.payeeOptions = response.data
      },

      // 汇率
      async getExchangeRate() {
        this.exchangeRate = await this.Dicts.getExchange('美元')
      },

      // 获取上传文件地址
      uploadList(fileList) {
        this.form.annex = JSON.stringify(fileList)
      },

      // 单据类型改变
      orderTypeChange() {
        this.form.costOrderList = []
        this.form.productList = []
        this.form.totalPayAmount = 0
        this.form.totalPayRmbAmount = 0
      },

      // 费用类型选择
      costTypeChange(val) {
        this.costypeOptions.forEach((item) => {
          if (item.financeSetupId == val) {
            this.form.costTypeName = item.financeName
            this.form.expenseMethod = item.expenseMethod
          }
        })
        this.changeExpense()
      },

      // 支付方式选择
      payChange() {
        this.paymentWay.find((item) => {
          if (item.financeSetupId == this.form.payId) {
            this.form.payName = item.financeName
          }
        })
      },

      // 计算支付总金额
      calcPayAmount() {
        if (this.form.currency == '美元') {
          this.form.totalPayAmount = this.utils.amountTotal(
            this.form.costOrderList,
            'payAmount',
            3
          )
          this.form.totalPayRmbAmount = (
            this.form.totalPayAmount * this.exchangeRate
          ).toFixed(3)
        } else {
          this.form.totalPayRmbAmount = this.utils.amountTotal(
            this.form.costOrderList,
            'payAmount',
            3
          )
          this.form.totalPayAmount = (
            this.form.totalPayRmbAmount / this.exchangeRate
          ).toFixed(3)
        }
      },

      // 保存
      save() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            if (!this.form.costOrderList.length) {
              this.$message.warning('请至少添加一条数据！')
              return false
            }
            if (this.form.totalPayRmbAmount.length > 10) {
              this.$message.warning('支付总金额不能超过10位数！')
              return false
            }
            for (let i = 0; i < this.form.costOrderList.length; i++) {
              const element = this.form.costOrderList[i]
              let totalPay = 0
              element.costProductExpenseDTOList.forEach((item) => {
                totalPay += Number(item.expense)
              })
              if (element.payAmount != _.round(totalPay, 3)) {
                this.$message.warning(
                  `${element.salesOrderNumber || element.purchaseOrderNumber
                  }产品费用分摊不相等！`
                )
                return
              }
            }
            let auditParams = {
              auditType: 8,
              status: 0,
              tenantId: this.userInfo.tenantId,
            }
            this.pubApi.getAuditTemplates(auditParams).then((response) => {
              if (!response.data.length) {
                this.$baseMessage(
                  '请联系管理员配置审核模板',
                  'warning',
                  false,
                  'erp-hey-message-warning'
                )
              } else {
                this.$refs['AuditChoose'].showAddEdit(auditParams)
              }
            })
          }
        })
      },

      // 保存审核模板信息
      saveClick(data) {
        this.form.auditObjectDTO = data
        this.saveRule()
      },

      // 保存校验
      saveRule() {
        const params = {
          ...this.form,
          costId: this.costId ? this.costId : null,
        }
        //采购订单费用申请需要查询库存是否满足需求
        this.saveCost(params)
      },

      // 保存费用申请
      saveCost(params) {
        params = JSON.parse(JSON.stringify(params))
        delete params.productList
        orderCostApply(params).then((res) => {
          if (res && res.code == '000000') {
            this.$message.success('保存成功！')
            this.$emit('success')
            this.$emit('refresh')
            this.close()
          }
        })
      },

      // 删除d订单
      deleteOrder(scope) {
        this.$confirm(`是否确认删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.form.costOrderList.splice(scope.$index, 1)
            this.$message.success('删除成功！')
            this.calcPayAmount()
            this.deleteProduct(scope)
          })
          .catch(() => { })
      },
      // 删除产品
      deleteProduct(scope) {
        if (this.form.orderType == 1) {
          this.form.productList = this.form.productList.filter((item) => {
            return item.salesOrder != scope.row.salesOrderNumber
          })
        }
        if (this.form.orderType == 2) {
          this.form.productList = this.form.productList.filter((item) => {
            return item.purchaseOrder != scope.row.purchaseOrderNumber
          })
        }
      },

      // 关闭弹窗重置表单
      close() {
        // this.costVisible = false
        this.$emit('update:costVisible', false)
        this.$emit('update:costCode', '')
        this.form = this.$options.data().form
        this.$refs.ruleForm.resetFields()
        this.$refs.ErpUpload.uploadListEmpty()
        this.codeOrName = ''
      },
      // 新增一行
      addLine() {
        let state = false
        if (this.form.orderType == 1) {
          state = this.form.costOrderList.every((item) => item.salesOrderNumber)
        }
        if (this.form.orderType == 2) {
          state = this.form.costOrderList.every(
            (item) => item.purchaseOrderNumber
          )
        }
        if (state) {
          this.form.costOrderList.push({})
        } else {
          this.$message.warning('单号为空，不可添加下一条！')
        }
      },
      // 根据单号获取信息
      async getOrderMsg(scope) {
        // 销售订单
        if (this.form.orderType == 1) {
          if (!scope.row.salesOrderNumber) return
          let orderList = this.form.costOrderList.map(
            (item) => item.salesOrderNumber
          )
          if (orderList.indexOf(scope.row.salesOrderNumber) < scope.$index) {
            this.$message.warning('单号不可重复添加！')
            this.$set(
              this.form.costOrderList[scope.$index],
              'salesOrderNumber',
              ''
            )
            return
          }
        }

        // 采购订单
        if (this.form.orderType == 2) {
          if (!scope.row.purchaseOrderNumber) return
          let orderList = this.form.costOrderList.map(
            (item) => item.purchaseOrderNumber
          )
          if (orderList.indexOf(scope.row.purchaseOrderNumber) < scope.$index) {
            this.$message.warning('单号不可重复添加！')
            this.$set(
              this.form.costOrderList[scope.$index],
              'purchaseOrderNumber',
              ''
            )
            return
          }
        }

        let res = await CostManageInteractor.getListCostApplyByOrder(
          this.form.orderType,
          scope.row
        )
        if (res?.code === '000000') {
          this.form.costOrderList = this.form.costOrderList.concat(res.data)
          this.form.costOrderList.splice(scope.$index, 1)
          this.form.productList = []
          this.form.costOrderList.forEach((item) => {
            this.form.productList = this.form.productList.concat(
              item.costProductExpenseDTOList
            )
          })
        } else {
          this.$set(
            this.form.costOrderList[scope.$index],
            'salesOrderNumber',
            ''
          )
          this.$set(
            this.form.costOrderList[scope.$index],
            'purchaseOrderNumber',
            ''
          )
          this.clearNumber(scope)
        }
      },
      // 计算产品分摊的金额
      calcProductMoney(row) {
        // 销售订单和采购订单需要的字段不同

        let numType = this.form.orderType == 1 ? 'productNum' : 'numPurchase'
        let moneyType =
          this.form.orderType == 1 ? 'totalProductAmount' : 'amountIncludingTax'
        // 当只有一个产品时，不管分摊方式
        if (row?.costProductExpenseDTOList?.length === 1) {
          this.$set(row.costProductExpenseDTOList[0], 'expense', row.payAmount)
        } else {
          // 产品超过一个
          if (this.form.expenseMethod == 1) {
            // 按数量分
            let totalAmount = 0
            row.costProductExpenseDTOList.forEach((ele) => {
              totalAmount += ele[numType]
            })
            // (row.payAmount * (ele[numType] / totalAmount)).toFixed(3)
            row.costProductExpenseDTOList.forEach((ele) => {
              this.$set(
                ele,
                'expense',
                _.round(row.payAmount * (ele[numType] / totalAmount), 2)
              )
            })

            // 拿到所有产品的总的分摊金额
            let totalProductPay = 0
            row.costProductExpenseDTOList.forEach((item) => {
              totalProductPay += item.expense
            })

            // 判断总金额是否等于所有产品的金额
            if (totalAmount != totalProductPay) {
              let mistake = row.payAmount - totalProductPay
              // 最后一项
              let ele =
                row.costProductExpenseDTOList[
                row.costProductExpenseDTOList.length - 1
                ]
              this.$set(
                ele,
                'expense',
                _.round(
                  (row.payAmount * ele[numType]) / totalAmount + mistake,
                  2
                )
              )
            }
          }
          // 按金额分
          if (this.form.expenseMethod == 2) {
            let totalPay = 0
            if (!row.costProductExpenseDTOList) return
            row.costProductExpenseDTOList.forEach((ele) => {
              totalPay += ele[moneyType] * 1000
            })
            totalPay = totalPay / 1000
            // (row.payAmount * (ele[moneyType] / totalPay)).toFixed(3)
            row.costProductExpenseDTOList?.forEach((ele) => {
              this.$set(
                ele,
                'expense',
                _.round(row.payAmount * (ele[moneyType] / totalPay), 2)
              )
            })
            // 拿到所有产品的总的分摊金额
            let totalProductPay = 0
            row.costProductExpenseDTOList.forEach((item) => {
              totalProductPay += item.expense
            })
            // 判断总金额是否等于所有产品的金额
            if (totalPay != totalProductPay) {
              let mistake = row.payAmount - totalProductPay
              // 最后一项
              let ele =
                row.costProductExpenseDTOList[
                row.costProductExpenseDTOList.length - 1
                ]
              this.$set(
                ele,
                'expense',
                _.round(
                  (row.payAmount * ele[moneyType]) / totalPay + mistake,
                  2
                )
              )
            }
          }
          // 手动分摊，清空
          if (this.form.expenseMethod == 3) {
            row.costProductExpenseDTOList.forEach((ele) => {
              this.$set(ele, 'expense', '')
            })
          }
        }
      },
      // 切换分摊方式
      changeExpense() {
        this.form.costOrderList.forEach((item) => {
          this.calcProductMoney(item)
        })
      },
      // 清空单号
      clearNumber(scope) {
        if (this.form.orderType == 1) {
          this.form.productList = this.form.productList.filter((item) => {
            return (
              item?.salesOrder !=
              scope?.row?.costProductExpenseDTOList?.[0]?.salesOrder
            )
          })
        }
        if (this.form.orderType == 2) {
          this.form.productList = this.form.productList.filter((item) => {
            return (
              item.purchaseOrder !=
              scope.row.costProductExpenseDTOList[0]?.purchaseOrder
            )
          })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-select-dropdown {
    width: 1080px;
  }

  .custom-dialog {

    .el-input,
    .el-select {
      width: 100%;
    }

    .el-form-item {
      margin-bottom: 18px;
    }

    p {
      margin: 0 0 10px;
    }

    .el-tooltip {
      position: absolute;
      top: 9px;
      left: -19px;
      cursor: pointer;
    }
  }

  ::v-deep {

    // .el-dialog__body {
    //   max-height: 650px;
    //   overflow-y: scroll;
    // }
    .el-form {
      max-height: 550px;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .el-upload--picture-card {
      width: 100px;
      height: 100px;
    }

    .el-icon-plus {
      position: relative;
      top: -20px;
    }

    .el-upload-list__item {
      width: 100px;
      height: 100px;
    }

    .el-upload-list__item-thumbnail {
      width: 100px;
      height: 100px;
    }

    .el-upload-list__item-actions {
      width: 100px;
      height: 100px;
    }
  }

  .mb-10 {
    margin-bottom: 10px;
  }
</style>
